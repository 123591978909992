import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import Sidebar from '../../Layout/Sidebar/Sidebar';
import ContactOrganisation from './ContactOrganisation';
import ContactList from './ContactList';
import { getLandCareData, landCareStartLoading, landCareStopLoading } from '../../../Store/Reducers/LandCareSlice';
import { landCareSelector, selectCurrentLandcare, selectOrgUsers, selectUserOrg, selectUserOrgContacts } from '../../../Store/Selectors/landCareSelectors';
import { DetailRecordTypes, loaderStyle, waitingText } from '../../../Helpers/constants';
import { selectUserProfile } from '../../../Store/Selectors/rootSelector';
import PageTitle from '../../Layout/PageTitle/PageTitle';
import { ILandCareUserOrgContact } from '../../../Models/LandCare/LandCare';
import { getUserProfile } from '../../../Store/Reducers/LocalStoreSlice';

import './Contact.scss';

const Contact : React.FC<any> = (props) => {
    const contacts = useSelector(selectUserOrgContacts);

    const orgUsers = useSelector(selectOrgUsers);
    const userOrgContacts = useSelector(selectUserOrgContacts);
    const currentLandCare = useSelector(selectCurrentLandcare);
    
    // find the first primary contact ; if none then null
    let contact = userOrgContacts.find((c: ILandCareUserOrgContact) => c && c.isPrimary) || null;
    if (!contact) {
        contact = userOrgContacts.length ? userOrgContacts[0] : null;
    }

    const organisation = {...useSelector(selectUserOrg), ...contact};
    
    const { isLoading } = useSelector(landCareSelector);
    const userProfile = useSelector(selectUserProfile);
    const isAuthenticated = useIsAuthenticated();

    const dispatch = useDispatch();
    const history = useHistory();

    let userOrgId = '';

    let accessToken = '';

    useEffect(() => {
        if (!Object.keys(userProfile).length) {
          const refreshObj = async () =>  {
            accessToken = await props.getAccessToken();
            if (accessToken && !Object.keys(userProfile).length) {
              await dispatch(getUserProfile(accessToken, history));
            }
          };
          refreshObj();
        }
    }, [isAuthenticated]);
    
    useEffect(() => {
        dispatch(landCareStartLoading());

        const refreshObj = async () => {
            const urlIDBlock = window.location.pathname.split('/')[3];

            accessToken = await props.getAccessToken();

            if (accessToken && userProfile.id) {
                await dispatch(getLandCareData(accessToken, userProfile.id, urlIDBlock, [DetailRecordTypes.CONTACTS]));  
                dispatch(landCareStopLoading());
            }
      }
      refreshObj();
    }, [props.getAccessToken, userProfile.id]);
    
    if (currentLandCare.landHolding && currentLandCare.landHolding.length)
        userOrgId = currentLandCare.landHolding[0].organisationId;

    return (
        <Container fluid className="gerx-contents-panel">
            <Row>
                <Col>
                    <Sidebar key="sidebar" highlight='contact' active={'active'}>
                        <Row>
                            <Col>
                            <PageTitle title={'Organisation: ' + (currentLandCare?.landHoldingUserOrg?.userOrgName ? currentLandCare.landHoldingUserOrg.userOrgName : '')} />
                            </Col>
                        </Row>
                        <LoadingOverlay className="row" active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                            <Container fluid>
                                <ContactOrganisation organisation={organisation} />
                                <ContactList getAccessToken={props.getAccessToken} contacts={contacts} userOrgId={userOrgId} />
                            </Container>
                        </LoadingOverlay>
                    </Sidebar>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;