import React from 'react';

import './PageTitle.scss';

interface IPageTitle {
    title: string;
}

const PageTitle : React.FC<any> = (props: IPageTitle) => {
    return (
        <div className="page-title">
            {props.title}
            <hr />
         </div>
    );
};

export default PageTitle;