import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../App/App.scss';

let PolygonPointsOverview : React.FC<any> = (props) => {
  {console.log('polygon comp in Overview: ', props.landHolding)}
  return (
    <div className="longitude-points">
      {props.landHolding !== 0 && props.landHolding.mapGraphItems && props.landHolding.mapGraphItems.map((graphItem: any, idx: any) => {
        return (
          <div key={idx}>
            {console.log('polygon lat/lon: ', graphItem.centerPoint )}
            <div className="title-break"> Property-{idx + 1}: <br/>[{graphItem.centerPoint ?  'Lat:' + graphItem.centerPoint[0].toString().slice(0, -3) : 'Lat: NA'}, {graphItem.centerPoint? 'Lon:' + graphItem.centerPoint[1].toString().slice(0, -3) : 'Lon: NA'}]</div>
          </div>
        )
      })}
    </div>
  );
}

export default PolygonPointsOverview;