import * as yup from 'yup';
import { IValidationField } from '../Validator/validationHelper';

export const contactsFieldList: IValidationField[] = [
    {
        name: 'firstName',
        title: 'First Name',
        width: '10%',
        validation: yup.string().required().min(2).max(150)
    },
    {   name: 'lastName',
        title: 'Last Name',
        width: '10%',
        validation: yup.string().required().min(2).max(150)
    },
    {   name: 'position',
        title: 'Position',
        width: '10%',
        validation: yup.string().min(2).max(150)
    },
    {   name: 'email',
        title: 'Email',
        width: '10%',
        validation: yup.string().required().email()
    },
    {   name: 'mobile',
        title: 'Mobile',
        width: '10%',
        validation: yup.string()
    },
    {   name: 'phone',
        title: 'Phone',
        width: '10%',
        validation: yup.string()
    },
    {   name: 'notes',
        title: 'Notes',
        width: '40%',
        validation: yup.string()
    }
];

// const validationSchema: any = 
//     contactFieldList.reduce((field: IContactFieldList, obj: any) => ({...obj, [field.name]: field.validation }));

let validationSchema: any = {};

contactsFieldList.forEach((field: IValidationField) => {
    validationSchema[field.name] = field.validation;
});

export const contactsSchema = yup.object().shape(validationSchema);