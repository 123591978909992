import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../RootStore';
import _, { findLast } from 'lodash';
import { ILandCarer, ILandCarers, ILandCareUserOrg, ILandCarerNote, IPlantingSite, IUserOrgContact, ILandCareUserOrgContact, IlandCarerNoteContactUpdate, ILandCareFilePatchResponse } from '../../Models/LandCare/LandCare';
import { ILandholding } from '../../Models/LandHoldings/LandHoldings';
import {getAllLandCaresFromApi, getLandCaresByIdFromApi, getAllLandCaresFromApiAuto, getLandCaresWithPlantingsById, getLandHoldingByIdFromApi, patchLandCarerNote, fetchUserOrgContacts, addLandCarerNotePost, deleteLandCarerNotee, patchLandCarerNoteContact, uploadMediaFilesById, downloadMediaFilesById, deleteMediaFilesById, fetchLandCarerFiles, patchMediaFilesById, patchPlantingSite, patchLandCare, postLandCareUserStatus, postLandCareChatMessageReadNotification, putLandholdingMap, postUserOrg, putUserOrg, postUserOrgContact} from '../Api/LandCareApi';
import { fetchLandCarerNotes } from '../Api/LandCareApi';
import { saveUserOrgContact, removeUserOrgContact, addUserOrgContact, patchUserOrgContact} from '../Api/LandCareApi';
import { RootState } from "../RootStore";
import { DetailRecordTypes, ModiaFileModules } from '../../Helpers/constants';
import { getLandCareChatSummaries } from './LandCareChatSlice';
import { IMediaFile } from '../../Models/MediaFiles/IMediaFile';
import { IModulePageInfoPayload, ModulesEnum, setModulePageInfo } from './LocalStoreSlice';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { getPolygonFiles } from '../Api/LandHoldingsApi';

export interface ILandCareState {
  isLoading: boolean;
  isLoadingAC: boolean;
  showList: boolean;
  error: any;
  currentLandCare: ILandCarer;
  currentLandCareRef: ILandCarer;
  currentLandHolding: ILandholding;
  LandCare: ILandCarers[];
  AutoLandCare: ILandCarers[];
  searchResults: any;
  newStatusAuto: boolean;
  inProgressStatusAuto: boolean;
  monitoringStatusAuto: boolean;
  implementationStatusAuto: boolean;
  closedStatusAuto: boolean;
  deletedStatusAuto: boolean;
  currentPlantingSite: IPlantingSite;
  currentPlantingSiteRef: IPlantingSite;
  landCarerUserOrgContact: IUserOrgContact;
  landCarerNotes: ILandCarerNote[];
  userOrg: ILandCareUserOrg;
  orgUsers: ILandCareUserOrgContact[];
  userOrgContacts: ILandCareUserOrgContact[];
  fileUpload: IMediaFile[];
  landCarerFiles: IMediaFile[];
  polygonFiles: IMediaFile[];
}

const initialState : ILandCareState = {
  isLoading: false,
  isLoadingAC: false,
  showList: false,
  error: null,
  currentLandCare: {} as ILandCarer,
  currentLandCareRef: {} as ILandCarer,
  currentLandHolding: {} as ILandholding,
  LandCare: [],  
  AutoLandCare: [],
  searchResults: [],
  newStatusAuto: false,
  inProgressStatusAuto: false,
  monitoringStatusAuto: false,
  implementationStatusAuto: false,
  closedStatusAuto: false,
  deletedStatusAuto: false,
  currentPlantingSite: {} as IPlantingSite,
  currentPlantingSiteRef: {} as IPlantingSite,
  landCarerUserOrgContact: {} as IUserOrgContact,
  landCarerNotes: [],
  userOrg: {} as ILandCareUserOrg,
  orgUsers: [],
  userOrgContacts: [],
  fileUpload: [],
  landCarerFiles: [],
  polygonFiles: []
};

const LandCareSlice = createSlice({
  name: "LandCare",
  initialState: initialState,
  reducers: {
    landCareStartLoading(state) {
      state.isLoading = true
    },
    landCareStopLoading(state) {
      state.isLoading = false
    },
    getAutoCompleteStart(state: ILandCareState) {
      state.isLoadingAC = true
    },
    landCareFailed(state: ILandCareState, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = action.payload
    },
    getAutoCompleteShow(state: ILandCareState) {
      state.showList = true
    },
    getAutoCompleteHide(state: ILandCareState) {
      state.showList = false
    },
    addToStatusNew(state: ILandCareState) {
      state.newStatusAuto = true
    },
    addToStatusInProgress(state: ILandCareState) {
      state.inProgressStatusAuto = true
    },
    addToStatusMonitoring(state: ILandCareState) {
      state.monitoringStatusAuto = true
    },
    addToStatusImplementation(state: ILandCareState) {
      state.implementationStatusAuto = true
    },
    addToStatusClosed(state: ILandCareState) {
      state.closedStatusAuto = true
    },
    addToStatusDeleted(state: ILandCareState) {
      state.deletedStatusAuto = true
    },
    updateLandholding(state, {payload}: PayloadAction<any>) {
      const neededSite = payload.currentLHList.find((site: any) => site.id === payload.selectedLH);
      state.currentLandHolding = neededSite;
      state.error = null;  
    },
    startLoadingState(state, {payload}: PayloadAction<any>) {
      state.isLoading = payload;
    },
    stopLoadingState(state) {
      state.isLoading = false;
    },
    getLandCareByIdSuccess(state, {payload}: PayloadAction<ILandCarer>) {
      state.searchResults = payload;
      state.error = null;
    },
    addUserOrgContactSuccess(state, {payload}: PayloadAction<ILandCareUserOrgContact>) {
      state.userOrgContacts.splice(0, 0, payload);
      state.error = null;
    },
    addUserOrgContactFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    saveUserOrgContactSuccess(state, {payload}: PayloadAction<ILandCareUserOrgContact>) {
      let index = state.userOrgContacts.findIndex((c: ILandCareUserOrgContact) => c.id === payload.id);
      state.userOrgContacts[index] = payload;
      state.error = null;
    },
    saveUserOrgContactFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    updateUserOrgContactFieldSuccess(state, {payload}: PayloadAction<ILandCareUserOrgContact>) {
      let index = state.userOrgContacts.findIndex((c: ILandCareUserOrgContact) => c.id === payload.id);
      const contact = { ...state.userOrgContacts[index], ...payload };
      state.userOrgContacts[index] = contact;

      state.error = null;
    },
    updateUserOrgContactFieldFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    removeUserOrgContactSuccess(state, {payload}: PayloadAction<string>) {
      let index = state.userOrgContacts.findIndex((c: ILandCareUserOrgContact) => c.id === payload);
      state.userOrgContacts.splice(index, 1);
      state.error = null;
    },
    removeUserOrgContactFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    getLandHoldingCurrent(state, {payload}: PayloadAction<ILandholding>) {
      state.currentLandHolding = payload;
      state.error = null;
    },
    getLandCareSuccess(state, {payload}: PayloadAction<any>) {
      state.currentLandCare = { ...(state.currentLandCare), ...payload };
      state.currentLandCareRef = state.currentLandCare;
      
      if (payload?.plantingSites.length) {
        // if the currentPlantingSite is not set
        if (!state.currentPlantingSite?.id) { 
          state.currentPlantingSite = payload.plantingSites[0];
          state.currentPlantingSiteRef = payload.plantingSites[0];
        }
      }
      else {
        state.currentPlantingSite = {} as IPlantingSite;
        state.currentPlantingSiteRef = {} as IPlantingSite;
      }

      state.error = null;
    },

    setLandCareField(state, {payload}: PayloadAction<ILandCarer>) {
      const landCare = { ...(state.currentLandCare), ...payload };
      state.currentLandCare = landCare;
    },
    patchLandCareSuccess(state, {payload}: PayloadAction<ILandCarer>) {
      const landCare = { ...(state.currentLandCare), ...payload };
      state.currentLandCare = landCare;
      state.currentLandCareRef = landCare;
      state.error = null;
    },
    patchLandCareFailed(state: ILandCareState, {payload}: PayloadAction<any>) {
      state.currentLandCare = {} as ILandCarer;
      state.currentLandCareRef = {} as ILandCarer;
      state.error = payload;
    },
 
    resetCurrentLandCare(state: ILandCareState) {
      state.currentLandCare = {} as ILandCarer;
      state.currentLandCareRef = {} as ILandCarer;
      state.currentLandHolding = {} as ILandholding;
      state.currentPlantingSite = {} as IPlantingSite;
      state.currentPlantingSiteRef = {} as IPlantingSite;
      state.landCarerFiles = [];
    },

    setCurrentPlantingSiteSuccess(state: ILandCareState, {payload}: PayloadAction<any>) {
      state.currentPlantingSite = payload;
      state.currentPlantingSiteRef = payload;
      state.error = null;
    },
    setPlantingSiteField(state, {payload}: PayloadAction<IPlantingSite>) {
      const plantingSite = { ...(state.currentPlantingSite), ...payload };
      state.currentPlantingSite = plantingSite;
    },

    patchPlantingSiteSuccessShallow(state, {payload}: PayloadAction<IPlantingSite>) {
      const plantingSite = { ...(state.currentPlantingSite), ...payload };
      state.currentPlantingSite = plantingSite;
      const index = state.currentLandCare.plantingSites.findIndex((site: IPlantingSite) => site.id === plantingSite.id);
      state.currentLandCare.plantingSites[index] = plantingSite
    },

    patchPlantingSiteSuccess(state, {payload}: PayloadAction<IPlantingSite>) {
      const plantingSite = { ...(state.currentPlantingSite), ...payload };
      state.currentPlantingSite = plantingSite;
      state.currentPlantingSiteRef = plantingSite;
      const index = state.currentLandCare.plantingSites.findIndex((site: IPlantingSite) => site.id === plantingSite.id);
      state.currentLandCare.plantingSites[index] = plantingSite
      state.error = null;
    },
    patchPlantingSiteFailed(state: ILandCareState, {payload}: PayloadAction<any>) {
      //state.currentPlantingSite = {} as IPlantingSite;
      //state.currentPlantingSiteRef = {} as IPlantingSite;
      state.error = payload;
    },
    addPlantingSiteSuccess(state: ILandCareState, {payload}: PayloadAction<IPlantingSite>) {
      state.currentLandCare.plantingSites.push(payload);      
    },

    getAllLandCareSuccess(state, {payload}: PayloadAction<ILandCarers[]>) {
      state.LandCare = payload;
      state.error = null;
    },
    searchForLandCare(state, {payload}: PayloadAction<any>) {
      state.LandCare = payload;
      state.error = null;
    },
    searchForLandCareAuto(state, {payload}: PayloadAction<any>) {
      state.AutoLandCare = payload;
      state.isLoadingAC = false;
      state.error = null;
    },
    getSearchResultsList(state, {payload}: PayloadAction<any>) {
      state.searchResults = _.concat(payload);
      state.error = null;
    },
    getLandCarerNotesSuccess(state, {payload}: PayloadAction<any>) {
      state.landCarerUserOrgContact = payload.userOrgContact;
      state.landCarerNotes = (payload as ILandCarerNote[])
                                .sort((note1: ILandCarerNote, note2: ILandCarerNote) => (note1.updatedAt.valueOf() - note2.updatedAt.valueOf() ? 0 : -1))
      state.error = null;
    },
    getLandCarerNotesFailed(state, {payload}: PayloadAction<any>) {
      state.landCarerNotes = [];
      state.error = payload;
    },
    deleteLandCarerNoteSuccess(state, {payload}: PayloadAction<any>) {
      state.landCarerNotes = state.landCarerNotes.filter(note => note.id != payload);
      state.error = null;
    },
    deleteLandCarerNoteFailed(state, {payload}: PayloadAction<any>) {
      state.error = null;
    },
    clearLandCarerNotes(state) {
      state.landCarerNotes = [];
    },
    updateLandCarerNoteFieldSuccess(state, {payload}: PayloadAction<ILandCarerNote>) {
      let index = state.landCarerNotes.findIndex((c: ILandCarerNote) => c.id === payload.id);
      const note = { ...state.landCarerNotes[index], ...payload };
      state.landCarerNotes[index] = note;
    
      state.error = null;
    },
    updateLandCarerNoteContactOrgName(state, {payload}: PayloadAction<ILandCarerNote>) {
      
      if (payload.userOrgContact && payload.userOrgContact.length) {
        const updatedContact : IUserOrgContact = {
          _id :  payload.userOrgContact[0]._id,
          firstName : payload.userOrgContact[0].firstName,
          lastName : payload.userOrgContact[0].lastName,
        } as IUserOrgContact

        let index = state.landCarerNotes.findIndex((c: ILandCarerNote) => c.id === payload._id);
        state.landCarerNotes[index].userOrgContact[0] = updatedContact;
        
      }
    },
    updateLandCarerNoteFieldFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    updateLandHoldingSuccess(state, {payload}: PayloadAction<any>) {
      state.currentLandCare.landHolding[0] = payload;
      state.error = null;
    },
    updateLandHoldingFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    getUserOrgContactsSuccess(state, {payload}: PayloadAction<any>) {
      if (payload.hasOwnProperty("userOrg")) {
        state.userOrg = payload.userOrg;
        state.orgUsers = payload.users;
        state.userOrgContacts = (payload.userOrgContacts as ILandCareUserOrgContact[])
                                  .sort((contact1: ILandCareUserOrgContact, contact2: ILandCareUserOrgContact) => (contact1.updatedAt.valueOf() - contact2.updatedAt.valueOf() ? 0 : -1))
        state.error = null;
      }
    },
    getUserOrgContactsFailed(state, {payload}: PayloadAction<any>) {
      state.userOrgContacts = [];
      state.error = payload;
    },
    clearUserOrgContacts(state) {
      state.userOrgContacts = [];
    },
    addLandCarerNoteSuccess(state, {payload}: PayloadAction<ILandCarerNote>) {
      state.landCarerNotes.splice(0, 0, payload);
      state.error = null;
    },
    addLandCarerContactUpdateSuccess(state, {payload}: PayloadAction<IlandCarerNoteContactUpdate>) {
      // state.landCarerNotes.splice(0, 0, payload);
      // jm: todo something to retrive the updated value to front-end
      state.error = null;
    },
    addLandCarerNoteFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    fileUploadSuccess(state, {payload}: PayloadAction<IMediaFile[]>) {
      state.fileUpload = payload;

      // curently there is a feature in api => if upload same file, the same id is returned
      let fileIndex = state.landCarerFiles.findIndex((file => file.id == payload[0].id));

      // if file doesn't already exist 
      if (fileIndex === -1) {
        // add to the redux store at position 0
        state.landCarerFiles.splice(0, 0, payload[0]);
      }

      console.log("jm: we are in the success ");

      state.error = null;
    },
    fileUploadFailed(state, {payload}: PayloadAction<any>) {
      state.error = "An error occurred while trying ot upload the file " + JSON.stringify(payload);
    },
    filePatchSuccess(state, {payload}: PayloadAction<ILandCareFilePatchResponse>) {
      
      if (payload.description) {
        // Find index of specific object using findIndex method.   
        let fileIndex = state.landCarerFiles.findIndex((file => file.id == payload.id));
        state.landCarerFiles[fileIndex].description = payload.description;
      }
    },
    filePatchFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    fileDeleteSuccess(state, {payload}: PayloadAction<any>) {
      state.landCarerFiles = state.landCarerFiles.filter(file => file.id != payload);
      state.error = null;
    },
    fileDeleteFailed(state, {payload}: PayloadAction<any>) {
      state.fileUpload = payload.mediaFiles;
      state.error = null;
    },
    fileDownloadSuccess(state, {payload}: PayloadAction<any>) {
      state.error = null;
    },
    fileDownloadFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    resetFileUploaded(state) {
      state.fileUpload = [];
    },
    clearLandCarerFiles(state) {
      state.landCarerFiles = [];
    },
    getLandCarerFilesSuccess(state, {payload}: PayloadAction<any>) {
      state.landCarerFiles = state.landCarerFiles.concat(payload as IMediaFile[]);
      state.error = null;
    },
    getLandCarerFilesFailed(state, {payload}: PayloadAction<any>) {
      state.landCarerFiles = [];
      state.error = payload;
    },
    getAllPolygonFilesSuccess(state, {payload}: PayloadAction<any>) {
      state.polygonFiles = payload;
      state.error = null;
    },
    getAllPolygonFilesFailed(state, {payload}: PayloadAction<any>) {
      state.polygonFiles = [];
      state.error = payload;
    },
    clearPolygonFiles(state) {
      state.polygonFiles = [];
    },
  }
});

//KS: Export reducer (Can include other reducers here too)
export const LandCareReducers = LandCareSlice.reducer;

// //KS: Export action creators
export const { 
  landCareStartLoading, 
  startLoadingState,
  stopLoadingState,
  landCareStopLoading,
  landCareFailed, 
  updateLandholding,
  getLandCareByIdSuccess, 
  getLandHoldingCurrent,
  getAllLandCareSuccess,
  searchForLandCare,
  searchForLandCareAuto,
  getSearchResultsList,
  getAutoCompleteStart,
  getAutoCompleteShow,
  getAutoCompleteHide,
  addToStatusNew,
  addToStatusInProgress,
  addToStatusMonitoring,
  addToStatusImplementation,
  addToStatusClosed,
  addToStatusDeleted,
  getLandCareSuccess,
  resetCurrentLandCare,
  setCurrentPlantingSiteSuccess,
  setPlantingSiteField,
  setLandCareField,
  patchLandCareSuccess,
  patchLandCareFailed,
  patchPlantingSiteSuccess,
  patchPlantingSiteSuccessShallow,
  patchPlantingSiteFailed,
  addPlantingSiteSuccess,
  getLandCarerNotesSuccess,
  getLandCarerNotesFailed,
  deleteLandCarerNoteSuccess,
  deleteLandCarerNoteFailed,
  clearLandCarerNotes,
  updateLandCarerNoteFieldSuccess,
  updateLandCarerNoteContactOrgName,
  updateLandCarerNoteFieldFailed,
  updateLandHoldingSuccess,
  updateLandHoldingFailed,
  getUserOrgContactsSuccess,
  getUserOrgContactsFailed,
  clearUserOrgContacts,
  addLandCarerNoteSuccess, 
  addLandCarerNoteFailed,
  addLandCarerContactUpdateSuccess,
  addUserOrgContactSuccess,
  addUserOrgContactFailed,
  saveUserOrgContactSuccess,
  saveUserOrgContactFailed,
  updateUserOrgContactFieldSuccess,
  updateUserOrgContactFieldFailed,
  removeUserOrgContactSuccess,
  removeUserOrgContactFailed,
  fileUploadSuccess,
  fileUploadFailed,
  filePatchSuccess,
  filePatchFailed,
  fileDownloadSuccess,
  fileDownloadFailed,
  fileDeleteSuccess,
  fileDeleteFailed,
  resetFileUploaded,
  clearLandCarerFiles,
  getLandCarerFilesSuccess,
  getLandCarerFilesFailed,
  getAllPolygonFilesSuccess,
  getAllPolygonFilesFailed,
  clearPolygonFiles
  //setPlantingSites
} = LandCareSlice.actions;

/***** API Calls */
//call this on page startup and pass into reducer store
export const getLandCare = (accessToken: string, pageNumber: number = 0, pageSize: number = 0, filters: any = {}) : AppThunk => async dispatch => {
  try {
    const result = await getAllLandCaresFromApi(accessToken, pageNumber, pageSize, filters);
    dispatch(getAllLandCareSuccess(result.landCarers));
    dispatch(getSearchResultsList(result.landCarers));
    if (pageNumber === 1) {
      const recordCount = result.recordCount;
      const payload: IModulePageInfoPayload = {
        moduleName: ModulesEnum.Home,
        modulePageInfo: { pageNumber, pageSize, recordCount }
      }
      dispatch(setModulePageInfo(payload));
    }
  } catch(err) {
    dispatch(landCareFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getLandCarerById = (accessToken: string | undefined, landCareId: string | undefined) : AppThunk => async dispatch => {
  try {
    getLandCaresByIdFromApi(accessToken, landCareId).then(
      (landCare: any) => { dispatch(getLandCareByIdSuccess(landCare)); },
      (err: any) => { dispatch(landCareFailed(err)); }
    )
  } catch(err) {
    dispatch(landCareFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const getLandCareData = (accessToken: string, userProfileId: string, landCareId: string = '', detailRecordTypes?: DetailRecordTypes[], successFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await getLandCaresWithPlantingsById(accessToken, landCareId);
    const landCare: ILandCarer = result.data.landCarer;
    console.log('Result ===', landCareId,result)
    dispatch(getLandCareSuccess(landCare));
    const userOrgId = landCare?.landHolding ? landCare.landHolding[0].organisationId : '';
    const landHoldingId = landCare?.landHolding[0]._id;

    dispatch(clearUserOrgContacts());
    if (detailRecordTypes?.includes(DetailRecordTypes.CONTACTS))
      await dispatch(getUserOrgContacts(accessToken, userOrgId));

    dispatch(clearLandCarerNotes());
    if (detailRecordTypes?.includes(DetailRecordTypes.NOTES)) {
      await dispatch(getLandCarerNotes(accessToken, landCareId));
    }

    dispatch(clearPolygonFiles());
    if (detailRecordTypes?.includes(DetailRecordTypes.POLYGONFILES)) {
      await dispatch(getLandHoldingPolygonFiles(accessToken, landHoldingId));
    }

    if (detailRecordTypes?.includes(DetailRecordTypes.CHAT)) {
      await dispatch(getLandCareChatSummaries(accessToken, userProfileId, landCareId, 10));
    }

    if (successFn) successFn(landCare?.landHolding[0]);
  } catch(err) {
    dispatch(landCareFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
  }
};

export const getUserOrgContacts = (accessToken: string, orgId: string) : AppThunk => async dispatch => {
  try {
    const userOrg = await fetchUserOrgContacts(accessToken, orgId);
    dispatch(getUserOrgContactsSuccess(userOrg));
  }
  catch(err) {
    dispatch(getUserOrgContactsFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
  }
};

export const updateLandholdingMap = (accessToken: string, landholdingId: string, landHolding : ILandholding) : AppThunk => async dispatch => {

  try {
    const result = await putLandholdingMap(accessToken, landholdingId, landHolding);
    dispatch(updateLandHoldingSuccess(result));
  }
  catch(err) {
    dispatch(updateLandHoldingFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
  }
};

export const updateLandholdingAC = (payload: any) : AppThunk => async dispatch => {
  await dispatch(updateLandholding({currentLHList: payload.currentLHList, selectedLH: payload.selectedLH}))
}

export const setLoadingToTrue = (startLoading : boolean) : AppThunk => async dispatch => {
  await dispatch(startLoadingState(startLoading))
}

export const createNewPlantingSiteAC = (accessToken: string | undefined, landCareId: string | undefined, name: string, currentLandCareInForm: ILandCarer) : AppThunk => async dispatch => {

  const newPlantingSite = {
    landCarerId: landCareId,
    name: name,
    plantingArea: '',
    desktopFeasibility: '',
    plantingSiteInspection: '',
    plantingSummary: '',
    riskRating: '',
    inspection: '',
    stemCount: '',
    soilDescription: '',
    projectStyle: '',
    carbonYield: '',
    marketComment: '',
    market: '',
    landSecured: '',
    landSecuredFromDate: "2021-01-27T07:39:35.486Z",
    landSecuredToDate: "2021-01-27T07:39:35.486Z",
    files: [],
    internalComment: [],
    polygon: []
  }

  const postMethod = {
    method: 'POST',
    headers: { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-type': 'application/json; charset=UTF-8' 
    },
    body: JSON.stringify(newPlantingSite)
  }

  const getMethod = {
    method: 'GET',
    headers: { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-type': 'application/json; charset=UTF-8' 
    }
  }

  // const baseUrlPlanting = 'https://gerxdev.azure-api.net/landcarers/';
  // const url = `${baseUrlPlanting}entity?id=${landCareId}`;
  // const baseUrlNewPlanting = 'https://gerxdev.azure-api.net/landcarers/projectsites';

  
  const baseUrlPlanting = process.env.REACT_APP_LANDCARE_API_BASEURI;
  const url = `${baseUrlPlanting}entity?id=${landCareId}`;
  const baseUrlNewPlanting = `${baseUrlPlanting}projectsites`;

  
  let created: any = {};
   
  await fetch(baseUrlNewPlanting, postMethod)
    .then(response => response.json())
    .then(dataResponse => {console.log('Data in CREATE AC fetch from response: ', dataResponse); created = dataResponse}) // Manipulate the data retrieved back
    .catch(err =>
      showMessage(err.message, MessageModeEnum.ERROR)
    );
    
  if (created !== {}) {
    const site = created.result;
    dispatch(addPlantingSiteSuccess(site));
    await fetch(url, getMethod)
    .then(response => response.json())
    .then(async (data: any) => {console.log('Data in CREATE LC FROM GET in AC fetch from response: ', data.landCarer) // Manipulate the data retrieved back
      const site : IPlantingSite = data.landCarer.plantingSites[(data.landCarer.plantingSites.length -1)]
      const currentWithNewPlanting = {
        caseManager: currentLandCareInForm.caseManager,
        createdBy: currentLandCareInForm.createdBy,
        creditMarket: currentLandCareInForm.creditMarket,
        eligibleArea: currentLandCareInForm.eligibleArea,
        experienceLevel: currentLandCareInForm.experienceLevel,
        feasibilityComment: currentLandCareInForm.feasibilityComment,
        files: currentLandCareInForm.files,
        id: currentLandCareInForm.id,
        internalComment: currentLandCareInForm.internalComment,
        landHolding: currentLandCareInForm.landHolding,
        landHoldingAddress: currentLandCareInForm.landHoldingAddress,
        landHoldingId: currentLandCareInForm.landHoldingId,
        landHoldingState: currentLandCareInForm.landHoldingState,
        landHoldingStatus: currentLandCareInForm.landHoldingStatus,
        landHoldingUser: currentLandCareInForm.landHoldingUser,
        landSecuredFromDate: currentLandCareInForm.landSecuredFromDate,
        marketComment: currentLandCareInForm.marketComment,
        name: currentLandCareInForm.name,
        plantingArea: currentLandCareInForm.plantingArea,
        plantingSites: currentLandCareInForm.plantingSites.concat(site),
        probabilityToClose: currentLandCareInForm.probabilityToClose,
        marketFeasibility: currentLandCareInForm.marketFeasibility,
        implementationProgress: currentLandCareInForm.implementationProgress,
        riskRating: currentLandCareInForm.riskRating,
        shortlisted: currentLandCareInForm.shortlisted,
        siteManager: currentLandCareInForm.siteManager,
        status: currentLandCareInForm.status,
        suitableArea: currentLandCareInForm.suitableArea,
        technicalFeasibility: currentLandCareInForm.technicalFeasibility,
        updatedBy: currentLandCareInForm.updatedBy,
        _id: currentLandCareInForm._id
      }
      await dispatch(getLandCareSuccess(currentWithNewPlanting));
      await dispatch(setCurrentPlantingSiteSuccess(site));
    })
    .then(() => {})
    .catch(err => 
      showMessage(err.message, MessageModeEnum.ERROR)
    );
  }
}

export const updatePlantingSiteShallow = (plantingSiteId: string, fieldName: string, fieldValue: any) : AppThunk => async dispatch => {
  try {
    // jm: this will just update the local store
    const payload: any = { [fieldName] : fieldValue, 'id' : plantingSiteId } as IPlantingSite;
    dispatch(patchPlantingSiteSuccessShallow(payload));
  } catch(err) {
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const updatePlantingSiteField = (accessToken: string, plantingSiteId: string, fieldName: string, fieldValue: any, endFn: Function) : AppThunk => async dispatch => {
  try {
    const result = await patchPlantingSite(accessToken, plantingSiteId, fieldName, fieldValue);
    const payload: any = { [fieldName] : result[fieldName] } as IPlantingSite;
    dispatch(patchPlantingSiteSuccess(payload));
    if (endFn) endFn();
  }
  catch(err) {
    dispatch(patchPlantingSiteFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
    if (endFn) endFn();
  }
};

export const addLandCarerNote = (accessToken: string, note: ILandCarerNote) : AppThunk => async dispatch => {
  try {
    const result = await addLandCarerNotePost(accessToken, note);
    await dispatch(addLandCarerNoteSuccess(result));
  } catch(err) {
    dispatch(addLandCarerNoteFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const updateLandCareField = (accessToken: string, id: string, fieldName: string, fieldValue: any, endFn: Function) : AppThunk => async dispatch => {
  try {
    const result = await patchLandCare(accessToken, id, fieldName, fieldValue);
    const payload: any = { [fieldName] : result[fieldName] } as IPlantingSite;
    dispatch(patchLandCareSuccess(payload));
    if (endFn) endFn();
  }
  catch(err) {
    dispatch(patchLandCareFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
    if (endFn) endFn();
  }
};

export const updateUserOrgContactField = (accessToken: string, contactId: string, fieldName: string, fieldValue: string, endFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await patchUserOrgContact(accessToken, contactId, fieldName, fieldValue);
    if (result) {
      const payload: any = { id: contactId, [fieldName] : result[fieldName] };
      dispatch(updateUserOrgContactFieldSuccess(payload));
    }
  }
  catch(err) {
    dispatch(updateUserOrgContactFieldFailed(err));
    //showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
    if (endFn) endFn();
  }
};

export const showAutoList = () : AppThunk => async dispatch => {
  dispatch(getAutoCompleteShow())
}

export const hideAutoList = () : AppThunk => async dispatch => {
  dispatch(getAutoCompleteHide())
}

export const setCurrentPlantingSite = (plantingSites: IPlantingSite[], selectedPlantingSite: any) : AppThunk => async dispatch => {
  const neededSite = plantingSites.find((site: any) => site.id == selectedPlantingSite);
  dispatch(setCurrentPlantingSiteSuccess(neededSite));
}

export const getLandholdingById = (accessToken: string, landholdingId: string) : AppThunk => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  try {
    dispatch(landCareStartLoading());
    const landHolding = await getLandHoldingByIdFromApi(accessToken, landholdingId);
    await dispatch(getLandHoldingCurrent(landHolding));
  } catch(err) {
    dispatch(landCareFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
}

export const getLandCarerNotes = (accessToken: string, landCareId: string) : AppThunk => async dispatch => {
  try {
    const landCarerNotes = await fetchLandCarerNotes(accessToken, landCareId);
    const landCareNotes2 : ILandCarerNote[] = landCarerNotes.map((note : ILandCarerNote) => {
      const userOrgContactId = (note?.userOrgContact && note?.userOrgContact.length) ? note.userOrgContact[0]._id : "";
      return {...note, userOrgContactId};
    });
    dispatch(getLandCarerNotesSuccess(landCareNotes2));
  }
  catch(err) {
    dispatch(getLandCarerNotesFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
  finally {
  }
};

export const getLandHoldingPolygonFiles = (accessToken: string, landHoldingId: string) : AppThunk => async dispatch => {
  try {
    const polygonFiles = await getPolygonFiles(accessToken, landHoldingId);
    dispatch(getAllPolygonFilesSuccess(polygonFiles));
  }
  catch(err) {
    dispatch(getAllPolygonFilesFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
  finally {
  }
};

export const updateLandCareNoteField = (accessToken: string, noteId: string, fieldName: string, fieldValue: any, endFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await patchLandCarerNote(accessToken, noteId, fieldName, fieldValue);
    dispatch(updateLandCarerNoteFieldSuccess(result));  
    dispatch(updateLandCarerNoteContactOrgName(result));
    if (endFn) endFn();
  }
  catch(err) {
    dispatch(updateLandCarerNoteFieldFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
    if (endFn) endFn();
  }
};

export const deleteLandCarerNote = (accessToken: string, landCareId: string, noteId: string) : AppThunk => async dispatch => {
  try {
    const result = await deleteLandCarerNotee(accessToken, landCareId, noteId);
    dispatch(deleteLandCarerNoteSuccess(result.note.id));  
  }
  catch(err) {
    dispatch(deleteLandCarerNoteFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
}


export const updateMediaFilesById = (accessToken: string, module: string, moduleRefId: string, fileId: string, fieldName: string, fieldValue: string) : AppThunk => async dispatch => {
  try {
    const result = await patchMediaFilesById(accessToken, module, moduleRefId, fileId, fieldName, fieldValue);
    dispatch(filePatchSuccess(result));
  } catch(err) {
    dispatch(filePatchFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }

  
}

export const updateLandCarerNoteContact = (accessToken: string, note: IlandCarerNoteContactUpdate) : AppThunk => async dispatch => {
  try {
    const result = await patchLandCarerNoteContact(accessToken, note);
    await dispatch(addLandCarerContactUpdateSuccess(result));
  } catch(err) {
    dispatch(addLandCarerNoteFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
}

export const addUserContact = (accessToken: string, contact: ILandCareUserOrgContact, successFn: Function, errorFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await addUserOrgContact(accessToken, contact);
    if (result) {
      await dispatch(addUserOrgContactSuccess(result));
      successFn();
    }
  } catch(err) {
    dispatch(addUserOrgContactFailed(err));
    if(errorFn) errorFn(err.message);
  }
};
   

export const saveUserContact = (accessToken: string, contact: ILandCareUserOrgContact) : AppThunk => async dispatch => {
  try {
    const result = await saveUserOrgContact(accessToken, contact);
    await dispatch(saveUserOrgContactSuccess(result));
  } catch(err) {
    dispatch(saveUserOrgContactFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const removeUserContact = (accessToken: string, id: string) : AppThunk => async dispatch => {
  try {
    const result = await removeUserOrgContact(accessToken, id);
    await dispatch(removeUserOrgContactSuccess(id));
  } catch(err) {
    dispatch(removeUserOrgContactFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const uploadMediaAttachment = (accessToken: string, module: string, moduleRefId: string, files: Array<any>, patchNote?: boolean, noteId?: string, fieldName?: string, description?: string) : AppThunk => async dispatch => {
  try {
    const result = await uploadMediaFilesById(accessToken, module, moduleRefId, files);
    await dispatch(fileUploadSuccess(result.mediaFiles));  
    
    let fileId = result.mediaFiles[0].id;

    // if call to uploadMediaAttachment has patchNote && noteId && fieldName ; then set the fieldValue to the result's first mediafile
    if (patchNote && noteId && fieldName) {
      await dispatch(updateLandCareNoteField(accessToken, noteId, fieldName, [fileId]));
      await dispatch(updateMediaFilesById(accessToken, module, moduleRefId, fileId, "refId", noteId))
    }

    if (description) {
      dispatch(updateMediaFilesById(accessToken, module, moduleRefId, fileId, "description", description))
    }
  } catch(err) {
    dispatch(fileUploadFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
    throw err;
  }
};

export const downloadMediaAttachment = (accessToken: string, module: string, moduleRefId: string, fileId: string, name: string, document: any, window: any) : AppThunk => async dispatch => {
  try {
    const result = await downloadMediaFilesById(accessToken, module, moduleRefId, fileId);

    if(result && result.data.size > 0) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', name);
      document.body.appendChild(link);

      link.click();
    }
    else {
      console.log('Error occurred while fetching file!');
    }
    
  } catch(err) {
    dispatch(fileUploadFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const deleteMediaAttachment = (accessToken: string, module: string, moduleRefId: string, fileId: string, deleteNote?: boolean, noteId?: string, fieldName?: string) : AppThunk => async dispatch => {
  try {
    const result = await deleteMediaFilesById(accessToken, module, moduleRefId, fileId);
    
    await dispatch(fileDeleteSuccess(fileId));
    
    if (deleteNote && noteId && fieldName) {
      // set the files fieldname to empty array ; jm: todo - later delete just the file that was requested to be deleted
      await dispatch(updateLandCareNoteField(accessToken, noteId, fieldName, []));
    }

  } catch(err) {
    dispatch(fileUploadFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const getAllLandCarerFiles = (accessToken: string, moduleRefId: string, landHoldingId: string) : AppThunk => async dispatch => {
  try {    
    dispatch(clearLandCarerFiles());

    const landCarerNotesFiles = await fetchLandCarerFiles(accessToken, ModiaFileModules.LandCareNotes, moduleRefId);
    dispatch(getLandCarerFilesSuccess(landCarerNotesFiles.data.mediaFiles));

    const landCarerOverviewFiles = await fetchLandCarerFiles(accessToken, ModiaFileModules.LandCarers, moduleRefId);
    dispatch(getLandCarerFilesSuccess(landCarerOverviewFiles.data.mediaFiles));

    const landCarerPlantingSiteFiles = await fetchLandCarerFiles(accessToken, ModiaFileModules.ProjectSites, moduleRefId);
    dispatch(getLandCarerFilesSuccess(landCarerPlantingSiteFiles.data.mediaFiles));

    const landCarerGeneralFiles = await fetchLandCarerFiles(accessToken, ModiaFileModules.LandCarersGeneral, moduleRefId);
    dispatch(getLandCarerFilesSuccess(landCarerGeneralFiles.data.mediaFiles));

    const landHoldingFiles = await fetchLandCarerFiles(accessToken, ModiaFileModules.LandHoldings, landHoldingId);
    dispatch(getLandCarerFilesSuccess(landHoldingFiles.data.mediaFiles));
  }
  catch(err) {
    dispatch(getLandCarerFilesFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const saveUserOrg = (accessToken: string, userOrg: ILandCareUserOrg) : AppThunk => async dispatch => {
  return await postUserOrg(accessToken, userOrg);
};

export const saveUserOrgWithContact = (accessToken: string, userOrg: ILandCareUserOrg, userOrgContact: ILandCareUserOrgContact, successFn?: Function) : AppThunk => async dispatch => {
  let apiResult = {} as any;

  try {
    let userOrgResult: any;
    let userOrgContactResult: any;
    if (userOrg.id) {
      userOrgResult =  await putUserOrg(accessToken, userOrg);
    }
    else {
      userOrgResult =  await postUserOrg(accessToken, userOrg);
    }
    if (userOrgContact && Object.keys(userOrgContact).length && userOrgResult && userOrgResult._id) {
      apiResult.UserOrgResult = userOrgResult;
      userOrgContact.userOrgId = userOrgResult._id;
      userOrgContactResult = await postUserOrgContact(accessToken, userOrgContact);
      apiResult.userOrgContactResult = userOrgContactResult;
    }
    if(successFn) successFn(userOrgResult, userOrgContactResult);
  }
  catch(err) {
    console.log("An error occurred while calling saveUserOrgWithContact");
  } 

  return apiResult;
};

export const sendLandCareUserStatus = (accessToken: string) : AppThunk => async dispatch => {
  await postLandCareUserStatus(accessToken);
};

/* Export reducer store: */
export const landCareList = (state: RootState) => state.landCarers as any;