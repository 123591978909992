import React, { ReactFragment, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from "react-redux";
import {Spinner, Form, Container, Row, Col, Button, Dropdown} from 'react-bootstrap';
import { ILandCarer, ISubUser } from '../../Models/LandCare/LandCare';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useIsAuthenticated } from '@azure/msal-react';
import tokml from '@maphubs/tokml';
import proj4 from 'proj4';

import OverviewMap from '../ArcGIS/OverviewMap';
import PolygonPointsOverview from './_PolygonPointsOverview';
import Sidebar from '../Layout/Sidebar/Sidebar';
import { landCareList, updateLandCareField, getLandCareData, updateLandholdingAC, uploadMediaAttachment, landCareStopLoading, landCareStartLoading, setLandCareField, setCurrentPlantingSite, downloadMediaAttachment } from '../../Store/Reducers/LandCareSlice';
import { selectUserProfile, selectLandCareModuleConfigPages, selectSiteManaagers } from '../../Store/Selectors/rootSelector';
import { selectCurrentLandcare, selectPolygonFiles } from '../../Store/Selectors/landCareSelectors';
import { DetailRecordTypes, ModiaFileModules, waitingText } from '../../Helpers/constants';
import FileUploadModal from '../../Helpers/UI/FileUploadModal';
import PageTitle from '../Layout/PageTitle/PageTitle';
import ProgressBar from '../../Helpers/UI/ProgressBar/ProgressBar';
import { getMapGeoJson } from '../../Store/Api/LandCareApi';
import { IModulePage, IModuleSection } from '../../Store/Reducers/ModuleConfigSlice';
import { assignMetaValues, renderControls } from '../../Helpers/UI/RenderControlsHelper2';
import { getUserProfile } from '../../Store/Reducers/LocalStoreSlice';
import { downloadFile } from '../../Helpers/HelperFunctions';

import '../App/App.scss';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { exportSHPFile } from '../../Helpers/polygonFunctions';

const loaderStyle = {
    overlay: (base: any) => ({
        ...base,
        background: 'rgb(211,211,211, 0.7)',
        color: 'black',
        width: '100%',
          '& svg circle': {
            stroke: 'rgba(255, 0, 0, 0.2)'
          },
        zIndex: 1
    })
};

const Overview: React.FC<any> = (props) => {
  const { currentLandCareRef, isLoading } = useSelector(landCareList)
  const currentLandCare = useSelector(selectCurrentLandcare);
  const userProfile = useSelector(selectUserProfile);
  const siteManagers = useSelector(selectSiteManaagers);
  const polygonFiles = useSelector(selectPolygonFiles);

  let page = cloneDeep(useSelector(selectLandCareModuleConfigPages)?.find((page: IModulePage) => page.id === 'Overview'));

  let overviewMap: ReactFragment = <></>;

  const [ isSaving, setIsSaving ] = useState({} as ILandCarer);
  
  // FileUploadModal
  const [showFileAddModal, setFileAddShowModal] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!Object.keys(userProfile).length) {
      const refreshObj = async () =>  {
        const accessToken = await props.getAccessToken();
        if (accessToken && !Object.keys(userProfile).length) {
          await dispatch(getUserProfile(accessToken, history));
        }
      };
      refreshObj();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const urlIDBlock = window.location.pathname.split('/')[2];
    
    dispatch(landCareStartLoading());
    
    const refreshObj = async () => {
      const accessToken = await props.getAccessToken();

      if (accessToken && userProfile.id) {
        await dispatch(getLandCareData(accessToken, userProfile.id, urlIDBlock, [DetailRecordTypes.POLYGONFILES]));
        dispatch(landCareStopLoading());
      }
    };
    refreshObj();
    return () =>  { dispatch(setCurrentPlantingSite([], null)); } 
  }, [userProfile.id]);

  overviewMap = 
    (<OverviewMap listGeometryItems={currentLandCare.landHolding && currentLandCare.landHolding[0].mapGraphItems} 
                  getAccessToken={props.getAccessToken}
                  currentLandCare={currentLandCare} />
    );

  const setCurrentLandHolding = (payload: any) => {
    dispatch(updateLandholdingAC({currentLHList: currentLandCare.landHolding, selectedLH: payload}));
  };

  const constructPayload = (event: any, status: boolean) => {
    return { ...isSaving, [event.target.id]: status}
  };

  const downlodPolygonFile = async () => {
    const accessToken = await props.getAccessToken();
    const landHoldingId = currentLandCare.landHolding[0]._id || '';
    const file = polygonFiles[0];
    if (landHoldingId && file) {
      await dispatch(downloadMediaAttachment(accessToken, ModiaFileModules.LandHoldingPolygons, landHoldingId, file.id, file.name, document, window));
    }
  };

  const blurFn = async (event: any) => {
    const fieldName = event.target.id;
    const newVal = event.target.value;
    const oldVal = currentLandCareRef[fieldName];
    if (newVal != oldVal) {
      setIsSaving(constructPayload(event, true));
      const stopSpinnerFn = () => setIsSaving(constructPayload(event, false));
      const accessToken = await props.getAccessToken();
      dispatch(updateLandCareField(accessToken, currentLandCare.id, fieldName, newVal, stopSpinnerFn));
    }
  };

  const ctrlBootstrapStyling = 'col-sm-12 col-md-6';

  const changeFn = (fieldName: string, fieldValue: any) => dispatch(setLandCareField({ [fieldName]: fieldValue } as ILandCarer));

  const landholdingName = currentLandCare.landHolding?.length ? currentLandCare.landHolding[0].name : '';

  // this is used for the FileAdd modal
	const handleFileCloseModal = () => {
    setFileAddShowModal(false)
  };

  interface IBarProgress {
    name: string;
    value: number;
    color?: string;
  }

  const prosArray: IBarProgress[] = [
    { name: '', value: 0, color: '' },
    { name: 'Lead / Prospect', value: 25, color: '' },
    { name: 'Qualification', value: 50, color: '' },
    { name: 'Proposal', value: 75, color: '' },
    { name: 'Negotiation', value: 100, color: '' },
    { name: 'Application', value: 100, color: '' },
    { name: 'Implementation', value: 100, color: '' },
    { name: 'Monitoring', value: 100, color: '' },
    { name: 'On Hold', value: 100, color: '' },
    { name: 'Closed/Lost', value: 100, color: '' }
  ];

  const techFeasArray: IBarProgress[] = [
    { name: '', value: 0, color: '' },
    { name: 'Tech Feasibility', value: 40 },
    { name: 'On Ground Feasibility', value: 70, color: 'green' },
    { name: 'Feasible/Completed', value: 100, color: 'green' }
  ];

  const credMarketArray: IBarProgress[] = [
    { name: '', value: 0, color: '' },
    { name: 'Contact', value: 25 },
    { name: 'Discussion', value: 25, color: 'green' },
    { name: 'Proposal', value: 50 },
    { name: 'Negotiation', value: 75, color: 'green' },
    { name: 'Agreement', value: 100, color: 'green' },
    { name: 'Closed/Lost', value: 0 }
  ];

  const negoArray: IBarProgress[] = [
    { name: '', value: 0, color: '' },
    { name: 'Lead/Prospect', value: 0 },
    { name: 'Qualification', value: 0 },
    { name: 'Proposal', value: 0 },
    { name: 'Negotiation', value: 50 },
    { name: 'Application', value: 75 },
    { name: 'Implementation', value: 100 },
    { name: 'Monitoring', value: 100 },
    { name: 'On Hold', value: 100 },
    { name: 'Closed/Lost', value: 0 }
  ];

  const implArray: IBarProgress[] = [
    { name: '', value: 0, color: '' },
    { name: 'Lead/Prospect', value: 0 },
    { name: 'Qualification', value: 0 },
    { name: 'Proposal', value: 0 },
    { name: 'Negotiation', value: 0 },
    { name: 'Application', value: 0 },
    { name: 'Implementation', value: 50 },
    { name: 'Monitoring', value: 100, color: 'green' },
    { name: 'On Hold', value: 75 },
    { name: 'Closed/Lost', value: 0 }
  ];

  const getBarProgress = (fieldValue: string, array: IBarProgress[]) => {
    return array.find((item: IBarProgress) => item.name === fieldValue) as IBarProgress;
  };

  const prosBar = getBarProgress(currentLandCare?.status, prosArray);
  const techFeasBar = getBarProgress(currentLandCare?.technicalFeasibility, techFeasArray);
  const credMarketBar = getBarProgress(currentLandCare?.creditMarket, credMarketArray);
  const negoBar = getBarProgress(currentLandCare?.status, negoArray);
  const impBar = getBarProgress(currentLandCare?.status, implArray);

  const siteManagerMetaValues = siteManagers.map((siteManager: ISubUser) => ({ ...siteManager, name: siteManager.fullName })) as any[];

  assignMetaValues(page, 'siteManager', siteManagerMetaValues);

  const formHeaderTop = page?.sections?.find((section: IModuleSection) => section.id === 'formHeaderTop');
  const formHeaderSection = page?.sections?.find((section: IModuleSection) => section.id === 'formHeader');
  const rightTopSection = page?.sections?.find((section: IModuleSection) => section.id === 'rightTop');
  const rightMiddleSection = page?.sections?.find((section: IModuleSection) => section.id === 'rightMiddle');

  const showMapLandHolding = () => {
    let path =`/projects/map/${currentLandCare.id}`;
    history.push(path);  
  };

  const showPolygonShapeFile = () => {
    let path =`/projects/upload-polygon-file/${currentLandCare.id}`;
    history.push(path);  
  };

  const exportGeoJsonFile = async (isExporting = true, disableProjection = false) => {
    let geoJsonData = {} as any;
    if(currentLandCare && currentLandCare.id) {
      const accessToken = await props.getAccessToken();
      geoJsonData = await getMapGeoJson(accessToken, currentLandCare.id, disableProjection);

      if(geoJsonData) {
        if (isExporting) {
          let geoJsonAsString = JSON.stringify(geoJsonData);
          const fileName = `${currentLandCare.name}-${currentLandCare.id}.json`;
          downloadFile(fileName, geoJsonAsString);
        }
        else {
          return geoJsonData;
        }
      }
      else {
        showMessage("No geoJson data was retrieved!", MessageModeEnum.WARNING);
      }
    }
    else {
      showMessage("No current LandCarer record set in state", MessageModeEnum.WARNING);
    }
  };

  const exportSHP = async () => {
    const geoJSON = await exportGeoJsonFile(false);
    const fileName = `${currentLandCare.name}-${currentLandCare.id}`;
    exportSHPFile(fileName, geoJSON);
  };

  const exportKML = async () => {
    const geoJSON = await exportGeoJsonFile(false);
    var kmlContents = tokml(geoJSON);
    const fileName = `${currentLandCare.name}-${currentLandCare.id}.kml`;
    downloadFile(fileName, kmlContents);
  };

  return (
    <Container fluid className="overview gerx-contents-panel">
      {/* {isLoading && <><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Updating database...</p></>} */}
        <Row>
          <Col xs={12}>
            <Sidebar key="sidebar" highlight='overview' active={'active'} _id={currentLandCare._id}>

              <FileUploadModal
                getAccessToken={props.getAccessToken}
                showModal={showFileAddModal} 
                closeModal={handleFileCloseModal}
                fileUploadComponent={"landcarers"}
              />

              <Row>
                <Col>
                  <PageTitle title={'Organisation: ' + (currentLandCare?.landHoldingUserOrg?.userOrgName ? currentLandCare.landHoldingUserOrg.userOrgName : '')} />
                </Col>
              </Row>

              { !formHeaderTop ? <></> :
                <Row>
                  <Col xs={12} className={`${window.innerWidth < 1000 ? 'btn-styles media-prog' : 'display-prog'}`}>
                    <ProgressBar title="Prospecting" percent={prosBar?.value} color={prosBar?.color} />
                    <ProgressBar title="Feasbility" percent={techFeasBar?.value} color={techFeasBar?.color} />
                    <ProgressBar title="Credit Market" percent={credMarketBar?.value} color={credMarketBar?.color} />
                    <ProgressBar title="Negotiation" percent={negoBar?.value} color={negoBar?.color} />
                    <ProgressBar title="Implementation" percent={impBar?.value} color={impBar?.color} />
                  </Col>
                </Row>
              }
              
              { page && renderControls(formHeaderSection, currentLandCare, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving) }
              
              <LoadingOverlay className="row" active={isLoading} spinner text={waitingText} styles={loaderStyle}>

                <Col sm={12} md={6} className="p-3">
                  <Row className="border-bottom">

                    { currentLandCare.id && currentLandCare?.landHolding.length && currentLandCare.landHolding[0].mapGraphItems?.length ? <></> :
                      <>
                        {/* <Button className="add-planting-btn" variant="success" onClick={() => showMapLandHolding()} >Edit Property</Button> */}
                        <Button className="add-planting-btn" variant="success" onClick={() => showPolygonShapeFile()} >Edit/Upload Polygons</Button>
                      </>
                    }

                    <Col sm={12}>
                      <Form.Label className="bold-labels">Property: {landholdingName}</Form.Label>
                      {currentLandCare.id !== undefined
                        ? ( overviewMap )
                        : (<><br/><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Loading map...</p></>)}
                    </Col>
                      
                    <Col sm={12}>
                      <Row className="p-3">
                        <Col sm={12}>
                          <Form.Group controlId="address">
                            <Form.Label className="bold-labels">Property Address</Form.Label>
                            <div>{ currentLandCare.landHoldingAddress ? currentLandCare.landHolding[0]?.address.line1 : ''}</div>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="postCode">
                            <Form.Label className="bold-labels">Post Code</Form.Label>
                            <div>{currentLandCare.landHolding ? currentLandCare.landHolding[0]?.address.postCode : ''}</div>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="state">
                            <Form.Label className="bold-labels">State</Form.Label>
                            <div>{currentLandCare.landHolding ? currentLandCare.landHolding[0]?.address.state : ''}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>


                  <Row className="border-bottom">
                    <Col sm={12}>
                      <Row className="p-3">
                        <Col sm={12}>
                          <Form.Group controlId="address">
                            <Form.Label className="bold-labels">Postal Address</Form.Label>
                            <div>{ currentLandCare.landHoldingAddress ? currentLandCare.landHolding[0]?.postalAddress.line1 : ''}</div>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="postCode">
                            <Form.Label className="bold-labels">Post Code</Form.Label>
                            <div>{currentLandCare.landHolding ? currentLandCare.landHolding[0]?.postalAddress.postCode : ''}</div>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="state">
                            <Form.Label className="bold-labels">State</Form.Label>
                            <div>{currentLandCare.landHolding ? currentLandCare.landHolding[0]?.postalAddress.state : ''}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="pt-3">
                    <Col sm={12}>
                      <Row>
                        <Col sm={12}>
                          <Form.Label className="bold-labels">Property Centre Point</Form.Label>
                          <PolygonPointsOverview landHolding={currentLandCare.landHolding ? currentLandCare.landHolding[0] : 0} />
                        </Col>

                        <Col sm={12}>
                          <Form.Label className="bold-labels">Available Property Size</Form.Label>
                          <div>
                            <p>{ currentLandCare.landHoldingAddress ? currentLandCare.landHolding[0]?.availableSizePercentage + '%' : 'N/A'}</p>
                          </div>
                        </Col>

                        <Col sm={6} className="overflow-hidden">
                          <Form.Label className="bold-labels">Style of Projects</Form.Label>
                          <div>
                            {currentLandCare?.landHolding && currentLandCare?.landHolding.length ? (
                              Object.entries(currentLandCare?.landHolding[0]?.projectStyles).map((style: any, idx: any) => {
                                if (style[1] === true) {
                                  return <p key={idx}>{style[0]?.substring(2)}</p>
                                }
                              })
                            ) : ''}
                          </div>
                        </Col>

                        <Col sm={6}>
                          <Form.Label className="bold-labels">Soil Description</Form.Label>
                          <div className="title-break">
                            {currentLandCare?.landHolding && currentLandCare?.landHolding.length ? (
                              Object.entries(currentLandCare.landHolding[0].soilTypes).map((style: any, idx: any) => {
                                if (style[1] === true) {
                                  return <p key={idx}>{style[0].substring(2)}</p>
                                }
                              })
                            ) : ''}
                          </div>
                        </Col>

                        {/* <Col sm={12}>
                          <Form.Group controlId="cadastralInformation">
                            <Form.Label className="bold-labels">Cadastral Information</Form.Label>
                            <div className="title-break">{ currentLandCare?.landHolding?.length ? currentLandCare.landHolding[0].cadastralInformation : ''}</div>
                          </Form.Group>
                        </Col> */}

                        <Col sm={12}>
                          <Form.Group controlId="currentUse">
                            <Form.Label className="bold-labels">Current Uses Description by Property Holder</Form.Label>
                            <div className="title-break">{ currentLandCare?.landHolding?.length ? currentLandCare.landHolding[0].currentUseDescription : ''}</div>
                          </Form.Group>
                        </Col>

                        <Col sm={12}>
                          <Form.Group controlId="commentLandholder">
                            <Form.Label className="bold-labels label-spacing-comments">Comment by Property Holder</Form.Label>
                            <div className="title-break">{ currentLandCare?.landHolding?.length ? currentLandCare.landHolding[0].furtherInformationOrComments : ''}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </Col>

                <Col sm={12} md={6} className="p-3 border-left">
                  <Row className="border-right-0 border-dark">
                    {/* {isLoading && <><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Updating database...</p></>} */}
                    <Col sm={12}>
                      <Row className="p-3 border-bottom">
                        <Form.Group controlId="selectParcel" className={ctrlBootstrapStyling}>
                          <Form.Label className="bold-labels">Select Parcel</Form.Label>
                          <Form.Control as="select" name="selectParcel" onChange={(e: any) => setCurrentLandHolding(e.target.value)}>
                            {currentLandCare !== undefined && currentLandCare.landHolding !== null && currentLandCare.landHolding !== undefined && currentLandCare.landHolding.map((landHoldingId: any)=> {
                              return (
                                <option value={landHoldingId?._id} disabled={isLoading ? true : false} 
                                  selected={
                                    currentLandCare.landHoldingId ?
                                    landHoldingId ? currentLandCare.landHoldingId === landHoldingId._id : false
                                      : false
                                  }
                                >
                                  {landHoldingId?.name ? landHoldingId?.name : 'Property Holding Not Named' }
                                </option>
                                )
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                        
                        { page && renderControls(rightTopSection, currentLandCare, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving) }

                      </Row>
                    </Col>

                    <Col sm={12}>
                      <Row className="p-3">
                        { page && renderControls(rightMiddleSection, currentLandCare, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving) }
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Row className="title-bkgd">
                    <Col xs={3} className="list-title">Planting Site</Col>
                    <Col xs={3} className="list-title">Area</Col>
                    <Col xs={2} className="list-title">Feasibility</Col>
                    <Col xs={2} className="list-title">Inspection</Col>
                    <Col xs={2} className="list-title">Risk</Col>
                  </Row>
                  
                  {/* plantingsite summary */}
                  { 
                    currentLandCare.plantingSites !== undefined && currentLandCare.plantingSites !== null &&
                    currentLandCare.plantingSites.map((plantingSite: any) => {
                      return (
                        <Row className="">
                          <Col xs={3} className="planting-sum"> { plantingSite !== null && plantingSite !== undefined ? plantingSite.internalComment : 'None yet' } </Col>
                          <Col xs={3} className="planting-sum"> { plantingSite !== null && plantingSite !== undefined ? plantingSite.plantingArea : 'None yet'  } </Col>
                          <Col xs={2} className="planting-sum"> { plantingSite !== null && plantingSite !== undefined ? plantingSite.desktopFeasibility : 'None yet'  } </Col>
                          <Col xs={2} className="planting-sum"> { plantingSite !== null && plantingSite !== undefined ? plantingSite.inspection : 'None yet'  } </Col>
                          <Col xs={2} className="planting-sum"> { plantingSite !== null && plantingSite !== undefined ? plantingSite.riskRating : 'None yet'  } </Col>
                        </Row>
                      )
                    })
                  }
                </Col>
                
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Form.Label className="bold-labels">Overview Files</Form.Label>
                  <br></br>
                  <Button style={{margin: '5px'}} className="btn-primary" onClick={() => setFileAddShowModal(true)}>Add File</Button>

                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => exportGeoJsonFile(true, false)}>GeoJSON File</Dropdown.Item>
                      <Dropdown.Item onClick={() => exportGeoJsonFile(true, true)}>GeoJSON File (Old)</Dropdown.Item>
                      <Dropdown.Item onClick={() => exportSHP()}>Shape File</Dropdown.Item>
                      <Dropdown.Item onClick={() => exportKML()}>KML File</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Download
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => downlodPolygonFile()} disabled={!polygonFiles.length}>SHP File</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                
              </LoadingOverlay>
            </Sidebar>
          </Col>
        </Row>
      <br/><br/><br/><br/><br/><br/>
    </Container>
  )
}

export default Overview;