import { createSelector } from 'reselect';

import { IUserOrgAutoSuggestState } from '../Reducers/UserOrgAutoSuggestSlice';
import { RootState } from '../RootStore';

export const selectAutoSuggest = (state: RootState) => state.userOrgAutoSuggest;

export const selectAutoSuggestSearchTerm = createSelector(
  [selectAutoSuggest],
  (state: IUserOrgAutoSuggestState) => state.searchTerm as any
);

export const selectAutoSuggestSuggestions = createSelector(
  [selectAutoSuggest],
  (state: IUserOrgAutoSuggestState) => state.suggestions
);

export const selectIsCreateNew = createSelector(
  [selectAutoSuggest],
  (state: IUserOrgAutoSuggestState) => state.isCreateNew
);

export const selectIsEditing = createSelector(
  [selectAutoSuggest],
  (state: IUserOrgAutoSuggestState) => state.isEditing
);

export const selectCurrUserOrg = createSelector(
  [selectAutoSuggest],
  (state: IUserOrgAutoSuggestState) => state.currUserOrg
);