import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import { Button, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
 
import { IChatSummary, IChatMessage, MessageStatusEnum, IChatMessageResponse, IMessagesReadByUserResponse, IActiveUserStatusResponse } from '../../../Models/LandCare/IChat';
import { landCareStartLoading, landCareStopLoading, sendLandCareUserStatus } from '../../../Store/Reducers/LandCareSlice';
import { selectCurrentGroupId, selectCurrentSummaryIndex, selectLandCareChatSummaries, selectLandCareEndOfRecords } from '../../../Store/Selectors/landCareChatSelectors';
import { attachLandCareChatFile,
         getLandCareChatMessages,
         removeLandCareChatFile,
         sendLandCareChatMessage,
         sendLandCareChatMessageReadNotification,
         setLandCareChatCurrentSummaryIndex,
         signalRAppendReceivedChatMessage,
         signalRMessagesReadByUser,
         signalRUpdateUserStatusToActive,
         updateStatusOfChatGroups
        } from '../../../Store/Reducers/LandCareChatSlice';
import { selectLandCareAttachedFiles } from '../../../Store/Selectors/landCareChatSelectors';

import ChatContact from './ChatContact';
import ChatMessage from './ChatMessage';

import './Chat.scss';
import ChatFiles, { DisplayMode } from './ChatFiles';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import { landCareSelector, selectCurrentLandcare } from '../../../Store/Selectors/landCareSelectors';
import { DetailRecordTypes, loaderStyle, signalREndPoints, waitingText } from '../../../Helpers/constants';
import { getLandCareData } from '../../../Store/Reducers/LandCareSlice';
import { selectUserProfile } from '../../../Store/Selectors/rootSelector';
import { IMediaFile } from '../../../Models/MediaFiles/IMediaFile';
import GetSignalRConnection from '../../../SignalR/setupFunctions';
import { IUserProfile } from '../../../Models/Auth/IUser';

const Chat : React.FC<any> = (props) => {
    const currentLandCare = useSelector(selectCurrentLandcare);
    const chatSummaries = useSelector(selectLandCareChatSummaries);
    const isEndOfRecords = useSelector(selectLandCareEndOfRecords);
    const { isLoading } = useSelector(landCareSelector);
    const currentSummaryIndex = useSelector(selectCurrentSummaryIndex);
    const userProfile = useSelector(selectUserProfile);
    const attachedFiles = useSelector(selectLandCareAttachedFiles);
    const currentGroupId = useSelector(selectCurrentGroupId);

    const dispatch = useDispatch();

    const chatMessageRef = useRef(null);

    const CURRENT_COMPONENT = 'landcarechats';

    const chatSummary = chatSummaries && chatSummaries.length ?
        chatSummaries.map((summary: IChatSummary) => {
            const len = (summary?.messages?.length || 0);
            const lastMessage = (summary?.messages && summary?.messages.length) ? (summary?.messages[len - 1]) : {} as IChatMessage;
            let lastMessageStatus = 0;
            if (lastMessage?.createdBy === userProfile.id) {
                if (lastMessage.messageStatus === MessageStatusEnum.IsRead)
                    lastMessageStatus = 2;
                else
                    lastMessageStatus = 1;
            }
            return { ...summary, lastMessageStatus };
        })
        : [];

    const landCareId = window.location.pathname.split('/')[3];

    useEffect(() => {
        const refreshObj = async () => {
            if (landCareId && userProfile.id) {
                const accessToken = await props.getAccessToken();
                dispatch(landCareStartLoading());
                await dispatch(getLandCareData(accessToken, userProfile.id, landCareId, [DetailRecordTypes.CHAT]));
                await dispatch(setLandCareChatCurrentSummaryIndex(accessToken, 0, landCareId, currentGroupId));
                dispatch(landCareStopLoading());
                scrollToBottomOfMessages();
                
                const fn = async () => {
                    await dispatch(sendLandCareUserStatus(accessToken));
                };
                fn();
            }
        }
        refreshObj();
        
        let updateChatGroupUserStatusTimeoutInterval: any;

        if (!updateChatGroupUserStatusTimeoutInterval) {
            const refreshRate: number = parseInt(process.env.REACT_APP_CHAT_USER_STATUS_PING_INTERVAL_MS || '300000');

            updateChatGroupUserStatusTimeoutInterval =
                setInterval(
                    async () => {
                        const accessToken = await props.getAccessToken();
                        await dispatch(sendLandCareUserStatus(accessToken));
                        dispatch(updateStatusOfChatGroups());
                    }
                , refreshRate);
        }

        return () => {
            clearTimeout(updateChatGroupUserStatusTimeoutInterval);
        }
    }, [landCareId, userProfile]);
    
    const signalRConnection = GetSignalRConnection.getConnection();

    useEffect(() => {
        signalRConnection?.off(signalREndPoints.notifyNewLandCarersChatMessage);
        signalRConnection?.on(signalREndPoints.notifyNewLandCarersChatMessage, async (data: IChatMessageResponse) => {
            if (userProfile && (data.createdBy != userProfile.id)) {
                data.userProfileId = userProfile.id;
                dispatch(signalRAppendReceivedChatMessage(data));
                if (currentGroupId === data.groupId) {
                    scrollToBottomOfMessages();
                    const accessToken = await props.getAccessToken();
                    dispatch(sendLandCareChatMessageReadNotification(accessToken, landCareId, data.groupId));
                }
            }
        });

        signalRConnection?.off(signalREndPoints.notifyMessagesReadByUser);
        signalRConnection?.on(signalREndPoints.notifyMessagesReadByUser, (data: IMessagesReadByUserResponse) => {
            if (data.userId && (data.userId != userProfile.id)) {
                data.userProfileId = userProfile.id;
                dispatch(signalRMessagesReadByUser(data));
            }
        });

        signalRConnection?.off(signalREndPoints.notifyUserStatus);
        signalRConnection?.on(signalREndPoints.notifyUserStatus, (data: IActiveUserStatusResponse) => {
            console.log('Active Notification ===', data);
            dispatch(signalRUpdateUserStatusToActive(data));
        });

        return () => {
            signalRConnection?.on(signalREndPoints.notifyNewLandCarersChatMessage, () => {});
            signalRConnection?.on(signalREndPoints.notifyMessagesReadByUser, () => {});
            signalRConnection?.on(signalREndPoints.notifyUserStatus, () => {});
        };
    }, [signalRConnection, landCareId, currentGroupId]);

    useEffect(() => {
        scrollToBottomOfMessages();
    }, [currentSummaryIndex])


    /***** Message Polling *****/

    // let interval: any;
    // useEffect(() => {  
    //     clearInterval(interval);
    //     interval = setInterval(async () => {
    //         const accessToken = await props.getAccessToken();

    //         if (landCareId && groupId) {
    //             dispatch(getLandCareChatMessages(accessToken, landCareId, groupId));
    //         }
    //     }, 10000);
        
    //     return () => clearInterval(interval);
    // }, [groupId]);


    const getMessages = (summary: IChatSummary) : React.ReactFragment => {
        let messages: IChatMessage[] = [];

        if (summary?.messages?.length) {
            messages = summary.messages.map((msg: IChatMessage) => ({ ...msg, isMyMessage: false }));

            messages = messages.sort((x: IChatMessage, y: IChatMessage) => new Date(x.createdAt).valueOf() - new Date(y.createdAt).valueOf()) || [];
            
            let myMessages = messages?.filter((message: IChatMessage) => message.createdBy === userProfile.id).reverse();
            myMessages.forEach((msg: IChatMessage) => {
                msg.isMyMessage = true;
                msg.isMyLastMessage = false;
                msg.messageStatus = msg.isRead ? MessageStatusEnum.IsRead : msg.messageStatus = MessageStatusEnum.IsSent
            });
        
            if (myMessages.length)
                myMessages[0].isMyLastMessage = true;
        }

        let prevDate = '';

        return messages?.length ? messages.map((msg: IChatMessage, i: number) => {
            const date = (new Date(msg.createdAt)).toDateString();

            let flag = false;
            if (date !== prevDate) flag = true

            prevDate = date;

            return (
                <>
                    {
                        flag ? (
                            <div className="separator">
                                <Moment className="msg-time" date={msg.createdAt} format="dddd, DD MMM yyyy" />
                            </div>
                        ) : <></>
                    }
                    <ChatMessage key={'message_' + i} summary={summary} msg={msg} />
                </>
            );
        }) : <></>;
    };

    const scrollToBottomOfMessages = () => {
        const elements = document.getElementsByClassName('message-group-' + currentSummaryIndex);

        if (elements.length) {
            const el = elements[0];
            el.scrollTo({ top: el.scrollHeight - el.clientHeight });
        }
    };

    const onMessageScroll = async (event: any) => {
        const top = event.target.scrollTop;
        if ((top === 0) && !isEndOfRecords) {
            const elements = document.getElementsByClassName('message-group-' + currentSummaryIndex);
            const el = elements[0];
            const h1 = el.scrollHeight;
    
            const accessToken = await props.getAccessToken();
            const nextPageNo = chatSummary[currentSummaryIndex]?.lastPageNo || 0;
            
            await dispatch(getLandCareChatMessages(accessToken, landCareId, currentGroupId || '', nextPageNo + 1, 10));

            const h2 = el.scrollHeight;

            el.scrollTo({ top: h2 - h1 });
        }
    };

    const onContactClicked = async (index: number) => {
        const accessToken = await props.getAccessToken();
        (chatMessageRef?.current as any).value = '';
        const groupId = chatSummary[index]?.groupId || '';
        dispatch(setLandCareChatCurrentSummaryIndex(accessToken, index, landCareId, groupId));
        scrollToBottomOfMessages();
    };

    const onTextBoxKeyDown = async (event: any) => {
        if (event.keyCode === 13)
            await sendMessage();
    };

    const attachFile = async (event: any) => {
        if (event.target.files.length) {
            const accessToken = await props.getAccessToken();
            await dispatch(attachLandCareChatFile(accessToken, CURRENT_COMPONENT, currentGroupId, event.target.files[0]));
            scrollToBottomOfMessages();
        }
    };

    const removeFile = async (event: any) => {
        const accessToken = await props.getAccessToken();
        dispatch(removeLandCareChatFile(accessToken, event));
    };

    const sendMessage = async () => {
        const isDisabled = getIsDisabled();
        const message = (chatMessageRef?.current as any);
        const msgVal: string = message.value;
        if (!isDisabled && msgVal.trim()) {
            const accessToken = await props.getAccessToken();
            const fileIds = attachedFiles.map((file: IMediaFile) => file.id);
            message.value = '';
            const summary = chatSummaries[currentSummaryIndex];
            const groupId = summary.groupId || '';
            const toContactId = summary.userOrgContact.id || '';
            await dispatch(sendLandCareChatMessage(accessToken, landCareId, userProfile.id, toContactId, groupId, msgVal, fileIds, scrollToBottomOfMessages));
        }
    };
    
    const getIsDisabled = () => !(chatMessageRef?.current as any).value && !attachedFiles.length;

    const contactsPanelHeight = window.outerHeight - 280;
    const messageBoxHeight = contactsPanelHeight - 64;
    const contactsColumnHeight = contactsPanelHeight - 12;

    return (
        <Container fluid className="gerx-contents-panel">
            <Row>
                <Col xs={12}>
                    <Sidebar key="sidebar" highlight='chat' active={'active'} _id={currentLandCare._id}>
                        <LoadingOverlay className="row" active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                            <Tab.Container defaultActiveKey="summary_0">
                                <Row className="gerx-chat" style={{ maxHeight: (contactsPanelHeight + 'px') }}>
                                    <Col md={3} className="gerx-chat-contacts-panel">
                                        <h6>Contacts</h6>
                                        <div className="gerx-contacts-column" style={{ maxHeight: contactsColumnHeight + 'px'}}>
                                            <Nav variant="pills" className="flex-column">
                                                {
                                                    chatSummary && chatSummary.map((summary: IChatSummary, c: number) => (
                                                        <Nav.Item key={'summary_' + c} className="gerx-chat-contacts" onClick={() => onContactClicked(c)}>
                                                            <Nav.Link eventKey={'summary_' + c}>
                                                                <ChatContact summary={summary} />
                                                                {/* <small className="text-danger">{summary.userOrgContact.created}</small> */}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                )}
                                            </Nav>
                                        </div>
                                    </Col>
                                    <Col md={9} className="gerx-chat-contents-panel">
                                        <Tab.Content>
                                            {
                                                chatSummary
                                                ? chatSummary.map((summary: IChatSummary, c: number) => (
                                                    <Tab.Pane eventKey={'summary_' + c}>
                                                        <h6>Chat with {summary.userOrgContact.firstName} {summary.userOrgContact.lastName}</h6>
                                                        <div className={"gerx-chat-messages message-group-" + c} style={{ height: (messageBoxHeight + 'px') }} onScroll={onMessageScroll}>
                                                            { summary.isRegisteredUser
                                                            ? getMessages(summary)
                                                            : (!summary?.messages?.length
                                                                ? <div className="msg-note">
                                                                        <span>
                                                                        This contact is currently unregistered. Sending any message to this contact 
                                                                        will send an invitation link to register in the system and join the chat.
                                                                        </span>
                                                                    </div>
                                                                : <>
                                                                    <div className="msg-note">
                                                                        <span>
                                                                        Chat invitation has been sent.
                                                                        </span>
                                                                    </div>
                                                                    {getMessages(summary)}
                                                                    </>
                                                                )
                                                            }

                                                            <ChatFiles files={attachedFiles}
                                                                    displayMode={DisplayMode.ATTACH}
                                                                    onCrossClicked={removeFile}
                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                    )
                                                )
                                                : null
                                            }
                                        </Tab.Content>
                                        <div className="messaging">
                                            <Form.Control className="messaging-box"
                                                        placeholder="Type your message here..."
                                                        ref={chatMessageRef}
                                                        onKeyDown={onTextBoxKeyDown}
                                            ></Form.Control>
                                            
                                            <label htmlFor="fileInput" className="fa fa-paperclip fa-lg msg-file-input"></label>
                                            <input id="fileInput" type="file" onChange={attachFile} hidden/>
                                            <Button className='send-btn'
                                                    onClick={sendMessage}>
                                            Send
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </LoadingOverlay>
                    </Sidebar>
                </Col>
            </Row>
        </Container>
    );
};

export default Chat;