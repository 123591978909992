import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import FileUploadModal from "../../../Helpers/UI/FileUploadModal";
import { selectLandCareFormFields } from "../../../Store/Selectors/rootSelector";
import { ILandCareFile} from "../../../Models/LandCare/LandCare";
import { downloadMediaAttachment, deleteMediaAttachment, updateMediaFilesById } from "../../../Store/Reducers/LandCareSlice";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { IMediaFile, IMediaFileValidateResponse } from "../../../Models/MediaFiles/IMediaFile";
import { IMetaData } from "../../../Store/Reducers/ModuleConfigSlice";

import './Files.scss';

interface IFileAddProps {
  getAccessToken: Function;
  clear?: any;
}

export interface IFileAddResult {
  isValid: boolean;
  data: IMediaFileValidateResponse;
  errors: any[];
}

const FilesList: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const configFormFields = useSelector(selectLandCareFormFields);
  const currentLandCarerId = window.location.pathname.split('/')[3];

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [focusInfo, setFocusInfo] = useState({} as any);
  const [itemToDelete, setItemToDelete] = useState<string>("");
  const [itemModule, setItemModule] = useState<string>("");
  const [noteFileRefId, setNoteFileRefId] = useState<string>("");

  // FileUploadModal
  const [showFileAddModal, setFileAddShowModal] = useState<boolean>(false);
  
  interface Options {
    [key: string]: string;
  }

  // the value the dropdown filters on is the 'datafield' of the column
  const generateFileModuleFilterOptions = (configFormFields: IMetaData[]) => {
    if (configFormFields) {
      var selectOptionsDynamic: Options[] = [];

      const selectOptionsMetaData: IMetaData = configFormFields?.find(
        (f: IMetaData) => f.metaId === "landcareFileModules"
      ) as IMetaData;
      const selectOptionsDynamicArray = selectOptionsMetaData?.metaValues;

      // needs to be refactored / improved
      selectOptionsDynamicArray?.forEach(
        (option) => (selectOptionsDynamic.push({value: option.id || '', label: option.name || ''}))
      );
      return selectOptionsDynamic;
    } else {
      // return an empty obj
      return {};
    }
  };

  const downloadMediaFile = async (row: any, moduleRefId: string, id: string, name: string) => {
    //console.log("Row data: ", row);
    if(id && name) {
      const accessToken = await props.getAccessToken();
      await dispatch(downloadMediaAttachment(accessToken, row.module, moduleRefId, id, name, document, window));
    }
  }

  function getMappedModuleValues(
    fieldName: string,
    cell: string,
    row: any,
    rowIndex: number,
    formatExtraData: any
  ) {
    if(row.module === "landcarenotes") {
      return (<><div key={fieldName}>Notes</div></>)
    } else if(row.module === "landcarers") { 
      return (<><div key={fieldName}>Overview</div></>)
    } else if(row.module === "projectsites") { 
      return (<><div key={fieldName}>Planting Sites</div></>)
    } else if(row.module === "landcarersgeneral") { 
      return (<><div key={fieldName}>General</div></>)
    } else if(row.module === "landholdings") { 
      return (<><div key={fieldName}>Land Portal</div></>)
    } else {
      return (<></>)
    }
  }

  const showModalHandler = (fileId: string, fileModule: string, refId?: string) => {
    setShowModal(true);
    setItemToDelete(fileId);
    setItemModule(fileModule);

    // if the file to be deleted has a refId/noteId
    if(refId) {
      setNoteFileRefId(refId);
    }
  }

  // this is used for the delete modal
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // this is used for the FileAdd modal
  const handleFileCloseModal = () => {
    setFileAddShowModal(false)
  }

  const deleteAttachment = async () => {
    const accessToken = await props.getAccessToken();

    if(itemModule === "landcarenotes") {
      await dispatch(deleteMediaAttachment(accessToken, itemModule, currentLandCarerId, itemToDelete, true, noteFileRefId, "files"));
    } else {
      await dispatch(deleteMediaAttachment(accessToken, itemModule, currentLandCarerId, itemToDelete));
    }
    setShowModal(false);
  }

  function dateFormatter(cell: string, row: any, rowIndex: number, formatExtraData: any) {
    let date_obj = new Date(Date.parse(row.updatedAt))
    return <p>{date_obj.toLocaleDateString() + " " + date_obj.toLocaleTimeString().slice(0,-3)}</p>;
  };

  const fieldCheck = (fieldName: string, rowNo: number) => {
    return (fieldName === focusInfo.fieldName) && (rowNo === focusInfo.rowNo);
  };

  const columnFormatter = (fieldName: string, cell: any, row: IMediaFile, rowNo: number, formatExtraData: any) => {
    const check = isSaving && fieldCheck(fieldName, rowNo);
    const tooltip = 'Double-click the cell to edit';

    const columnStyle: any = (fieldName === 'description') ?
      {
        maxHeight: "70px",
        overflowY: 'auto',
        overflowX: 'hidden',
        whiteSpace: 'pre-wrap',
      }
      :
      {};

    return (
      <div style={columnStyle} key={fieldName} title={tooltip}>{cell}</div>
    );
};

  const onBlur = async (
    oldVal: string,
    newVal: string,
    row: IMediaFile,
    column: any,
    done: Function
  ) => {
    if (newVal !== oldVal) {
      const fileId = row.id;
      const fieldName = column.dataField;

      const rowNo = myfiles.findIndex(
        (file: IMediaFile) => file.id === fileId
      );
      
      try {
        if (row.module)  {
          setIsSaving(true);
          setFocusInfo({fieldName, rowNo});
          const accessToken = await props.getAccessToken();
          console.log('accessToken', accessToken)
          await dispatch(updateMediaFilesById(accessToken, row.module, currentLandCarerId, row.id, "description", newVal))
          setIsSaving(false);
          setFocusInfo({});
        }
      } catch(err: any) {
        const errors: string[] = err.errors ((err: string) => err.replace('this', column.text));

        let errorStr = '';
        errors.forEach((err: string) => errorStr += '\u2022 ' + err + '\n');

        toast(errorStr, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeButton: false,
            closeOnClick: true,
            draggable: false,
            progress: undefined,
            style: { color: 'white', backgroundColor: '#f57070' }
        });
    
        done(false);
      }
    }

    return { async: true };
  };

  const columns = [
    {
      dataField: "updatedAt",
      text: "Date Updated",
      headerStyle: () => ({ width: "12%" }),
      formatter: dateFormatter,
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "createdByUser[0].firstName",
      text: "Created By",
      headerStyle: () => ({ width: "8%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "module",
      text: "Module",
      headerStyle: () => ({ width: "15%" }),
      editable: () => {
        return false;
      },
      formatter: (cell: any, row: IMediaFile, rowIndex: number, formatExtraData: any) => 
        getMappedModuleValues("id", cell, row, rowIndex, formatExtraData),  
      filter: selectFilter({
        options: generateFileModuleFilterOptions(configFormFields),
      }),
      sort: true,
      sortFunc: (a: string, b : string, order: string, dataField : string,  rowA: IMediaFile, rowB : IMediaFile) => {
        const valA :string = (rowA?.module &&  rowA?.module.length) ? rowA?.module : "";
        const valB : string = (rowB?.module && rowB?.module.length) ? rowB?.module : "";
        if (order === 'asc') {
          return valA.localeCompare(valB);
        }
        else {
          return valB.localeCompare(valA);
        }
      }
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => ({ width: "15%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      // empty headerstyle - take rest of the width
      formatter: (cell: any, row: IMediaFile, rowIndex: number, formatExtraData: any) => 
        columnFormatter("description", cell, row, rowIndex, formatExtraData),
      editor: {
        type: Type.TEXTAREA,
      },
      sort: true,
    },
    {
      dataField: "size",
      text: "Size",
      headerStyle: () => ({ width: "5%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      headerStyle: () => ({ width: "8%" }),
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "files",
      text: "Files",
      headerStyle: () => ({ width: "3%" }),
      isDummyField: true,
      formatter: (cell: any, row: IMediaFile, rowIndex: number) => {
        const isLandholding = row.module === 'landholdings';
        return (
          <div>
            { 
              <i
                className="fa fa-download gerx-hand-pointer"
                onClick={() =>
                  {
                    if (row.module) {
                      downloadMediaFile(row, currentLandCarerId, row.id, row.name);
                    }
                  }
                }
              />
            } 
            &nbsp;
            { 
              <i
                className={"fa fa-trash gerx-hand-pointer" + (isLandholding ? ' disabled' : '')}
                title={isLandholding ? "Can't delete this file as it was uploaded by the landholder." : ''}
                onClick={() =>
                  {
                    if (row.module && !isLandholding) {
                      showModalHandler(row.id, row.module, row.refId);
                    }
                  }
                }
              />
            }
          </div>
        );
      },
      editable: () => {
        return false;
      },
    },
  ];

  const rowStyleFormatter = (row: any, index: number) => {
    return { cursor: ':hover: progress' };
  };

  let myfiles = props.my_files.map((files: ILandCareFile) => ({ ...files }));

  return (
    <div key="main" className="py-3">
      <Row>
        <Col xl="12" className="gerx-button-group text-right">
          <Button className="btn-primary" onClick={() => setFileAddShowModal(true)}>Add File</Button>
        </Col>
      </Row>

      <FileUploadModal  
        getAccessToken={props.getAccessToken}
        showModal={showFileAddModal} 
        closeModal={handleFileCloseModal}
        fileUploadComponent={"landcarersgeneral"}
        />
      
      <Modal size="lg" show={showModal} onHide={handleCloseModal}> 
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choosing delete will permanently delete this item.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteAttachment}>
            Delete permanently
          </Button>
        </Modal.Footer>
      </Modal>

      <BootstrapTable
        keyField="id"
        data={myfiles}
        columns={columns}
        noDataIndication="No File Records Found"
        classes={"gerx-files table table-bordered table-striped table-sm " + (isSaving ? "saving" : "")}
        cellEdit={cellEditFactory({
          mode: "dbclick",
          blurToSave: true,
          beforeSaveCell: onBlur,
        })}
        bootstrap4
        filter={filterFactory()}
      />
    </div>
  );
};

export default FilesList;
