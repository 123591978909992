import React from 'react';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Jumbotron} from 'react-bootstrap';

interface UserProfileProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface UserProfileState {
  isOpen: boolean;
}

function UserProfileContent(props: UserProfileProps) {
  // If authenticated, greet the user
  if (props.isAuthenticated) {
    return (
      <div>
        <h4>Profile Details (WIP: To be retrieved from graph api)</h4>
        <p>Name: {props.user.name}</p>
        <p>User Name: {props.user.displayName}</p>
        <p>Email: {props.user.email}</p>
      </div>
    );
  }
  
  // Not authenticated, present a sign in button  
  return (
    <Button color="primary" onClick={props.authButtonMethod}>Please Sign-in to view profile</Button>
  );
}

class UserProfile extends React.Component<UserProfileProps, UserProfileState> {
  render() {
    return (
      <Jumbotron>
        <UserProfileContent
          isAuthenticated={this.props.isAuthenticated}
          user={this.props.user}
          authButtonMethod={this.props.authButtonMethod} />
      </Jumbotron>
    );
  }
}

export default UserProfile;
