import React from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';

export interface IConfirmModalState {
  heading: string;
  text: string;
  confirmButtonText: string;
  showModal: boolean;
  handleClose: Function;
  handleConfirm: Function;
};

export const ConfirmModal : React.FC<IConfirmModalState> = (props: IConfirmModalState) => {
  return (
    <>
      <Modal size="lg" show={props.showModal} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.text}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose.bind(this)}>
            Close
          </Button>
          <Button variant="primary" onClick={props.handleConfirm.bind(this)}>
            {props.confirmButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}