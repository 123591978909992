import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StepProgressBar from 'react-step-progress';
import { ProgressStep } from 'react-step-progress/dist/models';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

import Step1 from '../Steps/step-1/step-1.component';
import Step2 from '../Steps/step-2/step-2.component';
import Step3 from '../Steps/step-3/step-3.component';
import { createLandHolding, getLandHoldingById, updateLandHolding } from '../../../../Store/Reducers/LandHoldingsSlice';
import { loaderStyle, waitingText } from '../../../../Helpers/constants';
import { ILandholding } from '../../../../Models/LandHoldings/LandHoldings';
import { selectCurrentLandholding } from '../../../../Store/Selectors/landholdingSelector';
import { MessageModeEnum, showMessage } from '../../../../Helpers/Validator/validationHelper';
import SpinnerButton from '../../../Components/SpinnerButton/SpinnerButton';

import './EditLandholding.scss';

interface IAddLandholdingProps {
    getAccessToken: Function;
    match?: any;
    history?: any;
}

const EditLandholding: React.FC<IAddLandholdingProps> = (props) => {
    const dispatch = useDispatch();

    const landholding = useSelector(selectCurrentLandholding);

    const { register, handleSubmit, getValues, setValue } = useForm();

    const { getAccessToken } = props;

    const [ currentStep, setCurrentStep ] = useState(1);
    const [ isNextClicked, setIsNextClicked ] = useState(false);
    const [ isPrevClicked, setIsPrevClicked ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    const { match: {params} } = props;

    useEffect(() => {
        const refreshObj = async () => {
            if (params.landholdingId) {
                setIsLoading(true);

                const accessToken = await getAccessToken();
                const successFn = (landHolding: ILandholding) => {
                    const values = Object.values(landHolding);
                    Object.keys(landHolding).forEach((key: string, k: number) => {
                        setValue(key, values[k]);
                    });
                }
                await dispatch(getLandHoldingById(accessToken, params.landholdingId, successFn));

                setIsLoading(false);
            }
        };

        refreshObj();
    }, [params]);

    const steps: ProgressStep[] = [
        { label: 'Step 1', name: 'step 1', content: null },
        { label: 'Step 2', name: 'step 2', content: null },
        { label: 'Step 3', name: 'step 3', content: null }
    ];

    const nextStep = () => {
        setIsNextClicked(true);
    };

    const prevStep = () => {
        setIsPrevClicked(true);
        setCurrentStep(currentStep - 1);
    };

    const onSubmit = async () => {
        if (isNextClicked || isPrevClicked) {
            setIsSaving(true);

            const accessToken = await getAccessToken();
            let successFn: any;

            if (currentStep < 3) {
                successFn = () => setCurrentStep(currentStep + 1);
            }

            let landholdingObj = getValues() as ILandholding;

            landholdingObj._id = landholding._id;
            landholdingObj.mapGraphItems = landholding.mapGraphItems;

            switch (currentStep) {
                case 1:
                    if (!landholdingObj._id) {
                        await dispatch(createLandHolding(accessToken, landholdingObj, successFn));
                    }
                    else {
                        await dispatch(updateLandHolding(accessToken, landholdingObj, successFn, params.organisationId));
                    }
                    break;
                case 2:
                    successFn();
                    break;
                case 3:
                    await dispatch(updateLandHolding(accessToken, landholdingObj, successFn, params.organisationId));
                    showMessage('Landholding created successfully', MessageModeEnum.SUCCESS);
                    props.history.push('/landholdings');
                    break;
            }

            setIsPrevClicked(false);

            setIsSaving(false);

            document.body.scrollIntoView();
        }
    };

    return (
        <Container className="add-landholding">
            <h4 className="text-center mt-1">Form Progress</h4>

            <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                <form className="col-12" onSubmit={handleSubmit(onSubmit)}>
                    <StepProgressBar
                        key={currentStep - 1}
                        startingStep={currentStep - 1}
                        onSubmit={() => {}}
                        steps={steps}
                        wrapperClass={''}
                        buttonWrapperClass="hidden"
                    />
                    <Row className="add-landholding-steps">
                        <Step1 isVisible={currentStep === 1} orgId={params.organisationId} register={register} getValues={getValues} setValue={setValue} getAccessToken={getAccessToken} isNextClicked={isNextClicked} />
                        <Step2 isVisible={currentStep === 2} orgId={params.organisationId} register={register} getValues={getValues} setValue={setValue} getAccessToken={getAccessToken} isNextClicked={isNextClicked} />
                        <Step3 isVisible={currentStep === 3} orgId={params.organisationId} register={register} getValues={getValues} setValue={setValue} getAccessToken={getAccessToken} isNextClicked={isNextClicked} />
                    </Row>
                    <Row>
                        <Col className="margin-btm-common">
                            <Link to={'/landholdings'}>
                                <Button className="float-left btn-secondary">Cancel</Button>
                            </Link>
                        </Col>
                        <Col>
                            {
                                currentStep === 3 ? <></> :
                                <SpinnerButton variant="primary" className="float-right" onClick={nextStep} type="submit" isActive={isSaving}>
                                    Next
                                </SpinnerButton>
                            }
                            {
                                currentStep !== 3 ? <></> :
                                <SpinnerButton variant="success" className="float-right" onClick={nextStep} type="submit" isActive={isSaving}>
                                    Save
                                </SpinnerButton>
                            }
                            {
                                currentStep === 1 ? <></> :
                                <Button variant="primary" className="float-right" onClick={prevStep}>Previous</Button>
                            }
                        </Col>
                    </Row>
                </form>
            </LoadingOverlay>
        </Container>
    );
};

export default EditLandholding;