import { createSlice, PayloadAction} from '@reduxjs/toolkit';

import { AppThunk } from '../RootStore';
import { getAllUserOrgFromApiAuto } from '../Api/LandCareApi';
import { ILandCareUserOrg } from '../../Models/LandCare/LandCare';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';

export interface IUserOrgAutoSuggestState {
    filterTerm: string;
    isCreateNew: boolean;
    isEditing: boolean;
    searchTerm: string;
    suggestions: any[];
    isSearching: boolean;
    currUserOrg: ILandCareUserOrg | null;
    error: any;
}

const initialState: IUserOrgAutoSuggestState = {
    filterTerm: '',
    isCreateNew: false,
    isEditing: false,
    searchTerm: '',
    suggestions: [],
    isSearching: false,
    currUserOrg: null,
    error: null
};

const userOrgAutoSuggestSlice = createSlice({
    name: "UserOrgAutoSuggest",
    initialState,
    reducers: {
        setFilterTerm(state: IUserOrgAutoSuggestState, action: PayloadAction<string>) {
            state.filterTerm = action.payload
        },
        setIsCreateNew(state: IUserOrgAutoSuggestState, action: PayloadAction<boolean>) {
            state.isCreateNew = action.payload
        },
        setIsEditing(state: IUserOrgAutoSuggestState, action: PayloadAction<boolean>) {
          state.isEditing = action.payload
        },
        setSearchTerm(state: IUserOrgAutoSuggestState, action: PayloadAction<string>) {
            state.searchTerm = action.payload;
        },
        startSearching(state: IUserOrgAutoSuggestState) {
            state.isSearching = true;
        },
        endSearching(state: IUserOrgAutoSuggestState) {
            state.isSearching = false;
        },
        getAutoSuggestionsSuccess(state: IUserOrgAutoSuggestState, action: PayloadAction<any[]>) {
            state.suggestions = action.payload;
            state.error = null
        },
        getAutoSuggestionsFailed(state: IUserOrgAutoSuggestState, action: PayloadAction<any[]>) {
          state.suggestions = [];
            state.error = action.payload;
        },
        clearAutoSuggestions(state: IUserOrgAutoSuggestState) {
            state.suggestions = [];
        },
        setCurrUserOrg(state: IUserOrgAutoSuggestState, action: PayloadAction<ILandCareUserOrg>) {
          state.currUserOrg = action.payload;
        },
        clearCurrUserOrg(state: IUserOrgAutoSuggestState) {
          state.currUserOrg = null;
          state.searchTerm = '';
          state.filterTerm = '';
        }
    }
});

export const { setFilterTerm, setIsCreateNew, setIsEditing, setSearchTerm, startSearching, endSearching, getAutoSuggestionsSuccess, getAutoSuggestionsFailed, clearAutoSuggestions, setCurrUserOrg, clearCurrUserOrg } = userOrgAutoSuggestSlice.actions;

export const UserOrgAutoSuggestReducers = userOrgAutoSuggestSlice.reducer;

export interface IUserOrgAutoSuggestions {
  userOrgName: string,
  userOrg: ILandCareUserOrg[];
}
export const getAutoSuggestions = (accessToken: string, searchTerm: any) : AppThunk => async dispatch => {
    const escapedValue = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    if (escapedValue === '') {
        return [];
    }
    
    const regex = new RegExp('^' + escapedValue, 'i');
    
    dispatch(startSearching());

    try {
      const userOrgResults: any = await getAllUserOrgFromApiAuto(accessToken, searchTerm, 10);
      
      const suggestions: IUserOrgAutoSuggestions[] = userOrgResults.map(
        (r: ILandCareUserOrg) => (
          
          { userOrgName: r.userOrgName, userOrg: [r] }
        )
      );
      dispatch(getAutoSuggestionsSuccess(suggestions));
    }
    catch(err) {
      dispatch(getAutoSuggestionsFailed(err));
      showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
      dispatch(endSearching());
    }     
}