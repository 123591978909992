import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IStepProps } from '../../shared';
import { uploadMediaFile } from '../../../../../Store/Reducers/LandHoldingsSlice';
import { selectCurrentLandholding, selectFiles } from '../../../../../Store/Selectors/landholdingSelector';
import MediaFiles from '../../../../Components/MediaFiles/MediaFiles';
import { MessageModeEnum, showMessage } from '../../../../../Helpers/Validator/validationHelper';

import './step-3.styles.scss';

const Step3 : React.FC<IStepProps> = (props) => {
    const { register, getValues, setValue, isVisible, isNextClicked, getAccessToken } = props;

    const files = useSelector(selectFiles);

    const dispatch = useDispatch();

    const [refreshMediafiles, callRefreshMediaFiles ] = useState(0);

    const landholding = useSelector(selectCurrentLandholding);
    
    const containerId = "gerxmedia";
    const module = "landholdings";

    const handleFileChange = async (event: any) => {
        event.preventDefault();
        
        let files = event.target.files;
        if(files && files.length > 0) {
            const accessToken = await props.getAccessToken();
            const moduleRefId = landholding._id || '';
            await dispatch(uploadMediaFile(accessToken, containerId, module, moduleRefId, files));
            callRefreshMediaFiles((refreshMediafiles: any) => refreshMediafiles + 1);
        }
        else {
            showMessage("No files selected", MessageModeEnum.WARNING);
        }
    };

    return (
        <div style={{display: isVisible ? '' : 'none'}}>
            <Row>
                <Col>
                    <div>
                        <h3>Create or Edit Property</h3><br/>
                        <p>In this section, please share any files or additional information that could help us assess your property better.<br></br>For example, images of erosion, previous projects, plans, buildings, access to water, cadastral files or anything else that will help us learn more about the condition of your property.</p>
                        <div>
                            <Form.Group controlId="name">
                                <Form.Label>Property Name:</Form.Label>
                                <h3>{landholding.name}</h3>
                            </Form.Group>
                        </div>
                        <br/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <h6>Upload files</h6>

                        <div>
                            <Form.Group>
                                <Form.Label className="bold-labels">File Upload</Form.Label>
                                <Form.File name="file"
                                    label="Select file(s)"
                                    custom
                                    id="mediafiles"
                                    feedbackTooltip
                                    onChange={handleFileChange}
                                />
                                <MediaFiles {...props} containerId={containerId} module={module} folderId={landholding._id || ''} refresh={refreshMediafiles} />
                            </Form.Group>

                        </div>
                        <br/><br/><br/><br/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <h6>Further Information or Comments</h6>
                        <div>
                            <Form.Group>
                                <Form.Control as="textarea"
                                              name="furtherInformationOrComments"
                                              rows={4}
                                              placeholder="Please include any additional comments here."
                                              ref={register} />
                            </Form.Group>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Step3;