import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useHistory } from 'react-router-dom';

import { landCareList } from '../../../Store/Reducers/LandCareSlice';
import { toggleSideMenu, setSideMenuExpanded } from '../../../Store/Reducers/LocalStoreSlice';
import { selectIsSideMenuExpanded } from '../../../Store/Selectors/rootSelector';
import './Sidebar.scss';

interface ISidebarProps {
    highlight: string;
    children: any;
}

const Sidebar : React.FC<any> = (props: ISidebarProps) => {
    const { currentLandCare } = useSelector(landCareList)
    const isExpanded = useSelector(selectIsSideMenuExpanded);

    const history = useHistory();

    const dispatch = useDispatch();

    const onSelect = (selected: any) => {
        let path = '';
        switch (selected) {
            case 'home': path=`/`; break;
            case 'overview': path=`/projects/${currentLandCare?.id}`; break;
            case 'planting_sites': path=`/projects/planting/${currentLandCare?.id}`; break;
            case 'notes_comments': path=`/projects/notescomments/${currentLandCare?.id}`; break;
            case 'files': path=`/projects/files/${currentLandCare?.id}`; break;
            case 'contact': path=`/projects/contact/${currentLandCare?.id}`; break;
            case 'chat': path=`/projects/chat/${currentLandCare?.id}`; break;
            default: path = '/' + selected;
        }

        dispatch(setSideMenuExpanded(false));

        history.push(path);
    };

    const onToggle = () => {
        dispatch(toggleSideMenu());
    };

    const containerStyle: React.CSSProperties = {
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'hidden',
        transition: 'all .15s',
        padding: '0 0 0 8px',
        marginLeft: (isExpanded ? 16 : 8) + 'px'
    };

    const isRecordLoaded = true;//currentLandCare && Object.keys(currentLandCare).length;

    const sidebarHeight = window.outerHeight - 240;

    return (
        <div className="sidebar"
             style={{
                marginLeft: isExpanded ? 240 : 48,
                minHeight: sidebarHeight
            }}>
            <SideNav onSelect={onSelect} onToggle={onToggle} style={{ height: 'calc(100% + 80px)' }}>
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected={props.highlight}>
                    <NavItem eventKey="home">
                        <NavIcon><i className="fa fa-fw fa-landmark img"/></NavIcon>
                        <NavText>Home</NavText>
                    </NavItem>
                    <NavItem eventKey="overview" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-eye img" /></NavIcon>
                        <NavText>Overview</NavText>
                    </NavItem>
                    <NavItem eventKey="planting_sites" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-tree img" /></NavIcon>
                        <NavText>Planting Sites</NavText>
                    </NavItem>
                    <NavItem eventKey="notes_comments" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-edit img" /></NavIcon>
                        <NavText>Notes</NavText>
                    </NavItem>
                    <NavItem eventKey="files" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-copy img" /></NavIcon>
                        <NavText>Files</NavText>
                    </NavItem>
                    {/* <NavItem eventKey="chat" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-comments img" /></NavIcon>
                        <NavText>Chat</NavText>
                    </NavItem> */}
                    <NavItem eventKey="contact" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-tty img" /></NavIcon>
                        <NavText>Contact</NavText>
                    </NavItem>
                    {/* <NavItem eventKey="reports" disabled={!isRecordLoaded}>
                        <NavIcon><i className="fa fa-fw fa-chart-line img" /></NavIcon>
                        <NavText>Reports</NavText>
                    </NavItem> */}
                </SideNav.Nav>
            </SideNav>

            <div style={containerStyle}>
                {props.children}
            </div>
        </div>
    );
}

export default Sidebar;
