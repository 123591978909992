import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {  Form, OverlayTrigger } from "react-bootstrap";
import { Col, Row} from "react-bootstrap";

import { landCareList } from '../../../Store/Reducers/LandCareSlice';
import { selectLandCareFormFields } from '../../../Store/Selectors/rootSelector';
import { renderDropdownFormGroup } from "../../../Helpers/UI/RenderControlsHelper";
import { ILandCarerNote, ILandCareUserOrgContact } from "../../../Models/LandCare/LandCare";
import { notesFieldList, notesSchema } from "../../../Helpers/Schema/NotesSchema";
import { getErrorsByFieldName, renderTooltip } from "../../../Helpers/Validator/validationHelper";
import { selectFileUploaded, selectUserOrgContacts } from "../../../Store/Selectors/landCareSelectors";

import "bootstrap/dist/css/bootstrap.min.css";

interface INoteAddProps {
  inputData: ILandCarerNote;
  landCarerOrgContacts: ILandCareUserOrgContact[];
  getAccessToken: Function;
  clear?: any;
}

export interface INoteAddResult {
  isValid: boolean;
  data: ILandCarerNote;
  errors: any[];
}

const NoteAdd: React.FC<any> = forwardRef((props: INoteAddProps, ref: any)  => {    

  const CURRENT_COMPONENT = 'landcarenotes';
  const userOrgContacts = useSelector(selectUserOrgContacts);
  const fileUploaded = useSelector(selectFileUploaded);
  const { currentLandCare, isLoading } = useSelector(landCareList);
  const configFormFields = useSelector(selectLandCareFormFields);
  const [ validationErrors, setValidationErrors ] = useState({});

  // load form with no default values
  const { register, handleSubmit, reset } = useForm({});

  useImperativeHandle(ref,
    () => ({        
        validate: async () => {
            let result: INoteAddResult = {} as INoteAddResult;

            const options = {
                strict: false,
                abortEarly: false,
                stripUnknown: false,
                recursive: true
            };
            
            await handleSubmit(async (data: any) => {
                try {
                    await notesSchema.validate(data, options);
                    let fileIds = [];

                    if (fileUploaded && fileUploaded.length > 0) {
                      fileIds.push(fileUploaded[0].id);
                    } 

                    result = {
                        data: { ...data,  files: fileIds },
                        isValid: true,
                        errors: []
                    };
                }
                catch(err: any) {
                    result = {
                        data: {} as ILandCarerNote,
                        isValid: false,
                        errors: err.errors
                    };

                    setValidationErrors(getErrorsByFieldName(notesFieldList, err.errors));
                }                    
            })();

            return result;
        },
        clear: () => {
          reset({userOrgContactId: '', contactMode: ''} as any);
          setValidationErrors({})
        }
    }),
  )

  const getLandcareFieldName = (fieldName: string) => {
    return 'landcare' + fieldName.substr(0, 1).toUpperCase() + fieldName.substr(1);
  };

  const ctrlBootstrapStyling = '';
  const emptyCtrlHandler = (event: any) => {
  };

  const getDropdownControl = (fieldName: string, ref?: any) => {
    const errors: string[] = (validationErrors as any)[fieldName] || [];
     
    const renderedControl = renderDropdownFormGroup(configFormFields, currentLandCare, fieldName, getLandcareFieldName(fieldName), emptyCtrlHandler, ctrlBootstrapStyling, isLoading, ref, errors);

    return (
      <> { renderedControl } </>
    )
  };
  
  const getUserContactsDropdown = (fieldName: string) => {
    const errors: string[] = (validationErrors as any)[fieldName];
    

    const formControl = () => 
      (
        <Form.Control as="select" name={fieldName} ref={register} isInvalid={errors && errors.length ? true : false}>
          {/* jm: this can probably be done better / refactored */}
          <option selected value="" disabled>Select OrgRef</option>
          {
            userOrgContacts.map((val: any) => 
              <option key={val.id} value={val.id}>{val.firstName + " " + val.lastName}</option>
            )
          }
        </Form.Control>
      );

      return (
        <>
        {   errors && errors.length ?
            (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(fieldName, errors)}>
              {formControl()}
            </OverlayTrigger>
            )
            : formControl()
        }
        </>
      )
  }

  const getNoteTextArea = (fieldName : string) => {
    const errors: string[] = (validationErrors as any)[fieldName];

    const formControl = () => (
      <Form.Control
                as="textarea"
                rows={3}
                name="notes"
                placeholder="Enter note information"
                isInvalid={errors && errors.length ? true : false}
                ref={register}
      />
    )
    return (
      <>
        {   errors && errors.length ?
            (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(fieldName, errors)}>
              {formControl()}
            </OverlayTrigger>
            )
            : formControl()
        }
        </>
    )
  }

  return (
    <div className="gerx-add-container py-3">
      <Form>
        <Row>
          <Col xs={12} sm={6} md={4} lg={4} >
            <Form.Group>
              <Form.Label className="bold-labels">Reference</Form.Label>
              { getUserContactsDropdown("userOrgContactId") }
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} >
              { getDropdownControl('contactMode', register)}  
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} >
            <Form.Group>
              <Form.Label className="bold-labels">Note</Form.Label>
              {getNoteTextArea("notes")}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );

});

export default NoteAdd;
