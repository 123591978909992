import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IUserOrg } from '../../Models/Auth/IUser';
import { ILandCareUserOrg, ILandCareUserOrgContact } from '../../Models/LandCare/LandCare';
import { landCareStartLoading, landCareStopLoading } from '../../Store/Reducers/LandCareSlice';
import { clearCurrentLandHolding } from '../../Store/Reducers/LandHoldingsSlice';
import { clearCurrUserOrg, setCurrUserOrg, setIsCreateNew, setIsEditing } from '../../Store/Reducers/UserOrgAutoSuggestSlice';
import { clearUserOrg, getUserOrg, setCurrentUserOrg } from '../../Store/Reducers/UserOrgSlice';
import { selectUserProfile } from '../../Store/Selectors/rootSelector';
import { selectIsCreateNew, selectIsEditing } from '../../Store/Selectors/userOrgAutoSuggestSelector';
import { selectCreatedByOrg, selectCurrentUserOrg } from '../../Store/Selectors/userOrgSelectors';
import SearchUserOrg from '../Components/SearchUserOrg/SearchUserOrg';
import ListLandHoldings from '../LandHoldings/ListLandHoldings';
import ManageUserOrg from './ManageUserOrg';

import './UserOrganisation.scss';

const SelectUserOrg = (props: any) => {
    const dispatch = useDispatch();

    const currUserOrg = useSelector(selectCurrentUserOrg);
    const isCreateNew = useSelector(selectIsCreateNew);
    const isEditing = useSelector(selectIsEditing);
    const userProfile = useSelector(selectUserProfile);
    const createdByOrg = useSelector(selectCreatedByOrg);

    const history = useHistory();
    const [token, setToken] = useState<string>();    
    const [filters, setFilters] = useState<any>({ state: '' });

    useEffect(() => {
        dispatch(landCareStartLoading());
        const getToken = async () => {
            const accessToken = await props.getAccessToken();
            setToken(accessToken);
            dispatch(landCareStopLoading());
        };
        getToken();
        dispatch(clearCurrentLandHolding());
    }, []);

    const onItemSelected = async (item: any) => {
        dispatch(setCurrUserOrg(item.userOrg[0]));

        const accessToken = await props.getAccessToken();
        dispatch(getUserOrg(accessToken, item.userOrg[0].id, true, true));
    };

    const applySearchTerm = (searchterm: string) => {
        setFilters({ ...filters, searchterm, searchlimit: 100 });
    };

    const onCreateNewSelected = () => {
        dispatch(setIsCreateNew(true));
    };

    const onEditSelected = () => {
        dispatch(setIsEditing(true));
    };

    const onCompleted = (landCareUserOrg: ILandCareUserOrg) => {
        const userOrg = landCareUserOrg as IUserOrg;
        dispatch(setCurrentUserOrg(userOrg));
        dispatch(setIsCreateNew(false));
        dispatch(setIsEditing(false));
        dispatch(setCurrUserOrg(landCareUserOrg));
    };

    const onManageLandHolding = () => {
        history.push(`/add-landholding/${currUserOrg?.id}`);
    };

    const onClearSelection = () => {
        dispatch(clearCurrUserOrg());
        dispatch(clearUserOrg());
    };

    const isEditingDisabled = !((currUserOrg?.createdBy === userProfile._id) ||
                                (createdByOrg?.id === userProfile.userOrganisationId));

    return (
        <div className="user-organsiation">
            <Container fluid className="gerx-contents-panel">
                <div>            
                    <div className="card">
                        <h4 className="card-header">Manage Organisation and Properties</h4>
                        <div className="card-body">
                            <Row style={{display: (isCreateNew || isEditing) ? '' : 'none'}}>
                                <Col sm={12}>
                                    <ManageUserOrg
                                        isAuthenticated={props.isAuthenticated}
                                        accessToken={token as string}
                                        getAccessToken={props.getAccessToken}
                                        onCompleted={onCompleted}
                                    >                                    
                                    </ManageUserOrg>
                                </Col>
                            </Row>                            
                            
                            <Row style={{display: (isCreateNew || isEditing) ? 'none' : ''}}>
                                <Col sm={4} md={3}>
                                    <Row>
                                        <Col>
                                            <SearchUserOrg key="search"
                                                getAccessToken={props.getAccessToken}
                                                onSearchByTerm={applySearchTerm}
                                                onSelection={onItemSelected}
                                            >
                                            </SearchUserOrg>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={4} md={5}>
                                    {
                                        (currUserOrg) ? (
                                            <div>
                                                <div>Selected Organisation:</div>
                                                <span style={{fontWeight: 'bold'}}>{currUserOrg.userOrgName}</span>
                                                <div>ABN: {currUserOrg.abn} State: {currUserOrg.mailingAddress?.state}</div>
                                                <div>Address: {currUserOrg.mailingAddress?.fullAddress}</div>
                                            </div>
                                            ) 
                                        : (
                                            <div>Please lookup an organisation or create new.</div>
                                        )
                                    }                                
                                </Col>
                                <Col sm={4} md={4} className="text-right">
                                    <Row>
                                        <Col>
                                        { currUserOrg
                                            ? <Button className="btn-primary" onClick={onEditSelected} disabled={isEditingDisabled}>Edit Organisation</Button>
                                            : <Button className="btn-primary" onClick={onCreateNewSelected}>Add Organisation</Button>
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-1">
                                            <Button disabled={!currUserOrg} className="btn-success" onClick={onManageLandHolding}>Add Property</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-1">
                                            <Button className="btn-secondary" onClick={onClearSelection}>Clear Selection</Button>
                                        </Col>
                                    </Row>                                
                                </Col>
                                <Col>
                                    <ListLandHoldings orgId={currUserOrg?.id || ''} getAccessToken={props.getAccessToken} />
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SelectUserOrg;