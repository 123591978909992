import { createSlice, PayloadAction} from '@reduxjs/toolkit';

import { AppThunk } from '../RootStore';
import { fetchUserOrg, fetchUserOrgByUserId } from '../Api/LandCareApi';
import { ILandCareUserOrgContact } from '../../Models/LandCare/LandCare';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { IUserOrg } from '../../Models/Auth/IUser';

export interface IUserOrgState {
    currentUserOrg: IUserOrg | null;
    createdByOrg: IUserOrg | null;
    userOrgContacts: ILandCareUserOrgContact[] | null;
    error: any;
}

const initialState: IUserOrgState = {
    currentUserOrg: null,
    createdByOrg: null,
    userOrgContacts: null,
    error: null
};

export interface ICurrentUserOrgResult {
  userOrganisation: IUserOrg;
  createdByOrg: IUserOrg;
  userOrgContacts: ILandCareUserOrgContact[];
}

const userOrgSlice = createSlice({
    name: "userOrg",
    initialState,
    reducers: {
        getUserOrgSuccess(state: IUserOrgState, action: PayloadAction<ICurrentUserOrgResult>) {
            state.currentUserOrg = action.payload.userOrganisation;
            state.createdByOrg = action.payload.createdByOrg;
            state.userOrgContacts = action.payload.userOrgContacts;
            state.error = null;
        },
        getUserOrgFailed(state: IUserOrgState, action: PayloadAction<any>) {
          state.currentUserOrg = null;
          state.createdByOrg = null;
          state.userOrgContacts = null;
          state.error = action.payload;
        },
        setCurrentUserOrg(state: IUserOrgState, action: PayloadAction<IUserOrg>) {
          state.currentUserOrg = action.payload;
        },
        setUserOrgContacts(state: IUserOrgState, action: PayloadAction<ILandCareUserOrgContact[]>) {
          state.userOrgContacts = action.payload;
        },
        setCreatedByUserOrg(state: IUserOrgState, action: PayloadAction<IUserOrg>) {
          state.createdByOrg = action.payload;
        },
        clearUserOrg(state: IUserOrgState) {
          state.currentUserOrg = null;
          state.createdByOrg = null;
          state.userOrgContacts = null;
          state.error = null;
        }
    }
});

export const { getUserOrgSuccess, getUserOrgFailed, setCurrentUserOrg, setCreatedByUserOrg, setUserOrgContacts, clearUserOrg } = userOrgSlice.actions;

export const userOrgReducers = userOrgSlice.reducer;

export const getUserOrg = (accessToken: string, orgId?: any, includeCreatedByOrg?: boolean, includeUserOrgContacts?: boolean, successFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await fetchUserOrg(accessToken, orgId, includeCreatedByOrg, includeUserOrgContacts);
    await dispatch(getUserOrgSuccess(result));
    if (successFn) successFn(result);
  } catch(err) {
    dispatch(getUserOrgFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getUserOrgByUserId = (accessToken: string, userId?: any, successFn?: Function) : AppThunk => async dispatch => {
  try {
    const result = await fetchUserOrgByUserId(accessToken, userId);
    await dispatch(getUserOrgSuccess(result));
    if (successFn) successFn(result);
  } catch(err) {
    dispatch(getUserOrgFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};