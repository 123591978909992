import React, {useState, useEffect } from 'react';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

interface Props {
  location: any;
  match: any
}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

interface IUser {
    id: number;
    name: string;
}

const Users: React.FC = () => {
  
    useEffect(() => {
        console.log('Fetch items called...');
        fetchItems();
    }, []);

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const req = await fetch('https://jsonplaceholder.typicode.com/users');
        const items = await req.json();
        //console.log("Received data", items);
        setItems(items);
    }

    const renderItems = () => {
        return (
            items.map((item: IUser) => {
            return (
                <h3 key={item.id}>{item.name}</h3>
            );
        }));
    }

    return (
        <div>
            <span>This is Users page.</span>
            <div>{renderItems()}</div>
        </div>
    );    
}

export default Users;
