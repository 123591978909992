import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../RootStore';

import { ILandholding } from '../../Models/LandHoldings/LandHoldings'
import {deleteLandHolding, getAllLandHoldingsByOrgId, getAllLandHoldingsFromApi, getLandHoldingByIdFromApi, postLandHolding, putLandHolding, uploadMediaFilesById} from '../Api/LandHoldingsApi';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { breakupPayload } from '../../Helpers/HelperFunctions';
import { IMediaFile } from '../../Models/MediaFiles/IMediaFile';

export interface LandHoldingsState {
  isLoading: boolean,
  error: string | null,
  currentLandHolding: ILandholding,
  landHoldings: ILandholding[],
  files: any[]
}

const initialLandHoldingState : LandHoldingsState = {
  isLoading: false,
  error: null,
  currentLandHolding : {} as ILandholding,
  landHoldings: [],
  files: []
};

function startLoading(state: LandHoldingsState) {
  state.isLoading = true
}

function loadingFailed(state: LandHoldingsState, action: PayloadAction<string>) {
  state.isLoading = false
  state.error = action.payload
}

const LandHoldingsSlice = createSlice({
  name: "landHoldings",
  initialState: initialLandHoldingState,
  reducers: {

    getLandHoldingStart: startLoading,
    getLandHoldingsStart: startLoading,
    getLandHoldingFailed: loadingFailed,
    getLandHoldingsFailed: loadingFailed,

    getLandHoldingByIdSuccess(state, {payload}: PayloadAction<ILandholding>) {
      state.currentLandHolding = payload;
      state.isLoading = false;
      state.error = null;
    },
    getAllLandHoldingSuccess(state, {payload}: PayloadAction<ILandholding[]>) {
      state.landHoldings = payload;
      state.isLoading = false;
      state.error = null;
    },
    clearLandHoldingList(state) {
      state.landHoldings = [];
    },
    updateLandHoldingSuccess(state, {payload}: PayloadAction<ILandholding>) {
      state.currentLandHolding = payload;
      state.error = null;
    },
    updateLandHoldingFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    deleteLandHoldingSuccess(state, {payload}: PayloadAction<string>) {
      const index = state.landHoldings.findIndex((landholding: ILandholding) => landholding._id === payload);
      state.landHoldings.splice(index, 1);
    },
    deleteLandHoldingFailed(state, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    updateLandHoldingFieldValue(state, {payload}: PayloadAction<any>) {
      const payload2 = breakupPayload(payload);
      //let mergedPayload = getMergedPayload(state.currentLandHolding, payload2);
      //console.log('poayload2', mergedPayload);
      state.currentLandHolding = { ...state.currentLandHolding, ...payload2 };
    },
    clearCurrentLandHolding(state) {
      state.currentLandHolding = {} as ILandholding;
    },
    fileUploadSuccess(state, {payload}: PayloadAction<IMediaFile[]>) {
      // state.fileUpload = payload;

      // curently there is a feature in api => if upload same file, the same id is returned
      let fileIndex = state.files.findIndex((file => file.id == payload[0].id));

      // if file doesn't already exist 
      if (fileIndex === -1) {
        // add to the redux store at position 0
        state.files.splice(0, 0, payload[0]);
      }

      state.error = null;
    },
    fileUploadFailed(state, {payload}: PayloadAction<any>) {
      state.error = "An error occurred while trying ot upload the file " + JSON.stringify(payload);
    }
  }
});


// //KS: Export action creators
export const { 
  getLandHoldingStart, 
  getLandHoldingsStart, 
  getLandHoldingFailed, 
  getLandHoldingsFailed, 
  getLandHoldingByIdSuccess, 
  getAllLandHoldingSuccess,
  clearLandHoldingList,
  updateLandHoldingSuccess,
  updateLandHoldingFailed,
  deleteLandHoldingSuccess,
  deleteLandHoldingFailed,
  updateLandHoldingFieldValue,
  clearCurrentLandHolding,
  fileUploadSuccess,
  fileUploadFailed
} = LandHoldingsSlice.actions;

export const LandHoldingReducers = LandHoldingsSlice.reducer;

/***** API Calls */
export const getLandHoldings = (accessToken: string) : AppThunk => async dispatch => {
  try {
    dispatch(getLandHoldingsStart());

    //console.log("Access token: ", accessToken);
    const landHoldings = await getAllLandHoldingsFromApi(accessToken);
    dispatch(getAllLandHoldingSuccess(landHoldings));

  } catch(err) {
    //console.log("Error: ", err);
    dispatch(getLandHoldingsFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const getLandHoldingsByOrgId = (accessToken: string, orgId: string, successFn?: Function) : AppThunk => async dispatch => {
  try {
    dispatch(getLandHoldingsStart());

    //console.log("Access token: ", accessToken);
    const landHoldings = await getAllLandHoldingsByOrgId(accessToken, orgId);
    dispatch(getAllLandHoldingSuccess(landHoldings));

  } catch(err) {
    //console.log("Error: ", err);
    dispatch(getLandHoldingsFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
    if (successFn) successFn();
  }
};

export const getLandHoldingById = (accessToken: string, landholdingId: string, successFn?: Function) : AppThunk => async dispatch => {
  try {
    dispatch(getLandHoldingStart());

    const landHolding = await getLandHoldingByIdFromApi(accessToken, landholdingId);
    console.log('landholding =====', landHolding)
    dispatch(getLandHoldingByIdSuccess(landHolding));
    if (successFn) successFn(landHolding);
  } catch(err) {
    dispatch(getLandHoldingsFailed(err.toString()));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const createLandHolding = (accessToken: string, landholding: ILandholding, successFn?: Function) : AppThunk => async dispatch => {
  try {
    const name = landholding.name || (landholding.mapGraphItems && landholding.mapGraphItems[0]?.title);
    if (name) {
      const payload = {...landholding, name};
      const landHolding = await postLandHolding(accessToken, payload);
      dispatch(updateLandHoldingSuccess(landHolding));
      if (successFn) successFn(landHolding);
    }
    else {
      showMessage('Title missing from Landholding', MessageModeEnum.ERROR);
    }
    // dispatch(updateLandHoldingSuccess(landholding));
  } catch(err) {
    dispatch(updateLandHoldingFailed(err.toString()));
    if (err.customCode === 999) {
      console.log('Duplicate creation stopped by the backend');
    }
    else {
      showMessage(err.message, MessageModeEnum.ERROR);
    }
  }
};

export const updateLandHolding = (accessToken: string, landholding: ILandholding, successFn?: Function, organisationId?: string) : AppThunk => async dispatch => {
  try {
    const landHolding = await putLandHolding(accessToken, landholding, organisationId);
    dispatch(updateLandHoldingSuccess(landHolding));
    if (successFn) successFn();
  } catch(err) {
    dispatch(updateLandHoldingFailed(err.toString()));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const removeLandHolding = (accessToken: string, landholdingId: string, successFn?: Function) : AppThunk => async dispatch => {
  try {
    const landHolding = await deleteLandHolding(accessToken, landholdingId);
    dispatch(deleteLandHoldingSuccess(landholdingId));
    if (successFn) successFn();
  } catch(err) {
    dispatch(deleteLandHoldingFailed(err.toString()));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};

export const uploadMediaFile = (accessToken: string, containerId: string, module: string, moduleRefId: string, files: Array<any>) : AppThunk => async dispatch => {
  try {
    const uploadedFiles = await uploadMediaFilesById(accessToken, containerId, module, moduleRefId, files);
    dispatch(fileUploadSuccess(uploadedFiles.mediaFiles));
  }
  catch(err) {
    dispatch(fileUploadFailed(err.toString()));
    showMessage(err.message, MessageModeEnum.ERROR);;
  }
};