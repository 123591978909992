import React, { useState } from 'react';
import { Row, Col, Badge } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import '../../App/App.scss';
import './FilterBar.scss';

interface ILandholdingCare {
  children?: any;
  onLinkClicked?: Function;
}

let LandholdingCare : React.FC<ILandholdingCare> = (props: ILandholdingCare) => {
  const [ selected, setSelected ] = useState('');

  const getPillFrag : any = (statusId: string, statusTitle = statusId) => {
    const onSelected = async (name: string) => {
      if (name === selected) {
        name = '';
      }
      setSelected(name);
      props.onLinkClicked && props.onLinkClicked(name);
    };

    return (
      <Col xs={12} sm={4} md={2} className="filter-bar">
        <Badge variant={selected === statusId ? 'success' : 'secondary'}
                className='mb-1'
                onClick={(e: any) => onSelected(statusId)}>
          {statusTitle}
        </Badge>
      </Col>
    );
  };

  return (
    <React.Fragment>
      {/* <Row className="btn-margin-top mb-2">
        {getPillFrag('New')}
        {getPillFrag('InProgress', 'In Progress')}
        {getPillFrag('Implementation')}
        {getPillFrag('Monitoring')}
        {getPillFrag('Closed')}
        {getPillFrag('Deleted')}
      </Row> */}
      <Row className="margin-table-bottom">
        <div className="scroll-table">
              {props.children}
        </div>
      </Row>
    </React.Fragment>
  );
}

export default LandholdingCare;