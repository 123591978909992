import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import polygonIcon from '../../../../../Assets/Images/polygonIcon.jpg';
import Checkbox from '../../../../Components/Checkbox/Checkbox';

import { LandholdingFieldNames } from '../../../../../Models/LandHoldings/LandHoldings';
import { states } from '../../../../../Helpers/constants';
import { IStepProps, IValidation, renderDropdownControl, renderTextControl } from '../../shared';
import { getNestedValue } from '../../../../../Helpers/HelperFunctions';
import { updateLandHoldingFieldValue } from '../../../../../Store/Reducers/LandHoldingsSlice';
import BaseMap from '../../../../ArcGIS/BaseMap/BaseMap';
import { selectCurrentLandholding } from '../../../../../Store/Selectors/landholdingSelector';

import './step-1.styles.scss';

const Step1 = (props: IStepProps) => {
    const { register, getValues, setValue, isVisible, isNextClicked, getAccessToken, orgId } = props;

    const dispatch = useDispatch();
    const landholding = useSelector(selectCurrentLandholding);

    const [isCopyAddressChecked, setIsCopyAddressChecked] = useState(false);
    const [zipLength, setZipLength] = useState('');
    
    const stateList = states.map(state => ({ name: state.name, value: state.name }));
    
    const handleCopyAddress = () => {
        const copyAddress = !isCopyAddressChecked;
        setIsCopyAddressChecked(copyAddress);
        
        let address = '', postcode = '', state = '';
        if (copyAddress) {
            const values = getValues();
            address = getNestedValue(LandholdingFieldNames.addressLine1, values);
            postcode = getNestedValue(LandholdingFieldNames.addressPostCode, values);
            state = getNestedValue(LandholdingFieldNames.addressState, values);
        }
        else {
            address = postcode = state = '';
        }

        setValue(LandholdingFieldNames.postalAddressLine1, address);
        setValue(LandholdingFieldNames.postalAddressPostCode, postcode);
        setValue(LandholdingFieldNames.postalAddressState, state);
    };

    const onChange = (event: any) => {
        dispatch(updateLandHoldingFieldValue({ [event.target.name]: event.target.value }));
    };

    const textControl = (name: string, label: string, placeholder = '', validation?: IValidation, disabled = false, autoComplete = true) => {
        return renderTextControl(register, isNextClicked, getValues(), name, label, placeholder, validation, onChange, disabled, autoComplete);
    };

    const dropdownControl = (name: string, label: string, placeholder: string, list: any[], validation = {}, disabled = false) => {
        return renderDropdownControl(register, isNextClicked, getValues(), name, label, placeholder, list, validation, onChange, disabled);
    };

    const handleUpdateShape = async (shapes: any) => {
        console.log('shapes ===', landholding.mapGraphItems, shapes)
        const mapGraphItems = shapes.map((shape: any) => ({ ...shape, geometry: null }));
        await dispatch(updateLandHoldingFieldValue({ mapGraphItems }));
    };

    return (
        <div className="form-group" style={{display: isVisible ? '' : 'none'}}>
            <Row>
                <Col>
                    <div>
                        <h3>Create or Edit Property</h3>
                        <br></br>
                        <p>The information provided will help us to assess the projects that are feasible on the described land. The more information we have, the better will be our assessment on potential projects</p>                                
                        {textControl(LandholdingFieldNames.propertyName, "Give this property a name to identify it:", "Property Name", { required: true }, false, false)}
                        <br/><br/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <h6>Draw an outline of the property.</h6>
                        <ul>
                        <li>Click and drag to move the map around to desired location; scroll to zoom in and out.</li>
                        <li>Create a polygon (Note: Property name is required) by clicking on the <img src={polygonIcon} alt="polygon" /> icon.</li>
                        <li>Click on the desired locations on the map to make property boundary points.</li>
                        <li>Close the boundary by clicking the start point, and the polygon will be auto saved.</li>
                        <li>Polygons that are created on the map will also be listed under it with address and area in hectares.</li>
                        <li>You can use the icons to the right of the listings (under the map) to locate the polygon on the map or delete it.</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col /*xs={10} sm={10} md={10} lg={10} xl={10}*/>
                    <BaseMap    getAccessToken={props.getAccessToken}
                                landholding={landholding}
                                organisationId={orgId}
                                onUpdateShape={handleUpdateShape}
                    />
                    <br/><br/><br/><br/><br/>
                </Col>
            </Row>
            <h6>Address Information</h6>
            <Row>
                <Col>{textControl(LandholdingFieldNames.addressLine1, "Property Address", "e.g. Road number & name", { required: true })}</Col>
            </Row>
            <Row>
                <Col>{textControl(LandholdingFieldNames.addressPostCode, "Postcode " + zipLength, "Enter Postcode", { required: true }, isCopyAddressChecked)}</Col>
                <Col>{dropdownControl(LandholdingFieldNames.addressState, "State " + zipLength, "Please select State", stateList, { required: true }, isCopyAddressChecked)}</Col>
                <Col></Col>
            </Row>
            <Row>
                <Col><Checkbox label="Copy Property Address to Postal Address below" checked={isCopyAddressChecked} onChange={handleCopyAddress} /></Col>
            </Row>
            <Row>
                <Col>{textControl(LandholdingFieldNames.postalAddressLine1, 'Postal Address', 'e.g. Road number & name', { required: true }, isCopyAddressChecked)}</Col>
            </Row>
            <Row>
                <Col>{textControl(LandholdingFieldNames.postalAddressPostCode, `Postcode ${zipLength}`, 'Enter Postcode', { required: true }, isCopyAddressChecked)}</Col>
                <Col>{dropdownControl(LandholdingFieldNames.postalAddressState, "State ", "Please select State", stateList, { required: true }, isCopyAddressChecked)}</Col>
                <Col></Col>
            </Row>
        </div>
    );
};

export default Step1;
