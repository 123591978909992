import React from 'react';
import { Badge } from 'react-bootstrap';
import Moment from 'react-moment';

import { getNameInitials } from '../../../Helpers/HelperFunctions';
import { IChatMessage, IChatSummary } from '../../../Models/LandCare/IChat';
import ChatContactPhoto from './ContactPhoto';

interface IChatContactProps {
    summary: IChatSummary;
}

const ChatContact : React.FC<IChatContactProps> = ({ summary } : IChatContactProps) => {
    const lastMessage = summary?.messages?.length ? summary?.messages[summary?.messages.length - 1] : {} as IChatMessage;
    var parser = new DOMParser();
    var body = parser.parseFromString(lastMessage.body, 'text/html').body.innerText;
    let lastMessageBody = body && (body != 'undefined')
        ? body.substr(0, 30) + (body.length > 30 ? '...' : '')
        : '';
    const contactName = summary.userOrgContact.firstName + ' ' + summary.userOrgContact.lastName;

    let statusClass = '';
    switch (summary.lastMessageStatus) {
        case 1: statusClass = 'fa-reply'; break;
        case 2: statusClass = 'fa-check'; break;
    }

    return (
        <div className={'gerx-contact' + (summary.isRegisteredUser ? '' : ' unregistered')}>
            <ChatContactPhoto name={contactName} isOnline={summary?.isOnline || false}></ChatContactPhoto>
            
            <div className="contact-info">
                <div className="contact-name">{contactName}</div>
                <div className="contact-body" dangerouslySetInnerHTML={{__html: lastMessageBody}}></div>
            </div>

            <div className="contact-status">
                <Moment className="contact-time" date={lastMessage.createdAt} format="hh:mm A" />
                <div className="contact-count">
                    {summary.unreadMessageCount
                            ? <Badge variant="danger">{summary.unreadMessageCount}</Badge>
                            : <></>
                    }
                </div>
                <i className={'fa ' + statusClass}></i>
            </div>
        </div>
    );
}

export default ChatContact;