import React, { createRef, useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import shp from "shpjs";
import proj4 from 'proj4';
import { kml } from '@tmcw/togeojson';

import BaseMapSub from '../BaseMap/BaseMapSub';
import { ILandholding } from '../../../Models/LandHoldings/LandHoldings';
import { uploadMediaAttachment } from '../../../Store/Reducers/LandCareSlice';
import { createLandHolding, updateLandHolding } from '../../../Store/Reducers/LandHoldingsSlice';
import { MessageModeEnum, showMessage } from '../../../Helpers/Validator/validationHelper';
import { loaderStyle2 } from '../../../Helpers/constants';

import './BaseMap.scss';
import { IFeatureCollection } from './IFeatureCollection';

interface IProps {
  landholding: ILandholding;
  organisationId?: string;
  getAccessToken: Function;
  handleClose?: Function;
  autoSave?: boolean;
  basicMode?: boolean;
  onUpdateShape?: Function;
}

const BaseMap = (props: IProps) => {
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);

  const fileReader: any = createRef()

  const [ mapGraphItems, setMapGraphItems ] = useState(null as any[] | null);
  const [ mapGraphItemsDisplay, setMapGraphItemsDisplay ] = useState(null as any[] | null);
  const [ loadedFile, setLoadedFile ] = useState(null as any);

  const { landholding, autoSave = true, basicMode = false, onUpdateShape, getAccessToken, organisationId = '' } = props;

  if (!landholding) {
    showMessage('Unable to load Landholding.', MessageModeEnum.ERROR);
  }

  useEffect(() => {
    setMapGraphItemsDisplay(landholding?.mapGraphItems || []);
    //setTimeout(() => setMapGraphItemsDisplay(landholding?.mapGraphItems || []), 1000);
  }, [landholding]);

  useEffect(() => {
    setMapGraphItemsDisplay(null);
    setTimeout(() => setMapGraphItemsDisplay(mapGraphItems), 1);
  }, [mapGraphItems]);

  interface IMapGraphItem {
    collId: number,
    featureId: number,
    subId?: number,
    title?: string,
    type: string,
    address?: string;
    area?: number;
    centrePoint?: number[];
    geometry: {
      rings?: number[][],
      coordinates?: number[][],
      spatialReference: {
        latestWkid: number,
        wkid: number
      },
    }
    attributes?: any
  }

  
  const readFile = async (e: any) => {
    const reader = new FileReader();

    const extns = e.target.files[0].name.split('.');
    const extn = extns?.length ? extns[extns.length - 1] : '';
    
    reader.onload = async () => {
      const contents: any = reader?.result;

      let mapGraphItems: IMapGraphItem[] = [];
      let rings: any[][] = [];
      let geometry: any;
      let result: any;

      setIsLoading(true);

      console.log('Contents ===', contents)

      try {
        switch (extn) {
          case 'kml':
            const parser = new DOMParser();
            const xml = parser.parseFromString(contents, 'text/html');

            result = kml(xml);

            console.log('result', result)
            break;

          case 'zip':
          case '':
            result = await shp(contents);
            console.log('Polygon file data ===', result);
        }

        let totalFeatures = 0;
        let importedFeatures = 0;
        let featuresColl: IFeatureCollection[] = [];
        let features: any[];

        if (result.length) {
          featuresColl = result;
        }
        else if (result.features) {
          featuresColl = [{ features: result.features }];
        }

        if (featuresColl.length) {
          featuresColl.forEach((collection: IFeatureCollection, col: number) => {
            features = collection.features;
            totalFeatures+= features.length;

            if (features?.length) {
              features.forEach((feature: any, i: number) => {
                geometry = feature.geometry;

                let featureProps: any = {};
                Object.keys(feature.properties).forEach((key: string) => {
                  featureProps[key.toLowerCase()] = feature.properties[key];
                });

                let geometryProps: any = {};
                Object.keys(feature.geometry).forEach((key: string) => {
                  if (key != "coordinates") {
                    geometryProps[key.toLowerCase()] = feature.geometry[key];
                  }
                });

                switch (geometry?.type) {
                  case 'Polygon':
                    geometry.coordinates.forEach((coordinate: any, c: number) => {
                      rings = [coordinate];
                      rings[0] = rings[0].map((ring: any[]) => (
                        proj4('EPSG:4326', 'EPSG:3857', [ring[0],ring[1]])
                      ));

                      const props: any = geometry.coordinates.length > 1
                                          ? { collId: col, featureId: i, subId: c }
                                          : {};
                      
                      const hasProps = Object.keys(props).length;
                      const title = (featureProps.title || featureProps.name) + (hasProps ? '-' + (i + 1) + '-' + (c) : '');
                      
                      mapGraphItems.push({
                        ...props,
                        title,
                        address: hasProps ? '' : title,
                        type: geometry.type,
                        geometry: {
                          rings,
                          spatialReference: {
                            latestWkid: 3857,
                            wkid: 102100
                          }
                        },
                        attributes: {
                          properties: featureProps,
                          geometry: geometryProps,
                          type: feature.type
                        }
                      });

                      importedFeatures++;
                  });
                  break;

                  // case 'Point':
                  //   const coord = geometry.coordinates;
                  //   const points = proj4('EPSG:4326', 'EPSG:3857', [coord[0],coord[1]]);

                  //   mapGraphItems.push({
                  //     featureId: i,
                  //     title: featureProps.name + '-' + (i + 1),
                  //     type: geometry?.type,
                  //     geometry: {
                  //       coordinates: [coord[0], coord[1]],
                  //       spatialReference: {
                  //         latestWkid: 3857,
                  //         wkid: 102100
                  //       }
                  //     },
                  //     attributes: {
                  //       properties: featureProps,
                  //       type: feature.type
                  //     }
                  //   });
                  //   break;
                }
              });

              setMapGraphItems(mapGraphItems);
            }
          });
          
          console.log('Converted to mapGraphItems ===', mapGraphItems)
        }

        let subMsg = '';
        let msg: string;
        
        if (!importedFeatures) {
          showMessage(`0 out of ${totalFeatures} shapes were imported.`, MessageModeEnum.WARNING);
        }
        else if (importedFeatures < totalFeatures) {
          subMsg = `${importedFeatures} out of ${totalFeatures} shapes`;
        }

        if (importedFeatures) {
          if (autoSave) {
            msg = subMsg || 'Shapes parsed';
            showMessage(msg + ' and file uploaded successfully.', MessageModeEnum.INFO);
          }
          else {
            msg = subMsg ? subMsg + ' imported successfully.' : 'Shape file loaded successfully.';
            showMessage(msg, MessageModeEnum.INFO);
          }
        }
      }
      catch (ex) {
        showMessage(`Shape file doesn't seem to be in the correct format.`, MessageModeEnum.ERROR);
      }
      finally {
        setIsLoading(false);
      }
    };

    switch (extn) {
      case 'kml':
        reader.readAsText(e.target.files[0]);
        break;
      case 'zip':
      case '':
        reader.readAsArrayBuffer(e.target.files[0]);
        break;
      default:
        showMessage(`Unable to recognize the file of the extension '${extn}'`, MessageModeEnum.ERROR);
    }

    setLoadedFile(e.target.files[0]);
  };

  const handleUpdateShape = (shapes: any[]) => {
    setMapGraphItemsDisplay(shapes);
    //if (onUpdateShape) onUpdateShape(shapes);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const accessToken = await getAccessToken();
    //await dispatch(updateLandHoldingFieldValue({ mapGraphItems: JSON.parse(JSON.stringify(mapGraphItemsDisplay)) }));

    let stage = 0;
    try {
      const data = { ...landholding, mapGraphItems: mapGraphItemsDisplay } as ILandholding;

      stage = 1;
      if (landholding._id) {
        await dispatch(updateLandHolding(accessToken, data));
      } else {
        await dispatch(createLandHolding(accessToken, data));
      }

      stage = 2;
      const landHoldingId = data._id?.toString() || '';
      if (loadedFile) {
        await dispatch(uploadMediaAttachment(accessToken, 'landholdingpolygons', landHoldingId, [loadedFile], true, '', "files"));
      }

      showMessage('Shape saved successfully.', MessageModeEnum.SUCCESS);
      setIsSaving(false);
      handleClose();
    }
    catch (ex) {
      let message = '';
      switch (stage) {
        case 0: message = ex.message;
        case 1: message = 'An error occurred while saving the polygon data.'; break;
        case 2: message = 'An error occurred while uploading the polygon file.'; break;
      }
      showMessage(message, MessageModeEnum.ERROR);
      setIsSaving(false);
    }
  }

  const uploadFile = async (landHoldingId: string) => {
    if (loadedFile) {
      const accessToken = await getAccessToken();
      await dispatch(uploadMediaAttachment(accessToken, 'landholdingpolygons', landHoldingId, [loadedFile], true, '', "files"));
    }
  }

  const handleResetMap = () => {
    setMapGraphItems(null);
    setTimeout(() => setMapGraphItems(landholding?.mapGraphItems || []), 1);
    setLoadedFile(null);
  };

  const handleClearMap = () => {
    setMapGraphItems([]);
    setLoadedFile(null);
  };

  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };

  const waitingText = (isLoading) ? 'Loading - Please wait...' : (isSaving ? 'Saving - Please wait...' : '');

  const isDisabled = isLoading || isSaving;

  return (
    <Container fluid className="upload-polygon-file">
      <Row>
        <Col>
          <div className="upload-polygon-file">
            <Form.File name="file" ref={fileReader} style={{'display': 'none'}}
                label="Select file(s)" custom  id="mediafiles" feedbackTooltip onChange={readFile}
            />

            <div className="map-btns">
              {
                basicMode ? <></> :
                <>
                  <Button variant="primary" disabled={isDisabled} onClick={(e) => { fileReader?.current?.click(); e.preventDefault(); }} type="file">Load Polygon File</Button>
                  {!autoSave ? <Button variant="success" disabled={isDisabled || !mapGraphItemsDisplay || !mapGraphItemsDisplay.length} onClick={handleSave}>Save and Upload File</Button> : <></>}
                  <Button variant="info" disabled={isDisabled} onClick={handleResetMap}>Reset Map</Button>
                  <Button variant="warning" disabled={isDisabled || !mapGraphItemsDisplay || !mapGraphItemsDisplay.length} onClick={handleClearMap}>Clear Map</Button>
                </>
              }
              {props.handleClose ? <Button variant="secondary" onClick={handleClose}>Close</Button> : <></>}
            </div>

            <LoadingOverlay className="row" active={isDisabled} spinner text={waitingText} styles={loaderStyle2}>
              <Col>
                <div id="mapContainer" className="map-size">
                {
                    !mapGraphItemsDisplay ? <></> :
                    <BaseMapSub getAccessToken={getAccessToken}
                                listGeometryItems={mapGraphItemsDisplay ? mapGraphItemsDisplay : []}
                                thisItem={landholding}
                                thisObj={landholding}
                                autoSave={autoSave}
                                onUpdateShape={handleUpdateShape}
                                uploadFile={uploadFile}
                                organisationId={organisationId}
                    />
                }
                </div>
              </Col>
            </LoadingOverlay>
            <br/><br/><br/>
          </div>
        </Col>
      </Row>
    </Container>
  );
}


export default BaseMap;