import React, {ReactFragment, useEffect, useState} from "react";
import { ListGroup, Spinner, Button, Modal} from "react-bootstrap";

import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IUserProps } from "../../../Models/Auth/AuthModels";

import LandholdingsApiService from '../../../Services/LandHoldingsApiService';

interface IMediaFileProps {
  containerId: string; // Container Name
  module: string; // Module Name
  folderId : string; // ModuleRefId
  refresh: number; 
  getAccessToken: any;
}

interface IMediaFile {
  name: string;
  location: string;
  id: string;
}

const MediaFiles : React.FC<IMediaFileProps> = (props) => {

  const initialState: Array<IMediaFile> = [];

  const [mediafiles, updateMediafiles] = useState(initialState);
  const [isLoading, updateIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  
  const landholdingsApiService = new LandholdingsApiService();

  useEffect(() => {
    if(props.containerId && props.containerId.length > 0) {
      // Using an IIFE
      (async () => {
        if (props.containerId && props.module && props.folderId) {
          const accessToken = await props.getAccessToken();
          const landholdingMediaFiles = await landholdingsApiService.getMediaFilesById(accessToken, props.containerId, props.module, props.folderId);
          console.log('service returned: ', landholdingMediaFiles)     
          updateMediafiles(landholdingMediaFiles.data.mediaFiles);
          updateIsLoading(false);
        }
      })();
    }    
  }, [props.refresh, props.folderId]);

  const deleteMediaFile = ( async (event: any) => {
    //alert(fileName);
    event.preventDefault();

    //var blobId = event.currentTarget.getAttribute("data-value");
    var itemId: any = itemToDelete;
    if(event.currentTarget && itemId) {
      
      const accessToken = await props.getAccessToken();
      await landholdingsApiService.deleteMediaFilesById(accessToken, props.containerId, props.module, props.folderId, itemId);

      const landholdingMediaFiles = await landholdingsApiService.getMediaFilesById(accessToken, props.containerId, props.module, props.folderId);
      updateMediafiles(landholdingMediaFiles.data.mediaFiles);
    }
    handleCloseModal();
  });

  const downloadMediaFile = ( async (event: any) => {
    
    //alert(fileName);
    event.preventDefault();

    var blobId = event.currentTarget.getAttribute("data-value");
    var blobName = event.currentTarget.getAttribute("data-name");
    console.log("File Attrs:", blobId, blobName);

    if(event.currentTarget && blobId && blobName) {      
      const accessToken = await props.getAccessToken();
      var response = await landholdingsApiService.downloadMediaFileById(accessToken, props.containerId, props.module, props.folderId, blobId)

      if(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
  
        link.setAttribute('download', blobName);
        document.body.appendChild(link);
  
        link.click();
        console.log('File downloaded successfully!');
      }
      else {
        console.log('Error occurred while fetching file!');
      }
    }
  });

  const decodeUTF8 = ((str:string, mimeType: string, isUtf8?: boolean) => {
    let decoded = "";
    if(isUtf8) {
      decoded =  unescape(encodeURIComponent(str));
    }
    else{
      decoded = window.btoa(str);
    }
    decoded = `data:${mimeType};base64,${decoded}`;
    return decoded;      
  });

  const handleCloseModal = () => {
    setShowModal(false)
  }
  
  const showModalHandler = (item: string) => {
    setShowModal(true)
    setItemToDelete(item)
  }

  const renderMediaFilesAsListGroup = () : ReactFragment => {
    let fragment: ReactFragment = <>
        <Spinner animation="border" variant="secondary" size="sm">
          <span className="sr-only">Please wait...</span>
        </Spinner>
      </>;

    if(!isLoading)
    {
      // console.log("Haz state: ", mediafiles);
      // console.log(mediafiles.map((item: IMediaFile, idx: number) => <><ListGroup.Item>No files found.</ListGroup.Item> </>));
      if(mediafiles && mediafiles.length > 0) {
        fragment = (
          <>
            {mediafiles.map((file: IMediaFile, idx: number) => (
              <ListGroup.Item key={idx}>
                <span className="float-right">
                  <FontAwesomeIcon icon={faDownload} size="sm" data-value={file.id} data-name={file.name} onClick={downloadMediaFile} /> &nbsp;&nbsp; 
                  <FontAwesomeIcon icon={faTrash} size="sm" data-value={file.name} onClick={() => showModalHandler(file.id)} />
                </span>
                <span>{file.name}</span>
              </ListGroup.Item>
            ))}
          </>
        );
      }
      else {      
        fragment = <>
          <ListGroup.Item>No files found.</ListGroup.Item>
        </>;
      }
    }

    return fragment
  };

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choosing delete will permanently delete this item.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteMediaFile}>
            Delete permanently
          </Button>
        </Modal.Footer>
      </Modal>
      <ListGroup>
        {renderMediaFilesAsListGroup()}
      </ListGroup>
    </>
  );
}


export default MediaFiles; 