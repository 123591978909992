import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Row, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { contactsSchema, contactsFieldList } from '../../../Helpers/Schema/ContactsSchema';
import { IValidationField, getErrorsByFieldName, renderTooltip } from '../../../Helpers/Validator/validationHelper';
import { ILandCareUserOrgContact } from '../../../Models/LandCare/LandCare';

interface IContactAddProps {
    inputData: ILandCareUserOrgContact;
    clear?: any;
}

export interface IContactAddResult {
    isValid: boolean;
    data: ILandCareUserOrgContact;
    errors: any[];
}

const ContactAdd : React.FC<any> = forwardRef((props: IContactAddProps, ref: any) => {
    const { register, handleSubmit, reset } = useForm({ defaultValues: props.inputData });
    const [ validationErrors, setValidationErrors ] = useState({});

    useImperativeHandle(ref,
        () => ({        
            validate: async () => {
                let result: IContactAddResult = {} as IContactAddResult;

                const options = {
                    strict: false,
                    abortEarly: false,
                    stripUnknown: false,
                    recursive: true
                };
                
                await handleSubmit(async (data: any) => {
                    try {
                        await contactsSchema.validate(data, options);

                        result = {
                            data: { ...data, id: props.inputData.id },
                            isValid: true,
                            errors: []
                        };
                    }
                    catch(err: any) {
                        result = {
                            data: {} as ILandCareUserOrgContact,
                            isValid: false,
                            errors: err.errors
                        };

                        setValidationErrors(getErrorsByFieldName(contactsFieldList, err.errors));
                    }
                })();

                return result;
            },
            clear: () => {
                reset({} as any);
            }
        }),
    )

    const formTextControl = (fieldName: string) => {
        const field = contactsFieldList.find((field: IValidationField) => field.name === fieldName);
        const fieldTitle = field && field.title;
        const errors: string[] = (validationErrors as any)[fieldName] || [];
        
        const formControl = () => 
            (<Form.Control  type="input"
                            name={fieldName}
                            placeholder={fieldTitle}
                            isInvalid={errors.length ? true : false}
                            ref={register} />);

        return (
            <>
                <Form.Label>{fieldTitle}</Form.Label>
                {   errors.length ?
                        (<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(fieldName, errors)}>
                            {formControl()}
                        </OverlayTrigger>
                        )
                    : formControl()
                }
            </>
        );
    }

    const formTextAreaControl = (fieldName: string, rows?: number) => {
        const field = contactsFieldList.find((field: IValidationField) => field.name === fieldName);
        const fieldTitle = field && field.title;
        
        return (
            <>
                <Form.Label>{fieldTitle}</Form.Label>
                <Form.Control type="input" as="textarea" name={fieldName} defaultValue={(props.inputData as any)[fieldName]} placeholder={fieldTitle} ref={register} {...props} />
            </>
        );
    }

    return (
        <div className="gerx-add-container py-3">
            <Form>
                <Row>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('firstName')}</Col>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('lastName')}</Col>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('position')}</Col>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('email')}</Col>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('mobile')}</Col>
                    <Col xs={12} sm={6} md={3} lg={2}>{formTextControl('phone')}</Col>
                    <Col xs={12} sm={6} md={3} lg={4}>{formTextAreaControl('notes', 2)}</Col>
                </Row>
            </Form>
        </div>
    );
});

export default ContactAdd;