import { createSelector } from 'reselect';
import { IUserProfile } from '../../Models/Auth/IUser';
import { ISubUser } from '../../Models/LandCare/LandCare';
import { LocalStoreState, IEditingRow, IFocusInfo, IModulePageInfo, AuthStatusEnum } from '../Reducers/LocalStoreSlice';
import { IModule, IModuleConfig, IModuleConfigData, IMetaData, IModulePage } from '../Reducers/ModuleConfigSlice';
import { RootState } from '../RootStore';

export const moduleConfigSelector = (state: RootState) => state.moduleConfigData;

export const selectModules = createSelector(
    moduleConfigSelector,
    (state: IModuleConfigData) => state.modules as IModule[]
);

export const selectSiteManaagers = createSelector(
    moduleConfigSelector,
    (state: IModuleConfigData) => state.siteManagers as ISubUser[]
);

export const selectLandCareModule = createSelector(
    selectModules,
    (state: IModule[]) => state?.find((m: IModule) => m.moduleId === 'LandCarers') as IModule
);

export const selectLandCareModuleConfigPages = createSelector(
    selectLandCareModule,
    (state: IModule) => state?.pages as IModulePage[]
);


export const selectLandCareModuleConfig = createSelector(
    selectLandCareModule,
    (state: IModule) => state?.moduleConfig && state?.moduleConfig.find((config: IModuleConfig) => config.configName === 'FormFields') as IModuleConfig
);

export const selectLandCareFormFields = createSelector(
    selectLandCareModuleConfig,
    (state: IModuleConfig) => state?.metaData as IMetaData[]
);

//#region ***** region LocalStore *****/

export const localStoreSelector = (state: RootState) => state.localStore;

export const selectIsSideMenuExpanded = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.isSideMenuExpanded
);

export const selectCurrentMenuOption = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.currentMenuOption
);

export const selectAuthStatus = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.authStatus as AuthStatusEnum
);

export const selectIsEditingRow = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.isEditingRow as IEditingRow[]
);

export const selectFocusInfo = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.focusInfo as IFocusInfo
);

export const selectUserProfile = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.userProfile as IUserProfile
);

export const selectHomePageInfo = createSelector(
    [localStoreSelector],
    (state: LocalStoreState) => state.modulePageInfo.home as IModulePageInfo
);

//#endregion