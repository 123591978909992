import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface ChatFilesProps {
  files?: any[];
  displayMode: DisplayMode;
  onCrossClicked?: Function;
}

export enum DisplayMode {
  ATTACH,
  DOWNLOAD
}

const ChatFiles : React.FC<ChatFilesProps> = (props: ChatFilesProps) => {
  const isDownloadMode = props.displayMode === DisplayMode.DOWNLOAD;

  return <>
    {
      props.files && props.files.length ?
      <div className={'msg-files' + (isDownloadMode ? ' download' : ' attach')}>
          {
            props.files.map((file: any, index: number) => {
              return (
                <div key={'file_' + index} className="msg-file">
                  <Form.Label className="msg-filename">
                    { isDownloadMode
                      ? <Link to="/">{file.name}</Link>
                      : file.name
                    }
                  </Form.Label>
                  {
                    isDownloadMode ? <></> :
                    <i className="msg-cross fa fa-times" onClick={() => props.onCrossClicked && props.onCrossClicked(index)} />
                  }
              </div>
              )
            })
          }
          {
            isDownloadMode ? <></> :
            <div className="msg-files-note">
              Please note: Attached files will be sent along with the next message.
            </div>
          }
      </div>
      : <></>
    }
    </>
};

export default ChatFiles;