import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { stopReportingRuntimeErrors } from "react-error-overlay";

import App from './Components/App/App';
import * as serviceWorker from './Services/serviceWorker';
import RootStore from './Store/RootStore';
import { AuthProvider, getApiAccessToken, msalInstance } from './Containers/Auth/MsalBrowserProvider';
import { getIsUserAuthenticated, getUserInfo } from './Services/serviceHelper';

import './index.css';

if (process.env.NODE_ENV === "development") {
  stopReportingRuntimeErrors(); // disables error overlays
}

const isAuthenticated = getIsUserAuthenticated();

const login = () => {
  //window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signin';
  msalInstance.loginRedirect();
};

const logout = () => {
  //msalInstance.logoutRedirect();
  localStorage.clear();
  window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signout';
};

const signup = () => {};

const getAccessToken = () => getApiAccessToken(msalInstance);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={RootStore}>
        <App  isAuthenticated={isAuthenticated}
              user={() => getUserInfo()}
              login={login}
              logout={logout}
              signup={signup}
              getAccessToken={getAccessToken}/>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
