import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { Container, Row, Col } from 'react-bootstrap';

import BaseMap from '../BaseMap/BaseMap';
import { ILandholding } from '../../../Models/LandHoldings/LandHoldings';
import { updateLandHoldingSuccess } from '../../../Store/Reducers/LandHoldingsSlice';
import { getUserProfile } from '../../../Store/Reducers/LocalStoreSlice';
import { selectUserProfile } from '../../../Store/Selectors/rootSelector';
import { getLandCareData, resetCurrentLandCare } from '../../../Store/Reducers/LandCareSlice';
import { selectCurrentLandholding } from '../../../Store/Selectors/landholdingSelector';
import { loaderStyle2 } from '../../../Helpers/constants';

import './UploadPolygonFile.scss';

const UploadPolygonFile = (props: any) => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { match: {params} } = props;
  const landCareId = props.match.params.landCareId;
  
  const userProfile = useSelector(selectUserProfile);

  const landholding = useSelector(selectCurrentLandholding);

  const [ isLoading, setIsLoading ] = useState(true);

  const waitingText = 'Loading - Please wait...';

  useEffect(() => {
    if (!Object.keys(userProfile).length) {
      const refreshObj = async () =>  {
        const accessToken = await props.getAccessToken();
        
        if (accessToken && !Object.keys(userProfile).length) {
          setIsLoading(true);
          await dispatch(getUserProfile(accessToken));
          setIsLoading(false);
        }
      };
      
      refreshObj();
    }
  }, [isAuthenticated]);
  
  useEffect(() => {
    const refreshObj = async () => {
      const accessToken = await props.getAccessToken();
      
      if (accessToken && userProfile.id) {
        const successFn = (landholding: ILandholding) => {
          dispatch(updateLandHoldingSuccess(landholding));
          setIsLoading(false);
        };
        setIsLoading(true);
        await dispatch(getLandCareData(accessToken, userProfile.id, landCareId, [], successFn));
      }
    };

    refreshObj();
  }, [userProfile?.id]);

  const handleCloseMap = () => {
    dispatch(resetCurrentLandCare());

    let path = `/projects/${landCareId}`;
    history.push(path);
  };

  return (
    <Container fluid className="add-landholding">
      <Row>
        <Col>
          <LoadingOverlay className="row" active={isLoading} spinner text={waitingText} styles={loaderStyle2}>
            <BaseMap  getAccessToken={props.getAccessToken}
                      landholding={landholding}
                      autoSave={false}
                      handleClose={handleCloseMap}
            />
          </LoadingOverlay>
        </Col>
      </Row>
    </Container>
  );
}


export default UploadPolygonFile;