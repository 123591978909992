import React, { useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Popover, Row, Spinner } from 'react-bootstrap';

import { ILandCareUserOrgContact } from '../../../Models/LandCare/LandCare';
import { addUserContact, landCareStartLoading, landCareStopLoading, removeUserContact, saveUserContact, updateUserOrgContactField } from '../../../Store/Reducers/LandCareSlice';
import { ConfirmModal } from '../../Layout/ConfirmModal';
import { contactsFieldList, contactsSchema } from '../../../Helpers/Schema/ContactsSchema';
import { IValidationField, MessageModeEnum, showMessage, validateField } from '../../../Helpers/Validator/validationHelper';
import ContactAdd, { IContactAddResult } from './ContactAdd';
import { spinnerStyle } from '../../../Helpers/constants';
import { selectError } from '../../../Store/Selectors/landCareSelectors';

interface IContactList {
    getAccessToken: Function;
    contacts: ILandCareUserOrgContact[];
    userOrgId: string;
};

const ContactList : React.FC<any> = (props: IContactList) => {
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ deleteId, setDeleteId ] = useState<string>('');
    const [ isAdding, setIsAdding ] = useState<boolean>(false);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ focusInfo, setFocusInfo ] = useState({} as any);
    
    const landCareError = useSelector(selectError);

    const dispatch = useDispatch();

    const accessToken = props.getAccessToken();

    const handleAction = (action: string, rowNo: number, makeRowVisible: boolean) => {
        switch (action) {
            case 'D':
                setShowDeleteModal(true);
                const id = props.contacts[rowNo].id;
                setDeleteId(id);
                break;
        }
    };

    const deleteContact = async () => {
        const accessToken = await props.getAccessToken();
        await dispatch(removeUserContact(accessToken, deleteId));
        await setShowDeleteModal(false);
    };

    const onBlur = async (oldVal: string, newVal: string, row: ILandCareUserOrgContact, column: any, done: Function) => {
        const fieldName = column.dataField;

        if (fieldName === 'email') {
            showMessage('Email address cannot be modified.');
            return false;
        }

        if (newVal !== oldVal) {
            const contactId = row.id;
            const rowNo = contacts.findIndex((contact: ILandCareUserOrgContact) => contact.id === contactId );

            try {
                validateField(contactsSchema, fieldName, newVal);
                await updateField(fieldName, rowNo, contactId, newVal);
                done();
            }
            catch (err: any) {
                const errors: string[] = err.errors.map((err: string) => err.replace('this', column.text));
                
                let errorStr = '';
                errors.forEach((err: string) => errorStr += '\u2022 ' + err + '\n');

                showMessage(errorStr);

                done(false);
            }
        }

        return { async: true };
    };

    const updateField = async (fieldName: string, rowNo: number, contactId: string, value: string) => {
        setIsSaving(true);
        setFocusInfo({ fieldName, rowNo });

        const accessToken = await props.getAccessToken();
        await dispatch(updateUserOrgContactField(accessToken, contactId, fieldName, value));
        setIsSaving(false);
        setFocusInfo({});
    }

    const fieldCheck = (fieldName: string, rowNo: number) => {
        return (fieldName === focusInfo.fieldName) && (rowNo === focusInfo.rowNo);
    };
    
    const columnFormatter = (fieldName: string, cell: any, row: ILandCareUserOrgContact, rowNo: number, formatExtraData: any) => {
        const tooltip = 'Double-click the cell to edit';

        const divStyle: any = fieldName === 'notes' ?
            {
                maxHeight: '70px',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
            :
            {};

        return (
            <div style={divStyle} key={fieldName} title={tooltip}>{cell}</div>
        );
    };

    const getColumns = () => {
        const colNames = [ 'firstName', 'lastName', 'position', 'email', 'mobile', 'phone', 'notes' ];

        let columns: any[] = colNames.map((colName: string) => {
            const field: IValidationField = contactsFieldList.find((field: IValidationField) => colName === field.name) as IValidationField;

            return {
                dataField: field.name,
                text: field.title,
                editor: { type: field.name === 'notes' ? Type.TEXTAREA : Type.TEXT },
                headerStyle: () => ({ width: field.width }),
                // style: getColumnStyle(),
                formatter: (cell: any, row: ILandCareUserOrgContact, rowIndex: number, formatExtraData: any) =>
                    columnFormatter(field.name, cell, row, rowIndex, formatExtraData)
            };
        });

        columns.push({
            isDummyField: true,
            editable: false,
            formatter: (cell: any, row: ILandCareUserOrgContact, rowIndex: number) => {
                return (
                    <i  className="fa fa-trash-alt gerx-hand-pointer"
                        onClick={() => handleAction('D', rowIndex, false)}
                    />
                );
            }
        });

        return columns;
    };

    let contacts = props.contacts.map((contact: ILandCareUserOrgContact) => ({...contact}));

    if (contacts.length > 1) {
        let firstPrimaryContact = contacts.find(contact => contact?.isPrimary);

        if (firstPrimaryContact) {
            contacts = contacts.filter(contact => contact !== firstPrimaryContact);
        } else {
            // if there is no primary contacts - exclude the first (as it would be used in Contact.tsx as primary)
            contacts = contacts.slice(1, contacts.length);
        }
    }
    else {
        // if there is only one contact - that contact will be used in Contact.tsx and there should be no secondary contacts
        contacts = [] as Array<ILandCareUserOrgContact>;
    }

    let contactAddRef: any = useRef();

    const validateAndSave = async () => {
        const result: IContactAddResult = await contactAddRef?.current?.validate();
        
        if (result.isValid) {
            const accessToken = await props.getAccessToken();
            const data = { ...result.data, userOrgId: props.userOrgId };
            const successFn = () => { clearAddForm(); showMessage('Record added successfully', MessageModeEnum.SUCCESS); };
            dispatch(landCareStartLoading());
            await dispatch(addUserContact(accessToken, data, successFn));
            dispatch(landCareStopLoading());
        }
    };

    const clearAddForm = () => {
        contactAddRef?.current?.clear();
        setIsAdding(false);
    };

    return (
        <div key='main'
            className="py-3">
            <ConfirmModal showModal={showDeleteModal}
                heading="Delete the Contact"
                text="Permanently delete this Contact?"
                handleConfirm={() => deleteContact()}
                handleClose={() => setShowDeleteModal(false)}
                confirmButtonText="Delete"
            />

            <Row>
                <Col xl="6">
                    <h5>Secondary Contacts</h5>
                </Col>
                <Col xl="6" className="gerx-button-group text-right">
                    <Button className="btn-primary" style={{ display: isAdding ? 'none' : '' }} onClick={() => setIsAdding(true)}>Add Contact</Button>
                    <Button className="btn-success" style={{ display: isAdding ? '' : 'none' }} onClick={() => validateAndSave()}>Save</Button>
                    <Button className="btn-secondary" style={{ display: isAdding ? '' : 'none' }} onClick={() => clearAddForm()}>Cancel</Button>
                </Col>

            </Row>

            <div key={'add'} className={'gerx-add ' + (isAdding ? 'mt-3 opened' : 'closed')}>
                <ContactAdd ref={contactAddRef} inputData={{}} />
            </div>
            
            <BootstrapTable
                keyField="id"
                data={ contacts }
                columns={getColumns()}
                noDataIndication="No records found"
                classes={"table table-bordered table-striped table-sm " + (isSaving ? "saving" : "")}
                cellEdit={ cellEditFactory({
                    mode: 'dbclick',
                    blurToSave: true,
                    beforeSaveCell: onBlur,
                }) }
        />
        </div>
    );
};

export default ContactList;