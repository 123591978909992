import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import filterFactory from "react-bootstrap-table2-filter";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import './PaginationTable.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';

interface IPaginationTable {
    keyField: string;
    data: any[];
    columns: any[];
    classes: string;
    pageNumber?: number;
    pageSize?: number;
    recordCount?: number;
    rowEvents: any;
    onPageChanged: Function;
    onPageSizeChanged?: Function;
}

const PaginationTable : React.FC<IPaginationTable> = (props: IPaginationTable) => {
    const handlePageClick = ({ selected }: any) => {
        props.onPageChanged(selected + 1);
    };

    const onPageSizeChanged = (pageSize: any) => {
        props.onPageSizeChanged && props.onPageSizeChanged(pageSize as number);
    };

    const pageCount = props?.recordCount ? (props.recordCount / (props?.pageSize || 1)) : 0
    
    const tableHeight = (window.innerHeight - 220) + "px";
    
    return (
        <div className="pagination-table" style={{maxHeight: tableHeight}}>
            <BootstrapTable keyField={ props.keyField }
                            data={ props.data }
                            columns={ props.columns }
                            classes={ props.classes }
                            filter={filterFactory()}
                            rowEvents={ props.rowEvents }
                            bootstrap4
            />

            <div className="paginator">
                <DropdownButton title={props.pageSize} onSelect={onPageSizeChanged}>
                    <Dropdown.Item eventKey="25">25</Dropdown.Item>
                    <Dropdown.Item eventKey="50">50</Dropdown.Item>
                    <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>

                <ReactPaginate  previousLabel={"‹"}
                                nextLabel={"›"}
                                forcePage={(props?.pageNumber ? props.pageNumber : 1) - 1}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"page-item disabled"}
                                activeClassName={"page-item active"}
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                />
            </div>
        </div>
    );
};

export default PaginationTable;