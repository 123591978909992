import { createSelector } from 'reselect';

import { RootState } from '../RootStore';
import { ILandCareState } from '../Reducers/LandCareSlice';
import { ILandCarer, ILandCareUserOrg, ILandCarerNote, ILandCareUserOrgContact, IPlantingSite, ILandholding } from '../../Models/LandCare/LandCare';
import { IMediaFile } from '../../Models/MediaFiles/IMediaFile';

export const landCareSelector = (state: RootState) => state.landCarers;

export const selectUserOrg = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.userOrg as ILandCareUserOrg
);

export const selectUserOrgContacts = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.userOrgContacts as ILandCareUserOrgContact[]
);

export const selectOrgUsers = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.orgUsers as ILandCareUserOrgContact[]
);


export const selectLandCarerNotes = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.landCarerNotes as ILandCarerNote[]
);

export const selectCurrentLandcare = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.currentLandCare as ILandCarer
);

export const selectCurrentPlantingSite = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.currentPlantingSite as IPlantingSite
);

export const selectError = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.error as any
);

export const selectFileUploaded = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.fileUpload as IMediaFile[]
);

export const selectLandCarerFiles = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.landCarerFiles as IMediaFile[]
);

export const selectLandCarerPlantingSites = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.currentLandCare?.plantingSites as IPlantingSite[]
);

export const selectCurrentLandHolding = createSelector(
    [landCareSelector],
    (state: ILandCareState) => state.currentLandCare?.landHolding as ILandholding[]
);

export const selectPolygonFiles = createSelector(
    landCareSelector,
    (state: ILandCareState) => state.polygonFiles as IMediaFile[]
);