import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { parse } from 'json2csv';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { IUserProfile, IUserRole } from '../../Models/Auth/IUser';
import { fetchCorrelationId, fetchUserProfile, getLandCareReport } from '../Api/LandCareApi';
import { AppThunk } from '../RootStore';

export interface IEditingRow {
  rowNo: number;
  isEditing: boolean;
};

export interface IFocusInfo {
  fieldName: string;
  rowNo: number;
};

export enum AuthStatusEnum {
  Undefined,
  Authenticated,
  NotAuthenticated
};

export interface LocalStoreState {
  currentLandHoldingStep: number;
  isSideMenuExpanded: boolean;
  currentMenuOption: string;
  accessToken: string;
  authStatus: AuthStatusEnum;
  isEditingRow: IEditingRow[];
  focusInfo: IFocusInfo;
  userProfile: IUserProfile;
  reportData: any;
  error: any;
  modulePageInfo: {
    home: IModulePageInfo
  };
};

export interface IModulePageInfo {
  pageNumber?: number,
  pageSize?: number,
  recordCount?: number
};

export interface IModulePageInfoPayload {
  moduleName: ModulesEnum,
  modulePageInfo: IModulePageInfo
}

const initialState: LocalStoreState = {
  currentLandHoldingStep: 1,
  isSideMenuExpanded: false,
  currentMenuOption: '',
  accessToken: '',
  reportData: null,
  authStatus: AuthStatusEnum.Undefined,
  isEditingRow: [],
  focusInfo: {} as IFocusInfo,
  userProfile: {} as IUserProfile,
  error: {},
  modulePageInfo: {
    home: {
      pageNumber: 1,
      pageSize: 25,
      recordCount: 0
    }
  }
};

export enum ModulesEnum {
  Home
};

const LocalStoreSlice = createSlice({
  name: "localStore",
  initialState,
  reducers: {
    setLandHoldingStep: (state: LocalStoreState, {payload}: PayloadAction<number>) => { 
      //KS: Can have array.push, etc in here too! ;)
      state.currentLandHoldingStep = payload as number
    },
    toggleSideMenu(state: LocalStoreState) {
      state.isSideMenuExpanded = !state.isSideMenuExpanded;
    },
    setSideMenuExpanded(state: LocalStoreState, {payload}: PayloadAction<boolean>) {
      state.isSideMenuExpanded = payload;
    },
    setAccessToken(state: LocalStoreState, {payload}: PayloadAction<string>) {
      state.accessToken = payload
    },
    setAuthStatus(state: LocalStoreState, {payload}: PayloadAction<AuthStatusEnum>) {
      state.authStatus = payload
    },
    setRowEditing(state: LocalStoreState, {payload}: PayloadAction<IEditingRow>) {
      let editingArray = [...state.isEditingRow];
      editingArray[payload.rowNo] = payload;
      state.isEditingRow = editingArray;
    },
    clearRowEditing(state: LocalStoreState) {
      state.isEditingRow = [];
    },
    setFocusInfo(state: LocalStoreState, {payload}: PayloadAction<IFocusInfo>) {
      state.focusInfo = payload;
    },
    clearFocusInfo(state: LocalStoreState) {
      state.focusInfo = {} as IFocusInfo;
    },
    getUserProfileSuccess(state: LocalStoreState, {payload}: PayloadAction<IUserProfile>) {
      state.userProfile = payload;
    },
    getUserProfileFailed(state: LocalStoreState, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    getLandCareReportDataFailed(state: LocalStoreState, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    setModulePageInfo(state: LocalStoreState, {payload}: PayloadAction<IModulePageInfoPayload>) {
      switch (payload.moduleName) {
        case ModulesEnum.Home: state.modulePageInfo.home = {
          ...state.modulePageInfo.home,
          ...payload.modulePageInfo
        };
        break;
      }
    }
  }
});

//KS: Export reducer (Can include other reducers here too)
export const LocalStoreReducers = LocalStoreSlice.reducer;

export const getUserProfile = (accessToken: string, history?: any) : AppThunk => async dispatch => {
  try {
    const result = await fetchUserProfile(accessToken);
    const index = result?.roles?.findIndex((role: IUserRole) => role.roleName === 'GERXUser');
    if (index === -1) {
      //history.push('/unauthorized');
    }
    await dispatch(getUserProfileSuccess(result));
  } catch(err) {
    dispatch(getUserProfileFailed(err));
    showMessage(err.message, MessageModeEnum.ERROR);
  }
};

export const getLandCareReportUrl = (url: any) : AppThunk => async dispatch => {
  try {
    const report_data = await getLandCareReport(url);
    let result = parse(report_data.data);
    const data = new Blob([result], { type: 'text/plain;charset=utf-8' });
    
    // This sets up the link and clicks it to download the file as well
    const element = document.createElement("a");
    element.href = URL.createObjectURL(data);
    element.download = `${report_data.title}.csv`;
    document.body.appendChild(element);
    element.click();
  } catch(err) {
    dispatch(getLandCareReportDataFailed(err));
  }
};

export const getCorrelationId = (accessToken: string, handleCorrelationId?: Function) : AppThunk => async dispatch => {
  try {
    const result = await fetchCorrelationId(accessToken);
    if (handleCorrelationId) handleCorrelationId(result.guid);
  }
  catch (err) {
    console.log('Error in getting the CorrelationID');
  }
}

// //KS: Export action creators
export const { setLandHoldingStep, toggleSideMenu, setSideMenuExpanded, setAccessToken, setRowEditing, clearRowEditing, setFocusInfo, clearFocusInfo, getUserProfileSuccess, getUserProfileFailed, setModulePageInfo, setAuthStatus, getLandCareReportDataFailed } = LocalStoreSlice.actions;
