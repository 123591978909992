import axios, { AxiosRequestConfig } from 'axios';
import { showMessage } from '../Helpers/Validator/validationHelper';
import { ISignalRNegotiationInfo } from '../Models/SignalR/ISignalRNegotiationInfo';

export class SignalRApiService {

    private baseUrl?: string;

    constructor () {
        this.baseUrl = process.env.REACT_APP_LANDCARE_CHAT_NEGOTIATION_URL;
    }

    postSignalRNegotiationInfo = async (accessToken: string) : Promise<ISignalRNegotiationInfo> => {
        let responseData: ISignalRNegotiationInfo = { url: '', accessToken: '' };

        const config : AxiosRequestConfig = {
            headers: { 
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const negotiationUrl = `${this.baseUrl}`;

        try {
            const response = await axios.get(negotiationUrl, config);
            responseData = response?.data;
        }
        catch(err: any) {
            showMessage(err.message);
        }

        return responseData;
    }
}