import React from 'react';
import { loadModules } from 'esri-loader';
import proj4 from 'proj4';
import lodash, { cloneDeep } from 'lodash';

import { ILandCarer } from '../../Models/LandCare/LandCare';
import { ListGroup } from 'react-bootstrap';
import { waitUntil } from '../../Helpers/HelperFunctions';
import { getCenterPoint, getGeometryItemsWithLocations } from '../../Helpers/polygonFunctions';
import LoadingOverlay from 'react-loading-overlay';
import { loaderStyle2 } from '../../Helpers/constants';
import { getFeatureCollection } from '../../Helpers/polygonFunctions';
import { IFeatureId } from './BaseMap/IFeatureCollection';

import './OverviewMap.scss';

interface IOverviewMapProps {
  listGeometryItems?: Array<any> | null;
  currentLandCare: ILandCarer;
  getAccessToken: Function;
}

interface IOverviewMapState {
  status?: string;
  listGeometryItems?: Array<any> | null;
  showModal: boolean;
  itemToDelete: any;
}

class OverviewMap extends React.Component<IOverviewMapProps, IOverviewMapState> {

  overviewMap: any;
  overviewMapView: any;
  graphicsLayer: any;
  plantingSitesGraphicsLayer: any;
  esriMapObject:any;
  listGeometryItems: any;
  listPolygonItems: Array<any>;
  addressLocator: any;

  constructor(props: IOverviewMapProps, state: IOverviewMapState) {
    super(props);

    this.listPolygonItems = [];
    if(this.props.currentLandCare && this.props.currentLandCare.plantingSites && this.props.currentLandCare.plantingSites.length > 0) {
      this.props.currentLandCare.plantingSites.forEach((projectSite) => {        
        if(projectSite && projectSite.polygon && projectSite.polygon.length > 0) {
          this.listPolygonItems.push(projectSite.polygon[0]);
        }
      });      
    }

    this.state = {
      status: 'loading',
      listGeometryItems: null,
      showModal: false,
      itemToDelete: {}
    }
  }

  async componentDidMount() {
    // console.log('Obj in mapper: ', this.props.thisItem)
    //console.log('Reducer obj: ', this.props.thisObj.mapGraphItems)

    const modules = await loadModules([
      'esri/views/MapView', 
      'esri/WebMap',
      'esri/widgets/Search',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/widgets/Sketch',
      "esri/config",
      "esri/tasks/Locator",
      "esri/geometry/geometryEngine"
    ]);

    const [ MapView, WebMap, Search, Graphic, GraphicsLayer, Sketch, Config, Locator, GeometryEngine ] = modules;

    try {
      this.esriMapObject = {};
      this.esriMapObject.MapView = MapView;
      this.esriMapObject.WebMap = WebMap;
      this.esriMapObject.Search = Search;
      this.esriMapObject.Graphic = Graphic;
      this.esriMapObject.GraphicsLayer = GraphicsLayer;
      this.esriMapObject.Config = Config;
      this.esriMapObject.Locator = Locator;
      this.esriMapObject.GeometryEngine = GeometryEngine;

      this.esriMapObject.Sketch = Sketch;
      this.graphicsLayer = new GraphicsLayer();
      this.plantingSitesGraphicsLayer = new GraphicsLayer();
      
      this.renderMap();
      this.renderMapGraphicItems();
      this.renderProjectSiteItems();
      this.overviewMap.add(this.graphicsLayer);
      this.overviewMap.add(this.plantingSitesGraphicsLayer);
      
      const geometryItems = cloneDeep(this.props.listGeometryItems) || [];
      
      this.addressLocator = new this.esriMapObject.Locator ({
        url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
      });

      const collIds = getFeatureCollection(geometryItems);

      const listGeometryItems = await getGeometryItemsWithLocations(geometryItems, collIds, this.addressLocator);

      this.setState({
        status: "done",
        listGeometryItems
      });
    }
    catch (err) {
      // handle any errors
      console.error(err);
    };
  }

  renderOverviewMap = () => {
    if(this.state.status === 'loading') {
      return <div>loading</div>;
    }
    else {
      return <></>;
    }
  }

  renderMap = () => {

    const zoomLevel = 4;
    const mapCenter = [133.77, -28.27]; // best value for mapId center at mapZoomLevel
		const mapId = "86265e5a4bbb4187a59719cf134e0018";  // ESRI imaging hybrid (see https://www.arcgis.com/home/item.html?id=86265e5a4bbb4187a59719cf134e0018)

    // then we load a web map from an id
    this.overviewMap = new this.esriMapObject.WebMap({
      portalItem: { // autocasts as new PortalItem()
        id: mapId
      }
    });

    this.overviewMapView = new this.esriMapObject.MapView({
      map: this.overviewMap,
      container: 'viewDiv',
      zoom: zoomLevel,
			center: mapCenter
    });

    
    this.overviewMapView.on("layerview-create", (event: any) => {
        var info = "<br> <span> layerview-create </span> - " + event.layer.title + " is " + event.layer.loadStatus;
        console.log("Info: ", info);
        this.zoomToFirstPolygonFeature();
      });

    // const that = this;
    // that.graphicsLayer.when(function() {
    //   return that.graphicsLayer.queryExtent();
    // })
    // .then(function(response:any) {
    //   that.overviewMapView.goTo(response.extent);
    // });

    // this.overviewMapView.whenLayerView(that.graphicsLayer)
    //   .then(function(layerView:any){
    //     layerView.watch("updating", function(val:any){
    //       if(!val) {
    //         layerView.queryExtent()
    //         .then(function(response:any) {
    //           that.overviewMapView.goTo(response.extent);
    //         });
    //       }
    //     });
    //   });
   
  }

  renderMapGraphicItems = () => {

    const listGeometryItemsRender = this.props.listGeometryItems
    console.log('Polygons in OverviewMap: ', listGeometryItemsRender)

    if(listGeometryItemsRender && listGeometryItemsRender.length > 0) {
      listGeometryItemsRender.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item.id, item.geometry.x, item.geometry.y);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item.id, item.paths);
            break;
          case "Polygon":
          case "polygon" :
            //this.renderMapPolygons(item.id, item.rings);
            this.renderMapPolygons(item.id, item.geometry.rings);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }


  renderProjectSiteItems = () => {
    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)
    let spatialReference: any;

    console.log('Polygons in PlantingSiteMap: ', this.listPolygonItems);    

    if(this.listPolygonItems && this.listPolygonItems.length > 0) {
      this.listPolygonItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item.id, item.geometry.x, item.geometry.y, spatialReference, this.plantingSitesGraphicsLayer);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item.id, item.paths, spatialReference, this.plantingSitesGraphicsLayer);
            break;
          case "Polygon":
          case "polygon" :
            //this.renderMapPolygons(item.id, item.rings, spatialReference, this.plantingSitesGraphicsLayer);
            this.renderMapPolygons(item.id, item.geometry.rings, spatialReference, this.plantingSitesGraphicsLayer);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderMapPoints = (pointId: number, x: number, y: number, spatialReference?: any, customGraphicsLayer?: any) => {

    console.log("Rendering point on map");
    var simpleMarkerSymbol = {
      type: "simple-marker",
      color: [192, 192, 192], // silver
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };    
    
    var point = {
      type: "point",
      //latitude: lat, //34.0005930608889,
      //longitude: lng // -118.80657463861
      x: x,
      y: y,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };
    
    if(spatialReference) {
      point.spatialReference = spatialReference;
    }
    
    var pointGraphic = new this.esriMapObject.Graphic({
      symbol: simpleMarkerSymbol,
      geometry: point,
      attributes: {
        gId: pointId
      }
    });

    //this.graphicsLayer.add(pointGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(pointGraphic);
    }
    else {
      this.graphicsLayer.add(pointGraphic);
    }
  }

  renderMapLines = (polylineId: number, paths: Array<Array<number>>, spatialReference?: any, customGraphicsLayer?: any) => {

    var simpleLineSymbol = {
      type: "simple-line",
      color: [80, 80, 80], // dark-silver
      width: 1.5
    };

    var polyline = {
      type: "polyline",
      paths: paths,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polyline.spatialReference = spatialReference;
    }

    var polylineGraphic = new this.esriMapObject.Graphic({
      symbol: simpleLineSymbol,
      geometry: polyline,
      attributes: {
        gId: polylineId
      }
    });

    //this.graphicsLayer.add(polylineGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polylineGraphic);
    }
    else {
      this.graphicsLayer.add(polylineGraphic);
    }
  }

  renderMapPolygons = (polygonId: number, rings: Array<Array<number>>, spatialReference?: any, customGraphicsLayer?: any) => {
    console.log('Rendering polygon on map.')
    var simpleFillSymbol = {
      type: "simple-fill",
      color: [192, 192, 192, 0.6], // silver, opacity 60%
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };
    
    var polygon = {
      type: "polygon",
      rings: rings,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polygon.spatialReference = spatialReference;
    }
    
    var polygonGraphic = new this.esriMapObject.Graphic({
      symbol: simpleFillSymbol,
      geometry: polygon,
      attributes: {
        gId: polygonId
      }
    });
    
    //this.graphicsLayer.add(polygonGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polygonGraphic);
    }
    else {
      this.graphicsLayer.add(polygonGraphic);
    }
  }

  zoomToFirstPolygonFeature = () => {

    let hasAtLeastOnePolygon = false;
    let firstPolygon;
    let polygonToCenterAt: any;

    if(this.graphicsLayer && this.graphicsLayer.graphics && this.graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = this.graphicsLayer.graphics;
      for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {

        let gItem = mapGeometryItems.items[idx];
        let feature = gItem.geometry;
        if(feature.type === 'polygon' || feature.type === 'Polygon')
        {
            if(!hasAtLeastOnePolygon) {
              hasAtLeastOnePolygon = true;
              //polygonToCenterAt = {...feature);
              //polygonToCenterAt = Object.assign({}, feature);
              //polygonToCenterAt = JSON.parse(JSON.stringify(feature));
              //firstPolygon = lodash.cloneDeep(feature);
              polygonToCenterAt = lodash.cloneDeep(feature);
              //polygonToCenterAt.rings = feature.rings;

              //console.log("Shape is a polygon...", firstPolygon);
              this.overviewMapView.goTo(feature.extent); //Works!
            }
            // else {
            //   feature.rings[0].forEach( (item: any) => {
            //     polygonToCenterAt.rings[0].push(item);
            //   });
            // }

            // var polygonCenter = feature.extent.center; //Works! Gets Lat/Lon at the point!
            // //https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html
            // this.overviewMapView.center = [polygonCenter.longitude, polygonCenter.latitude];
            // this.overviewMapView.zoom = 8;            
        }
      }
      
      // if(hasAtLeastOnePolygon) {
      //   this.overviewMapView.goTo(polygonToCenterAt.extent); //ToCheck
      // }
    }
  }

  renderMapGeometryItems = () => {

    let fragment = <></>;
    
    if(this.state.listGeometryItems && this.state.listGeometryItems.length > 0) {
      fragment = (
      <>
        {this.state.listGeometryItems.map((item: any, idx: number) => (
          <ListGroup.Item key={item.idx} className={item.address ? 'main-location': ''}>
          {
            item.address
            ? <span>Location: {item.address} [Area: {(Math.round(item.area * 100) / 100).toFixed(2)} hectares]</span>
            : <span>&nbsp;&nbsp;&nbsp;Shape: {item.title} [Area: {(Math.round(item.area * 100) / 100).toFixed(2)} hectares]</span>
          }
          </ListGroup.Item>
        ))}
      </>
      );
    }
    else if (!this.state.listGeometryItems) {
      fragment = <LoadingOverlay active={!this.state.listGeometryItems} spinner text='Calculating, please wait...' styles={loaderStyle2}>
                    <div className='waiting'></div>
                  </LoadingOverlay>
    }
    else {
      fragment = <>
        <ListGroup.Item>No shape items found.</ListGroup.Item>
      </>;
    }

    return fragment;
  }

  render() {
    return (
      <div className="overview-map">
        <div id="mapDiv">
          <div id="viewDiv" style={{height: '40vh', width: '100%'}}>{this.renderOverviewMap}</div>
        </div>
        <div>
          <ListGroup>
            {this.renderMapGeometryItems()}
          </ListGroup>
        </div>
      </div>
    )
  }
}

export default OverviewMap;