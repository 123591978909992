import React from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { getNestedValue } from '../../../Helpers/HelperFunctions';
import { INameValuePair } from '../../../Models/App/AppModels';
import { ILandholding, ILandholdingProjectStyle, ILandholdingSoilType } from '../../../Models/LandHoldings/LandHoldings';

export interface IValidation {
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
}

const validate = (value: any, validation?: IValidation) => {
    let validInput = true;

    if (validation?.required && (!value || (value && !value.trim()).length)) validInput = false;

    return validInput;
};

export const renderTextControl = (register: any, isNextClicked: boolean, values: any, name: string, label: string, placeholder = '', validation?: IValidation, onBlur?: any, disabled = false, autoComplete = true) => {
    const value = getNestedValue(name, values);
    
    let validInput = true;
    if (isNextClicked) {
        validInput = validate(value, validation);
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control   name={name}
                                type="text"
                                placeholder={placeholder}
                                className={`${validInput ? '' : 'error-input'}`}
                                autoComplete={autoComplete ? '' : 'none'}
                                disabled={disabled}
                                onBlur={onBlur}
                                ref={register(validation)} />
            </Form.Group>
        </div>
    );
};

export const renderTextAreaControl = (register: any, isNextClicked: boolean, values: any, name: string, label: string, placeholder = '', validation?: IValidation, onBlur?: any, disabled = false, autoComplete = true) => {
    const value = getNestedValue(name, values);
    
    let validInput = true;
    if (isNextClicked) {
        validInput = validate(value, validation);
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control   as="textarea"
                                rows={3}
                                name={name}
                                placeholder={placeholder}
                                className={`${validInput ? '' : 'error-input'}`}
                                autoComplete={autoComplete ? '' : 'none'}
                                disabled={disabled}
                                onBlur={onBlur}
                                ref={register(validation)} />
            </Form.Group>
        </div>
    );
};

// interface ITextControlProps {
//     name: string;
//     label: string;
//     placeholder?: string;
//     validation?: IValidation;
//     disabled?: boolean;
//     children?: any;
// }

// const TextControl = (props: ITextControlProps) => {
//     const { name, label, placeholder, validation, disabled } = props;

//     let validInput = true;
//     const value = getValues()[name];
//     if (validation!.required && (!value || (value && !value.trim().length))) validInput = false;

//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <Form.Control   name={name}
//                                 type="text"
//                                 placeholder={placeholder}
//                                 className={`${validInput ? '' : 'error-input'}`}
//                                 onBlur={(e: any) => validateForm(e)}
//                                 autoComplete="none"
//                                 disabled={disabled}
//                                 ref={register({required: true})} />
//             </Form.Group>
//         </div>
//     );
// };

export const renderDropdownControl = (register: any, isNextClicked: boolean, values: any, name: string, label: string, placeholder: string, list: any[], validation?: IValidation, onChange?: any, disabled = false) => {
    const value = getNestedValue(name, values);
    
    let validInput = true;
    if (isNextClicked) {
        validInput = validate(value, validation);
    }
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control   as="select"
                                name={name}
                                type="text"
                                placeholder={placeholder}
                                className={`${validInput ? '' : 'error-input'}`}
                                disabled={disabled}
                                onChange={onChange}
                                ref={register(validation)}>
                    <option value="">{placeholder}</option>
                    { list.map((item: INameValuePair, i: number) => 
                        <option key={i} value={item.value}>{item.name}</option>)
                    }
                </Form.Control>
            </Form.Group>
        </div>
    );
};

export const renderCheckboxControl = (register: any, isNextClicked: boolean, values: any, name: string, label: string, description: string, onChange: any, disabled = false) => {
    let validInput = true;
    
    return (
        <div>             
            <Form.Group controlId={name}>
                <FormCheck>
                    <FormCheck.Input type="checkbox" name={name} ref={register} onChange={onChange} />
                    <FormCheck.Label><strong>{label}</strong> - {description}</FormCheck.Label>
                </FormCheck>
            </Form.Group>
        </div>
    );
};

export const renderCheckbox2Control = (register: any, isNextClicked: boolean, values: any, name: string, label: string, onChange?: any, disabled = false) => {
    let validInput = true;
    
    return (
        <div>
            <Form.Group controlId={name}>
                <FormCheck>
                    <FormCheck.Input type="checkbox" name={name} ref={register} onChange={onChange} />
                    <FormCheck.Label>{label}</FormCheck.Label>
                </FormCheck>
            </Form.Group>
        </div>
    );
};

export interface IStepProps {
    orgId: string;
    register: any;
    getValues: Function;
    setValue: Function;
    isVisible: boolean;
    isNextClicked: boolean;
    getAccessToken: Function;
}