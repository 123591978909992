import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import Sidebar from "../../Layout/Sidebar/Sidebar";
import { getLandCareData, landCareList, landCareStartLoading, landCareStopLoading} from "../../../Store/Reducers/LandCareSlice";
import NotesList from "./NotesList"
import { selectCurrentLandcare, selectLandCarerNotes } from "../../../Store/Selectors/landCareSelectors";
import { DetailRecordTypes, loaderStyle } from "../../../Helpers/constants";
import { selectUserProfile } from "../../../Store/Selectors/rootSelector";
import PageTitle from "../../Layout/PageTitle/PageTitle";
import { getUserProfile } from "../../../Store/Reducers/LocalStoreSlice";

import "./Notes.scss";

const Notes: React.FC<any> = (props) => {
 
  const my_notes = useSelector(selectLandCarerNotes);
  const landCareNotes = useSelector(selectLandCarerNotes);
  const { isLoading } = useSelector(landCareList);
  const currentLandCare = useSelector(selectCurrentLandcare);
  const userProfile = useSelector(selectUserProfile);
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();

  let accessToken = '';

  useEffect(() => {
    if (!Object.keys(userProfile).length) {
      const refreshObj = async () =>  {
        accessToken = await props.getAccessToken();
        if (accessToken && !Object.keys(userProfile).length) {
          await dispatch(getUserProfile(accessToken, history));
        }
      };
      refreshObj();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(landCareStartLoading());

    const refreshObj = async () => {
      const urlIDBlock = window.location.pathname.split('/')[3];

      accessToken = await props.getAccessToken();

      if (accessToken && userProfile.id && !landCareNotes.length) {
        await dispatch(getLandCareData(accessToken, userProfile.id, urlIDBlock, [DetailRecordTypes.NOTES, DetailRecordTypes.CONTACTS]));
        dispatch(landCareStopLoading());
      }
    }; 
    refreshObj();
  }, [props.getAccessToken, userProfile.id]);

  return (
    <Container fluid className="gerx-contents-panel">
      <Row>
        <Col>
          <Sidebar key="sidebar" highlight="notes_comments" active={"active"}>
            <Row>
              <Col>
                <PageTitle title={'Organisation: ' + (currentLandCare?.landHoldingUserOrg?.userOrgName ? currentLandCare.landHoldingUserOrg.userOrgName : '')} />
              </Col>
            </Row>
            <LoadingOverlay
              className="row"
              active={isLoading}
              spinner
              text="Loading..."
              styles={loaderStyle}
            >
              <Container fluid>
                <NotesList getAccessToken={props.getAccessToken} my_notes={my_notes} />
              </Container>
            </LoadingOverlay>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
};

export default Notes;
