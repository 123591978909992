import LandCaresApiService from '../../Services/LandCareApiServices';
import { ILandCarerNote, ILandCarer, IlandCarerNoteContactUpdate, ILandCaresResponse, ILandCareFilePatchResponse, ILandCareUserOrg } from '../../Models/LandCare/LandCare';
import { ILandCareUserOrgContact } from '../../Models/LandCare/LandCare';
import { IChatMessage, IChatSummary } from '../../Models/LandCare/IChat';
import {  IUserProfile } from '../../Models/Auth/IUser';
import { IMediaFileResponse } from '../../Models/MediaFiles/IMediaFile';
import { ILandholding } from '../../Models/LandHoldings/LandHoldings';
import { ICurrentUserOrgResult } from '../Reducers/UserOrgSlice';

const landCareApiService = new LandCaresApiService();

export const getAllLandCaresFromApi = async (accessToken: string, pageNumber: number, pageSize: number, filter: any) : Promise<ILandCaresResponse> => {
  return await landCareApiService.getAllLandCares(accessToken, pageNumber, pageSize, filter);
};

export const getLandCaresByIdFromApi = async (accessToken: string | undefined, landCareId: string | undefined) : Promise<any> => {  
  return await landCareApiService.getLandCareById(accessToken, landCareId);
};
export const fetchUserOrgContacts = async (accessToken: string, orgId: string) : Promise<any> => {  
  return await landCareApiService.fetchUserOrgContacts(accessToken, orgId);
};

export const addUserOrgContact = async (accessToken: string, contact: ILandCareUserOrgContact) : Promise<any> => {  
  return await landCareApiService.postUserOrgContact(accessToken, contact);
};

export const saveUserOrgContact = async (accessToken: string, contact: ILandCareUserOrgContact) : Promise<any> => {  
  return await landCareApiService.updateUserOrgContact(accessToken, contact);
}

export const patchUserOrgContact = async (accessToken: string, contactId: string, fieldName: string, fieldValue: string)  : Promise<any> => {
  return await landCareApiService.patchUserOrgContact(accessToken, contactId, fieldName, fieldValue);
}

export const removeUserOrgContact = async (accessToken: string, id: string) : Promise<any> => {  
  return await landCareApiService.deleteUserOrgContact(accessToken, id);
}

export const getLandHoldingByIdFromApi = async (accessToken: string | undefined, landholdingId: string | undefined) : Promise<any> => {  
  return await landCareApiService.getLandHoldingById(accessToken, landholdingId);
}

export const getLandCaresWithPlantingsById = async (accessToken: string | undefined, landholdingId: string | undefined) : Promise<any> => {  
  return await landCareApiService.getLandCareWithPlantingsById(accessToken, landholdingId);
}

export const updateLandCareAPI = async (landholdingId: string | undefined, updatedLC: any, accessToken: string | undefined) : Promise<any> => {  
  return await landCareApiService.updateLandCaresById(landholdingId, updatedLC, accessToken);
}

export const updatePlantingSiteAPI = async (plantingSiteId: string | undefined, updatedLC: any, accessToken: string | undefined) : Promise<any> => {  
  return await landCareApiService.updatePlantingSiteById(plantingSiteId, updatedLC, accessToken);
}

export const patchLandCare = async (accessToken: string, landCareId: string, fieldName: string, fieldValue: any) : Promise<any> => {  
  return await landCareApiService.patchLandCare(accessToken, landCareId, fieldName, fieldValue);
};

export const putLandholdingMap = async (accessToken: string, landholdingId: string, landHolding : ILandholding) : Promise<any> => {  
  return await landCareApiService.putLandholdingMap(accessToken, landholdingId, landHolding);
};

export const patchPlantingSite = async (accessToken: string, plantingSiteId: string, fieldName: string, fieldValue: any) : Promise<any> => {  
  return await landCareApiService.patchPlantingSite(accessToken, plantingSiteId, fieldName, fieldValue);
};

export const getAllLandCaresFromApiAuto = async (accessToken: string, landCareTyped: any, limit: number) : Promise<ILandCarer> => {
  console.log('Access token inside API: ', accessToken)
  return await landCareApiService.getLandCareAuto(accessToken, landCareTyped, limit);
}

export const getAllUserOrgFromApiAuto = async (accessToken: string, userOrgTyped: any, limit: number) : Promise<ILandCareUserOrg> => {
  console.log('Access token inside API: ', accessToken)
  return await landCareApiService.getUserOrgAuto(accessToken, userOrgTyped, limit);
}

export const getConfigByOrgId = async (accessToken: string, orgId: string) : Promise<ILandCarer> => {
  return await landCareApiService.getConfigByOrgId(accessToken, orgId);
}

export const fetchLandCarerNotes =  async (accessToken: string, orgId: string) : Promise<any> => {
  return await landCareApiService.getLandCarerNotes(accessToken, orgId);
} 

export const deleteLandCarerNotee =  async(accessToken: string, landCareId: string, noteId: string) : Promise<any> => {
    return await landCareApiService.deleteLandCarerNote(accessToken, landCareId, noteId);
}

export const patchLandCarerNote = async (accessToken: string, noteId: string, fieldName: string, fieldValue: any)  : Promise<any> => {
  return await landCareApiService.patchLandCarerNote(accessToken, noteId, fieldName, fieldValue);
} 

export const addLandCarerNotePost = async (accessToken: string, note: ILandCarerNote) : Promise<any> => {  
  return await landCareApiService.addLandCarerNote(accessToken, note);
};

export const patchLandCarerNoteContact = async (accessToken: string, note: IlandCarerNoteContactUpdate) : Promise<any> => {  
  return await landCareApiService.patchLandCarerNoteContact(accessToken, note);
};

export const uploadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, files: Array<any>) : Promise<IMediaFileResponse> => {
  return await landCareApiService.uploadMediaFilesById(accessToken, module, moduleRefId, files);
};

export const getMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.getMediaFilesById(accessToken, module, moduleRefId, fileId);
};

export const patchMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string, fieldName: string, fieldValue: string) : Promise<ILandCareFilePatchResponse> => {
  return await landCareApiService.patchMediaFilesById(accessToken, module, moduleRefId, fileId, fieldName, fieldValue);
};

export const downloadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.downloadMediaFileById(accessToken, module, moduleRefId, fileId);
};

export const deleteMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.deleteMediaFileById(accessToken, module, moduleRefId, fileId);
};

export const fetchLandCareChatSummaries = async (accessToken: string, landCareId: string, pageSize: number) : Promise<IChatSummary[]> => {  
  return await landCareApiService.fetchLandCareChatSummaries(accessToken, landCareId, pageSize);
};

export const fetchLandCareChatMessages = async (accessToken: string, landCareId: string, groupId: string, pageNumber: number, pageSize: number) : Promise<IChatMessage[]> => {  
  return await landCareApiService.fetchLandCareChatMessages(accessToken, landCareId, groupId, pageNumber, pageSize);
};

export const postLandCareChatMessage = async (accessToken: string, landCareId: string, fromContactId: string, toContactId: string, groupId: string, message: string, files: string[]) : Promise<any> => {  
  return await landCareApiService.postLandCareChatMessage(accessToken, landCareId, fromContactId, toContactId, groupId, message, files);
};

export const fetchUserProfile = async (accessToken: string) : Promise<IUserProfile> => {  
  return await landCareApiService.fetchUserProfile(accessToken);
};

export const fetchLandCarerFiles =  async (accessToken: string, module: string, moduleRefId: string) : Promise<any> => {
  return await landCareApiService.getLandCarerFiles(accessToken, module, moduleRefId);
};

export const postLandCareUserStatus =  async (accessToken: string) : Promise<any> => {
  return await landCareApiService.postLandCareUserStatus(accessToken);
};

export const postLandCareChatMessageReadNotification = async (accessToken: string, landCareId: string, groupId: string) : Promise<any> => {
  return await landCareApiService.postLandCareChatMessageReadNotification(accessToken, landCareId, groupId);
};

export const getMapGeoJson =  async (accessToken: string, landCarerId: string, disableProjection = false) : Promise<any> => {
  return await landCareApiService.getMapGeoJson(accessToken, landCarerId, disableProjection);
};

export const getLandCareReport =  async (url: string) : Promise<any> => {
  return await landCareApiService.getLandCareReport(url);
};

export const fetchUserOrg = async (accessToken: string, orgId: string, includeCreatedByOrg?: boolean, includeUserOrgContacts?: boolean) : Promise<ICurrentUserOrgResult> => {  
  return await landCareApiService.fetchUserOrg(accessToken, orgId, includeCreatedByOrg, includeUserOrgContacts);
};

export const fetchUserOrgByUserId = async (accessToken: string, orgId: string) : Promise<ICurrentUserOrgResult> => {  
  return await landCareApiService.fetchUserOrgByUserId(accessToken, orgId);
};

export const postUserOrg = async (accessToken: string, userOrg: ILandCareUserOrg) : Promise<any> => {  
  return await landCareApiService.postUserOrg(accessToken, userOrg);
};

export const putUserOrg = async (accessToken: string, userOrg: ILandCareUserOrg) : Promise<any> => {  
  return await landCareApiService.putUserOrg(accessToken, userOrg);
};

export const postUserOrgContact = async (accessToken: string, userOrgContact: ILandCareUserOrgContact) : Promise<any> => {
  return await landCareApiService.postUserOrgContact(accessToken, userOrgContact);
};

export const fetchCorrelationId = async (accessToken: string) : Promise<any> => {
  return await landCareApiService.fetchCorrelationId(accessToken);
};