import * as signalR from '@microsoft/signalr'

class GetSignalRConnection {
    private static connection?: signalR.HubConnection;

    private constructor() { }

    public static createConnection(accessToken: string, connectionUrl: string) {
        if (!this.connection || (this.connection?.state === signalR.HubConnectionState.Disconnected)) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(connectionUrl, { accessTokenFactory: () => accessToken })
                // .configureLogging(signalR.LogLevel.Trace)
                .withAutomaticReconnect()
                .build();
            
            this.connection.onclose(function() {
                console.log('signalr disconnected ===')
            });

            this.connection.onreconnecting(err =>
                console.log('err reconnecting ===', err)
            );
            
            this.connection
                .start()
                .then(res => console.log('SignalR connection started ==='))
                .catch(console.error);
        }
    }

    public static getConnection(): signalR.HubConnection | undefined {
        return this.connection;
    }
}

export default GetSignalRConnection;