import React, { CSSProperties, useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, FormControl, FormGroup, FormLabel, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

// Using Selector/Dispatch as against using a connect/mapXxxToProps
import { useDispatch, useSelector} from "react-redux";
import { ILandCareUserOrg, ILandholdingAddress, ILandCareUserOrgContact } from '../../Models/LandCare/LandCare';
import { useToggle } from 'react-use';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { setIsCreateNew, setIsEditing } from '../../Store/Reducers/UserOrgAutoSuggestSlice';
import { saveUserOrgWithContact } from '../../Store/Reducers/LandCareSlice';
import { selectIsCreateNew, selectIsEditing } from '../../Store/Selectors/userOrgAutoSuggestSelector';
import { selectCurrentUserOrg, selectUserOrgContacts } from '../../Store/Selectors/userOrgSelectors';
import { setCreatedByUserOrg, setUserOrgContacts } from '../../Store/Reducers/UserOrgSlice';
import { IUserOrg } from '../../Models/Auth/IUser';
import { selectUserProfile } from '../../Store/Selectors/rootSelector';

interface IUserOrgProps {
  isAuthenticated: boolean;
  getAccessToken: Function; 
  accessToken: string;
  onCompleted: Function;
}

const ManageUserOrg : React.FC<IUserOrgProps> = (props: IUserOrgProps) => {

  const isCreateNew = useSelector(selectIsCreateNew);
  const isEditing = useSelector(selectIsEditing);
  const userOrg = useSelector(selectCurrentUserOrg);
  const userOrgContacts = useSelector(selectUserOrgContacts);
  const userProfile = useSelector(selectUserProfile);

  useEffect(() => {
    if (isEditing) {
      setValue('id', userOrg?.id);
      setValue('userOrgName', userOrg?.userOrgName);
      setValue('abn', userOrg?.abn);
      setValue('createdBy', userOrg?.createdBy);
      setValue('postCode', userOrg?.mailingAddress?.postCode);
      setValue('orgState', userOrg?.mailingAddress?.state);
      setValue('userOrgLabel', userOrg?.userOrgLabel);

      const contact = userOrgContacts && userOrgContacts.find((c: ILandCareUserOrgContact) => c.isPrimary);
      setValue('contactFirstName', contact ? contact.firstName : '');
      setValue('contactLastName', contact ? contact.lastName : '');
      setValue('contactPosition', contact ? contact.position : '');
      setValue('contactEmail', contact ? contact.email : '');
      setValue('contactMobile', contact ? contact.mobile : '');
    }
    else {
      reset();
    }
  }, [isCreateNew, isEditing, userOrg])

  const dispatch = useDispatch();
  
  //Need to move to correct location
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  let shape: any = {
    userOrgName: yup.string().required("Organisation Name is required").min(3, 'Must be at least 3 chars'),
    abn: yup.string(),
    ['orgState']: yup.string().required("State is required"),
    ['postCode']: yup.string().required("Postcode is required"),
  };
  
  if (isCreateNew) {
    shape = { ...shape,
      contactFirstName: yup.string().required("First Name is required").min(2, 'Must be at least 2 chars'),
      contactLastName: yup.string().required("Last Name is required").min(2, 'Must be at least 2 chars'),
      contactPosition: yup.string().required("Position is required").min(2, 'Must be at least 2 chars'),
      contactEmail: yup.string().required("Email is required").email("Email must be a valid email"),
      contactMobile: yup.string().required("Mobile is required").min(8, 'Must be at least 8 chars')
    };
  }
  const OrgWithPrimaryContactCreateSchema = yup.object().shape(shape);

  const {register, handleSubmit, reset, setValue, formState: { errors }} = useForm<any>({
    defaultValues: {
    },
    mode: "onBlur",
    resolver: yupResolver(OrgWithPrimaryContactCreateSchema)
  });
  
  const [isSavingOrg, setIsSavingOrg] = useToggle(false);

  const onSubmit = async (data:any) => {
    const accessToken = await props.getAccessToken();
      
    let userOrganisation = {
      id: userOrg?.id,
      userOrgName: data.userOrgName,
      userOrgLabel: data.userOrgName,
      abn: data.abn,
      mailingAddress: {
        fullAddress: data.postalAddress,
        postCode: data.postCode,
        state: data.orgState
      } as ILandholdingAddress
    } as ILandCareUserOrg

    let userOrgContact = {} as ILandCareUserOrgContact;
    if (isCreateNew) {
      userOrgContact = {
        id: '',
        isPrimary: true,
        firstName: data.contactFirstName,
        lastName: data.contactLastName,
        position: data.contactPosition,
        email: data.contactEmail,
        mobile: data.contactMobile,
        updatedAt: new Date()
      };
    }

    setIsSavingOrg(true);

    const successFn = (userOrg: IUserOrg, userOrgContact: ILandCareUserOrgContact) => {
      dispatch(setCreatedByUserOrg(userOrg));
      if (userOrgContact) {
        dispatch(setUserOrgContacts([userOrgContact]));
      }
      showMessage("Organisation saved successfully", MessageModeEnum.SUCCESS);
      props.onCompleted(userOrg);
      reset();
    };
    
    await dispatch(saveUserOrgWithContact(accessToken, userOrganisation, userOrgContact, successFn));
    
    setIsSavingOrg(false);
  };

  const onCancel = () => {
    dispatch(setIsCreateNew(false));
    dispatch(setIsEditing(false));
    reset();
  };

  return (
    <LoadingOverlay className="row" active={isSavingOrg} spinner text='Please wait...'>
      <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
        <Row>
          <Col className="mb-3">
            <h5>{isCreateNew ?  'Add New Organisation' : 'Edit Organisation' }</h5>
          </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel>Organisation Name:</FormLabel>
                  <FormControl isInvalid={!!errors.userOrgName} id="userOrgName" as="input" name="userOrgName" ref={register}></FormControl>
                  <Form.Control.Feedback type="invalid">{errors.userOrgName?.message}</Form.Control.Feedback>
                </FormGroup>
            </Col>

            <Col xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                <FormLabel>ABN:</FormLabel>
                <FormControl id="abn" as="input" name="abn" ref={register}></FormControl>
                </FormGroup>
            </Col>

            <Col xs={12} sm={6} md={4} lg={2}>
                <Form.Group>
                <Form.Label>Postcode</Form.Label>
                <Form.Control isInvalid={!!errors.postCode} id="postCode" type="text" name="postCode" placeholder="Enter Postcode" ref={register}/>
                <Form.Control.Feedback type="invalid">{errors.postCode?.message} </Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12} sm={6} md={4} lg={2}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control isInvalid={!!errors.orgState} id="orgState" as="select" name="orgState" ref={register}>
                    <option value="">Select State</option>
                    <option value="Australian Capital Territory">Australian Capital Territory</option>
                    <option value="Northern Territory">Northern Territory</option>
                    <option value="New South Wales">New South Wales</option>
                    <option value="Queensland">Queensland</option>
                    <option value="South Australia">South Australia</option>
                    <option value="Tasmania">Tasmania</option>
                    <option value="Victoria">Victoria</option>
                    <option value="Western Australia">Western Australia</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.orgState?.message} </Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12} sm={12} md={12} lg={12}>
              <hr />
              <Form.Group>
                <Form.Label>Main Contact</Form.Label>
              </Form.Group>
            </Col>
          
            <Col xs={12} sm={6} md={4} lg={2}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control isInvalid={!!errors.contactFirstName} id="contactFirstName" type="text" name="contactFirstName" placeholder="First Name" ref={register} disabled={isEditing}/>
                <Form.Control.Feedback type="invalid">{errors.contactFirstName?.message} </Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12} sm={6} md={4} lg={2}>
            
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control isInvalid={!!errors.contactLastName} id="contactLastName" type="text" name="contactLastName" placeholder="Last Name" ref={register} disabled={isEditing}/>
                <Form.Control.Feedback type="invalid">{errors.contactLastName?.message} </Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12} sm={6} md={4} lg={2}>
            
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control isInvalid={!!errors.contactPosition} id="contactPosition" type="text" name="contactPosition" placeholder="Position" ref={register} disabled={isEditing}/>
                <Form.Control.Feedback type="invalid">{errors.contactPosition?.message} </Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12} sm={6} md={4} lg={2}>
            
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control isInvalid={!!errors.contactEmail} id="contactEmail" type="text" name="contactEmail" placeholder="Email" ref={register} disabled={isEditing}/>
                <Form.Control.Feedback type="invalid">{errors.contactEmail?.message} </Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12} sm={6} md={4} lg={2}>
            
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control isInvalid={!!errors.contactMobile} id="contactMobile" type="text" name="contactMobile" placeholder="Mobile" ref={register} disabled={isEditing}/>
                <Form.Control.Feedback type="invalid">{errors.contactMobile?.message} </Form.Control.Feedback>
              </Form.Group>

            </Col>
            
            <Col xs={12} sm={12} md={12} lg={12} className="text-right">
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="submit" variant="success">Save</Button>
            </Col>
        </Row>
      </Form>
    </LoadingOverlay>
  );
}


export default ManageUserOrg;
