import React from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ILandCareUserOrg, ILandCareUserOrgContact } from '../../../Models/LandCare/LandCare';
import { landCareList } from '../../../Store/Reducers/LandCareSlice';

interface IContactOrganisation {
    organisation: ILandCareUserOrg & ILandCareUserOrgContact;
};

const ContactOrganisation : React.FC<any> = (props: IContactOrganisation) => {
    const org = props.organisation;

    return (
        <div>
            <Row>
                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-6">Company Name</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.userOrgName}</Form.Label>
                </Form.Group>

                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-4">ABN</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.abn}</Form.Label>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-6">Main Contact Name</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.firstName}</Form.Label>
                </Form.Group>

                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-4">Surname</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.lastName}</Form.Label>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-6">Email</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.email}</Form.Label>
                </Form.Group>

                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-4">Mobile</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.mobile}</Form.Label>
                </Form.Group>

                <Form.Group className="col-xs-12 col-sm-4">
                    <Form.Label className="col-xl-4">Phone</Form.Label>
                    <Form.Label className="col-xl-6 gerx-value-label">{org.phone}</Form.Label>
                </Form.Group>
            </Row>
        </div>
    )
};

export default ContactOrganisation;