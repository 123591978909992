import { AxiosRequestConfig } from "axios";

export const MSAL_LOCALSTORAGE_KEY = 'msalLocalStorageKey';

export const getConfig = (accessToken: string) => {
    const config : AxiosRequestConfig = {
        headers: { 
          'Authorization': `Bearer ${accessToken}`
        }
    };

    return config;
}

export const getUrlParamWithValue = (paramName: string, paramValue: any, isFirstParam: boolean = false): string => {
    return paramValue ? `${isFirstParam ? '?' : '&'}${paramName}=${paramValue}` : '';
};

export const getMSALInfo = () => {
  const key = localStorage?.getItem(MSAL_LOCALSTORAGE_KEY);

  if (key) {
      const obj = sessionStorage.getItem(key);
      return obj ? JSON.parse(obj) : null;
  }
  else {
      return null;
  }
};
  
export const getIsUserAuthenticated = () => {
  return getMSALInfo() ? true : false;
};

export const getUserInfo = (): any => {
  if (getIsUserAuthenticated()) {
    return getMSALInfo()?.idTokenClaims;
  }
  else {
    return null;
  }
};

export const getUserName = () => {
  const claims = getUserInfo();
  return claims ? claims.given_name + ' ' + claims.family_name : '';
};
