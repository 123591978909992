import React from 'react';
import { getNameInitials } from '../../../Helpers/HelperFunctions';

interface IChatContactPhoto {
    name: string;
    isOnline: boolean;
}

const ChatContactPhoto : React.FC<IChatContactPhoto> = ({ name, isOnline } : IChatContactPhoto) => {
    return (
        <div className="contact-photo">
            {getNameInitials(name)}
            <div className={'dot' + (isOnline ? ' online' : ' offline')}></div>
        </div>
    );
};

export default ChatContactPhoto;