import axios, { AxiosRequestConfig } from 'axios';
import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { IUserProfile } from '../Models/Auth/IUser';
import { IChatMessage, IChatSummary } from '../Models/LandCare/IChat';
import { ILandCarerNote, IPlantingSite, IlandCarerNoteContactUpdate, ILandCaresResponse, ILandCareFilePatchResponse, ILandCareUserOrg } from '../Models/LandCare/LandCare';
import { ILandCarer, ILandCareUserOrgContact } from '../Models/LandCare/LandCare';
import { ILandholding } from '../Models/LandHoldings/LandHoldings';
import { IMediaFileResponse } from '../Models/MediaFiles/IMediaFile';
import { ICurrentUserOrgResult } from '../Store/Reducers/UserOrgSlice';
import { getConfig, getUrlParamWithValue } from './serviceHelper';

class LandCaresApiService {

  baseUrl: string | undefined;
  baseUrlHoldings: string | undefined;
  baseModuleName: string | undefined;
  containerName: string | undefined;
  mainUrl: string | undefined;

  constructor () {
    // this.baseUrl = 'https://gerxdev.azure-api.net/landcarers/';
    // this.baseUrlHoldings = 'https://gerxdev.azure-api.net/landportal/'
    this.baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI;
    this.baseUrlHoldings = process.env.REACT_APP_LANDPORTAL_HOLDINGS_BASEURI;
    this.baseModuleName = process.env.REACT_APP_BASE_MODULE_NAME;
    this.containerName = process.env.REACT_APP_LANDPORTAL_CONTAINER_NAME;
    this.mainUrl = process.env.REACT_APP_API_URI;
  }

 
  showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    showMessage(message, MessageModeEnum.ERROR);
    console.log(message);
  }

  getAllLandCares = async (accessToken: string, pageNumber: number, pageSize: number, filters: any) : Promise<ILandCaresResponse> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    let filterParams = '';
    Object.keys(filters).forEach((key: string) => {
      filterParams += getUrlParamWithValue(key, filters[key]);
    });

    const landCarersUrl = `${this.baseUrl}opportunities`
                            + getUrlParamWithValue('pagenumber', pageNumber, true)
                            + getUrlParamWithValue('pagesize', pageSize)
                            + filterParams;
    //const landCarersUrl = `${this.baseUrl}configurations?module=LandCarers`;
    const response = await axios.get(landCarersUrl, config);

    return response.data;
  }

  getLandCareById = async (accessToken: string | undefined, landCareId: string | undefined) : Promise<any> => {

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const landCarersUrl = `${this.baseUrl}opportunities?id=${landCareId}`;

    try {
      const response = await axios.get(landCarersUrl, config);
      
      return response.data.landCarers;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandCareById', landCarersUrl, ex);
    }
  }

  fetchUserOrgContacts = async (accessToken: string, orgId: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const autoApi = `${this.baseUrl}userorgcontacts?userorgid=${orgId}`;

    try {
      const response = await axios.get(autoApi, config);

      return response.data.userOrgContactsData;
    }
    catch (ex: any) {
      this.showComposedMessage('fetchUserOrgContacts', autoApi, ex);
    }
  };

  fetchUserOrg = async (accessToken: string, orgId: string, includeCreatedByOrg?: boolean, includeUserOrgContacts?: boolean) : Promise<ICurrentUserOrgResult> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const suffix1 = includeCreatedByOrg ? '&includeCreatedByOrg=true' : '';
    const suffix2 = includeUserOrgContacts ? '&includeUserOrgContacts=true' : '';
    const url = `${this.baseUrl}userorg?id=${orgId}${suffix1}${suffix2}`;
    let response: any = {};

    try {
      response = await axios.get(url, config);
    }
    catch (ex: any) {
      this.showComposedMessage('fetchUserOrg', url, ex);
    }
    
    return response.data;
  };

  fetchUserOrgByUserId = async (accessToken: string, userId: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const url = `${this.baseUrl}userorg?userId=${userId}`;

    try {
      const response = await axios.get(url, config);

      return response.data.userOrganisation;
    }
    catch (ex: any) {
      this.showComposedMessage('fetchUserOrg', url, ex);
    }
  };

  postUserOrg = async (accessToken: string, data: ILandCareUserOrg) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const url = `${this.baseUrl}userorg`;
    const response: any = await axios.post(url, data, config)
      .catch(err => {
        showMessage(err?.response?.data?.message);
    });
    if (response.data) {
      return response.data.result;
    }
  };

  putUserOrg = async (accessToken: string, data: ILandCareUserOrg) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const url = `${this.baseUrl}userorg`;
    const response: any = await axios.put(url, data, config)
      .catch(err => {
        showMessage(err?.response?.data?.message);
    });
    if (response.data) {
      return response.data.result;
    }
  };

  postUserOrgContact = async (accessToken: string, data: ILandCareUserOrgContact) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const url = `${this.baseUrl}userorgcontacts`;
    const response: any = await axios.post(url, data, config)
                          .catch(err => {
                            showMessage(err?.response?.data?.message);
                          });
    if (response.data) {
      return response.data.result;
    }
  };

  updateUserOrgContact = async (accessToken: string, data: ILandCareUserOrgContact) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const url = `${this.baseUrl}userorgcontacts`;
    const response: any = await axios.put(url, data, config)
                          .catch(err => {
                            console.log('response', err.response)
                            showMessage(err?.response?.data?.message);
                          });

    return response.data.result;
  };
  
  patchUserOrgContact = async (accessToken: string, contactId: string, fieldName: string, fieldValue: string) => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const params = {
      id: contactId,
      [fieldName]: fieldValue
    };

    JSON.stringify(params)
    
    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}userorgcontacts?id=${contactId}`;

    const response: any = await axios.patch(url, params, config)
                          .catch(err => {
                            showMessage(err?.response?.data?.message);
                          });

    return response.data.result;

  };

  deleteUserOrgContact = async (accessToken: string, id: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const url = `${this.baseUrl}userorgcontacts?id=${id}`;
    try {
      const response = await axios.delete(url, config);

      return response.data.result;
    }
    catch (ex: any) {
      this.showComposedMessage('deleteUserOrgContact', url, ex);
    }

  };

  getLandCareWithPlantingsById = async (accessToken: string | undefined, landCareId: string | undefined) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const landCarersUrl = `${this.baseUrl}entity?id=${landCareId}`;
    try {
      const response = await axios.get(landCarersUrl, config);

      return response;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandCareWithPlantingsById', landCarersUrl, ex);
    }
  }

  getLandHoldingById = async (accessToken: string | undefined, landHoldingId: string | undefined) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const landHoldingsUrl = `${this.baseUrlHoldings}holdings?id=${landHoldingId}`;
    try {
      const response = await axios.get(landHoldingsUrl, config);

      // if(response && response.data && response.data.landCarers && response.data.landCarers.length === 1) {
      //   responseData = response.data.landCarers[0];
      // }
      
      return response;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandHoldingById', landHoldingsUrl, ex);
    }
  }

  updateLandCaresById = async (landCareId: string | undefined, updatedLC: ILandCarer, accessToken: string | undefined) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const updateLandCarersUrl = `${this.baseUrl}entity?id=${landCareId}`;
    try {
      const response = await axios.put(updateLandCarersUrl, updatedLC, config);

      return response.data.result;
    }
    catch (ex: any) {
      this.showComposedMessage('updateLandCarersUrl', JSON.stringify(updatedLC), ex);
    }
  }

  updatePlantingSiteById = async (plantingSiteId: string | undefined, updatedSP: IPlantingSite, accessToken: string | undefined) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const updatePlantingSiteUrl = `${this.baseUrl}projectsites?id=${plantingSiteId}`;
    try {
      const response = await axios.put(updatePlantingSiteUrl, updatedSP, config);

      return response.data.result;
    }
    catch (ex: any) {
      this.showComposedMessage('updatePlantingSiteById', JSON.stringify(updatedSP), ex);
    }
  }

  putLandholdingMap = async (accessToken: string, landholdingId: string, landHolding : ILandholding) : Promise<any> => {

    const putMethod = {
      method: 'PUT',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(landHolding)
    };

    const url = `${this.baseUrlHoldings}holdings?id=${landholdingId}`;
   
    try {
      const response = await fetch(url, putMethod)
      return response.json().then(((r: any) => r.result));
    }

    catch (ex: any) {
      this.showComposedMessage('patchLandCare', JSON.stringify(landHolding), ex);
    }
  }
  
  patchLandCare = async (accessToken: string, landCareId: string, fieldName: string, fieldValue: any) : Promise<any> => {
    const params = {
      id: landCareId,
      [fieldName]: fieldValue
    };

    const patchMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(params)
    };

    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}entity?id=${landCareId}`;
    
    try {
      const response = await fetch(url, patchMethod)
      return response.json().then(((r: any) => r.result));
    }
    catch (ex: any) {
      this.showComposedMessage('patchLandCare', JSON.stringify(params), ex);
    }
  };

  patchPlantingSite = async (accessToken: string, plantingSiteId: string, fieldName: string, fieldValue: any) : Promise<any> => {
    let i = 10;

    const params = {
      id: plantingSiteId,
      [fieldName]: fieldValue
    };

    const putMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(params)
    };
    // const baseUrl = 'https://gerxdev.azure-api.net/landcarers/';
    // const url = `${baseUrl}entity?id=${landCareId}`;

    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}projectsites?id=${plantingSiteId}`;

    try {
      const response = await fetch(url, putMethod);
      return response.json().then(((r: any) => r.result));
    }
    catch (ex: any) {
      this.showComposedMessage('patchLandCare', JSON.stringify(params), ex);
    }
  };

  getLandCareAuto = async (accessToken: string, landCareTyped: string, limit: number) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const autoApi = `${this.baseUrl}autocomplete?term=${landCareTyped}&limit=${limit}`
    const response = await axios.get(autoApi, config);
    console.log('response array: ', response.data.searchResults)

    return response.data.searchResults
  }

  getUserOrgAuto = async (accessToken: string, userOrgTyped: string, limit: number) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const autoApi = `${this.baseUrl}autocomplete?term=${userOrgTyped}&limit=${limit}&module=userorgs`
    const response = await axios.get(autoApi, config);
    console.log('response array: ', response.data.searchResults)

    return response.data.searchResults
  }

  getConfigByOrgId = async (accessToken: string, orgId: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const autoApi = `${this.baseUrl}configurations?module=${this.baseModuleName}` + (orgId && `&orgid=${orgId}`)
    
    try {
      const response = await axios.get(autoApi, config);

      return response.data.moduleConfigData;
    }
    catch (ex: any) {
      this.showComposedMessage('getConfigByOrgId', autoApi, ex);
    }
  }

  getLandCarerNotes = async (accessToken: string, orgId: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const autoApi = `${this.baseUrl}notes?landcarerid=${orgId}`;
    try {
      const response = await axios.get(autoApi, config);

      return response.data.notesData;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandCarerNotes', autoApi, ex);
    }
  };

  getLandCareReport = async (url: string) : Promise<any> => {
    try {
      const response = await axios.get(url);
      return response.data;
    }
    catch (ex: any) {
      showMessage('getLandCareReport - failed getting report json data', ex);
    }
  } 

  deleteLandCarerNote = async (accessToken: string, landCareId: string, noteId: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    const autoApi = `${this.baseUrl}notes?landCarerId=${landCareId}&id=${noteId}`;
    try {
      const response = await axios.delete(autoApi, config);
      
      return response.data.result;
    }
    catch (ex: any) {
      this.showComposedMessage('deleteLandCarerNote', autoApi, ex);
    }
  }

  patchLandCarerNote = async (accessToken: string, noteId: string, fieldName: string, fieldValue: any) : Promise<any> => {
    const params = {
      id: noteId,
      [fieldName]: fieldValue
    };

    const putMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(params)
    };
    
    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}notes?id=${noteId}`;

    try {
      const response = await fetch(url, putMethod)
      return response.json().then(((r: any) => r.result));
    }
    catch (ex: any) {
      this.showComposedMessage('patchLandCarerNote', JSON.stringify(params), ex);
    }
  };

  addLandCarerNote = async (accessToken: string, data: ILandCarerNote) : Promise<any> => {
    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}notes`;

    const postMethod = {
      method: 'POST',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(data)
    }

    try {
      const response = await fetch(url, postMethod);
      return response.json().then(((r: any) => r.result));
    }
    catch (ex: any) {
      this.showComposedMessage('addLandCarerNote', JSON.stringify(data), ex);
    }
  };

  patchLandCarerNoteContact = async (accessToken: string, data: IlandCarerNoteContactUpdate) : Promise<any> => {
    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    const url = `${baseUrl}notes`;

    const postMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(data)
    }

    try {
      const response = await fetch(url, postMethod);
    }
    catch (ex: any) {
      this.showComposedMessage('patchLandCarerNoteContact', JSON.stringify(data), ex);
    }
  };

  uploadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, files: Array<any>) : Promise<any> => {
    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    let responseData = {};
    let formData = new FormData();

    for(var i = 0; i< files.length; i++)
    {
      formData.append(files[i].type, files[i], files[i].name);
    }    
    
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    const fileUploadUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}`;
    
    try {
      const uploadResponse = await axios.post(fileUploadUrl, formData, config);
      responseData = uploadResponse.data;
      return responseData;
    }
    catch (ex: any) {
      this.showComposedMessage('uploadMediaFilesById', JSON.stringify(formData), ex);
    }
  }
  
  getMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId?: string) : Promise<any> => {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
    };

    let landMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    try {
      return await axios.get(landMediaUrl, config);
    }
    catch (ex: any) {
      this.showComposedMessage('getMediaFilesById', landMediaUrl, ex);
    }
  }

  patchMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string, fieldName: string, fieldValue: string) : Promise<ILandCareFilePatchResponse> => {
    const params = {
      id: fileId,
      [fieldName]: fieldValue
    };

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const putMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(params)
    };

    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    let landMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    let response: any;

    try {
      response = await fetch(landMediaUrl, putMethod);
    }
    catch (ex: any) {
      this.showComposedMessage('patchMediaFilesById', JSON.stringify(params), ex);
    }
    finally {}
      return response.json().then(((r: any) => r.result));
  };

  downloadMediaFileById = (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<Blob>=> {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
      responseType: 'blob',
      timeout: 30000
    };
  
    const attachmentMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}&asFile=true`;
    
    let response: any;
    try {
      response = axios.get(attachmentMediaUrl, config);
    }
    catch (ex: any) {
      this.showComposedMessage('downloadMediaFileById', JSON.stringify(attachmentMediaUrl), ex);
    }
    finally {
      return response;
    }
  }

  deleteMediaFileById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any>=> {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const deleteMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    try {
      const response = await axios.delete(deleteMediaUrl, config);
      responseData = response.data;
      return responseData;
    }
    catch (ex: any) {
      this.showComposedMessage('downloadMediaFileById', deleteMediaUrl, ex);
    }
  }

  getLandCarerFiles = async (accessToken: string, module: string, moduleRefId: string) : Promise<any> => {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    
    const autoApi = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}`;
    try {
      const response = await axios.get(autoApi, config);
      return response;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandCarerFiles', autoApi, ex);
    }
  };

  
  fetchLandCareChatSummaries = async (accessToken: string, landCareId: string, pageSize: number) : Promise<IChatSummary[]> => {
    const url = `${this.baseUrl}chatsummary`
                  + getUrlParamWithValue('landcareid', landCareId, true)
                  + getUrlParamWithValue('pageSize', pageSize);

    let response: any;
    try {
      response = await axios.get(url, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('fetchLandCareChatSummaries', url, ex);
    }
    finally {
      return response.data.chatSummary;
    }
  };

  fetchLandCareChatMessages = async (accessToken: string, landCareId: string, groupid: string, pageNumber: number, pageSize: number) : Promise<IChatMessage[]> => {
    const url = `${this.baseUrl}chats`
                  + getUrlParamWithValue('landcareid', landCareId, true)
                  + getUrlParamWithValue('groupid', groupid)
                  + getUrlParamWithValue('pagenumber', pageNumber);
                  // + getUrlParamWithValue('pagesize', pageSize);

    let response: any;
    try {
      response = await axios.get(url, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('fetchLandCareChatMessages', url, ex);
    }
    finally {
      return response?.data.chatMessages;
    }
  };

  fetchUserProfile = async (accessToken: string) : Promise<IUserProfile> => {
    const url = `${this.baseUrl}userprofile`;
    let response: any;
    try {
      response = await axios.get(url, getConfig(accessToken));
    }
    catch (ex: any) {
      //this.showComposedMessage('fetchUserProfile', url, ex);
      console.log(`[fetchUserProfile]: ${url}`);
    }
    finally {
      return response?.data.user[0];
    }
  };

  postLandCareChatMessage = async (accessToken: string, landCareId: string, fromContactId: string, toContactId: string, groupId: string, message: string, files: string[]) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats`;
    const payload = {
      landCarerId: landCareId,
      userOrgContactId: toContactId,
      groupId,
      body: message,
      files
    };

    let response: any;
    try {
      response = await axios.post(url, payload, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('postLandCareChatMessage', JSON.stringify(payload), ex);
    }
    finally {
      return response?.data.result;
    }
  };

  postLandCareUserStatus = async (accessToken: string) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats/ping`;

    let response: any;
    try {
      response = await axios.post(url, null, getConfig(accessToken));
    }
    catch (ex: any) {
      //this.showComposedMessage('postLandCareUserStatus', url, ex);
      console.log('[postLandCareUserStatus]', url);
    }
    finally {
      return response?.data.result;
    }
  };

  postLandCareChatMessageReadNotification = async (accessToken: string, landCarerId: string, groupId: string) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats/messagesread`;
    const payload = {
      groupId,
      landCarerId
    };

    let response: any;
    try {
      response = await axios.post(url, payload, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('postLandCareChatMessageReadNotification', JSON.stringify(payload), ex);
    }
    finally {
      return response?.data.result;
    }
  };

  getMapGeoJson = async (accessToken: string, landCarerId: string, disableProjection: boolean) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const mapsGeoJsonApi = `${this.baseUrl}maps/geojson?landcarerid=${landCarerId}&disableProjection=${disableProjection}`;
    try {
      const response = await axios.get(mapsGeoJsonApi, config);

      return response.data.geoJson;
    }
    catch (ex: any) {
      this.showComposedMessage('getMapGeoJson', mapsGeoJsonApi, ex);
    }
  };

  fetchCorrelationId = async (accessToken: string) : Promise<any> => {
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const url = `${this.mainUrl}core/guid`;
    try {
      const response = await axios.get(url, config);

      return response.data;
    }
    catch (ex: any) {
      console.log('fetchCorrelationId', url, ex);
    }
  };
};

export default LandCaresApiService;