import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../App/App.scss';

let PolygonPoints : React.FC<any> = (props) => {
  {console.log('polygon comp in Overview: ', props)}
  /* {JSON.stringify(props.polygon)} */
  return (
    <div className="longitude-points">
      <div className="title-break"> [
        { props.listGeometryItems !== undefined && props.listGeometryItems[0] !== undefined && props.listGeometryItems[0].centerPoint ? ('Lat:' + props.listGeometryItems[0].centerPoint[0]) : 'Lat: NA'},
        <br/>
        { props.listGeometryItems !== undefined && props.listGeometryItems[0] !== undefined && props.listGeometryItems[0].centerPoint ? ('Lon:' + props.listGeometryItems[0].centerPoint[1]) : 'Lon: NA'}
        ]</div>
    </div>
  );
}

export default PolygonPoints;
