import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import Sidebar from "../../Layout/Sidebar/Sidebar";
import { loaderStyle } from "../../../Helpers/constants";
import { getAllLandCarerFiles, landCareList } from "../../../Store/Reducers/LandCareSlice";
import { selectCurrentLandcare, selectLandCarerFiles } from "../../../Store/Selectors/landCareSelectors";
import PageTitle from "../../Layout/PageTitle/PageTitle";
import FilesList from "./FilesList";
import LoadLandCareRecord from "../LoadLandCareRecord";

import "./Files.scss";

const Files: React.FC<any> = (props) => {
 
  const landCareFiles = useSelector(selectLandCarerFiles);
  const { isLoading } = useSelector(landCareList);
  const currentLandCare = useSelector(selectCurrentLandcare);

  const landCareId = window.location.pathname.split('/')[3];
  
  LoadLandCareRecord({
    landCareId,
    getAccessToken: props.getAccessToken,
    dispatchFn: (accessToken: string) => getAllLandCarerFiles(accessToken, landCareId, currentLandCare.landHoldingId)
  });

  return (
    <Container fluid className="overview-grid-margin">
      <Row>
        <Col>
          <Sidebar key="sidebar" highlight="files" active={"active"}>
            <Row>
              <Col>
                <PageTitle title={'Organisation: ' + (currentLandCare?.landHoldingUserOrg?.userOrgName ? currentLandCare.landHoldingUserOrg.userOrgName : '')} />
              </Col>
            </Row>
            <LoadingOverlay
              className="row"
              active={isLoading}
              spinner
              text="Loading..."
              styles={loaderStyle}
            >
              <Container fluid>
                <FilesList getAccessToken={props.getAccessToken} my_files={landCareFiles} />
              </Container>
            </LoadingOverlay>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
};
 
export default Files;
