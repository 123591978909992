// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview

const b2cPolicies = {
    names: {
      signUpSignIn: "B2C_1A_gerx_signup_signin",
      signUp: "B2C_1A_gerx_signup",
      forgotPassword: "B2C_1_gerxpasswordreset",
      editProfile: "B2C_1_gerxprofileediting"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://gerx.b2clogin.com/gerx.onmicrosoft.com/B2C_1A_gerx_signup_signin",
        },        
        signUp: {
          authority: "https://gerx.b2clogin.com/gerx.onmicrosoft.com/B2C_1A_gerx_signup",
        },
        forgotPassword: {
            authority: "https://gerx.b2clogin.com/gerx.onmicrosoft.com/B2C_1_gerxpasswordreset",
        },
        editProfile: {
            authority: "https://gerx.b2clogin.com/gerx.onmicrosoft.com/B2C_1_gerxprofileediting"
        },
        signOut: {
            //Should be same as SignIn Authority
            authority: "https://gerx.b2clogin.com/gerx.onmicrosoft.com/B2C_1A_gerx_signup_signin",
        }
    },
    authorityDomain: "gerx.b2clogin.com"
}

export default b2cPolicies;