export const getNameInitials = (name: string) => {
    if (name.length === 0) return '';

    const parts = (name.replace('  ', ' ').trimRight() + ' ').split(' ');
    return parts[0][0] + (parts.length > 0 ? parts[1][0] : '');
};


export const getDateFormatDDMMYYY = (d: Date) => {
    return ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" + d.getFullYear();  
};

export const getNestedValue = (name: string, values: any) => {
    const keys = name?.split('.');
    let value: any;

    keys.forEach((key: string) => {
        if (values) value = values[key];
        values = value;
    });

    return value;
};

export const breakupPayload = (payload: any) => {
    const keys = Object.keys(payload)[0].split('.');
    const value: any = Object.values(payload)[0];

    const newKey = keys[0];
    let newValue = value;

    keys.splice(1, 1).forEach((key: string) => {
        console.log('key', key)
        newValue = { [key]: newValue };
    });

    console.log('newValue', newKey, newValue);

    return { [newKey]: newValue };
};

export const getMergedPayload = (state: any, payload: any) => {
    let keys = Object.keys(payload);
    let values: any = Object.values(payload);

    console.log('state', state)
    if (!Object.keys(values).length) {
        return values[0];
    }
    else {
        let stateValue = {};
        let payloadValue = {};
        keys.forEach((key: string, k: number) => {
            stateValue = state[key];
            console.log('stateValue', stateValue)
            payloadValue = values[k];
            values[k] = {stateValue, ...getMergedPayload(stateValue, payloadValue)};
        });
        return values;
    }
};

export const waitUntil = async (condition: any, intervalInMS = 5, intervalCount = 6) => {
    let count = 0;
    
    return await new Promise(resolve => {
        const interval = setInterval(() => {
            count++;
            if (condition || (count > intervalCount)) {
                clearInterval(interval);
                resolve(condition)
            }
        }, intervalInMS);
    });
};

export const downloadFile = (fileName: string, contents: string) => {
    // This creates the file. 
    const data = new Blob([contents], { type: 'text/plain;charset=utf-8' });

    // This sets up the link and clicks it to download the file as well
    const element = document.createElement("a");
    element.href = URL.createObjectURL(data);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
};

export const downloadZipFile = (fileName: string, contents: string) => {
    // This sets up the link and clicks it to download the file as well
    const element = document.createElement("a");
    element.href = 'data:application/zip;base64,' + contents;
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
};