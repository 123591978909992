import React, { useState, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {  Button, Form, Modal } from "react-bootstrap";
import { Col, Row} from "react-bootstrap";
import { landCareStartLoading, landCareStopLoading, resetFileUploaded, uploadMediaAttachment } from '../../Store/Reducers/LandCareSlice';
import { selectLandCarerFiles } from "../../Store/Selectors/landCareSelectors";

import "bootstrap/dist/css/bootstrap.min.css";

interface IFileAddProps {
  fileUploadComponent: string;
  accessToken?: string;
  getAccessToken: Function;
  showModal: any;
  closeModal: any;
  noteId?: string;
}

const FileUploadModal: React.FC<any> = forwardRef((props: IFileAddProps, ref: any)  => {    

  const landCareFiles = useSelector(selectLandCarerFiles);

  const [fileList, setFileList] = useState<any>();
  const [isFileExistingInStore, setFileExistingInStore] = useState<boolean>(false);
  const [fileUploadErrorStr, setFileUploadErrorStr] = useState<string>("");

  let accessToken = props.accessToken;

  useEffect(() => {
    const refreshObj = async () => {
      accessToken = await props.getAccessToken();
    };

    if (props.getAccessToken) refreshObj();
  })
  
  let currentLandCarerId =  window.location.pathname.split('/')[3];
  if(currentLandCarerId) {
    // do nothing ; valid
  } else {
    // workaround
    currentLandCarerId = window.location.pathname.split('/')[2];
  }
  
  const dispatch = useDispatch();

  // load form with no default values
  const { register, handleSubmit, reset, errors } = useForm();

  const onSave = async (values: any) => {

    if (fileList) {
      const moduleRefId = currentLandCarerId;
      dispatch(landCareStartLoading());

      try {
        const accessToken = await props.getAccessToken();
        // case - if trying to attach a file to a note
        if (props.noteId && props.noteId?.length > 0) {
          await dispatch(uploadMediaAttachment(accessToken, props.fileUploadComponent, moduleRefId, fileList, true, props.noteId, "files"));
        } 

        // will update the description if description string length > 0
        await dispatch(uploadMediaAttachment(accessToken, props.fileUploadComponent, moduleRefId, fileList, false, "", "", values.description));
        
        dispatch(landCareStopLoading());
        resetAndClose();
      } catch(err: any) {
        setFileUploadErrorStr('An error occurred while trying to upload the file ' + err);
        dispatch(landCareStopLoading());
      }
    }
  }

  const resetAndClose = () => {
    reset();
    dispatch(resetFileUploaded());
    setFileExistingInStore(false);
    setFileList([]);
    props.closeModal(false);
  }

  // jm: todo ; refactor ; pure component
  const handleFileChange = async (event: any) => {
    event.preventDefault();

    let files = event.target.files;
  
    setFileExistingInStore(false);
    // check if files[0].name exists in store
    landCareFiles.forEach( (element) => {
      if (props.fileUploadComponent === "landcarenotes" && element.module === "landcarenotes" && element.name === files[0].name) {
        // when on notes page - if the existing filename in store matches AND 
        //  existing (in store) file's module is landcarenotes
        setFileExistingInStore(true);
      } else if (props.fileUploadComponent === "landcarers" && element.module === "landcarers" && element.name === files[0].name) {
        // when on notes page - if the existing filename in store matches AND 
        //  existing (in store) file's module is landcarers 
        setFileExistingInStore(true);
      } else if (props.fileUploadComponent === "projectsites" && element.module === "projectsites" &&  element.name === files[0].name) {
        // when on notes page - if the existing filename in store matches AND 
        //  existing (in store) file's module is projectsites
        setFileExistingInStore(true);
      } else if (props.fileUploadComponent === "landcarersgeneral" && element.module === "landcarersgeneral" &&  element.name === files[0].name) { 
        // when on notes page - if the existing filename in store matches AND 
        //  existing (in store) file's module is landcarersgeneral
        setFileExistingInStore(true);
      }
    });

    if(files && files.length > 0) {
      setFileList(files);
    }
  }

  const getDescriptionTextArea = () => {

    const formControl = () => (
      <Form.Control
        as="textarea"
        rows={3}
        name="description"
        placeholder="Enter file description (optional)"
        ref={register}
      />
    )
    return (
      <>
        {formControl()}
      </>          
    )
  }

  return (
    <div className="gerx-add-container py-3">
      <Modal size="lg" show={props.showModal} onHide={ resetAndClose  } >
        <Form onSubmit={handleSubmit(onSave)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please Upload a file &amp; optionally enter a description.</p>
            { isFileExistingInStore ? <p style={{color: 'red'}}>You have selected a file that already exists, if you proceed to add this file, it will replace the file.</p> : <> </>}
            { fileUploadErrorStr.length>0 ? <p style={{color: 'red'}}>{fileUploadErrorStr}</p> : <> </>}
            
            <Row>
              <Col xs={12} sm={6} md={6} lg={6} >
                <Form.Group>
                  <Form.Label className="bold-labels">File Upload</Form.Label>
                  <Form.File name="file" ref={ register({required: "File upload is required"}) } 
                  label="Select file(s)" custom  id="mediafiles" feedbackTooltip onChange={handleFileChange}
                  />
                  { fileList && fileList.length > 0 && 
                    <p>{fileList[0].name}</p>
                  }
                </Form.Group>
                {errors.file && <p className="project_name" style={{color: "red"}}>{errors.file.message}</p>}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} >
                  <Form.Group>
                    <Form.Label className="bold-labels">Description</Form.Label>
                    {getDescriptionTextArea()}
                  </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ resetAndClose }>
              Close
            </Button>
            <Button variant="primary" type="submit" >
              Add File
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );

});

export default FileUploadModal;
