import React, { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getLandCareData, landCareStartLoading, landCareStopLoading, setCurrentPlantingSite } from '../../Store/Reducers/LandCareSlice';
import { getUserProfile } from '../../Store/Reducers/LocalStoreSlice';
import { selectCurrentLandcare } from '../../Store/Selectors/landCareSelectors';
import { selectUserProfile } from '../../Store/Selectors/rootSelector';

interface IProps {
    landCareId: string;
    getAccessToken: Function;
    dispatchFn: Function;
}

function LoadLandCareRecord(props: IProps) {
    const { landCareId, getAccessToken, dispatchFn } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();

    const userProfile = useSelector(selectUserProfile);
    const currentLandCare = useSelector(selectCurrentLandcare);

    useEffect(() => {
        if (!Object.keys(userProfile).length) {
        const refreshObj = async () =>  {
            const accessToken = await getAccessToken();
            if (accessToken && !Object.keys(userProfile).length) {
            await dispatch(getUserProfile(accessToken, history));
            }
        };
        refreshObj();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const refreshObj = async () => {
            const accessToken = await getAccessToken();
            
            if (accessToken && userProfile.id) {
                dispatch(landCareStartLoading());    
                await dispatch(getLandCareData(accessToken, userProfile.id, landCareId));
                dispatch(landCareStopLoading());
            }
        };
        
        if (!Object.keys(currentLandCare).length) refreshObj();

        return () =>  { dispatch(setCurrentPlantingSite([], null)); } 
    }, [userProfile.id]);

    useEffect(() => {
        const refreshObj = async () => {
          const accessToken = await getAccessToken();
    
          if (accessToken && currentLandCare.landHoldingId) {
            dispatch(landCareStartLoading());
            await dispatch(dispatchFn(accessToken));
            dispatch(landCareStopLoading());
          }      
        }; 
        refreshObj();
    }, [getAccessToken, currentLandCare]);
}

export default LoadLandCareRecord;