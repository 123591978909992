import React from 'react';
import StepProgressBar from 'react-step-progress';

import { IMetaData,  IMetaValue } from '../../../Store/Reducers/ModuleConfigSlice';
import 'react-step-progress/dist/index.css';
import './Stepper.scss';
import { MessageModeEnum, showMessage } from '../../Validator/validationHelper';

interface IStepperProps {
    field: IMetaData | undefined,
    refValue: string,
    wrapperClass?: string
}

const Stepper = ({ field, refValue, wrapperClass } : IStepperProps) => {

    let startingStep = refValue ? field?.metaValues?.findIndex((metaValue: IMetaValue) => 
            metaValue?.lookup?.some((value: string) => value == refValue)) || 0 : 0;

    if (startingStep < 0) {
        showMessage(`'${field?.metaName}' against '${refValue}' not found in metaValues.lookup array.`, MessageModeEnum.ERROR);
        startingStep = 0;
    }

    const stepperSteps: any = field?.metaValues?.map((metaValue: IMetaValue) => (
        {
            label: metaValue.name,
            name: metaValue.id
        }
    ));

    return (
        <>
            { !stepperSteps?.length ? <></> :
                <StepProgressBar
                    key={startingStep}
                    startingStep={startingStep}
                    onSubmit={() => {}}
                    steps={stepperSteps}
                    wrapperClass={wrapperClass}
                    buttonWrapperClass="hidden"
                />
            }
        </>
    );
};

export default Stepper;