import React from 'react';
import { IChatSummary, IChatMessage, MessageStatusEnum } from "../../../Models/LandCare/IChat";
import Moment from 'react-moment';
import { getNameInitials } from '../../../Helpers/HelperFunctions';

import './Chat.scss';
import { Card } from 'react-bootstrap';
import ChatContactPhoto from './ContactPhoto';
import ChatFiles, { DisplayMode } from './ChatFiles';

interface IChatMessageProps {
    summary: IChatSummary;
    msg: IChatMessage;
    isMyMessage: boolean;
}

const ChatMessage : React.FC<any> = ({ summary, msg }: IChatMessageProps) => {
    let senderName = '';
    let source = '';

    const isSystemMessage = msg.createdBy === '0';
    
    if (isSystemMessage) {
        senderName = 'System';
        source = 'msg-system';
    }
    else if (msg?.isMyMessage) {
        senderName = 'You';
        source = 'msg-sender';
    }
    else {
        senderName = summary.userOrgContact.firstName + ' ' + summary.userOrgContact.lastName;
        source = 'msg-recipient';
    }

    let messageStatusIcon: React.ReactFragment;
    switch (msg.messageStatus) {
        case MessageStatusEnum.IsRead: messageStatusIcon = (
            msg.isMyLastMessage ?
                <div className={'msg-read'}>
                    <i className="far fa-eye"></i>
                </div>
            :   <div className={'msg-read not-read'}>
                    <i className="fas fa-check"></i>
                </div>
            ); break;
        case MessageStatusEnum.IsSending: messageStatusIcon = (
            <div className={'msg-read'}>
                <i className="fas fa-spinner fa-pulse"></i>
            </div>); break;
        case MessageStatusEnum.IsNotSent: messageStatusIcon = (
            <div className={'msg-read'}>
                <i className="fas fa-times"></i>
            </div>); break;
        case MessageStatusEnum.IsSent: messageStatusIcon = (
            <div className={'msg-read'}>
                <i className="fas fa-check"></i>
            </div>); break;
        default: messageStatusIcon = (
            <div className='msg-read not-read'>
                <i className="fas fa-check"></i>
            </div>); break;
    }

    return (
        <>
          <div className={'message ' + source}>
              {
                  isSystemMessage ? <></>
                  : <ChatContactPhoto name={senderName} isOnline={summary.isOnline || false}></ChatContactPhoto>
              }

              <Card>
                  <Card.Header>
                      <div className='msg-name'>{senderName}</div>
                      <Moment className="msg-time" date={msg.createdAt} format="hh:mm A" />
                  </Card.Header>
                  <Card.Body>
                      <Card.Text>
                          <div className="msg-body" dangerouslySetInnerHTML={{__html: msg.body}}></div>
                      </Card.Text>

                      {
                        msg?.files
                        ? <ChatFiles files={msg?.files} displayMode={DisplayMode.DOWNLOAD} />
                        : <></>
                      }
                  </Card.Body>
              </Card>

              {
                isSystemMessage ? <></> : messageStatusIcon
              }
          </div>

          {
            msg?.files
            ? <></>
            : <ChatFiles displayMode={DisplayMode.DOWNLOAD} />
          }
        </>
    );
}

export default ChatMessage;