import React, { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import { landCareList, getLandCare } from '../../../Store/Reducers/LandCareSlice';
import './NewReports.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getDateFormatDDMMYYY } from "../../../Helpers/HelperFunctions";


// report urls from .env files - because report urls are different for each env
const report1_url = process.env.REACT_APP_LANDCARE_REPORT_1_URL || "";
const report2_url = process.env.REACT_APP_LANDCARE_REPORT_2_URL || "";
const report3_url = process.env.REACT_APP_LANDCARE_REPORT_3_URL || "";
const report4_url = process.env.REACT_APP_LANDCARE_REPORT_4_URL || "";
const report5_url = process.env.REACT_APP_LANDCARE_REPORT_5_URL || "";
const report6_url = process.env.REACT_APP_LANDCARE_REPORT_6_URL || "";
const report8_url = process.env.REACT_APP_LANDCARE_REPORT_8_URL || "";
const report9_url = process.env.REACT_APP_LANDCARE_REPORT_9_URL || "";
const report10_url = process.env.REACT_APP_LANDCARE_REPORT_10_URL || "";
const report11_url = process.env.REACT_APP_LANDCARE_REPORT_11_URL || "";
const report12_url = process.env.REACT_APP_LANDCARE_REPORT_12_URL || "";
const report13_url = process.env.REACT_APP_LANDCARE_REPORT_13_URL || "";
const report14_url = process.env.REACT_APP_LANDCARE_REPORT_14_URL || "";
const report15_url = process.env.REACT_APP_LANDCARE_REPORT_15_URL || "";
const report_base_url = process.env.REACT_APP_LANDCARE_REPORT_BASE_URL || "";

const sdk = new ChartsEmbedSDK({baseUrl: report_base_url});
const barChart = sdk.createChart({chartId: report1_url});
const geoChart = sdk.createChart({chartId: report2_url});
const lineChart = sdk.createChart({chartId: report3_url});
const textChart4 = sdk.createChart({chartId: report4_url});
const textChart5 = sdk.createChart({chartId: report5_url});
const textChart6 = sdk.createChart({chartId: report6_url});
const textChart8 = sdk.createChart({chartId: report8_url});
const textChart9 = sdk.createChart({chartId: report9_url});
const textChart10 = sdk.createChart({chartId: report10_url});
const textChart11 = sdk.createChart({chartId: report11_url});
const textChart12 = sdk.createChart({chartId: report12_url});
const textChart13 = sdk.createChart({chartId: report13_url});
const textChart14 = sdk.createChart({chartId: report14_url});
const textChart15 = sdk.createChart({chartId: report15_url});

let NewReports: React.FC<any> = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [token, setToken] = useState<string>()
  const LandCareAuto = useSelector(landCareList)
  const dispatch = useDispatch();
  
  const refBarChart = useRef(null);
  const refGeoChart = useRef(null);
  const refLineChart = useRef(null);
  const refTextChart4 = useRef(null);
  const refTextChart5 = useRef(null);
  const refTextChart6 = useRef(null);
  const refTextChart8 = useRef(null);
  const refTextChart9 = useRef(null);
  const refTextChart10 = useRef(null);
  const refTextChart11= useRef(null);
  const refTextChart12 = useRef(null);
  const refTextChart13 = useRef(null);
  const refTextChart14 = useRef(null);
  const refTextChart15 = useRef(null);

  const renderChart = useCallback(async (chart, ref) => {
    try {
      await chart.render(ref);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const setRefChart = useCallback((chart, refChart, ref) => {
    if (ref) {
      renderChart(chart, ref);
    }

    // Save a reference to the node
    refChart.current = ref;
  }, [renderChart]);
  
  const setRefBarChart = (ref: any) =>  setRefChart(barChart, refBarChart, ref) ;
  const setRefGeoChart = (ref: any) =>  setRefChart(geoChart, refGeoChart, ref) ;
  const setRefLineChart = (ref: any) =>  setRefChart(lineChart, refLineChart, ref) ;
  const setRefTextChart4 = (ref: any) =>  setRefChart(textChart4, refTextChart4, ref) ;
  const setRefTextChart5 = (ref: any) =>  setRefChart(textChart5, refTextChart5, ref) ;
  const setRefTextChart6 = (ref: any) =>  setRefChart(textChart6, refTextChart6, ref) ;
  const setRefTextChart8 = (ref: any) =>  setRefChart(textChart8, refTextChart8, ref) ;
  const setRefTextChart9 = (ref: any) =>  setRefChart(textChart9, refTextChart9, ref) ;
  const setRefTextChart10 = (ref: any) =>  setRefChart(textChart10, refTextChart10, ref) ;
  const setRefTextChart11 = (ref: any) =>  setRefChart(textChart11, refTextChart11, ref) ;
  const setRefTextChart12 = (ref: any) =>  setRefChart(textChart12, refTextChart12, ref) ;
  const setRefTextChart13 = (ref: any) =>  setRefChart(textChart13, refTextChart13, ref) ;
  const setRefTextChart14 = (ref: any) =>  setRefChart(textChart14, refTextChart14, ref) ;
  const setRefTextChart15 = (ref: any) =>  setRefChart(textChart15, refTextChart15, ref) ;

  const getDataForDateRange = (startDate: Date, endDate: Date) => {
    let filter = {
      createdAt : { 
        $gt: startDate, 
        $lt: endDate
      }
    };

    return Promise.all([
      barChart.setFilter(filter),
      geoChart.setFilter(filter),
      lineChart.setFilter(filter),
      textChart11.setFilter(filter),
      textChart13.setFilter(filter),
    ]);
  };

  const filterDataForDateRange = (start: any, end: any, label: any) => {
    setStartDate(getDateFormatDDMMYYY(start.toDate()));
    setEndDate(getDateFormatDDMMYYY(end.toDate()));
    getDataForDateRange(start.toDate(), end.toDate());
  }

  // const download_report = async (url: string) => {
  //     let download_json_url = "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/charts-gerx-production-xuhsr/service/http/incoming_webhook/embedding?id=" + url +"&tolerance=3600&sdk=1";
  //     await dispatch(getLandCareReportUrl(download_json_url));
  // }

  useEffect(() => {
    const getListings = async () => {
      const { getTokens } = props
      const tokens: { accessToken: string, idToken: string } = await getTokens(['https://gerx.onmicrosoft.com/devapi/read', 'https://gerx.onmicrosoft.com/devapi/write']);
      await console.log('tokens: ', tokens.accessToken)
      await setToken(tokens.accessToken)
      await dispatch(getLandCare(tokens.accessToken))
    }
    getListings()
  }) 
  const createReport = (colSize: number, reportId: string, reportRef: any) => {
    return (
      <Col md={colSize}>
      <div className="new-reports">
        <div className="charts">
          <div id={reportId} ref={reportRef}></div>
        </div>
      </div>
    </Col>
    )
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col md={6}>
              <br></br>
              <DateRangePicker initialSettings={{ startDate: '03/13/2021', endDate: '07/01/2021' }} onCallback={filterDataForDateRange}>
                <button className="btn btn-warning">Change Date Range</button>
              </DateRangePicker> 
              <br></br>
            </Col>
            <Col md={6}>
              <br></br>
              {
                startDate === "" ? <h3>Dates Selected: All Date Range</h3> : <h3>Dates Selected: {startDate} - {endDate} </h3>
              }
              <br></br>
            </Col>
          </Row>
        </Col>
        <br></br>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
          {/* Landholding Registrations by State */}
          { createReport(6, "barChart", setRefBarChart) }

          {/* Registrations By State */}
          { createReport(6, "geoChart", setRefGeoChart) }
          </Row>

          <Row>
          {/* Landholding Registrations by Week */}
          { createReport(12, "lineChart", setRefLineChart) }
          </Row>

          <Row>
          {/* Monthly Landholder Registrations & Registered Area (Hectares) */}
          { createReport(12, "textChart4", setRefTextChart4) }
          </Row>

          <Row>
          {/* Land Tenure Vehicle & Area (in Ha.) by State */}
          { createReport(12, "textChart5", setRefTextChart5) }
          </Row>

          <Row>
          {/* Land Access Status & Area (in Ha.) by State */}
          { createReport(12, "textChart6", setRefTextChart6) }
          </Row>

          <Row>
          {/* Land Bank: Targets, Actuals & Net Balances */}
          { createReport(12, "textChart8", setRefTextChart8) }
          </Row>

          <Row>
          {/* Allocated to Projects */}
          { createReport(12, "textChart9", setRefTextChart9) }
          </Row>

          <Row>
            {/* Pipeline Status - National Monthly Summary */}
            { createReport(12, "textChart10", setRefTextChart10) }
          </Row>

          <Row>
            {/* Pipeline Status By State */}
            { createReport(12, "textChart11", setRefTextChart11) }
          </Row>

          <Row>
            {/* Study Phase - National Monthly Summary */}
            { createReport(12, "textChart12", setRefTextChart12) }
          </Row>

          <Row>
            {/* Study Phase by State */}
            { createReport(12, "textChart13", setRefTextChart13) }
          </Row>

          <Row>
            { /* Technical Feasibility Study Status by State */ }
            { createReport(12, "textChart14", setRefTextChart14) }
          </Row>

          <Row>
            { /* Project Report LT-70 */ }
            { createReport(12, "textChart15", setRefTextChart15) }
          </Row>

         </Col>
       </Row>
     </Container>
  );
}

export default NewReports;
