import React from 'react';
import { Form, OverlayTrigger, Spinner } from 'react-bootstrap';

import { IMetaData } from "../../Store/Reducers/ModuleConfigSlice";
import { spinnerStyle } from '../constants';
import { renderTooltip } from '../Validator/validationHelper';

export const renderDropdownFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, handlerFn: any, className: string = '', isLoading: boolean, ref?:any, errors?:string[], isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};
        
    return (
        <>
            {/* {isLoading && <><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Updating...</p></>} */}
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                {   errors && errors.length ?
                    (
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={errors && renderTooltip(fieldName, errors)}>
                            <Form.Control as="select" name={fieldName} onChange={handlerFn} ref={ref} isInvalid={errors && errors.length ? true : false}>
                                <option value="" selected={!fieldValue}>Select...</option>
                                {
                                configData?.metaValues?.map((val: any) =>
                                    <option key={val.id} value={val.id} selected={val.id === fieldValue}>{val.name}</option>
                                )}
                            </Form.Control>
                        </OverlayTrigger>
                    )
                    :
                    <Form.Control as="select" disabled={!isEnabled} name={fieldName} onChange={handlerFn} ref={ref} isInvalid={errors && errors.length ? true : false}>
                        <option value="" selected={!fieldValue}>Select...</option>
                        {
                        configData?.metaValues?.map((val: any) =>
                            <option key={val.id} value={val.id} selected={val.id === fieldValue}>{val.name}</option>
                        )}
                    </Form.Control>
                }
            </Form.Group>
      </>
    );
};

export const renderDateFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};

    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control 
                    type="date" 
                    placeholder="mm/dd/yyyy" 
                    disabled={isLoading ? true : false} 
                    value={fieldValue} 
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    readOnly={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderTextAreaFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};

    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderTextFieldFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};

    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    type="text"
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderDecimalFieldFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};

    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    type="float"
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderNumberFieldFormGroup = (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === metaName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading ? '' : 'none')};

    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control type="number"
                            placeholder={configData?.metaPlaceholder}
                            value={fieldValue}
                            onChange={(e: any) => changeFn(fieldName, e.target.value)}
                            onBlur={blurFn}
                            disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};
