import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Badge, Button } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Multiselect } from 'react-widgets/cjs';
import { cloneDeep } from 'lodash';
import { useIsAuthenticated } from '@azure/msal-react';

import LandholdingCare from '../Components/FilterBar/FilterBar';
import SearchLandCare from '../Components/SearchLandCare/SearchLandCare';

import "react-widgets/styles.css";
import '../App/App.scss';

//put AC's inside the slice and import them here:
//then call them with useDispatch:
import { resetCurrentLandCare, landCareList, getLandCare, landCareStartLoading, landCareStopLoading } from '../../Store/Reducers/LandCareSlice';
import { ILandCarer, ISubUser } from '../../Models/LandCare/LandCare';
import { loaderStyle, waitingText } from '../../Helpers/constants';
import { selectHomePageInfo, selectLandCareFormFields, selectLandCareModuleConfigPages, selectSiteManaagers } from '../../Store/Selectors/rootSelector';
import PaginationTable from '../../Helpers/UI/PaginationTable/PaginationTable';
import { IModulePageInfoPayload, ModulesEnum, setModulePageInfo } from '../../Store/Reducers/LocalStoreSlice';
import { IMetaData, IMetaValue, IModulePage, IModuleSection } from '../../Store/Reducers/ModuleConfigSlice';
import { setIsEditing } from '../../Store/Reducers/UserOrgAutoSuggestSlice';

let Home : React.FC<any> = (props) => {
  const [token, setToken] = useState<string>();
  const [filters, setFilters] = useState<any>({ state: '', status: '', technicalFeasibility: '', sort: '', sortBy: '', sortOrder: '', minPerc: '', maxPerc: '', minArea: '', maxArea: '', caseManager: ''});
  const [minmaxFilters, setMinmaxFilters] = useState<any>({ minPerc: '', maxPerc: '', minArea: '', maxArea: ''});
  const [createdAtSort, setCreatedAtSort] = useState<string>("1");
  const [updatedAtSort, setUpdatedAtSort] = useState<string>("1");
  const [stateDefault, setStateDefault] = useState<any>([{id: "", name: "All"}]);
  const [statusDefault, setStatusDefault] = useState<any>([{id: "", name: "All"}]);
  const [relationshipMgrDefault, setRelationshipMgrDefault] = useState<any>([{id: "", name: "All"}]);
  const [studyPhaseDefault, setStudyPhaseDefault] = useState<any>([{id: "", name: "All"}]);

  const  clearFilters = () => {
    
    setStateDefault([{id: "", name: "All"}]);
    setStatusDefault([{id: "", name: "All"}]);
    setRelationshipMgrDefault([{id: "", name: "All"}]);
    setStudyPhaseDefault([{id: "", name: "All"}]);
    setFilters({ state: '', status: '', technicalFeasibility: '', sort: '', sortBy: '', sortOrder: '', minPerc: '', maxPerc: '', minArea: '', maxArea: '', caseManager: ''});
    setMinmaxFilters({ minPerc: '', maxPerc: '', minArea: '', maxArea: ''});
  }
  
  const { searchResults } = useSelector(landCareList);
  const siteManagers = useSelector(selectSiteManaagers);
  const { pageNumber, pageSize, recordCount } = useSelector(selectHomePageInfo);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    dispatch(resetCurrentLandCare());

    const getListings = async () => {
      const pageNo = 1;
      const modulePageInfo: IModulePageInfoPayload = {
        moduleName: ModulesEnum.Home,
        modulePageInfo: { pageNumber: pageNo }
      };
      dispatch(landCareStartLoading());
      const accessToken = await props.getAccessToken();
      setToken(accessToken);
      if (accessToken && pageSize) {
        dispatch(setModulePageInfo(modulePageInfo));
        await dispatch(getLandCare(accessToken, pageNo, pageSize, filters));
        dispatch(landCareStopLoading());
      }
    };

    if (isAuthenticated) {
      getListings();
    }
  }, [isAuthenticated, filters]);

  useEffect(() => {
    const getListings = async () => {
      if (isAuthenticated && pageNumber && pageSize) {
        const accessToken = await props.getAccessToken();
        setToken(accessToken);
        if (accessToken) {
          dispatch(landCareStartLoading());
          await dispatch(getLandCare(accessToken, pageNumber, pageSize, filters));
          dispatch(landCareStopLoading());
        }
      }
    };
    getListings();
  }, [isAuthenticated, pageNumber, pageSize]);

  const onItemSelected = (landCare: ILandCarer) => {
    //dispatch(getLandCarerById(token, landCare._id));
    props.history.push(`/projects/${landCare._id}`);

  };

  const applySearchTerm = (searchterm: string) => {
    setFilters({ ...filters, searchterm, searchlimit: 100 });
  }

  const onManageLandHoldings = () => {
    props.history.push(`/landholdings`);
  };

  let page = cloneDeep(useSelector(selectLandCareModuleConfigPages)?.find((page: IModulePage) => page.id === 'Overview'));
  const rightTopSection = page?.sections?.find((section: IModuleSection) => section.id === 'rightTop');
  const field = rightTopSection?.fields.find((field: IMetaData) => field.metaId === 'status');
  const statusList = field?.metaValues || [] as any[];
  
  const statusMultiSelect = (column: any, colIndex: any) => {

    // KS: This needs to pick up values from organisation's module configuration
    const data  = [
      { id: '', name: 'All' },
      { id: 'New', name: 'New' },
      ...statusList
    ];
  
    const handleChange = (selectedOption: any, metadata: any) => {
      if (metadata.dataItem.name === "All") {
        selectedOption = selectedOption.filter((element: any) => element.name === 'All')
      }

      if (selectedOption.length > 1) {
        selectedOption = selectedOption.filter((element: any) => element.name !== 'All')
      }
      setStatusDefault(selectedOption);

      let status = "";
      selectedOption.forEach((k: any, v: any) => {
        v > 0 ? status = status.concat(",").concat(k.id) : status = status.concat(k.id);
      });
  
      setFilters({ ...filters, status});     
    };
  
    return (
      <>
      { column.text }
        <Multiselect
          dataKey='id'
          textField='name'
          value={statusDefault}
          onChange={handleChange}
          data={data}
        />
      </>
        
    );
  }

  const StudyPhaseMultiSelect = (column: any, colIndex: any) => {

    const data  = [
      { id: '', name: 'All' },
      { id: 'Stage 1 PFS - In Progress', name: 'Stage 1 PFS - In Progress' },
      { id: 'Stage 1 PFS - Completed & Uploaded', name: 'Stage 1 PFS - Completed & Uploaded' },
      { id: 'Stage 2 FS - In Progress', name: 'Stage 2 FS - In Progress' },
      { id: 'Stage 2 FS - Completed & Uploaded', name: 'Stage 2 FS - Completed & Uploaded' },
      { id: 'Stage 3 DFS - In Progress', name: 'Stage 3 DFS - In Progress' },
      { id: 'Stage 3 DFS - Compeleted & Uploaded', name: 'Stage 3 DFS - Completed & Uploaded' },
    ];
  
    const handleChange = (selectedOption: any, metadata: any) => {
      if (metadata.dataItem.name === "All") {
        selectedOption = selectedOption.filter((element: any) => element.name === 'All')
      }

      if (selectedOption.length > 1) {
        selectedOption = selectedOption.filter((element: any) => element.name !== 'All')
      }
      setStudyPhaseDefault(selectedOption);

      let technicalFeasibility = "";
      selectedOption.forEach((k: any, v: any) => {
        v > 0 ? technicalFeasibility = technicalFeasibility.concat(",").concat(k.id.replace("&", "%26")) : technicalFeasibility = technicalFeasibility.concat(k.id.replace("&", "%26"));
      });
  
      setFilters({ ...filters, technicalFeasibility});     
    };
  
    return (
      <>
      { column.text }
        <Multiselect
          dataKey='id'
          textField='name'
          value={studyPhaseDefault}
          onChange={handleChange}
          data={data}
        />
      </>
    );
  }

  const eligibleAreaMinMax = (column: any, colIndex: any) => {

    const onMinChange = (e: any) => {

      let minArea = e.target.value;

      // if the existing filter is not empty string & currently user sets to empty string
      if (filters.minArea !== "" && minArea === "") {
        setFilters({ ...filters, minArea});
      }

      if (minArea>=0) {
        setFilters({ ...filters, minArea});
      }
    };

    const onMaxChange = (e: any) => {
      
      let maxArea = e.target.value;
      
      // if the existing filter is not empty string & currently user sets to empty string
      if (filters.maxArea !== "" && maxArea === "") {
        setFilters({ ...filters, maxArea});
      }

      if (maxArea>=0) {
        setFilters({ ...filters, maxArea});
      }
    };

    return (
      <>
        { column.text }
        <div style={{display: 'flex'}}>
          <input
          key="min"
          style={{width: '50px', backgroundColor: 'white', fontSize: '12px', borderRadius: '5px'}}
          type="text"
          value={minmaxFilters.minArea}
          onChange={(e) => setMinmaxFilters({...minmaxFilters, minArea: e.target.value})} 
          onBlur={onMinChange}
          placeholder="Min"
        />
        <input
          key="max"
          style={{width: '50px', backgroundColor: 'white', fontSize: '12px', borderRadius: '5px'}}
          type="text"
          value={minmaxFilters.maxArea}
          onChange={(e) => setMinmaxFilters({...minmaxFilters, maxArea: e.target.value})} 
          onBlur={onMaxChange}
          placeholder="Max"
        />
        </div>
        
      </>
        
    );
  }

  const availSizePerc = (column: any, colIndex: any) => {

    const onMinChange = (e: any) => {
      
      let minPerc = e.target.value;
      
      // if the existing filter is not empty string & currently user sets to empty string
      if (filters.minPerc !== "" && minPerc === "") {
        setFilters({ ...filters, minPerc});
      }

      if (minPerc>=0 && minPerc !== "") {
        setFilters({ ...filters, minPerc});
      }
    };

    const onMaxChange = (e: any) => {

      let maxPerc = e.target.value;

      // if the existing filter is not empty string & currently user sets to empty string
      if (filters.maxPerc !== "" && maxPerc === "") {
        setFilters({ ...filters, maxPerc});
      }
      
      if (maxPerc>=0 && maxPerc !== "") {
        setFilters({ ...filters, maxPerc});
      }
    };

    return (
      <>
        { column.text }
        <div style={{display: 'flex'}}>
          <input
          key="min"
          style={{width: '50px', backgroundColor: 'white', fontSize: '12px', borderRadius: '5px'}}
          type="text"
          value={minmaxFilters.minPerc}
          onChange={(e) => setMinmaxFilters({...minmaxFilters, minPerc: e.target.value})} 
          onBlur={onMinChange}
          placeholder="Min %"
        />
        <input
          key="max"
          style={{width: '50px', backgroundColor: 'white', fontSize: '12px', borderRadius: '5px'}}
          type="text"
          value={minmaxFilters.maxPerc}
          onChange={(e) => setMinmaxFilters({...minmaxFilters, maxPerc: e.target.value})} 
          onBlur={onMaxChange}
          placeholder="Max %"
        />
        </div>
        
      </>
        
    );
  }

  const createdAtSortClick = (e: any, column: any, columnIndex: any) => { 

    let sortBy = "createdAt";
    setFilters({...filters, sortBy})
    let sortOrder = createdAtSort === "1" ? "-1" : "1";
    setCreatedAtSort(sortOrder);
    setFilters({ ...filters, sortBy, sortOrder});
  }

  const updatedAtSortClick = (e: any, column: any, columnIndex: any) => { 
    
    let sortBy = "updatedAt";
    setFilters({...filters, sortBy})
    let sortOrder = updatedAtSort === "1" ? "-1" : "1";
    setUpdatedAtSort(sortOrder)
    setFilters({ ...filters, sortBy, sortOrder});
  }

  const caseManagerMultiSelect = (column: any, colIndex: any) => {
    
    const data  = [
      { id: '', name: 'All' },
    ];

    siteManagers.forEach((k: ISubUser, v: any) => {
      data.push({id: k.id || "" , name: k.fullName || "" });
    });

    const handleChange = (selectedOption: any, metadata: any) => {
      if (metadata.dataItem.name === "All") {
        selectedOption = selectedOption.filter((element: any) => element.name === 'All')
      }

      if (selectedOption.length > 1) {
        selectedOption = selectedOption.filter((element: any) => element.name !== 'All')
      }
      setRelationshipMgrDefault(selectedOption);

      let caseManager = "";
      selectedOption.forEach((k: any, v: any) => {
        v > 0 ? caseManager = caseManager.concat(",").concat(k.id) : caseManager = caseManager.concat(k.id);
      });
      setFilters({ ...filters, caseManager});
    };

    return (
      <>
        { column.text }
        <Multiselect
          dataKey='id'
          onChange={handleChange}
          textField='name'
          data={data}
          value={relationshipMgrDefault}
        />
      </>
    );
  } 

  const stateMultiSelect = (column: any, colIndex: any) => {

    const data  = [
      { id: '', name: 'All' },
      { id: 'NT', name: 'Northern Territory' },
      { id: 'WA', name: 'Western Australia' },
      { id: 'SA', name: 'South Australia' },
      { id: 'VIC', name: 'Victoria' },
      { id: 'TAS', name: 'Tasmania' },
      { id: 'ACT', name: 'Australian Capital Territory' },
      { id: 'NSW', name: 'New South Wales' },
      { id: 'QLD', name: 'Queensland' },
    ];

    const handleChange = (selectedOption: any, metadata: any) => {
      if (metadata.dataItem.name === "All") {
        selectedOption = selectedOption.filter((element: any) => element.name === 'All')
      }

      if (selectedOption.length > 1) {
        selectedOption = selectedOption.filter((element: any) => element.name !== 'All')
      }
      setStateDefault(selectedOption);

      let state = "";
      selectedOption.forEach((k: any, v: any) => {
        v > 0 ? state = state.concat(",").concat(k.id) : state = state.concat(k.id);
      });
      setFilters({ ...filters, state});
    };

    return (
      <>
        { column.text }
        <Multiselect
          dataKey='id'
          textField='name'
          // defaultValue={['']}
          value={stateDefault}
          onChange={handleChange}
          data={data}
        />
      </>
    );
  }

  const getFormattedDate = (date: Date) => {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return day + '/' + month + '/' + year;
  }

  const dateFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    let date_obj = new Date(Date.parse(cell))
    return <p>{getFormattedDate(date_obj)}</p>;
  };

  const createdByFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    
    if (row?.landHoldingCreatedByUser) {
      return <p>{row.landHoldingCreatedByUser.firstName} {row.landHoldingCreatedByUser.lastName}</p>;
    }
  
    return <></>;
  }
  
  const mainContactFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    
    if (row?.landHoldingUserOrgContact) {
      return <p>{row.landHoldingUserOrgContact.firstName} {row.landHoldingUserOrgContact.lastName}</p>;
    }
  
    return <></>;
  }
  
  const integerFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    let int_obj = Number(cell).toFixed(2);

    //handle case when there is no area
    int_obj = isNaN(Number(int_obj)) ? "" : int_obj;
    
    return <p>{int_obj}</p>;
  };

  const { isLoading } = useSelector(landCareList)

  const results = searchResults.map((res: any) => ({
    ...res,
    ownerName: !res?.landHoldingUser?.firstName ? 'UPLOADED' :
                (res?.landHoldingUser?.firstName ? res.landHoldingUser?.firstName : '') + ' ' +
                (res?.landHoldingUser?.lastName ? res.landHoldingUser?.lastName : ''),
    addressLine: res.landHolding[0]?.address?.line1,
    state: res.landHolding[0]?.address?.state
  }));

  const stringFormatter = (cell: string, row: any, rowIndex: number, formatExtraData: any) => {
    return cell ? cell.toString() : '';
  };

  const 
  columns = [{
    dataField: "createdAt",
    text: 'Created On',
    sort: true,
    formatter: dateFormatter,
    headerStyle: () => ({ width: '5%' }),
    headerEvents: {
      onClick: createdAtSortClick
    }
  }, {
    dataField: "updatedAt",
    text: 'Updated On',
    sort: true,
    formatter: dateFormatter,
    headerStyle: () => ({ width: '5%' }),
    headerEvents: {
      onClick: updatedAtSortClick
    }
  }, {
    dataField: 'landHoldingUserOrg.userOrgName',
    text: 'Organisation',
    headerStyle: () => ({ width: '10%' }),
  }, {
    dataField: 'name',
    text: 'Property Name',
    headerStyle: () => ({ width: '8%' }),
  }, {
    dataField: 'landHoldingCreatedByUser.firstName',
    text: 'Created By',
    formatter: createdByFormatter,
    headerStyle: () => ({ width: '6%' }),
  }, {
    dataField: "landHoldingUserOrgContact.firstName",
    text: 'Main Contact',
    formatter: mainContactFormatter,
    headerStyle: () => ({ width: '6%' }),
  }, {
    dataField: 'addressLine',
    text: 'Address',
    headerStyle: () => ({ width: '8%' }),
  }, {
    dataField: 'state',
    text: 'State',
    headerStyle: () => ({ width: '6%' }),
    headerFormatter: stateMultiSelect,
  }, {
    dataField: 'status',
    text: 'Status',
    headerStyle: () => ({ width: '6%' }),
    headerFormatter: statusMultiSelect,
  }, {
    dataField: "caseManager.fullName",
    text: 'Relationship Manager',
    formatter: stringFormatter,
    headerStyle: () => ({ width: '6%' }),
    headerFormatter: caseManagerMultiSelect
  }, {
    dataField: "landHolding[0].mapGraphItems[0].area",
    text: 'Size of Land',
    formatter: integerFormatter,
    headerStyle: () => ({ width: '3%' }),
    headerFormatter: eligibleAreaMinMax,
  }, {
    dataField: "landHolding[0].availableSizePercentage",
    text: 'Available Size %',
    headerStyle: () => ({ width: '3%' }),
    headerFormatter: availSizePerc, 
  }, {
    dataField: "technicalFeasibility",
    text: 'Study Phase',
    headerStyle: () => ({ width: '9%' }),
    headerFormatter: StudyPhaseMultiSelect,
  }];

  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: number) => {
      const id = results[rowIndex]._id;
      props.history.push(`/projects/${id}`);
    }
  };

  const changePageNo = async (pageNo: number) => {
    const modulePageInfo: IModulePageInfoPayload = {
      moduleName: ModulesEnum.Home,
      modulePageInfo: { pageNumber: pageNo }
    };
    console.log('Page No ===', modulePageInfo);
    dispatch(setModulePageInfo(modulePageInfo));
  };

  const changePageSize = (pageSize: number) => {
    const modulePageInfo: IModulePageInfoPayload = {
      moduleName: ModulesEnum.Home,
      modulePageInfo: { pageNumber: 1, pageSize }
    };
    dispatch(setModulePageInfo(modulePageInfo));
  };

  const filterByStatus = async (status: string) => {
    setFilters({ ...filters, status });
  };

  const buttonStyle = {
    color: 'grey',
    cursor: 'pointer'
  };

  return (
    <Container fluid style={{ marginTop: '8px' }} className="home">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <Button className="float-right" onClick={onManageLandHoldings}>
                Manage Organisations
            </Button>
          </div>

          <div data-test="Autocomplete">
            <SearchLandCare key="search"
                            landCareId={props.landCareId}
                            getTokens={props.getTokens}
                            accessToken={token as string}
                            onSearchByTerm={applySearchTerm}
                            onSelection={onItemSelected}
                            clearFilters={clearFilters}
            >
            </SearchLandCare>
          </div>
          
          <div className="z-index-table" style={{ padding: '0 16px' }}>
            <br></br>
            <LandholdingCare onLinkClicked={filterByStatus}>
              <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                <PaginationTable  keyField='id'
                                  data={ results }
                                  columns={ columns }
                                  classes="table table-bordered table-striped table-sm mt-0"
                                  pageNumber={ pageNumber }
                                  pageSize={ pageSize }
                                  recordCount={ recordCount }
                                  rowEvents={ rowEvents }
                                  onPageChanged={ changePageNo }
                                  onPageSizeChanged={ changePageSize }
                />
              </LoadingOverlay>
            </LandholdingCare>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;