import React, { useState } from 'react';

import './Checkbox.scss';

interface ICheckboxProps {
  label: string;
  isChecked: boolean;
  onChange?: any;
}

const Checkbox : React.FC<any> = (props: ICheckboxProps) => {
  const [ checked, setChecked ] = useState(false);

  const onChange = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };

  return (
    <div className="gerx-checkbox">
      <label>
        <input  type="checkbox"
                value={props.label}
                checked={props.isChecked}
                onChange={onChange}
        />
        &nbsp;{props.label}
      </label>
    </div>
  );
};

export default Checkbox;