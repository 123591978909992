import { createSelector } from 'reselect';

import { LandHoldingsState } from '../Reducers/LandHoldingsSlice';
import { RootState } from '../RootStore';

export const landholdingSelector = (state: RootState) => state.landHoldings;

export const selectCurrentLandholding = createSelector(
    [landholdingSelector],
    (state: LandHoldingsState) => state.currentLandHolding
);

export const selectFiles = createSelector(
    [landholdingSelector],
    (state: LandHoldingsState) => state.files
);