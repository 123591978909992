import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Jumbotron} from 'react-bootstrap';

import axios from 'axios';

interface GetDateProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
  getAccessToken: any;
  getTokens: any;
}

interface GetDateState {
  isOpen: boolean;
  serverDatetimes: IServerDateTimes;
}

interface IGetDateResponse {
  name: string | null;
  serverDatetimes: IServerDateTimes;
}

interface IServerDateTimes {
  serverDatetime: string;
  melbourneDateTime: string;
  adelaideDateTime: string;
  perthDateTime: string;
  darwinDateTime: string;
  brisbaneDateTime: string;
  sydneyDateTime: string;
  canberraDateTime: string;
  hobartDateTime: string;
}

function GetDateContent(props: IGetDateResponse) {

  const serverDatetimes = props.serverDatetimes;
  //const fmtServerDatetime = moment(serverDatetimes.serverDatetime).format("LLLL");
  // const fmtMelDatetime = moment(serverDatetimes.melbourneDateTime).format("LLLL");
  // const fmtBrisDatetime = moment(serverDatetimes.serverDatetime).format("LLLL");

  return (
    <div>
      <div><br/>Server DateTime: {serverDatetimes.serverDatetime}</div>
      <div>Melbourne DateTime: {serverDatetimes.melbourneDateTime}</div>
      <div>Brisbane DateTime: {serverDatetimes.brisbaneDateTime}</div>
    </div>
  );
}

// function getDataWithoutAuth(props: GetDateProps) {
//   console.log("Get Server Data was called!");
//   let LandCare = { responseData: ""};

//   axios.get("https://playground-gerxdev.azurewebsites.net/api/playground?name=Test")
//   .then(res => {
//     console.log(res);
//     LandCare.responseData = res.data;
//     console.log(LandCare.responseData);
//   });
  
//   console.log("Get Server Data call has been completed");
//   return (
//     LandCare
//   );
// }


async function getDataWithAuth(accessToken: string) : Promise<IGetDateResponse> {

  const gerxfnAppUri = process.env.REACT_APP_GERXFNAPP_API_URI;
  console.log("Get Server Data with Bearer Token was called! Calling api: " + gerxfnAppUri);
  let responseData : IGetDateResponse = {} as IGetDateResponse;
  //const tokens = await props.getTokens(['user.read']);  
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  // Part 1  
  await axios.get(gerxfnAppUri as string, config)
  .then(res => {
    console.log(res);
    console.log(res.data);
    responseData = res.data;
  });

  // // Part 2
  // let payload = await fetch("https://graph.microsoft.com/beta/me", {
  //   headers : {
  //     'Authorization' : `Bearer ${accessToken}`
  //   }
  // });  
  // let json = await payload.json();
  // console.dir(json);
  
  //console.log("Get Server Data with Bearer Token call has been completed");
  return responseData;
}


class GetDate extends Component<GetDateProps, GetDateState> {

  constructor(props: GetDateProps) {
    super(props);

    this.setState({} as GetDateState);
  }


  handleClick = async () => {
    console.log("Retrieved click event...");

    const tokens : { accessToken: string, idToken: string } = await this.props.getTokens(['https://gerx.onmicrosoft.com/devapi/read', 'https://gerx.onmicrosoft.com/devapi/write']);
    
    //console.log("Tokens as received: ", tokens);
    if(tokens && tokens.accessToken) {

      const serverData = await getDataWithAuth(tokens.accessToken);

      if(serverData && serverData.serverDatetimes)
      {
        this.setState((prevState) => ({isOpen: true, serverDatetimes: serverData.serverDatetimes}));
      }
      console.log("Got data as: ", serverData);
    }
  }

  render() {
    return (
      <Jumbotron>
          <div>
            <div>{
              (this.props.isAuthenticated) ?
              <React.Fragment>
              <Button variant="info" onClick={this.handleClick}>Call API</Button>
              <div>
                {(!this.state || !this.state.serverDatetimes) ?
                  <p><br/>API response will be displayed here</p> : 
                  <GetDateContent  
                    name={this.props.user.name}
                    serverDatetimes={this.state.serverDatetimes}
                    />
                }
              </div>
              </React.Fragment>            
              :  
              <Button color="primary" onClick={this.props.authButtonMethod}>Please Sign-in to call API</Button>
              }
            </div>
          </div>
      </Jumbotron>
    );
  }
}

export default GetDate;
