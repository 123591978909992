import { IUserProfile } from "../Auth/IUser";
import { ILandCareUserOrgContact } from "./LandCare";

export interface IChatSummary {
    userOrgContact: ILandCareUserOrgContact;
    isRegisteredUser: boolean;
    isOnline?: boolean;
    unreadMessageCount?: number;
    messages?: IChatMessage[];
    lastMessageStatus?: number;
    groupId?: string;

    lastPageNo?: number;
    isEndOfRecords?: boolean;
    lastActiveUtc?: Date;
    last_active?: Date;
};

export interface IChatMessage {
    _id: string;
    body: string;
    isRead?: boolean;
    createdAt: string;
    createdBy: string;
    createdByUser?: IUserProfile[];
    files?: any[];

    messageStatus: MessageStatusEnum;
    isMyMessage?: boolean;
    isMyLastMessage?: boolean;
    userProfileId?: string;
};

export interface IChatMessageResponse extends IChatMessage {
    groupId: string;
}

export interface IChatSummaryResponse {
    landCareId: string;
    chatSummaries: IChatSummary[];
};

export interface IChatMessagesResponse {
    pageNo: number;
    chatMessages: IChatMessage[];
};

export enum MessageStatusEnum {
    IsSending,
    IsSent,
    IsNotSent,
    IsRead
};

export interface IMessagesReadByUserResponse {
    groupId: string;
    landCareId: string;
    userId: string;
    userProfileId?: string;
    userOrgContactId: string;
};

export interface IActiveUserStatusResponse {
    userId: string;
    lastActiveUtc: Date;
};

export interface IChatSummaryPageInfo {
    groupId?: string;
    lastPageNo: number;
    recordCount: number;
};