import * as yup from 'yup';
import { IValidationField } from '../Validator/validationHelper';

export const notesFieldList: IValidationField[] = [
    {
        name: 'notes',
        title: 'Notes',
        validation: yup.string().required().min(2)
    },
    {
        name: 'userOrgContactId',
        title: 'User Organization Contact Id',
        validation: yup.string().when('contactMode', {
            is: (value: string) => (value === 'General' ||  value === 'Meeting'),
            then: yup.string(),
            otherwise: yup.string().required()
        })
    },
    {
        name: 'contactMode',
        title: 'Contact Mode',
        validation: yup.string().required()
    }
];

// const validationSchema: any = 
//     contactFieldList.reduce((field: IContactFieldList, obj: any) => ({...obj, [field.name]: field.validation }));

let validationSchema: any = {};

notesFieldList.forEach((field: IValidationField) => {
    validationSchema[field.name] = field.validation;
});

export const notesSchema = yup.object().shape(validationSchema);