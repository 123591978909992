import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface IProps {
    variant?: string;
    className?: string;
    onClick?: any;
    type?: string;
    isActive?: boolean;
}

const SpinnerButton: React.FC<IProps> = (props) => {
    return (
        <Button variant={props.variant}
                className={props.className}
                onClick={props.onClick}
                type={props.type}
                disabled={props.isActive}>
            {props.children}
            { !props.isActive ? <></> :
            <>
                &nbsp;<Spinner as="span" animation="border" size="sm" aria-hidden="true" />
            </>
            }
        </Button>
    );
};

export default SpinnerButton;