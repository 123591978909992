import { createSelector } from 'reselect';

import { IUserOrgState } from '../Reducers/UserOrgSlice';
import { RootState } from '../RootStore';

export const selectUserOrg = (state: RootState) => state.userOrg;

export const selectCurrentUserOrg = createSelector(
  [selectUserOrg],
  (state: IUserOrgState) => state.currentUserOrg
);

export const selectCreatedByOrg = createSelector(
  [selectUserOrg],
  (state: IUserOrgState) => state.createdByOrg
);

export const selectUserOrgContacts = createSelector(
  [selectUserOrg],
  (state: IUserOrgState) => state.userOrgContacts
);
