import React from 'react';
import { Form, OverlayTrigger, Spinner } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import { Option } from 'react-multi-select-component/dist/lib/interfaces';

import { IMetaData, IMetaValue, IModulePage, IModuleSection, MetaControls } from "../../Store/Reducers/ModuleConfigSlice";
import { spinnerStyle } from '../constants';
import { renderTooltip } from '../Validator/validationHelper';
import Stepper from './Stepper/Stepper';

export const renderControls = (section: IModuleSection | undefined, formObject: any, blurFn: any, changeFn: any, className: string, isLoading: boolean, isSaving?: any, isEnabled: boolean = true, ref?:any, errors?:string[]): any => {
    const configFormFields: IMetaData[] = section ? section.fields : [];

    return (
        <>
            {
                !section || !section.fields.length ? <></> :
                section.fields.slice().map((field: IMetaData) => (
                    <>
                        {
                            renderControlGroup(field, configFormFields, formObject, blurFn, changeFn, className, isLoading, isSaving, isEnabled)
                        }
                    </>
                ))
            }
        </>
    );
};

const renderControlGroup = (field: IMetaData, configFormFields: any, formObject: any, blurFn: any, changeFn: any, className: string, isLoading: boolean, isSaving: any, isEnabled: boolean) => {
    const fieldName = field.metaId;//'landcare' + field.metaId.substr(0, 1).toUpperCase() + field.metaId.substr(1);
    const configData: IMetaData = configFormFields?.find((f: IMetaData) => f.metaId === fieldName) as IMetaData;
    const fieldValue: string = (formObject && formObject[fieldName]) || '';
    const style = {...spinnerStyle, display: (isLoading || (isSaving as any)[fieldName] ? '' : 'none')};

    let group: any = <></>;

    const clickFn = (options: Option[]) => {
        const value = options.map((option: Option) => option.value).join();
        changeFn(fieldName, value);

        const event = { target: { id: fieldName, value }};
        blurFn(event);
    };

    switch (field.metaControl) {
        case MetaControls.Dropdown:
            group = renderDropdownFormGroup(fieldName, configData, fieldValue, style, blurFn, className, isEnabled); break;
        case MetaControls.Text:
            group = renderTextFieldFormGroup(fieldName, configData, fieldValue, style, blurFn, changeFn, className, isEnabled); break;
        case MetaControls.Decimal:
            group = renderDecimalFieldFormGroup(fieldName, configData, fieldValue, style, blurFn, changeFn, className, isEnabled); break;
        case MetaControls.Textarea:
            group = renderTextAreaFormGroup(fieldName, configData, fieldValue, style, blurFn, changeFn, className, isEnabled); break;
        case MetaControls.Date:
            group = renderDateFormGroup(fieldName, configData, fieldValue, style, blurFn, changeFn, className, isLoading, isEnabled); break;
        case MetaControls.Stepper:
            group = renderStepperField(field, formObject, className); break;
        case MetaControls.Multiselect:
            group = renderMultiselectField(fieldName, configData, fieldValue, style, clickFn, className, isEnabled); break;
    }

    return (
        <>{group}</>
    )
};

export const renderDropdownFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, changeFn: any, className: string = '', isEnabled: boolean, ref?: any, errors?:string[]): any => {
    if (fieldName && configData){
        if(configData?.metaValues){
            var sorted = configData?.metaValues
            if(configData?.metaValues?.length >= 0){
                sorted = configData?.metaValues?.slice().sort((a,b) => {
                    if(a.order && b.order){//Assuming array either has order or does not
                        return a.order > b.order? 1 : -1
                    }
                    return a.name > b.name? 1 : -1
                });
            }   
            return (
                <>
                    {/* {isLoading && <><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Updating...</p></>} */}
                    <Form.Group controlId={fieldName} className={className}>
                        <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                        <div style={style}>
                            <Spinner animation="border" role="status" size="sm" />
                        </div>
                        {   errors && errors.length ?
                            (
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={errors && renderTooltip(fieldName, errors)}>
                                    <Form.Control as="select" name={fieldName} onChange={changeFn} ref={ref} isInvalid={errors && errors.length ? true : false}>
                                        <option value="" selected={!fieldValue}>Select...</option>
                                        {
                                        sorted?.map((val: any) =>
                                            <option key={val.id} value={val.id} selected={val.id === fieldValue}>{val.name}</option>
                                        )}
                                    </Form.Control>
                                </OverlayTrigger>
                            )
                            :
                            <Form.Control as="select" disabled={!isEnabled} name={fieldName} onChange={changeFn} ref={ref} isInvalid={errors && errors.length ? true : false}>
                                <option value="" selected={!fieldValue}>Select...</option>
                                {
                                sorted?.map((val: any) =>
                                    <option key={val.id} value={val.id} selected={val.id === fieldValue}>{val.name}</option>
                                )}
                            </Form.Control>
                        }
                    </Form.Group>
              </>
            );
        }
    }
};

//= (configFormFields: any, formObject: any, fieldName: string, metaName: string, blurFn: any, changeFn: any, className: string = '', isLoading: boolean, isEnabled: boolean = true): any => {
    
export const renderDateFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, blurFn: any, changeFn: any, className: string = '', isLoading: boolean,  isEnabled: boolean, ref?: any, errors?:string[]): any => {
    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style} />
                <Form.Control 
                    type="date" 
                    placeholder="mm/dd/yyyy" 
                    disabled={isLoading} 
                    value={fieldValue} 
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    readOnly={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderTextAreaFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, blurFn: any, changeFn: any, className: string = '', isEnabled: boolean, ref?:any, errors?:string[]): any => {
    return (
        <>
            <Form.Group controlId={fieldName} className={'col-12'}>
                <Form.Label className="bold-labels col-12">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderTextFieldFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, blurFn: any, changeFn: any, className: string = '', isEnabled: boolean, ref?:any, errors?:string[]): any => {
    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    type="text"
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderDecimalFieldFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, blurFn: any, changeFn: any, className: string = '', isEnabled: boolean, ref?:any, errors?:string[]): any => {
    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control
                    type="number"
                    placeholder={configData?.metaPlaceholder}
                    value={fieldValue}
                    onChange={(e: any) => changeFn(fieldName, e.target.value)}
                    onBlur={blurFn}
                    disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderNumberFieldFormGroup = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, blurFn: any, changeFn: any, className: string = '', isEnabled: boolean, ref?:any, errors?:string[], ): any => {
    return (
        <>
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <div style={style}>
                    <Spinner animation="border" role="status" size="sm" />
                </div>
                <Form.Control type="number"
                            placeholder={configData?.metaPlaceholder}
                            value={fieldValue}
                            onChange={(e: any) => changeFn(fieldName, e.target.value)}
                            onBlur={blurFn}
                            disabled={!isEnabled}
                />
            </Form.Group>
        </>
    );
};

export const renderStepperField = (field: IMetaData, formObject: any, className: string): any => {
    // let section: IModuleSection | undefined;
    // let field: IMetaData | undefined;
    // let s = 0;
    // while (!field && page?.sections && s < page?.sections.length ) {
    //   section = page?.sections[s];
    //   field = section.fields.find((field: IMetaData) => field.metaPropId === stepperField?.metaRefId);
    //   s += 1;
    // }
  
    // const refValues = field?.metaValues;

    const fieldValue = field?.metaRefId ? formObject[field.metaRefId] : '';
    return (
        <>
            <Stepper field={field} refValue={fieldValue} wrapperClass="className" />
        </>
    );
};

export const assignMetaValues = (page: IModulePage | undefined, fieldName: string, metaValues: any[]) => {
    let section: IModuleSection | undefined;
    let field: IMetaData | undefined;
    let s = 0;

    while (!field && page?.sections && s < page?.sections.length ) {
      section = page?.sections[s];
      field = section.fields.find((field: IMetaData) => field.metaPropId === fieldName);
      s += 1;
    }

    if (field) field.metaValues = metaValues;
};

export const renderMultiselectField = (fieldName: string, configData: IMetaData, fieldValue: string, style: any, clickFn: any, className: string = '', isEnabled: boolean, ref?: any, errors?:string[]): any => {
    if (fieldName && configData) {
        const options: Option[] = configData?.metaValues?.map((metaValue: IMetaValue) => ({ label: metaValue.name, value: metaValue.id })) || [];
        const values: Option[] = fieldValue.split(',').map((value: string) => ({ label: value, value }));
        
        return (
            <Form.Group controlId={fieldName} className={className}>
                <Form.Label className="bold-labels">{configData?.metaName}</Form.Label>
                <MultiSelect
                    options={options}
                    value={values}
                    onChange={clickFn}
                    labelledBy="Select"
                />
            </Form.Group>
        );
    }
};