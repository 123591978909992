import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../RootStore';
import { IChatMessage, IChatMessageResponse, IChatSummary, IMessagesReadByUserResponse, MessageStatusEnum, IActiveUserStatusResponse, IChatSummaryPageInfo } from '../../Models/LandCare/IChat';
import { IMediaFile, IMediaFileResponse } from '../../Models/MediaFiles/IMediaFile';
import { fetchLandCareChatMessages, fetchLandCareChatSummaries, postLandCareChatMessage, postLandCareChatMessageReadNotification, uploadMediaFilesById } from '../Api/LandCareApi';
import { IModulePageInfo } from './LocalStoreSlice';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';

export interface ILandCareChatState {
    chatSummaries: IChatSummary[];
    currentSummaryIndex: number;
    attachedFiles: any[];
    error?: any;
}

const initialState: ILandCareChatState = {
    chatSummaries: [],
    currentSummaryIndex: 0,
    attachedFiles: [],
    error: null
};

const landCareChatSlice = createSlice({
    name: "Chat",
    initialState,
    reducers: {        
        setLandCareChatMessageReadSuccess(state: ILandCareChatState, action: PayloadAction<number>) {
            const index = action.payload;
            state.currentSummaryIndex = index;
            let summary = state.chatSummaries[index];
            if (summary?.unreadMessageCount && summary.unreadMessageCount > 0) {
                summary.unreadMessageCount = 0;
                state.chatSummaries[index].messages?.filter((msg: IChatMessage) => msg.messageStatus === MessageStatusEnum.IsSent);
            }

            state.error = {};
        },
        setLandCareChatMessageReadFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            state.error = action.payload;
        },

        getLandCareChatSummariesSuccess(state: ILandCareChatState, action: PayloadAction<IChatSummary[]>) {
            state.chatSummaries = action.payload;
        },
        getLandCareChatSummariesFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            state.chatSummaries = [];
            state.error = action.payload;
        },

        getLandCareChatSummariesSignalRSuccess(state: ILandCareChatState, action: PayloadAction<IChatSummary[]>) {
            const chatSummaries = action.payload;
            chatSummaries.forEach((chatSummary: IChatSummary) => {
                const index = state.chatSummaries.findIndex((summary: IChatSummary) => summary.userOrgContact.id === chatSummary.userOrgContact.id);
                chatSummaries[index] = chatSummary;
            });
        },
        getLandCareChatSummariesSignalRFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            state.error = action.payload;
        },

        setLandCareChatPageInfo(state: ILandCareChatState, action: PayloadAction<IChatSummaryPageInfo>) {
            const index = state.currentSummaryIndex;
            if (action.payload.recordCount === 0) {
                state.chatSummaries[index].isEndOfRecords = true;
            }
            else {
                state.chatSummaries[index].lastPageNo = action.payload.lastPageNo;
            }
        },
        getLandCareChatMessagesSuccess(state: ILandCareChatState, action: PayloadAction<IChatMessage[]>) {
            const index = state.currentSummaryIndex;
            if (index >= 0) {
                const messages = action.payload.map((msg: IChatMessage) => ({ ...msg }));
                state.chatSummaries[index].messages?.splice(0, 0, ...messages);
                state.chatSummaries[index].lastPageNo = 1;
                state.chatSummaries[index].isEndOfRecords = false;
                state.error = {};
            }
        },
        getLandCareChatMessagesFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            const index = state.currentSummaryIndex;
            if (index >= 0) {
                state.error = action.payload;
            }
        },
        // setLandCareChatSummaryEndOfRecords(state: ILandCareChatState) {
        //     const index = state.currentSummaryIndex;
        //     if (index >= 0) {
        //         state.chatSummaries[index].isEndOfRecords = true;
        //     }
        // },

        sendLandCareChatMessageSuccess(state: ILandCareChatState, action: PayloadAction<IChatMessageResponse>) {
            const index = state.currentSummaryIndex;
            const summary = state?.chatSummaries[index];
            if (!summary.groupId) {
                summary.groupId = action.payload.groupId;
            }
            let messages = summary.messages || [];
            const message: IChatMessage = { ...action.payload, files: state.attachedFiles, isMyMessage: true };
            messages.push(message);
            state.chatSummaries[index].messages = messages;
        },
        sendLandCareChatMessageFailed(state: ILandCareChatState, action: PayloadAction<IChatMessage>) {
            state.error = action.payload;
        },

        setLandCareChatMessage(state: ILandCareChatState, action: PayloadAction<IChatMessageResponse>) {
            const index = state.currentSummaryIndex;
            const summary = state?.chatSummaries[index];
            if (!summary.groupId) {
                state.chatSummaries[index].groupId = action.payload.groupId;
            }
            let messages = summary.messages || [];
            const msgIndex = messages.findIndex((msg: IChatMessage) => msg._id === '' && msg.body === action.payload.body);
            if (msgIndex >= 0) {
                messages[msgIndex] = action.payload;
                state.chatSummaries[index].messages = messages;
            }
        },

        setCurrentLandCareChatMessages(state: ILandCareChatState, action: PayloadAction<IChatMessage[]>) {
            state.chatSummaries[state.currentSummaryIndex].messages = action.payload;
        },

        clearLandCareChatMessageAndFiles(state: ILandCareChatState) {
          state.attachedFiles = [];
        },

        attachLandCareChatFileSuccess(state: ILandCareChatState, action: PayloadAction<any>) {
            state.attachedFiles.push(action.payload);
        },
        attachLandCareChatFileFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            state.error = action.payload;
        },
        removeLandCareChatFileSuccess(state: ILandCareChatState, action: PayloadAction<number>) {
            state.attachedFiles.splice(action.payload, 1)
        },
        removeLandCareChatFileFailed(state: ILandCareChatState, action: PayloadAction<any>) {
            state.error = action.payload;
        },

        /***** SignalR Actions *****/

        signalRAppendReceivedChatMessage(state: ILandCareChatState, action: PayloadAction<IChatMessageResponse>) {
            const response = action.payload;

            let index = state.chatSummaries.findIndex((summary: IChatSummary) => summary.groupId === response.groupId);
            // if (index === -1) {
            //     index = state.chatSummaries.findIndex((summary: IChatSummary) => !summary.groupId && summary.userOrgContact.id === response.userOrgContactId);
            // }

            if (index >= 0) {
                let messages = state?.chatSummaries[index].messages || [];
                const message = { ...action.payload };
                messages.push(message);
                state.chatSummaries[index].messages = messages;
                if (index != state.currentSummaryIndex) {
                    state.chatSummaries[index].unreadMessageCount = (state.chatSummaries[index].unreadMessageCount || 0) + 1;
                }
            }
        },
        signalRMessagesReadByUser(state: ILandCareChatState, action: PayloadAction<IMessagesReadByUserResponse>) {
            const response = action.payload;

            let index = state.chatSummaries.findIndex((summary: IChatSummary) => summary.groupId === response.groupId);
            if (index === -1) {
                index = state.chatSummaries.findIndex((summary: IChatSummary) => !summary.groupId && summary.userOrgContact.id === response.userOrgContactId);
            }

            if (index >= 0) {
                let messages = (state?.chatSummaries[index].messages || [])?.map((msg: IChatMessage) => ({...msg}));
                let myMsgIndex = -1;
                messages.forEach((msg: IChatMessage, i: number) => {
                    if (action.payload.userProfileId === msg.createdBy) {
                        msg.isMyLastMessage = false;
                        msg.isRead = true;
                        msg.messageStatus = MessageStatusEnum.IsRead;
                        myMsgIndex = i;
                    }
                });
                messages[myMsgIndex].isMyLastMessage = true;
                state.chatSummaries[index].messages = messages;
            }
        },
        signalRUpdateUserStatusToActive(state: ILandCareChatState, action: PayloadAction<IActiveUserStatusResponse>) {
            const summary = state.chatSummaries.find((summary: IChatSummary) => 
                                summary.userOrgContact.userId === action.payload.userId
                            );
            if (summary) {
                summary.lastActiveUtc = action.payload.lastActiveUtc;
                summary.isOnline = true;
                console.log('Marking active ===')
            }
        },
        updateStatusOfChatGroups(state) {
            state.chatSummaries.forEach((summary: IChatSummary) => {
                summary.last_active = summary.lastActiveUtc;
                setOnlineStatus(summary);
            })
        },
    }
});

const setOnlineStatus = (summary: IChatSummary) => {
    if (summary.last_active) {
        const dt1 = (new Date(summary.last_active)).valueOf();
        const dt2 = (new Date()).valueOf();
        summary.isOnline = (dt2 - dt1) <= parseInt(process.env.REACT_APP_CHAT_USER_STATUS_OFFLINE_INTERVAL_MS || '300000');
    }
    else {
        summary.isOnline = false;
    }
}

export const LandCareChatReducer = landCareChatSlice.reducer;

export const {
    setLandCareChatMessageReadSuccess,
    setLandCareChatMessageReadFailed,
    getLandCareChatSummariesSuccess,
    getLandCareChatSummariesFailed,
    getLandCareChatMessagesSuccess,
    getLandCareChatMessagesFailed,
    sendLandCareChatMessageSuccess,
    sendLandCareChatMessageFailed,
    setLandCareChatMessage,
    setCurrentLandCareChatMessages,
    clearLandCareChatMessageAndFiles,
    setLandCareChatPageInfo,
    // setLandCareChatSummaryEndOfRecords,
    attachLandCareChatFileSuccess,
    attachLandCareChatFileFailed,
    removeLandCareChatFileSuccess,
    removeLandCareChatFileFailed,
    signalRAppendReceivedChatMessage,
    signalRMessagesReadByUser,
    signalRUpdateUserStatusToActive,
    updateStatusOfChatGroups,
} = landCareChatSlice.actions;


export const getLandCareChatSummaries = (accessToken: string, userProfileId: string, landCareId: string, pageSize: number) : AppThunk => async dispatch => {
    try {
        const chatSummaries : IChatSummary[] = await fetchLandCareChatSummaries(accessToken, landCareId, pageSize);
        chatSummaries.forEach((summary: IChatSummary) => {
            summary.lastPageNo = 1;
            summary.messages = getSortedMessages(summary.messages?.length ? summary?.messages.map((msg: IChatMessage) => ({ ...msg })) : []);
            summary.isEndOfRecords = false;
            // summary.unreadMessageCount =
            //     summary.messages.filter((msg: IChatMessage) => (
            //         (msg.createdBy !== userProfileId) && !msg.isRead
            //     )).length;
            setOnlineStatus(summary);
        });
        
        dispatch(getLandCareChatSummariesSuccess(chatSummaries));
      }
      catch(err) {
        dispatch(getLandCareChatSummariesFailed(err));
        showMessage(err.message, MessageModeEnum.ERROR);;
      }
      finally {
        //dispatch(stopLoadingState());
      }
};

export const sendLandCareChatMessage = (accessToken: string, landCareId: string, fromContactId: string, toContactId: string, groupId: string, message: string, files: string[], endFn?: Function) : AppThunk => async dispatch => {
    const chatMessageResponse: IChatMessageResponse = {
        _id: '',
        body: message,
        createdBy: fromContactId,
        createdAt: (new Date()).toString(),
        messageStatus: MessageStatusEnum.IsSending,
        files: files,
        groupId
    };
    dispatch(sendLandCareChatMessageSuccess(chatMessageResponse));
    
    try {
        dispatch(clearLandCareChatMessageAndFiles());
        if (endFn) endFn();
        const result = await postLandCareChatMessage(accessToken, landCareId, fromContactId, toContactId, groupId, message, files);
        console.log('Sent Message Result ===', result);
        const newResponse = { ...chatMessageResponse, ...result };
        dispatch(setLandCareChatMessage({ ...newResponse, messageStatus: MessageStatusEnum.IsSent }));

        //dispatch(sendLandCareChatMessageSuccess({ ...result, isMyMessage: true }));
    }
    catch(err) {
        dispatch(sendLandCareChatMessageFailed(err));
        dispatch(setLandCareChatMessage({ ...chatMessageResponse, messageStatus: MessageStatusEnum.IsNotSent }));
        showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
        //dispatch(stopLoadingState());
    }
};

export const getLandCareChatMessages = (accessToken: string, landCareId: string, groupId: string, pageNumber: number, pageSize: number) : AppThunk => async dispatch => {
    try {
        const messages = await fetchLandCareChatMessages(accessToken, landCareId, groupId, pageNumber, pageSize);
        const chatMessages = getSortedMessages(messages);
        dispatch(getLandCareChatMessagesSuccess(chatMessages));

        const payload: IChatSummaryPageInfo = {
            lastPageNo: pageNumber,
            recordCount: chatMessages.length
        };
        dispatch(setLandCareChatPageInfo(payload));
    }
    catch(err) {
        dispatch(getLandCareChatMessagesFailed(err));
        showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
        //dispatch(stopLoadingState());
    }
};

export const setLandCareChatCurrentSummaryIndex = (accessToken: string, index: number, landCareId: string, groupId: string) : AppThunk => async dispatch => {
    try {
        //dispatch(landCareStartLoading());
        //const result = await setLandCareChatMessageRead(accessToken, landCareId, groupId, contactId)
        dispatch(setLandCareChatMessageReadSuccess(index));
        dispatch(clearLandCareChatMessageAndFiles());
        if (groupId) {
            await postLandCareChatMessageReadNotification(accessToken, landCareId, groupId);
        }
    }
    catch(err: any) {
        dispatch(setLandCareChatMessageReadFailed(err));
        showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
        //dispatch(stopLoadingState());
    }
};

export const sendLandCareChatMessageReadNotification = (accessToken: string, landCareId: string, groupId: string) : AppThunk => async dispatch => {
    await postLandCareChatMessageReadNotification(accessToken, landCareId, groupId);
};
 
export const attachLandCareChatFile = (accessToken: string, module: string, moduleRefId: string, file: IMediaFile) : AppThunk => async dispatch => {
  try {
      //dispatch(landCareStartLoading());
      //const result = await setLandCareChatMessageRead(accessToken, landCareId, groupId, contactId)
      const result: IMediaFileResponse = await uploadMediaFilesById(accessToken, module, moduleRefId, [file]);
      const mediaFile = result?.mediaFiles[0];
      dispatch(attachLandCareChatFileSuccess(mediaFile));
  }
  catch(err) {
      //dispatch(attachLandCareChatFileFailed(err));
      showMessage(err.message, MessageModeEnum.ERROR);;
  }
  finally {
  }
};

export const removeLandCareChatFile = (accessToken: string, index: number) : AppThunk => async dispatch => {
    try {
        //dispatch(landCareStartLoading());
        //const result = await setLandCareChatMessageRead(accessToken, landCareId, groupId, contactId)
        dispatch(removeLandCareChatFileSuccess(index))
    }
    catch(err) {
        dispatch(removeLandCareChatFileFailed(err));
        showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
        //dispatch(stopLoadingState());
    }
};

const getSortedMessages = (messages: IChatMessage[]) : IChatMessage[] => {
    if (messages?.length) {
        messages = messages.map((msg: IChatMessage) => ({ ...msg }));
    }

    return messages.sort((x: IChatMessage, y: IChatMessage) => new Date(x.createdAt).valueOf() - new Date(y.createdAt).valueOf()) || [];
};