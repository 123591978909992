import React from 'react';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class NotFound extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <Jumbotron>
        <h1>Not Found!</h1>
        <p className="lead">
          Sorry! The resouce you requested for, could not be located.
        </p>
      </Jumbotron>
    );
  }
}

export default NotFound;
