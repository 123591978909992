import React, { useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
// import { landCareList, getLandCare, searchLandCare, showAutoList, hideAutoList } from '../../../Store/Reducers/LandCareSlice';
import Autosuggest from 'react-autosuggest';
import { getAutoSuggestions, clearAutoSuggestions, selectAutoSuggestSearchTerm, setSearchTerm, selectAutoSuggestSuggestions, ILandCareAutoSuggestions } from '../../../Store/Reducers/LandCareAutoSuggestSlice';
import { ILandCarer } from '../../../Models/LandCare/LandCare';
import { Badge, Button } from 'react-bootstrap';
import './SearchLandCare.scss';

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
interface ISearchLandCare {
  key: string;
  getTokens: Function;
  accessToken: string;
  landCareId: string;
  onSelection: Function;
  onSearchByTerm: Function;
  clearFilters: Function;
}

let timeout: any;

const SearchLandCare: React.FC<ISearchLandCare> = (props) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(selectAutoSuggestSearchTerm);
  const suggestions = useSelector(selectAutoSuggestSuggestions);
  const [ filterTerm, setFilterTerm ] = useState('');
  
  const getSuggestionValue = (suggestion: any) => {
    props.onSelection(suggestion);
    return suggestion.name;
  };
  
  const renderSuggestion = (suggestion: ILandCarer) => {
    return (
      <span style={{fontSize: '14px'}}>{suggestion.name}</span>
    );
  }
  
  const renderSectionTitle = (section: ILandCareAutoSuggestions) => {
    return (
      <strong>{section.status}</strong>
    );
  }
  
  const getSectionSuggestions = (section: ILandCareAutoSuggestions) => {
    return section.records;
  }  

  const onChange = (event: any, { newValue, method }: any) => {
    dispatch(setSearchTerm(newValue));
  };
  
  const onGetAutoSuggestions = ({ value }: any) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (value && value.length >= 3) {
        dispatch(getAutoSuggestions(props.accessToken, value));
      }
    }, 700);
  };

  const onClearAutoSuggestions = () => {
    dispatch(clearAutoSuggestions());
  };

  const searchByTerm = () => {
    clearTimeout(timeout);
    setFilterTerm(searchTerm);
    props.onSearchByTerm(searchTerm);
  }

  const inputProps = {
    placeholder: "Enter Search Term",
    value: searchTerm,
    onChange
  };

  const condition = searchTerm && searchTerm.length >= 3;

  const buttonStyle = {
    color: condition ? 'black' : 'grey',
    cursor: condition ? 'pointer' : 'auto'
  };

  const input: any = document.getElementsByClassName('react-autosuggest__input')[0];
  if (input) input.onkeypress = function (event: any) {
    if (event.keyCode === 13) {
      searchByTerm();
    }
  };

  const clearSearchTerm = () => {
    setFilterTerm('');
    props.onSearchByTerm('');
    dispatch(setSearchTerm(''));
  };

  const clearFilters = () => {
    props.clearFilters(true);
  }

  return (
    <>
      <div data-test="App" className="search-bar">
        <Autosuggest  multiSection={true}
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={onGetAutoSuggestions}
                      onSuggestionsClearRequested={onClearAutoSuggestions}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      renderSectionTitle={renderSectionTitle}
                      inputProps={inputProps}
                      getSectionSuggestions={getSectionSuggestions}
        />
        <div className="button" onClick={searchByTerm} style={ buttonStyle }>
          <i  className="fa fa-search" title="Search" />
        </div>
        <Button className="float-left" onClick={clearFilters}>
            Clear Filters
        </Button>
      </div>
      {!filterTerm ? <></> :
        <div className="search-term">
          <label>Displaying search result for:</label>
          <Badge>
            {filterTerm}
            <i  className="fa fa-times" title="Clear Search" onClick={clearSearchTerm} />
          </Badge>
        </div>
      }
    </>
  );
};

export default SearchLandCare;