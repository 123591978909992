import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, CardGroup, Card, Container } from 'react-bootstrap';
import Sidebar from '../Layout/Sidebar/Sidebar';
import '../App/App.scss';
import { landCareList, getLandCare, getLandCarerById, hideAutoList } from '../../Store/Reducers/LandCareSlice';


let Reports: React.FC<any> = (props) => {
  const LandCareAuto = useSelector(landCareList)
  const dispatch = useDispatch();

  // report urls from .env files - because report urls are different for each env
  const report1_url = process.env.REACT_APP_LANDCARE_REPORT_1_URL;
  const report2_url = process.env.REACT_APP_LANDCARE_REPORT_2_URL;
  const report3_url = process.env.REACT_APP_LANDCARE_REPORT_3_URL;

  const chooseResult = async (AutoLandCare: any) => {
    console.log('chosen item: ', AutoLandCare)
    const accessToken = await props.getAccessToken();
    dispatch(hideAutoList())
    const match = await LandCareAuto.AutoLandCare.filter(function (found: any) {
      return found.name === AutoLandCare.target.innerHTML;
    })
    const matchQ: any = match[0]
    const ID = matchQ._id
    console.log('ID: ', ID)
    await dispatch(getLandCarerById(accessToken, ID))
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Sidebar highlight='reports'>
            <h1>Reports</h1>
            <CardGroup>
                <Card >
                  <Card.Body className="p-0">
                    <Card.Text> 
                      <iframe title="Property Registrations by State" className="embed-responsive" width="640" height="480" src={report1_url}></iframe>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="p-0">
                    <Card.Text > 
                      <iframe title="Property Registrations by Size" className="embed-responsive" width="640" height="480" src={report2_url}></iframe>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </CardGroup>
            <Card >
              <Card.Body className="p-0">
                <Card.Text > 
                  <iframe title="Property Registrations by week" className="embed-responsive" width="640" height="480" src={report3_url}></iframe>
                </Card.Text>
              </Card.Body>
            </Card>
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
}

export default Reports;
