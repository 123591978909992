import React, { CSSProperties } from 'react';

import './ProgressBar.scss';

interface IProgressBar {
    title: string;
    percent: number;
    color?: string;
}

const ProgressBar : React.FC<IProgressBar> = (props: IProgressBar) => {
    const used = props.percent || 0;
    const unused = 100 - used;
    const color = props.color || 'black';

    const usedStyle: React.CSSProperties = {
        display: 'inline-block',
        height: '10px',
        width: used,
        backgroundColor: color
    };

    const unusedStyle: React.CSSProperties = {
        display: 'inline-block',
        height: '10px',
        width: unused,
        backgroundColor: 'grey'
    };

    return (
        <div className={`${window.innerWidth > 1000 ? 'display-inline' : ''}`}>
            <div>{props.title}</div>
            <div style={usedStyle}></div>
            <div style={unusedStyle}></div>
        </div>
    );
};

export default ProgressBar;