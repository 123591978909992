import { createSelector } from 'reselect';
import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../RootStore';
import { RootState } from "../RootStore";
import { getAllLandCaresFromApiAuto } from '../Api/LandCareApi';
import { ILandCarer } from '../../Models/LandCare/LandCare';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';

interface ILandCareAutoSuggestState {
    searchTerm: string;
    suggestions: any[];
    isSearching: boolean;
    error: any;
}

const initialState: ILandCareAutoSuggestState = {
    searchTerm: '',
    suggestions: [],
    isSearching: false,
    error: null
};

const landCareAutoSuggestSlice = createSlice({
    name: "LandCareAutoSuggest",
    initialState,
    reducers: {
        setSearchTerm(state: ILandCareAutoSuggestState, action: PayloadAction<string>) {
            state.searchTerm = action.payload;
        },
        startSearching(state: ILandCareAutoSuggestState) {
            state.isSearching = true;
        },
        endSearching(state: ILandCareAutoSuggestState) {
            state.isSearching = false;
        },
        getAutoSuggestionsSuccess(state: ILandCareAutoSuggestState, action: PayloadAction<any[]>) {
            state.suggestions = action.payload;
            state.error = null
        },
        getAutoSuggestionsFailed(state: ILandCareAutoSuggestState, action: PayloadAction<any[]>) {
          state.suggestions = [];
            state.error = action.payload;
        },
        clearAutoSuggestions(state: ILandCareAutoSuggestState) {
            state.suggestions = [];
        }
    }
});

export const { setSearchTerm, startSearching, endSearching, getAutoSuggestionsSuccess, getAutoSuggestionsFailed, clearAutoSuggestions } = landCareAutoSuggestSlice.actions;

export const LandCareAutoSuggestReducers = landCareAutoSuggestSlice.reducer;

export interface ILandCareAutoSuggestions {
  status: string;
  records: ILandCarer[];
}
export const getAutoSuggestions = (accessToken: string, searchTerm: any) : AppThunk => async dispatch => {
    const escapedValue = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    if (escapedValue === '') {
        return [];
    }
    
    const regex = new RegExp('^' + escapedValue, 'i');
    
    dispatch(startSearching());

    try {
      const result: any = await getAllLandCaresFromApiAuto(accessToken, searchTerm, 10);
      result.forEach((r: ILandCarer) => {
        if (!r.status) r.status = 'New';
      });
      let statusList: any[] = [];
      result.forEach((lc: ILandCarer) => {
        if (!statusList.find((status: string) => status === lc.status)) {
          statusList.push(lc.status)
        }
      });
      const suggestions: ILandCareAutoSuggestions[] = statusList.map(
        (status: string) => (
          { status, records: result.filter((lc: ILandCarer) => lc.status === status) }
        )
      );
      dispatch(getAutoSuggestionsSuccess(suggestions));
    }
    catch(err) {
      dispatch(getAutoSuggestionsFailed(err));
      showMessage(err.message, MessageModeEnum.ERROR);;
    }
    finally {
      dispatch(endSearching());
    }
      
}

export const selectAutoSuggest = (state: RootState) => state.landCareAutoSuggest;

export const selectAutoSuggestSearchTerm = createSelector(
  [selectAutoSuggest],
  (state: ILandCareAutoSuggestState) => state.searchTerm
);

export const selectAutoSuggestSuggestions = createSelector(
  [selectAutoSuggest],
  (state: ILandCareAutoSuggestState) => state.suggestions
);