import { INameValuePair } from "../Models/App/AppModels";

export const loaderStyle = {
    overlay: (base: any) => ({
        ...base,
        background: 'rgb(170,170,170, 0.7)',
        color: 'white',
        width: '100%',
          '& svg circle': {
            stroke: 'rgba(255, 0, 0, 0.2)'
          },
        zIndex: 1
    })
};

export const loaderStyle2 = {
  overlay: (base: any) => ({
      ...base,
      background: 'rgb(211,211,211, 0.7)',
      color: 'black',
      width: '100%',
        '& svg circle': {
          stroke: 'rgba(255, 0, 0, 0.2)'
        },
      zIndex: 1
  })
};

export const spinnerStyle: any = {
  position: 'absolute',
  width: '100%',
  height: '40px',
  textAlign: 'center',
  opacity: '50%',
};

export const waitingText = 'Please wait...';

export enum DetailRecordTypes {
  CONTACTS,
  NOTES,
  CHAT,
  POLYGONFILES
};

export const signalREndPoints = {
  notifyNewLandCarersChatMessage: 'notifyNewLandCarersChatMessage',
  notifyMessagesReadByUser: 'notifyMessagesReadByUser',
  notifyUserStatus: 'notifyUserStatus'
};

export const states: INameValuePair[] = [
  { name: 'Australian Capital Territory', value: 'ACT' },
  { name: 'New South Wales', value: 'NSW' },
  { name: 'Northern Territory', value: 'NT' },
  { name: 'Queensland', value: 'QLD' },
  { name: 'South Australia', value: 'SA' },
  { name: 'Tasmania', value: 'TAS' },
  { name: 'Victoria', value: 'VIC' },  
  { name: 'Western Australia', value: 'WA' },
]

export enum ModiaFileModules {
  LandCareNotes = 'landcarenotes',
  LandCarers = 'landcarers',
  ProjectSites = 'projectsites',
  LandCarersGeneral = 'landcarersgeneral',
  LandHoldings = 'landholdings',
  LandHoldingPolygons = 'landholdingpolygons'
}