import React, { createRef, useEffect, useState } from 'react';
import {Spinner, Form, Container, Row, Col, Button} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import PolygonPoints from '../_PolygonPoints';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import PlantingSiteMap from '../../ArcGIS/PlantingSiteMap';
import { IPlantingSite } from '../../../Models/LandCare/LandCare';
import { selectUserProfile, selectLandCareModuleConfigPages } from '../../../Store/Selectors/rootSelector';
import { selectLandCarerPlantingSites } from '../../../Store/Selectors/landCareSelectors';
import { landCareList, setLoadingToTrue, setCurrentPlantingSite, getLandCareData, createNewPlantingSiteAC, landCareStopLoading, landCareStartLoading, updatePlantingSiteField, setPlantingSiteField } from '../../../Store/Reducers/LandCareSlice';
import { loaderStyle, waitingText } from '../../../Helpers/constants';
import { MessageModeEnum, showMessage } from '../../../Helpers/Validator/validationHelper';
import FileUploadModal from '../../../Helpers/UI/FileUploadModal';
import PageTitle from '../../Layout/PageTitle/PageTitle';
import ProgressBar from '../../../Helpers/UI/ProgressBar/ProgressBar';
import { IModulePage, IModuleSection } from '../../../Store/Reducers/ModuleConfigSlice';
import { renderControls } from '../../../Helpers/UI/RenderControlsHelper2';
import { getUserProfile } from '../../../Store/Reducers/LocalStoreSlice';

import '../../App/App.scss';
import './PlantingSite.scss';
import { useIsAuthenticated } from '@azure/msal-react';

let PlantingSites: React.FC<any> = (props) => {
  const [showAddForm, setShowAddForm] = useState(false)
  const { currentLandCare, currentPlantingSite, currentPlantingSiteRef, isLoading } = useSelector(landCareList)
  const plantingSites = useSelector(selectLandCarerPlantingSites);
  const userProfile = useSelector(selectUserProfile);
  const page = useSelector(selectLandCareModuleConfigPages)?.find((page: IModulePage) => page.id === 'PlantingSite');
  const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();
  const history = useHistory();

  const state = useState({} as any);
  const isSaving: IPlantingSite = state[0];
  const setIsSaving = state[1];

  // FileUploadModal
  const [showFileAddModal, setFileAddShowModal] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm();

  let plantingSite = {...currentPlantingSite};

  useEffect(() => {
    if (!Object.keys(userProfile).length) {
      const refreshObj = async () =>  {
        const accessToken = await props.getAccessToken();
        if (accessToken && !Object.keys(userProfile).length) {
          await dispatch(getUserProfile(accessToken, history));
        }
      };
      refreshObj();
    }
  }, [isAuthenticated]);

  //So that we don't lose the LandCare object with a page refresh:
  useEffect(() => {
    const urlIDBlock = window.location.href.split('/')[5];

    dispatch(landCareStartLoading());

    const refreshObj = async () => {
      const accessToken = await props.getAccessToken();

      if (accessToken && userProfile.id) {
        await dispatch(getLandCareData(accessToken, userProfile.id, urlIDBlock));
        dispatch(landCareStopLoading());
      }
    };
    refreshObj();
  }, [props.getAccessToken, userProfile.id])

  const setCurrentParcelHandler = (siteChosen: any) => {
    dispatch(setCurrentPlantingSite(currentLandCare.plantingSites, siteChosen))
  };

  const submitAddForm = async (input: any) => {
    const name = input.name;
    if (plantingSites.map((site: IPlantingSite) => site.name).indexOf(name) >= 0) {
      showMessage("Planting site '" + name + "' already exists.");
    }
    else {
      const accessToken = await props.getAccessToken();

      dispatch(setLoadingToTrue(true));
      await dispatch(createNewPlantingSiteAC(accessToken, currentLandCare._id, name, currentLandCare));
      dispatch(setLoadingToTrue(false));
      setShowAddForm(false);
      reset();
      showMessage('Planting site added successfully.', MessageModeEnum.SUCCESS)
    }
  };

  const showMapPlantingSite = () => {
    if(plantingSite) {
      let path =`/projects/map/${currentLandCare.id}/${plantingSite.id}`;
      history.push(path);  
    }
  }

 
  const constructPayload = (event: any, status: boolean) => {
    return { ...isSaving, [event.target.id]: status}
  };
  const blurFn = async (event: any) => {
    const accessToken = await props.getAccessToken();
    const fieldName = event.target.id;
    const newVal = event.target.value;
    const oldVal = currentPlantingSiteRef ? currentPlantingSiteRef[fieldName] : '';
    if (newVal != oldVal) {
      setIsSaving(constructPayload(event, true));
      const stopSpinnerFn = () => setIsSaving(constructPayload(event, false));
      dispatch(updatePlantingSiteField(accessToken, plantingSite.id, fieldName, newVal, stopSpinnerFn));
    }
  };

  const changeFn = (fieldName: string, fieldValue: any) => dispatch(setPlantingSiteField({ [fieldName]: fieldValue } as IPlantingSite));

  const isEnabled: boolean = plantingSite && plantingSite.id ? plantingSite.id : false;

  const ctrlBootstrapStyling = 'col-sm-12 col-md-6';
  
  const cancelAddForm = () => {
    setShowAddForm(false);
    reset({});
  };

  const landholdingName = currentLandCare.landHolding?.length ? currentLandCare.landHolding[0].name : '';

  // this is used for the FileAdd modal
  const handleFileCloseModal = () => {
    setFileAddShowModal(false)
  };  

  interface IBarProgress {
    name: string;
    value: number;
    color?: string;
  }

  const inspArray = [
    { name: '', value: 0, color: '' },
    { name: 'In Progress', value: 75 },
    { name: 'Suitable', value: 100, color: 'green' },
    { name: 'Not Suitable', value: 100, color: 'red' }
  ];

  const deskFeasArray = [
    { name: '', value: 0, color: '' },
    { name: 'In Progress', value: 25 },
    { name: 'Suitable', value: 25, color: 'green' },
    { name: 'Not Suitable', value: 100, color: 'red' }
  ];

  const credMarketArray = [
    { name: '', value: 0, color: '' },
    { name: 'Contact', value: 25 },
    { name: 'Discussion', value: 25, color: 'green' },
    { name: 'Proposal', value: 50 },
    { name: 'Negotiation', value: 75, color: 'green' },
    { name: 'Agreement', value: 100, color: 'green' },
    { name: 'Closed', value: 0 }
  ];

  const implArray = [
    { name: '', value: 0, color: '' },
    { name: '10%', value: 10 },
    { name: '25%', value: 25 },
    { name: '35%', value: 35 },
    { name: '50%', value: 50 },
    { name: '60%', value: 60 },
    { name: '70%', value: 70 },
    { name: '75%', value: 75 },
    { name: '80%', value: 80 },
    { name: '90%', value: 90 },
    { name: '100%', value: 100, color: 'green' },
    { name: '0%', value: 100, color: 'red' }
  ];

  const getBarProgress = (fieldValue: string, array: IBarProgress[]) => {
    return array.find((item: IBarProgress) => item.name === fieldValue) as IBarProgress;
  };

  let deskFeasBar: IBarProgress;

  if (plantingSite?.inspection)
    deskFeasBar = getBarProgress(plantingSite?.inspection, inspArray);
  else
    deskFeasBar = getBarProgress(plantingSite?.desktopFeasibility, deskFeasArray);

  const credMarketBar = getBarProgress(currentLandCare.creditMarket, credMarketArray);
  const implBar = getBarProgress(currentLandCare.probabilityToClose, implArray);
  
  const formHeaderTop = page?.sections.find((section: IModuleSection) => section.id === 'formHeaderTop');
  const formHeaderSection = page?.sections.find((section: IModuleSection) => section.id === 'formHeader');
  const section1 = page?.sections.find((section: IModuleSection) => section.id === 'section1');
  const section2 = page?.sections.find((section: IModuleSection) => section.id === 'section2');
  const section3 = page?.sections.find((section: IModuleSection) => section.id === 'section3');
  const section4 = page?.sections.find((section: IModuleSection) => section.id === 'section4');

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
        <Sidebar highlight={'planting_sites'} currentPage={'planting_sites'} id={currentLandCare.id}>

          <FileUploadModal  
            getAccessToken={props.getAccessToken}
            showModal={showFileAddModal} 
            closeModal={handleFileCloseModal}
            fileUploadComponent={"projectsites"}
            />

          <Row>
            <Col>
              <PageTitle title={'Organisation: ' + (currentLandCare?.landHoldingUserOrg?.userOrgName ? currentLandCare.landHoldingUserOrg.userOrgName : '')} />
            </Col>
          </Row>

          { page && renderControls(formHeaderSection, currentLandCare, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving, isEnabled) }

          <LoadingOverlay className="row" active={isLoading} spinner text={waitingText} styles={loaderStyle}>
            <Col>
              <Row>
                <Col xs={12}>
                  { !formHeaderTop ? <></> :
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${window.innerWidth < 1000 ? 'btn-styles media-prog' : 'display-prog'}`}>
                      <>
                      <ProgressBar title="Feasibility" percent={deskFeasBar?.value} color={deskFeasBar?.color} />
                      <ProgressBar title="Credit Market" percent={credMarketBar?.value} color={credMarketBar?.color} />                    
                      <ProgressBar title="Implementation" percent={implBar?.value} color={implBar?.color} />
                      </>
                    </Col>
                  }
                  <Row>
                    <Button className="edit-planting-map-btn" variant="success" disabled={plantingSite.id ? false : true } onClick={() => showMapPlantingSite()}>Edit Planting Site Map</Button>
                    { showAddForm ? <></> :
                      <Button className="add-planting-btn" variant="success" onClick={() => setShowAddForm(true)}>Add Planting Site</Button>
                    }
                    {showAddForm === true ? (
                      <form className="add-form" onSubmit={handleSubmit(form => submitAddForm(form))}>
                        <Form.Label>New Planting Site Name</Form.Label>
                        <Form.Control type="text" name="name" ref={register} />
                        <Button className="add-form-margin btn btn-primary" type="submit">Submit</Button>
                        <Button className="add-form-margin btn-secondary" onClick={cancelAddForm}>Cancel</Button>
                      </form>
                    ) : ''}
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      { plantingSite.id ? <></> : <p>Please add a planting site</p> }
                      <Form.Label className="bold-labels">Property: {landholdingName} - Planting Site: {plantingSite?.id ? plantingSite.name : 'N/A'}</Form.Label>
                      {
                        currentLandCare.plantingSites !== undefined ? 
                        (
                          <PlantingSiteMap 
                          key={plantingSite && plantingSite.id ? plantingSite.id : "tempKey"}
                          currentLandCare={currentLandCare}
                          currentProjectSiteId={plantingSite && plantingSite.id ? plantingSite.id : "" }
                          currentProjectSite={plantingSite}
                          getAccessToken={props.getAccessToken} />
                        ) : 
                        (
                          <><br/><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Loading map...</p></>
                        )
                      }
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        {isLoading && <><Spinner animation="border" variant="secondary" size="sm" /><br/><p>Updating database...</p></>}
                        <Form.Group controlId="selectParcel" className={ctrlBootstrapStyling}>
                          <Form.Label className="bold-labels">Selecting Planting Site</Form.Label>
                          <Form.Control as="select" name="selectParcel" onChange={(e: any) => setCurrentParcelHandler(e.target.value)}>
                            {currentLandCare !== undefined && currentLandCare.plantingSites !== null && currentLandCare.plantingSites !== undefined && currentLandCare.plantingSites.map((plantingSiteSelection: any)=> {
                              return (
                                <option 
                                  disabled={isLoading ? true : false} 
                                  value={plantingSiteSelection.id}
                                  selected={plantingSiteSelection.id === plantingSite?.id}
                                >{plantingSiteSelection.name ? plantingSiteSelection.name : 'Planting Site Not Named' }</option>
                                )
                              })
                            }
                          </Form.Control>
                        </Form.Group>
                        
                        { page && renderControls(section1, plantingSite, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving, isEnabled) }
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <br/><br/>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Form.Group controlId="postCode">
                        <Form.Label className="bold-labels">Post Code</Form.Label>
                        <div>{currentLandCare.landHolding ? currentLandCare.landHolding[0].postalAddress.postCode : 'No post code' }</div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Form.Group controlId="state">
                        <Form.Label className="bold-labels">State</Form.Label><br/>
                        <div>{currentLandCare ? currentLandCare.landHoldingState ? currentLandCare.landHoldingState : 'No state given' : 'No state given'}</div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Form.Group controlId="propertyArea">
                        <Form.Label className="bold-labels">Property Area</Form.Label>
                        <div>{currentLandCare ? currentLandCare.plantingArea ? currentLandCare.plantingArea : 'No area size given' : 'No area size given'}</div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                    { page && renderControls(section2, plantingSite, blurFn, changeFn, ctrlBootstrapStyling, isLoading, isSaving, isEnabled) }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Form.Label className="bold-labels">Property Coordinates</Form.Label>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                      <br/>
                      <PolygonPoints listGeometryItems={plantingSite && plantingSite?.polygon ? plantingSite?.polygon : []} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>

              { page && renderControls(section3, plantingSite, blurFn, changeFn, 'col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12', isLoading, isSaving, isEnabled) }

              <Col xs={12} sm={12} md={4} lg={3} xl={3}>
                <Form.Label className="bold-labels">Planting Site File</Form.Label>
                <div>
                  <Button style={{margin: '5px'}} className="btn-primary" onClick={() => setFileAddShowModal(true)} disabled={!isEnabled}>Add File</Button>
                </div>
              </Col>
              
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                { page && renderControls(section4, plantingSite, blurFn, changeFn, '', isLoading, isSaving, isEnabled) }
              </Col>
            </Row>
            </Col>
          </LoadingOverlay>
          <br/><br/><br/><br/><br/><br/>
        </Sidebar>
      </Col>
      </Row>
    </Container>
  )
}

export default PlantingSites;