import { LogLevel } from '@azure/msal-browser';

import apiConfig from './apiConfig';
import b2cPolicies from './b2cpolicies';

/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * 
 * */

/**
 * Prod redirectUri: "https://gerxdev.z8.web.core.windows.net/"
 * */
const msalConfig = {
  auth: {
    clientId: "f32e978b-3d49-46b9-9e82-7d1cefa3c0c6",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: process.env.REACT_APP_GERX_REDIRECT_URL,
    knownAuthorities: [b2cPolicies.authorityDomain],
    // postLogoutRedirectUri: "https://gerxdev.z8.web.core.windows.net",
    // navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {	
        if (containsPii) {		
            return;		
        }		
        switch (level) {		
            case LogLevel.Error:		
                console.error(message);		
                return;		
            case LogLevel.Info:		
                console.info(message);		
                return;		
            case LogLevel.Verbose:		
                console.debug(message);		
                return;		
            case LogLevel.Warning:		
                console.warn(message);		
                return;		
        }
      }
    }
  }
};

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const loginRequest = {
  scopes: ["openid", "profile"],
};

// Add here scopes for access token to be used at the API endpoints.
const tokenRequest = {
  scopes: apiConfig.b2cScopes,  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export { msalConfig, loginRequest, tokenRequest }