import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from 'react-autosuggest';

import { setFilterTerm, setIsCreateNew, getAutoSuggestions, clearAutoSuggestions, setSearchTerm, IUserOrgAutoSuggestions } from '../../../Store/Reducers/UserOrgAutoSuggestSlice';
import { ILandCareUserOrg } from '../../../Models/LandCare/LandCare';
import { selectAutoSuggestSearchTerm, selectAutoSuggestSuggestions } from '../../../Store/Selectors/userOrgAutoSuggestSelector';

import './SearchUserOrg.scss';


// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

let timeout: any;

const SearchUserOrg: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(selectAutoSuggestSearchTerm);
  const suggestions = useSelector(selectAutoSuggestSuggestions);

  const getSuggestionValue = (suggestion: any) => {
    props.onSelection(suggestion);
    return suggestion.userOrgName;
  };
  
  const renderSuggestion = (suggestion: any) => {
    return (
      <div>
        <span style={{fontWeight: 'bold'}}>{suggestion.userOrgName}</span>
        <div>ABN: {suggestion.userOrg[0].abn} State: {suggestion.userOrg[0].mailingAddress?.state}</div>
        <div>Address: {suggestion.userOrg[0].mailingAddress?.fullAddress}</div>
      </div>
    );
  }
  
  const renderSectionTitle = (section: IUserOrgAutoSuggestions) => {
    return (
      <strong>{section.userOrgName}</strong>
    );
  }
  
  const getSectionSuggestions = (section: IUserOrgAutoSuggestions) => {
    return section.userOrg;
  }

  const onChange = (event: any, { newValue, method }: any) => {
    dispatch(setSearchTerm(newValue));
  };
  
  const onGetAutoSuggestions = ({ value }: any) => {
    clearTimeout(timeout);

    timeout = setTimeout(async () => {
      if (value && value.length >= 3) {
        const accessToken = await props.getAccessToken();
        dispatch(getAutoSuggestions(accessToken, value));
      }
    }, 700);
  };

  const onClearAutoSuggestions = () => {
    dispatch(clearAutoSuggestions());
  };

  const searchByTerm = () => {
    clearTimeout(timeout);
    setFilterTerm(searchTerm);
    props.onSearchByTerm(searchTerm);
  }

  const inputProps = {
    placeholder: "Lookup by org name",
    value: searchTerm,
    onChange
  };

  const condition = searchTerm && searchTerm.length >= 3;

  const buttonStyle = {
    color: condition ? 'black' : 'grey',
    cursor: condition ? 'pointer' : 'auto'
  };

  const input: any = document.getElementsByClassName('react-autosuggest__input')[0];
  if (input) input.onkeypress = function (event: any) {
    if (event.keyCode === 13) {
      searchByTerm();
    }
  };

  const clearSearchTerm = () => {
    setFilterTerm('');
    props.onSearchByTerm('');
    dispatch(setSearchTerm(''));
  };

  const onCreateNewSelected = () => {
    //props.history.push(`/createuserorg`);
    setIsCreateNew(true);
  }

  const onCreateNewUserOrganisation = () => {
    console.log("Create new User Organisation");
  };

  const onCancelCreateNew = () => {
    //props.history.push(`/createuserorg`);
    setIsCreateNew(false);
  };

  return (
    <>
      <div data-test="App" className="search-bar">
        <Autosuggest  multiSection={false}
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={onGetAutoSuggestions}
                      onSuggestionsClearRequested={onClearAutoSuggestions}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      renderSectionTitle={renderSectionTitle}
                      inputProps={inputProps}
                      getSectionSuggestions={getSectionSuggestions}
        />          
      </div>
    </>
  );
};

export default SearchUserOrg;