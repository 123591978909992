import React from 'react';
import { Form, Row, Col, FormCheck } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IStepProps, IValidation, renderCheckbox2Control, renderCheckboxControl, renderDropdownControl, renderTextAreaControl, renderTextControl } from '../../shared';
import { selectCurrentLandholding } from '../../../../../Store/Selectors/landholdingSelector';
import { LandholdingFieldNames } from '../../../../../Models/LandHoldings/LandHoldings';
import { updateLandHoldingFieldValue } from '../../../../../Store/Reducers/LandHoldingsSlice';

import './step-2.styles.scss';

const Step2 : React.FC<IStepProps> = (props: any) => {
    const { register, getValues, setValue, isVisible, isNextClicked } = props;

    const dispatch = useDispatch();
    
    const landholding = useSelector(selectCurrentLandholding);

    const onCheckboxChange = (event: any) => {
        console.log('event.target.name', event.target.name)
        dispatch(updateLandHoldingFieldValue({ [event.target.name]: event.target.checked }));
    };

    const onChange = (event: any) => {
        dispatch(updateLandHoldingFieldValue({ [event.target.name]: event.target.value }));
    };

    const textControl = (name: string, label: string, placeholder = '', validation?: IValidation, disabled = false, autoComplete = true) => {
        return renderTextControl(register, isNextClicked, getValues(), name, label, placeholder, validation, disabled, autoComplete);
    };

    const textAreaControl = (name: string, label: string, placeholder = '', validation?: IValidation, disabled = false, autoComplete = true) => {
        return renderTextAreaControl(register, isNextClicked, getValues(), name, label, placeholder, validation, onChange, disabled, autoComplete);
    };

    const checkboxControl = (name: string, label: string, description: string, disabled = false) => {
        return renderCheckboxControl(register, isNextClicked, getValues(), name, label, description, onCheckboxChange, disabled);
    };

    const checkbox2Control = (name: string, label: string, disabled = false) => {
        return renderCheckbox2Control(register, isNextClicked, getValues(), name, label, onCheckboxChange, disabled);
    };

    return (
        <div style={{display: isVisible ? '' : 'none'}}>
            <Row>
                <Col>
                    <div>
                    <h3>Create or Edit Property</h3><br/>
                    <div>
                        <Form.Group controlId="name">
                        <Form.Label>Property Name:</Form.Label>
                        <h3>{landholding.name}</h3>
                        </Form.Group>
                    </div>
                    </div>
                </Col>
                </Row>
            <Row>
                <Col>
                    <br/><br/>
                    <div>
                        <h6>Current Use(s)</h6>
                        <p>Please let us know about the current land usage of your property </p>
                        {textAreaControl(LandholdingFieldNames.currentUseDescription, "Description", "Notes about land usage for the project workers", undefined, false, false)}
                    </div>
                    <br/><br/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                    <h6>Style(s) of Projects</h6>
                    <p>What type of projects would you be interested in doing on your property? Please select your preferences so that we can advise you.</p>
                    <div>
                        {checkboxControl(LandholdingFieldNames.isConservationBiodiversity, 'Conservation / Biodiversity', 'protecting existing native vegetation on your property')}

                        {checkboxControl(LandholdingFieldNames.isConversionOfLowProductiveAndDegradedLand, 'Conversion of low productive and degraded land to conservation', 'activities include planting and or management of native regrowth')}

                        {checkboxControl(LandholdingFieldNames.isRevegetationBlockPlanting, 'Revegetation Block Planting', 'planting an area of any shape')}

                        {checkboxControl(LandholdingFieldNames.isRevegetationLinearBeltPlanting, 'Revegetation Linear / Belt Planting', 'planting windbreak-line strips of vegetation across a paddock. Can include shelter and palatable fodder.')}

                        {checkboxControl(LandholdingFieldNames.isSaltLandsPlanting, 'Salt Lands Planting', 'planting salt tolerant species for shade, shelter and fodder in agricultural systems')}

                        {checkboxControl(LandholdingFieldNames.isPaddockTrees, 'Paddock Trees', 'planting scattered shelter trees throughout grazing paddocks without interrupting stocking or rotations')}

                        {checkboxControl(LandholdingFieldNames.isCarbonOffset, 'Carbon Offset', 'register the planting site to create carbon credits')}
                        
                        {checkboxControl(LandholdingFieldNames.isWaterQualityOffset, 'Water Quality Offset', 'register the project site for Reef Credits (only applicable in Great Barrier Reef Catchments)')}

                        {checkboxControl(LandholdingFieldNames.isRiverRiparianRestoration, 'River / Riparian Restoration', 'protection and/or management of river and riparian areas activities. May include fencing, planting, alternative stock water and erosion control structures')}

                        {checkboxControl(LandholdingFieldNames.isWetlandRestoration, 'Wetland Restoration / Management', 'establishing or managing wetlands to treat water runoff and or improve biodiversity')}
                        
                        {checkboxControl(LandholdingFieldNames.isErosionControl, 'Erosion Control', 'inclusive of remediation and or land practice change activities. May include planting, earthworks, land practice change (e.g., destocking), fencing, erosion control structures')}
                        
                        {/* {checkboxControl(LandholdingFieldNames.landholdingStyleOther, 'Other Style', 'if the project style is not listed check here and provide details below')} */}

                        <div>
                            <Form.Group controlId="isOther">
                                <FormCheck>
                                    <FormCheck.Input type="checkbox" name={LandholdingFieldNames.landholdingStyleOther} ref={register} onChange={onCheckboxChange} /> 
                                    <FormCheck.Label>
                                        <strong>Other Style</strong> - if the project style is not listed check here and provide details below
                                    </FormCheck.Label>
                                </FormCheck>
                                <Form.Control type="text" name={LandholdingFieldNames.projectStyleDescription} placeholder="Project style if it isn't listed" ref={register} disabled={!landholding.projectStyles?.isOther} />
                            </Form.Group>
                        </div>
                    </div>
                    
                    <br/>

                    <div>
                        <br/>
                        <Row>
                            <Col>
                                What percentage of your property is available for restoration?
                                <div className="sliderContainer">
                                    <input className="slider" type="range" defaultValue={0} name={LandholdingFieldNames.availableSizePercentage} ref={register} onChange={onChange} />
                                    <div className="slider-tool">{getValues()[LandholdingFieldNames.availableSizePercentage]}%</div>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                    </div><br/><br/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div>
                        <h6>Soil Description</h6>
                        <div>
                            {checkbox2Control(LandholdingFieldNames.isClayAndSilts, 'Clay and Silts')}

                            {checkbox2Control(LandholdingFieldNames.isSand, 'Sand')}

                            {checkbox2Control(LandholdingFieldNames.isGravel, 'Gravel')}
                            
                            {checkbox2Control(LandholdingFieldNames.isLoam, 'Loam')}
                            
                            {checkbox2Control(LandholdingFieldNames.isRocky, 'Rocky')}

                            {checkbox2Control(LandholdingFieldNames.isOutCroppingRock, 'Outcropping Rock')}
                            
                            <div>
                                <Form.Group controlId="isSDOther">
                                    <FormCheck>
                                        <FormCheck.Input type="checkbox" name={LandholdingFieldNames.soilAndDescriptionOther} ref={register} onChange={onCheckboxChange} />
                                        <FormCheck.Label>Other Soil</FormCheck.Label>
                                    </FormCheck>
                                    <Form.Control type="text" name={LandholdingFieldNames.soilTypeDescription} placeholder="Type of ground if it isn't listed" ref={register} disabled={!landholding.soilTypes?.isOther} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                </Col>
            </Row>
        </div>
    );
};

export default Step2;