import { connect } from 'react-redux';
import React, {Component} from 'react';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from "history";

import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/free-brands-svg-icons';
import '@fortawesome/free-regular-svg-icons';
import '@fortawesome/react-fontawesome';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import IAuthProps from '../../Containers/Auth/IAuthProps';
import Menubar from '../Layout/Menubar/Menubar';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Home from '../Pages/Home';
import Reports from '../Pages/Reports';
import About from '../Pages/About';
import Notes from '../Pages/Notes/Notes';
import Contact from '../Pages/Contact/Contact';
import News from '../Pages/News';
import Logout from '../Pages/Logout';
import Version from '../Pages/Version';
import NotFound from '../Pages/NotFound';
import NewReports from '../Pages/NewReports/NewReports';
import Overview from '../Pages/Overview';
import PlantingSites from '../Pages/PlantingSite/PlantingSites';
import Users from '../Users/Users';
import TestRBS from '../Pages/TestRBS';
import ErrorMessage from '../Auth/ErrorMessage';
import UserProfile from '../Pages/UserProfile';
import GetDate from '../LandCareApi/GetDate';
import LandCareProjectsMap from '../ArcGIS/LandCareProjectsMap';
import { AuthStatusEnum, getUserProfile, setAuthStatus } from '../../Store/Reducers/LocalStoreSlice';
import Chat from '../Pages/Chat/Chat';
import { getSignalRNegotiationInfo } from '../../Store/Reducers/SignalRSlice';
import { RootState } from '../../Store/RootStore';
import { selectAuthStatus, selectUserProfile } from '../../Store/Selectors/rootSelector';
import { selectSignalRAccessToken, selectSignalRUrl } from '../../Store/Selectors/signalRSelectors';
import GetSignalRConnection from '../../SignalR/setupFunctions';
import Files from '../Pages/Files/Files';
import { getModuleConfig } from '../../Store/Reducers/ModuleConfigSlice';
import { IUserRole } from '../../Models/Auth/IUser';
import Unauthorised from '../Pages/Unauthorised';
import LandCarePropertyMap from '../ArcGIS/LandCarePropertyMap';
import { sendLandCareUserStatus } from '../../Store/Reducers/LandCareSlice';
import AddLandholding from '../LandHoldings/Manage/AddLandholding/AddLandholding';
import EditLandHolding from '../LandHoldings/Manage/EditLandholding/EditLandholding';
import SelectUserOrg from '../UserOrganisation/SelectUserOrganisation';
import UploadPolygonFile from '../ArcGIS/UploadPolygonFile/UploadPolygonFile';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const tagManagerArgs = {
  gtmId: 'GTM-P6XZX5W'
}

TagManager.initialize(tagManagerArgs);

enum AuthStateEnum {
  Undefined,
  Authorised,
  Unauthorised
}

class App extends Component<IAuthProps, any> {
  pingUserStatusTimeoutInterval: any;

  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.getAccessToken) {
      const accessToken = await this.props.getAccessToken();
      
      if (accessToken) {
        const history = createBrowserHistory();
        await this.props.getUserProfile(accessToken, history);
        
        const refreshRate: number = parseInt(process.env.REACT_APP_CHAT_USER_STATUS_PING_INTERVAL_MS || '60000');
        this.pingUserStatusTimeoutInterval =
          setInterval(
              async () => {
                  const accessToken = await this.props.getAccessToken();
                  await this.props.sendLandCareUserStatus(accessToken);
              }
          , refreshRate);
      }
      else {
        const pos = window.location.href.search('signout');
        if (pos === -1) {
          this.props.login();
        }
      }
    }
  }

  async componentDidUpdate() {
    const accessToken = await this.props.getAccessToken();

    this.state = { accessToken };
    
    if (accessToken) {
      //this.props.setAuthStatus(AuthStatusEnum.Authenticated);

      if(this.props?.userProfile && !Object.keys(this.props?.userProfile).length) {
        //if (this.props.getUserDetailsViaGraphApi) await this.props.getUserDetailsViaGraphApi(accessToken);
        if (this.props.getUserProfile) await this.props.getUserProfile(accessToken); 
      }
      
      this.props.getSignalRNegotiationInfo && this.props.getSignalRNegotiationInfo(accessToken);

      await this.getModuleInfo(accessToken);
    }

    if (this.props?.signalRAccessToken && this.props?.signalRUrl && this.props.createSignalRConnection) {
      this.props.createSignalRConnection(this.props.signalRAccessToken, this.props?.signalRUrl);
    }
  }

  componentWillUnmount() {
    clearInterval(this.pingUserStatusTimeoutInterval);
  }

  async getModuleInfo(accessToken: string) {
    const orgId: string = process.env.REACT_APP_GA_ORG_ID || '';
    
    if (this.props.getModuleConfig) {
      await this.props.getModuleConfig(accessToken, orgId);
    }
  }

  signout(origin: string) {
    localStorage.clear();
    if (origin) {
      const originText = origin ? ('?origin=' + origin) : '';
      window.location.href = (process.env.REACT_APP_GERX_LOGOUT_REDIRECT_NEXT_APP_URL + '/signout' + originText) || '/';
    }
    else {
      window.location.href = (process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signout') || '/';
    }
  }

  render() {
    const authRoles = process.env.REACT_APP_LANDCARE_ROLES;
    const roles = authRoles?.split(',') || [];

    let authState = AuthStateEnum.Undefined;
    if (Object.keys(this.props.userProfile || {}).length) {
      const isAuthorised = this.props.userProfile.roles?.some((role: IUserRole) => roles.includes(role.roleName));
      const history = createBrowserHistory();
      if (!isAuthorised) {
        authState = AuthStateEnum.Unauthorised;
        history.push('/unauthorised');
      }
      else {
        authState = AuthStateEnum.Authorised;
      }
    }

    let error = null;
    if (this.props.error) {
      error = <ErrorMessage
        message={this.props.error.message}
        debug={this.props.error.debug} />;
    }

    if (authState === AuthStateEnum.Unauthorised) {
      return (
        <Router>
          <Header />
          <div className="App">
            <Menubar
              isAuthorised={false}
              authButtonMethod={() => this.signout('')}
              user={this.props.user}
            />
          </div>
          <Unauthorised />
          <Footer />
        </Router>
      )
    }
    else
      return (
        <>
          <Router>
            <Route path="/signout" exact render={(props) => {
              const origin = props.location.search.split('?origin=')[1] || '';

              setTimeout(() =>{
                this.signout(origin);
              }, 500);
              
              return <>Please wait while we sign you out from GERX</>
            }} />
          </Router>
          {
            window.location.href.search("/signout") > -1 ? <></> :
            <Router>
              <ToastContainer />
              <Header/>
              <div className="App">
                <Menubar
                  isAuthenticated={this.props.isAuthenticated}
                  isAuthorised={true}
                  authButtonMethod={() => this.signout('')}
                  user={this.props.user}
                />
                
                <div key="head">
                  {error}
                  <Switch>
                    {/* <Route path="/signout" exact render={(props) => {
                      return <div className="my-3 text-center">Please wait while signing out from Landtrack application</div>
                    }} /> */}

                    <Route path="/" exact render={(props) => (
                        <Home {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        getTokens={this.props.getTokens}
                        getAccessToken={this.props.getAccessToken}
                        authButtonMethod={this.props.login} />
                      )
                    } />

                    <Route path="/reports" render={(props) => (
                        <Reports {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        getTokens={this.props.getTokens}
                        authButtonMethod={this.props.login} />
                      )
                    } />

                    <Route path="/new-reports" render={(props) => (
                        <NewReports {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        getTokens={this.props.getTokens}
                        authButtonMethod={this.props.login} />
                      )
                    } />

                    <Route path="/about" component={About} />
                    <Route path="/news" component={News} />
                    <Route path="/version" component={Version} />
                    <Route path="/logout" component={Logout} />


                    <Route path="/landholdings" exact render={(props: any) => (
                        <>
                          <SelectUserOrg {...props} 
                            getAccessToken={this.props.getAccessToken}
                            getTokens={this.props.getTokens}
                          />
                          {/* <ListLandHoldings {...props}
                          isAuthenticated={this.props.isAuthenticated}
                          user={this.props.user}
                          authButtonMethod={this.props.login} 
                          getAccessToken={this.props.getAccessToken}
                          getTokens={this.props.getTokens}
                          /> */}
                        </>
                      )
                    } />

                    <Route path="/add-landholding/:organisationId/" exact render={(props: any) => (
                        <AddLandholding {...props} getAccessToken={this.props.getAccessToken} />
                      )
                    } />
                    
                    <Route path="/edit-landholding/:landholdingId/:organisationId" exact render={(props: any) => (
                        <EditLandHolding {...props} getAccessToken={this.props.getAccessToken} />
                      )
                    } />

                    <Route path="/landholding/editlandholding/:lhid/:organisationId" exact render={(props: any) => (
                        <EditLandHolding {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        authButtonMethod={this.props.login} 
                        getAccessToken={this.props.getAccessToken}
                        getTokens={this.props.getTokens}
                        />
                      )
                    } />
                    
                    <Route path="/userprofile" render={(props) => (
                        <UserProfile {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        authButtonMethod={this.props.login} />
                      )
                    } />

                    <Route path="/callapi" render={(props) => (
                        <GetDate {...props}
                        isAuthenticated={this.props.isAuthenticated}
                        user={this.props.user}
                        authButtonMethod={this.props.login} 
                        getAccessToken={this.props.getAccessToken}
                        getTokens={this.props.getTokens}/>
                      )
                    } />

                    <Route path="/test" component={TestRBS} />

                    <Route exact path="/projects/:projectId" render={(props) => (
                      <Overview {...props}
                      isAuthenticated={this.props.isAuthenticated}
                      authStatus={this.props.authStatus}
                      user={this.props.user}
                      authButtonMethod={this.props.login} 
                      getAccessToken={this.props.getAccessToken}
                      getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route exact path="/projects/planting/:projectId" render={(props) => (
                      <PlantingSites {...props}
                      isAuthenticated={this.props.isAuthenticated}
                      user={this.props.user}
                      authButtonMethod={this.props.login} 
                      getAccessToken={this.props.getAccessToken}
                      getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route exact path="/projects/notescomments/:projectId" render={(props) => (
                      <Notes {...props}
                      isAuthenticated={this.props.isAuthenticated}
                      user={this.props.user}
                      authButtonMethod={this.props.login} 
                      getAccessToken={this.props.getAccessToken}
                      getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route exact path="/projects/files/:projectId" render={(props) => (
                      <Files {...props}
                      isAuthenticated={this.props.isAuthenticated}
                      user={this.props.user}
                      authButtonMethod={this.props.login} 
                      getAccessToken={this.props.getAccessToken}
                      getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route exact path="/projects/contact/:projectId" render={(props) => (
                      <Contact {...props}
                      isAuthenticated={this.props.isAuthenticated}
                      user={this.props.user}
                      authButtonMethod={this.props.login} 
                      getAccessToken={this.props.getAccessToken}
                      getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route exact path="/projects/map/:landCareId" render={(props) => (
                      <LandCarePropertyMap {...props} 
                      getAccessToken={this.props.getAccessToken}/>
                      )
                    }/>

                    <Route exact path="/projects/map/:landCareId/:projectSiteId" render={(props) => (
                      <LandCareProjectsMap {...props} 
                      getAccessToken={this.props.getAccessToken}/>
                      )
                    }/>

                    <Route exact path="/projects/upload-polygon-file/:landCareId" render={(props) => (
                      <UploadPolygonFile {...props} 
                      getAccessToken={this.props.getAccessToken}/>
                      )
                    }/>

                    <Route exact path="/projects/chat/:projectId" render={(props) => (
                      <Chat {...props}
                            isAuthenticated={this.props.isAuthenticated}
                            user={this.props.user}
                            authButtonMethod={this.props.login} 
                            getAccessToken={this.props.getAccessToken}
                            getTokens={this.props.getTokens}/>
                      )
                    }/>

                    <Route path="/unauthorised" exact render={(props: any) => (
                        <Unauthorised />
                      )
                    } />

                    <Route path="/users/:userId" component={Users} />
                    
                    <Route default component={NotFound} />
                  </Switch>

                </div>
              </div>
              <Footer/>
            </Router>
          }
        </>
      );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    signalRAccessToken: selectSignalRAccessToken(state),
    signalRUrl: selectSignalRUrl(state),
    authStatus: selectAuthStatus(state),
    userProfile: selectUserProfile(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAuthStatus: (payload: AuthStatusEnum) => dispatch(setAuthStatus(payload)),
    getSignalRNegotiationInfo: (payload: string) => dispatch(getSignalRNegotiationInfo(payload)),
    createSignalRConnection: (signalRAccessToken: string, signalRUrl: string) => GetSignalRConnection.createConnection(signalRAccessToken, signalRUrl),
    //getSignalRConnection: (signalRAccessToken: string, signalRUrl: string) => dispatch(getSignalRConnection(signalRAccessToken, signalRUrl))
    getSignalRConnection: () => GetSignalRConnection.getConnection(),
    getModuleConfig: (accessToken: string, orgId: string) => dispatch(getModuleConfig(accessToken, orgId)),
    getUserProfile: (accessToken: string, history: any) => dispatch(getUserProfile(accessToken, history)),
    sendLandCareUserStatus: (accessToken: string) => dispatch(sendLandCareUserStatus(accessToken))
  };
};

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
