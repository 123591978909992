import { createSelector } from 'reselect';

import { RootState } from '../RootStore';
import { ISignalRState } from '../Reducers/SignalRSlice';

export const signalRSelector = (state: RootState) => state.SignalRInfo;

export const selectSignalRAccessToken = createSelector(
    [signalRSelector],
    (state: ISignalRState) => state.accessToken
);

export const selectSignalRUrl = createSelector(
    [signalRSelector],
    (state: ISignalRState) => state.url
);