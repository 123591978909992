import axios, { AxiosRequestConfig } from 'axios';
import { ModiaFileModules } from '../Helpers/constants';
import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { AddressType, ILandholding } from "../Models/LandHoldings/LandHoldings";

class LandholdingsApiService {

  baseUrl: string;
  landcareUrl: string;
  //landholdings : Array<ILandholding>;

  constructor () {
    //Setup defaults if any...
    this.baseUrl = process.env.REACT_APP_LANDPORTAL_HOLDINGS_BASEURI || '';
    this.landcareUrl = process.env.REACT_APP_LANDCARE_API_BASEURI || '';
    //this.landholdings = this.getDefaultLandholdings();
  }


  getAllLandholdings = async (accessToken: string) : Promise<any> => {

    let responseData = {};

      const config : AxiosRequestConfig = {
        headers: { 
          'Authorization': `Bearer ${accessToken}`
        }
      };

      const landholdingsUrl = `${this.baseUrl}holdings`;
      const response = await axios.get(landholdingsUrl, config);

      responseData = response.data.landholdings;
      return responseData;
  }

  getLandholdingById = async (accessToken: string, landholdingId: string) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const landholdingsUrl = `${this.baseUrl}holdings?id=${landholdingId}`;
    const response = await axios.get(landholdingsUrl, config);

    if(response && response.data && response.data.landholdings && response.data.landholdings.length === 1) {
      responseData = response.data.landholdings[0];
    }
    
    return responseData;
  }

  getAllLandholdingsByOrgId = async (accessToken: string, orgId: string) : Promise<any> => {

    let responseData = [];

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const landholdingsUrl = `${this.landcareUrl}landholdings?orgId=${orgId}`;
    const response = await axios.get(landholdingsUrl, config);

    if(response && response.data && response.data.landHoldings) {
      responseData = response.data.landHoldings;
    }
    
    return responseData;
  }

  deleteLandholding = async (accessToken: string, landholdingId: string) : Promise<any> => {
    const config = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    const landholdingsUrl = `${this.baseUrl}holdings?id=${landholdingId}`;
    const response = await axios.delete(landholdingsUrl, config);
    return response.data;
  };

  getMediaFilesById = async (accessToken: string, containerId: string, module: string, moduleRefId: string,  id?: string, asFile?: boolean ) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    // console.log('container ID in service: ', containerId)
    // console.log('folderID in service: ', moduleRefId)
    // console.log('ID for getMedia: ', id)
  
    let landMediaUrl = `${this.baseUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}`;
    if(id && id.length > 0) {
      if(asFile) {
        landMediaUrl = `${landMediaUrl}&id=${id}&asFile=true`;
      }
      else {
        landMediaUrl = `${landMediaUrl}&id=${id}`;
      }
    } /*else {
      const mediafiles: any = []
      return mediafiles
    }*/
    
    const response = await axios.get(landMediaUrl, config);

    responseData = response;
    return responseData;
  }

  downloadMediaFileById = (accessToken: string, containerId: string, module:string, moduleRefId: string, id: string) => {

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
      responseType: 'blob',
      timeout: 30000
    };
  
    const landMediaUrl = `${this.baseUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}&id=${id}&asFile=true`;
   
    return axios.get(landMediaUrl, config);
  }

  uploadMediaFilesById = async (accessToken: string, containerId: string, module: string, moduleRefId: string, files: Array<any>) : Promise<any> => {
    let responseData = {};
    let formData = new FormData();

    //This file is here from Browse
    console.log('Files in update: ', files);

    for(var i = 0; i< files.length; i++)
    {
      formData.append(files[i].type, files[i], files[i].name);
    }    
    
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    //landholderId=KaushTest&subFolder=Media
    const fileUploadUrl = `${this.baseUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}`;
    const uploadResponse = await axios.post(fileUploadUrl, formData, config);

    responseData = uploadResponse.data;

    console.log('Upload response data: ', responseData)

    return responseData;
  }

  deleteMediaFilesById = async (accessToken: string, containerId: string, moduleId: string, moduleRefId: string,  fileId: string ) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const deleteMediaUrl = `${this.baseUrl}media?container=${containerId}&module=${moduleId}&moduleRefId=${moduleRefId}&id=${fileId}`;

    const response = await axios.delete(deleteMediaUrl, config);

    responseData = response.data;
    return responseData;
  }

  postLandholding = async (accessToken: string, payload: ILandholding) : Promise<any> => {
    const config = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    
    // On Creation, the _id needs to be set to undefined or MongoDb ignores create new record
    console.log('CREATE ACCESS TOKEN: ', accessToken);
    console.log('Obj. in create: ', payload)
    const landholdingObj: ILandholding = {
      _id: undefined,
      correlationId: payload.correlationId,
      organisationId: payload.organisationId,
      name: payload.name,
      userId: payload.userId ? payload.userId : "userID",
      addresses : [],
      address: {
        addressType: AddressType.default,
        country: "Australia",
        fullAddress: "Full address: ",
        isDirty: true,
        lat: "",
        lon: "",
        line1: payload.address?.line1 || "",
        line2: payload.address?.line2 || "",
        postCode: payload.address?.postCode || "",
        state: payload.address?.state || "",
        streetNumber: "",
        streetType: "",
        streetName: "",
        suburb: ""
      },
      postalAddress: {
        addressType: AddressType.default,
        country: "Australia",
        fullAddress: "Full address: ",
        isDirty: true,
        lat: "",
        lon: "",
        line1: payload.postalAddress?.line1 || "",
        line2: payload.postalAddress?.line2 || "",
        postCode: payload.postalAddress?.postCode || "",
        state: payload.postalAddress?.state || "",
        streetNumber: "",
        streetType: "",
        streetName: "",
        suburb: ""
      }, 
      cadastralInformation: payload.cadastralInformation ? payload.cadastralInformation : "",
      currentUseDescription: payload.currentUseDescription ? payload.currentUseDescription : "",
      projectStyles: {
        isConservationBiodiversity : payload.projectStyles?.isConservationBiodiversity ? payload.projectStyles?.isConservationBiodiversity : false,
        isConversionOfLowProductiveAndDegradedLand: payload.projectStyles?.isConversionOfLowProductiveAndDegradedLand ? payload.projectStyles?.isConversionOfLowProductiveAndDegradedLand : false,
        isRevegetationBlockPlanting: payload.projectStyles?.isRevegetationBlockPlanting ? payload.projectStyles?.isRevegetationBlockPlanting : false,
        isRevegetationLinearBeltPlanting: payload.projectStyles?.isRevegetationLinearBeltPlanting ? payload.projectStyles?.isRevegetationLinearBeltPlanting : false,
        isSaltLandsPlanting: payload.projectStyles?.isSaltLandsPlanting ? payload.projectStyles?.isSaltLandsPlanting : false,
        isPaddockTrees: payload.projectStyles?.isPaddockTrees ? payload.projectStyles?.isPaddockTrees : false,
        isCarbonOffset: payload.projectStyles?.isCarbonOffset ? payload.projectStyles?.isCarbonOffset : false,
        isWaterQualityOffset: payload.projectStyles?.isWaterQualityOffset ? payload.projectStyles?.isWaterQualityOffset : false,
        isRiverRiparianRestoration: payload.projectStyles?.isRiverRiparianRestoration ? payload.projectStyles?.isRiverRiparianRestoration : false,
        isWetlandRestoration: payload.projectStyles?.isWetlandRestoration ? payload.projectStyles?.isWetlandRestoration : false,
        isErosionControl: payload.projectStyles?.isErosionControl ? payload.projectStyles?.isErosionControl : false,
        isOther: payload.projectStyles?.isOther ? payload.projectStyles?.isOther : false,
        isOtherDescription: payload.projectStyles?.isOtherDescription ? payload.projectStyles?.isOtherDescription : ""
      },
      availableSizePercentage: payload.availableSizePercentage ? payload.availableSizePercentage : 0,
      soilTypes: {
        isClayAndSilts: payload.soilTypes?.isClayAndSilts ? payload.soilTypes?.isClayAndSilts : false,
        isSand: payload.soilTypes?.isSand ? payload.soilTypes?.isSand : false,
        isGravel: payload.soilTypes?.isGravel ? payload.soilTypes?.isGravel : false,
        isLoam: payload.soilTypes?.isLoam ? payload.soilTypes?.isLoam : false,
        isRocky: payload.soilTypes?.isRocky ? payload.soilTypes?.isRocky : false,
        isOutCroppingRock: payload.soilTypes?.isOutCroppingRock ? payload.soilTypes?.isOutCroppingRock : false,
        isOther: payload.soilTypes?.isOther ? payload.soilTypes?.isOther : false, //isSDOther
        isOtherDescription: payload.soilTypes?.isOtherDescription ? payload.soilTypes?.isOtherDescription : ""
      },
      furtherInformationOrComments: payload.furtherInformationOrComments ? payload.furtherInformationOrComments : "",
      mapGraphItems: payload.mapGraphItems ? payload.mapGraphItems : [],
      createdBy: payload.userId ? payload.userId : "userID",
      updatedBy: payload.userId ? payload.userId : "userID"//,
      //createdAt: new Date(),
      //updatedAt: new Date()
    }

    try {
      const response = await axios.post<any> (`${this.baseUrl}holdings`, landholdingObj, config);
      if (response.data.code && response.data.code === 11000) {
        throw({ customCode: 999 })
      }
      else {
        return response.data.result;
      }
    }
    catch (ex: any) {
      throw(ex);
    }
  };

  putLandholding = async (accessToken: string, payload: ILandholding, organisationId?: string) : Promise<any> => {
    const config = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    let landholdingConfig: any = {
      _id: payload._id,
      organisationId: payload.organisationId,
      name: payload.name,
      userId: payload.userId ? payload.userId : "userID",
      address: {
        country: "Australia",
        fullAddress: "Full address: ",
        lat: "",
        lng: "",
        line1: payload.address?.line1 || "",
        line2: payload.address?.line2 || "",
        postCode: payload.address?.postCode|| "",
        state: payload.address?.state || "",
        streetNumber: "",
        streetType: "",
        streetName: "",
        suburb: ""
      },
      postalAddress: {
        country: "Australia",
        fullAddress: "Full address: ",
        lat: "",
        lng: "",
        line1: payload.postalAddress?.line1 || "",
        line2: payload.postalAddress?.line2 || "",
        postCode: payload.postalAddress?.postCode || "",
        state: payload.postalAddress?.state || "",
        streetNumber: "",
        streetType: "",
        streetName: "",
        suburb: ""
      },
      cadastralInformation: payload.cadastralInformation ? payload.cadastralInformation : "",
      currentUseDescription: payload.currentUseDescription ? payload.currentUseDescription : "",
      projectStyles: {
        isConservationBiodiversity: payload.projectStyles?.isConservationBiodiversity ? payload.projectStyles?.isConservationBiodiversity : false,
        isConversionOfLowProductiveAndDegradedLand: payload.projectStyles?.isConversionOfLowProductiveAndDegradedLand ? payload.projectStyles?.isConversionOfLowProductiveAndDegradedLand : false,
        isRevegetationBlockPlanting: payload.projectStyles?.isRevegetationBlockPlanting ? payload.projectStyles?.isRevegetationBlockPlanting : false,
        isRevegetationLinearBeltPlanting: payload.projectStyles?.isRevegetationLinearBeltPlanting ? payload.projectStyles?.isRevegetationLinearBeltPlanting : false,
        isSaltLandsPlanting: payload.projectStyles?.isSaltLandsPlanting ? payload.projectStyles?.isSaltLandsPlanting : false,
        isPaddockTrees: payload.projectStyles?.isPaddockTrees ? payload.projectStyles?.isPaddockTrees : false,
        isCarbonOffset: payload.projectStyles?.isCarbonOffset ? payload.projectStyles?.isCarbonOffset : false,
        isWaterQualityOffset: payload.projectStyles?.isWaterQualityOffset ? payload.projectStyles?.isWaterQualityOffset : false,
        isRiverRiparianRestoration: payload.projectStyles?.isRiverRiparianRestoration ? payload.projectStyles?.isRiverRiparianRestoration : false,
        isWetlandRestoration: payload.projectStyles?.isWetlandRestoration ? payload.projectStyles?.isWetlandRestoration : false,
        isErosionControl: payload.projectStyles?.isErosionControl ? payload.projectStyles?.isErosionControl : false,
        isOther: payload.projectStyles?.isOther ? payload.projectStyles?.isOther : false,
        isOtherDescription: payload.projectStyles?.isOtherDescription ? payload.projectStyles?.isOtherDescription : ""
      },
      availableSizePercentage: payload.availableSizePercentage ? payload.availableSizePercentage : 0,
      soilTypes: {
        isClayAndSilts: payload.soilTypes?.isClayAndSilts ? payload.soilTypes?.isClayAndSilts : false,
        isSand: payload.soilTypes?.isSand ? payload.soilTypes?.isSand : false,
        isGravel: payload.soilTypes?.isGravel ? payload.soilTypes?.isGravel : false,
        isLoam: payload.soilTypes?.isLoam ? payload.soilTypes?.isLoam : false,
        isRocky: payload.soilTypes?.isRocky ? payload.soilTypes?.isRocky : false,
        isOutCroppingRock: payload.soilTypes?.isOutCroppingRock ? payload.soilTypes?.isOutCroppingRock : false,
        isOther: payload.soilTypes?.isOther ? payload.soilTypes?.isOther : false,
        isOtherDescription: payload.soilTypes?.isOtherDescription ? payload.soilTypes?.isOtherDescription : ""
      },
      furtherInformationOrComments: payload.furtherInformationOrComments ? payload.furtherInformationOrComments : "",
      mapGraphItems: payload.mapGraphItems, //? landholding.mapGraphItems : [],
      createdBy: payload.userId ? payload.userId : "userID",
      updatedBy: payload.userId ? payload.userId : "userID"//,
      //createdAt: new Date(),
      //updatedAt: new Date()
    }

    const landholdingsUrl = `${this.baseUrl}holdings?id=${payload._id}`
                            + (organisationId ? `&orgId=${organisationId}` : '');

    try {
      const response = await axios.put<any>(landholdingsUrl, landholdingConfig, config);
      return response.data.result;
    }
    catch (ex: any) {
      throw(ex);
    }
  };
 
  showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    showMessage(message, MessageModeEnum.ERROR);
    console.log(message);
  }

  getPolygonFiles = async (accessToken: string, moduleRefId: string) : Promise<any> => {
    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
  
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
    
    const autoApi = `${this.landcareUrl}media?container=${containerName}&module=${ModiaFileModules.LandHoldingPolygons}&moduleRefId=${moduleRefId}`;
    try {
      const response = await axios.get(autoApi, config);
      return response?.data.mediaFiles;
    }
    catch (ex: any) {
      this.showComposedMessage('getLandCarerFiles', autoApi, ex);
    }
  };  
}

export default LandholdingsApiService;