import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

interface Props {}

interface State {
  isOpen: boolean
}

class Footer extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
    <footer className="App-Footer">
        <div style={{ paddingTop: '20px', zIndex: 1000}}>
          &copy; Copyright Greening Australia 2020
        </div>
    </footer>
    );
  }
}

export default Footer;
