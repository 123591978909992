import { cloneDeep } from "lodash";
import proj4 from "proj4";
import shpwrite from "shp-write-multi";

import { IFeatureCollection, IFeatureId, IFeatureIdCollection } from "../Components/ArcGIS/BaseMap/IFeatureCollection";
import { downloadZipFile, waitUntil } from "./HelperFunctions";

export const exportSHPFile = (fileName: string, geoJSON: any) => {
    const options = {
        folder: 'shapes'
    };
    const zipContents = shpwrite.zip(geoJSON, options);
    downloadZipFile(fileName + '.zip', zipContents);
};

export const getFeatureCollection = (mapGraphItems: any[]): IFeatureIdCollection[] => {
    let collIds: IFeatureIdCollection[] = [];
    let collIndex: number;
    let featureIds: IFeatureId[];

    mapGraphItems.forEach((item: any) => {
        collIndex = collIds.findIndex((coll: IFeatureId) => coll.id === item.collId);
        if (collIndex === -1) {
            collIds.push({ id: item.collId, featureIds: [{ id: item.featureId }]});
        }
        else {
            if (item.featureId >= 0) {
                featureIds = collIds[collIndex].featureIds;
                if (featureIds.findIndex(((feature: IFeatureId) => feature.id == item.featureId)) === -1) {
                    featureIds.push({ id: item.featureId });
                }
            }
        }
    });

    return collIds;
};


export const getCenterPoint = (item: any) => {
    let rings: any[];
    let minLat = 0, maxLat = 0, minLng = 0, maxLng = 0;

    rings = item?.geometry && item.geometry.rings ? item.geometry.rings[0] : [];
    if (rings?.length) {
        minLat = rings[0][0];
        maxLat = rings[0][0];
        minLng = rings[0][1];
        maxLng = rings[0][1];

        rings.forEach((ring: any[]) => {
            if (minLat > ring[0]) minLat = ring[0];
            if (maxLat < ring[0]) maxLat = ring[0];
            if (minLng > ring[1]) minLng = ring[1];
            if (maxLng < ring[1]) maxLng = ring[1];
        });
    }

    return [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
};

export const getGeometryItemsWithLocations = async (geometryItems: any[], collIds: IFeatureIdCollection[], addressLocator: any): Promise<any[]> => {
    let shapes: any[];
    let totalFeatures = 0;
    let featureCount = 0;

    await collIds.forEach(async (coll: IFeatureIdCollection) => {
        if (coll.id >= 0) {
            totalFeatures += coll.featureIds.length;

            await coll.featureIds.forEach(async (feature: IFeatureId) => {
                shapes = geometryItems.filter((item: any) => (item.collId === coll.id) && (item.featureId === feature.id));
                const maxAreaShape = shapes.reduce((prev, curr) => curr.area > prev.area ? curr : prev);

                if (!maxAreaShape.centerPoint) {
                    maxAreaShape.centerPoint = getCenterPoint(maxAreaShape);
                }

                const centerPoint = maxAreaShape.centerPoint;
                //const centerPoint = proj4('EPSG:3857', 'EPSG:4326', [point[0], point[1]]);

                if (!maxAreaShape.address || (maxAreaShape.address === 'undefined')) {
                    const centerPoint2 = proj4('EPSG:3857', 'EPSG:4326', [centerPoint[0],centerPoint[1]]);
                    const params = { location: centerPoint2 };
                    const response = await addressLocator.locationToAddress(params);
                    maxAreaShape.title = response.address;
                    maxAreaShape.address = response.address;
                }
                
                featureCount++;
            });
        }
        else {
            shapes = geometryItems.filter((item: any) => item.collId == undefined);

            await shapes.forEach(async (shape: any) => {
                if (!shape.centerPoint) {
                    shape.centerPoint = getCenterPoint(shape);
                }

                if (!shape.address || (shape.address === 'undefined')) {
                    const centerPoint2 = proj4('EPSG:3857', 'EPSG:4326', [shape.centerPoint[0],shape.centerPoint[1]]);
                    const params = { location: centerPoint2 };
                    const response = await addressLocator.locationToAddress(params);
                    shape.title = response.address;
                    shape.address = response.address;
                }
            });
        }
    });
    
    let intervalCount = 0;
    return new Promise((resolve: any) => {
        const interval = setInterval(() => {
            if ((featureCount === totalFeatures) || (intervalCount > 10)) {
                clearInterval(interval);
                resolve(geometryItems);
            }
    
            intervalCount++;
        }, 1000);    
    })
}