import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Row, Col, Button, Spinner, Modal } from "react-bootstrap";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import NoteAdd, { INoteAddResult } from "./NoteAdd";
import { selectLandCareFormFields } from "../../../Store/Selectors/rootSelector";
import { addLandCarerNote, deleteLandCarerNote, deleteMediaAttachment, downloadMediaAttachment, landCareList, landCareStartLoading, landCareStopLoading, updateLandCareNoteField, uploadMediaAttachment } from "../../../Store/Reducers/LandCareSlice";
import { IMetaData } from "../../../Store/Reducers/ModuleConfigSlice";
import { selectFileUploaded, selectUserOrgContacts } from "../../../Store/Selectors/landCareSelectors";
import { notesSchema } from "../../../Helpers/Schema/NotesSchema";
import { validateField } from "../../../Helpers/Validator/validationHelper";
import { ILandCarerNote, ILandCareUserOrgContact } from "../../../Models/LandCare/LandCare";
import { spinnerStyle } from '../../../Helpers/constants';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getMediaFilesById } from "../../../Store/Api/LandCareApi";
import FileUploadModal from "../../../Helpers/UI/FileUploadModal";
import DeleteModal from "../../../Helpers/UI/DeleteModal";




interface INotesList {
  getAccessToken: Function;
  my_notes: ILandCarerNote[];
}

const NotesList: React.FC<any> = (props: INotesList) => {

  const CURRENT_COMPONENT = 'landcarenotes';
  const configFormFields = useSelector(selectLandCareFormFields);
  const { currentLandCare, isLoading } = useSelector(landCareList);
  const userOrgContacts = useSelector(selectUserOrgContacts);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [focusInfo, setFocusInfo] = useState({} as any);
  const [ cellErrors, setCellErrors ] = useState({} as any);
  const [ showErrorMessage, setShowErrorMessage ] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteNoteModal, setDeleteNoteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [noteId, setNoteId] = useState<string>("");

  const dispatch = useDispatch();

  // FileUploadModal
  const [showFileAddModal, setFileAddShowModal] = useState<boolean>(false);

  let accessToken = '';

  useEffect(() => {
    const refreshObj = async () => {
      accessToken = await props.getAccessToken();
    };

    refreshObj();
  }, [props.getAccessToken])

  interface Options {
    [key: string]: string;
  }

  const generateContactModeFilterOptions = (configFormFields: IMetaData[]) => {
    if (configFormFields) {
      var selectOptionsDynamic: Options = {};

      const selectOptionsMetaData: IMetaData = configFormFields?.find(
        (f: IMetaData) => f.metaId === "landcareContactMode"
      ) as IMetaData;
      const selectOptionsDynamicArray = selectOptionsMetaData?.metaValues;

      // needs to be refactored / improved
      selectOptionsDynamicArray?.forEach(
        (option) => (selectOptionsDynamic[option.name] = option.name)
      );
      return selectOptionsDynamic;
    } else {
      // return an empty obj
      return {};
    }
  };

  const generateLandCarerOrgContactsFilterOptions = (landCarerOrgContacts : ILandCareUserOrgContact[]) => {
    if (landCarerOrgContacts) {
      var selectOptionsDynamic: Options[] = [];

      // jm: needs to be refactored / improved
      landCarerOrgContacts?.forEach(
        (option) => (  
          selectOptionsDynamic.push({value: option.id, label: option.firstName + " " + option.lastName}) 
        )
      );
      return selectOptionsDynamic;
    } else {
      // return an empty obj
      return {};
    }
  };

  const generateDropdownMode = (configFormFields: IMetaData[]) => {
    if (configFormFields) {
      var selectOptionsDynamic: Options[] = [];

      const selectOptionsMetaData: IMetaData = configFormFields?.find(
        (f: IMetaData) => f.metaId === "landcareContactMode"
      ) as IMetaData;
      const selectOptionsDynamicArray = selectOptionsMetaData?.metaValues;

      // needs to be refactored / improved
      selectOptionsDynamicArray?.forEach(
        (option) => ( selectOptionsDynamic.push({value: option.name, label: option.name}) )
      );
      return selectOptionsDynamic;
    } else {
      return [];
    }
  };
  
  const fieldCheck = (fieldName: string, rowNo: number) => {
    return (fieldName === focusInfo.fieldName) && (rowNo === focusInfo.rowNo);
  };

  const columnFormatter = (fieldName: string, cell: any, row: ILandCarerNote, rowNo: number, formatExtraData: any) => {
    const check = isSaving && fieldCheck(fieldName, rowNo);
    const tooltip = 'Double-click the cell to edit';

    const columnStyle: any = (fieldName === 'notes') ?
      {
        maxHeight: "70px",
        overflowY: 'auto',
        overflowX: 'hidden'
      }
      :
      {};

    return (
        <div style={columnStyle} key={fieldName} title={tooltip}>{cell}</div>
    );
};

  const generateLandCarerOrgContactsOptions = (landCarerOrgContacts : ILandCareUserOrgContact[], cellInfo : any) => {
    if (landCarerOrgContacts) {
      var selectOptionsDynamic: Options[] = [];
      const cellInfoId : string = cellInfo?.row?.userOrgContactId;

      // jm: needs to be refactored / improved
      landCarerOrgContacts?.forEach(
        (option) => (  
          selectOptionsDynamic.push({value: option.id, label: option.firstName + " " + option.lastName, selected: option.id === cellInfoId ? "selected" : "" }) 
        )
      );
      return selectOptionsDynamic;
    } else {
      // return an empty obj
      return {};
    }
  }

  function dateFormatter(cell: string, row: any, rowIndex: number, formatExtraData: any) {
    let date_obj = new Date(Date.parse(row.updatedAt))
    return <p>{date_obj.toLocaleDateString() + " " + date_obj.toLocaleTimeString().slice(0,-3)}</p>;
  };

  function combineUserOrgContactName(
    fieldName: string,
    cell: string,
    row: any,
    rowIndex: number,
    formatExtraData: any
  ) {
      const check = isSaving && fieldCheck(fieldName, rowIndex);
      const style = {...spinnerStyle, width: 'inherit', display: (check ? '' : 'none')};
      const tooltip = 'Double-click the cell to edit';

    if (row?.userOrgContact && row?.userOrgContact.length) {

      return (
        <>
          <span style={style}>
            <Spinner animation="border" role="status" size="sm" />
          </span>
          <span key={fieldName} title={tooltip}>{row.userOrgContact[0].firstName}&nbsp;{row.userOrgContact[0].lastName}</span>
        </>
      );
    } else {
      return (
        <>
          <span style={style}>
            <Spinner animation="border" role="status" size="sm" />
          </span>
          <span key={fieldName} title={tooltip}>-</span>
        </>
      );
    }

    return <></>;
  }

  function combineUserName(
    cell: string,
    row: any,
    rowIndex: number,
    formatExtraData: any
  ) {
    if (row?.createdByUser && row?.createdByUser.length) {
      return <p>{row.createdByUser[0].firstName}</p>;
    }

    return <></>;
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDeleteNoteCloseModal = () => {
    setDeleteNoteModal(false)
  }

  const onBlur = async (
    oldVal: string,
    newVal: string,
    row: ILandCarerNote,
    column: any,
    done: Function
  ) => {
    if (newVal !== oldVal) {
      const noteId = row.id;
      const fieldName = column.dataField;
      const rowNo = mynotes.findIndex(
        (note: ILandCarerNote) => note.id === noteId
      );
      
      try {
        validateField(notesSchema, fieldName, newVal);
        setIsSaving(true);
        setFocusInfo({fieldName, rowNo});
        const stopFn = () => {
          setIsSaving(false);
          setFocusInfo({});
        };
        const accessToken = props.getAccessToken();
        await dispatch(updateLandCareNoteField(accessToken, noteId, fieldName, newVal, stopFn));
        
      } catch(err: any) {
        const errors: string[] = err.errors.map((err: string) => err.replace('this', column.text));
        const cellErrors: any = {
            [rowNo]: { [fieldName]: errors }
        };
        
        let errorStr = '';
        errors.forEach((err: string) => errorStr += '\u2022 ' + err + '\n');

        toast(errorStr, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeButton: false,
            closeOnClick: true,
            draggable: false,
            progress: undefined,
            style: { color: 'white', backgroundColor: '#f57070' }
        });
    
        setShowErrorMessage(true);
        setCellErrors(cellErrors);
        done(false);
      }
    }

    return { async: true };
  };

  const columns = [
    {
      dataField: "updatedAt",
      text: "Date",
      headerStyle: () => ({ width: "15%" }),
      formatter: dateFormatter,
      editable: () => {
        return false;
      },
    },
    {
      dataField: "createdByUser[0].firstName",
      text: "Created By",
      headerStyle: () => ({ width: "15%" }),
      formatter: combineUserName,
      editable: () => {
        return false;
      },
      sort: true,
    },
    {
      dataField: "userOrgContactId",
      text: "Ref ",
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell: any, row: ILandCarerNote, rowIndex: number, formatExtraData: any) => 
        combineUserOrgContactName("userOrgContactId", cell, row, rowIndex, formatExtraData),
      editor: {
        type: Type.SELECT,
        getOptions:  (setOptions: any, info : any) => {
          return generateLandCarerOrgContactsOptions(userOrgContacts, info)
        }
      },
      filter: selectFilter({
        options: generateLandCarerOrgContactsFilterOptions(userOrgContacts),
      }),
      sort: true,
      sortFunc: (a: string, b : string, order: string, dataField : string,  rowA: ILandCarerNote, rowB : ILandCarerNote) => {
        const valA :string = (rowA?.userOrgContact &&  rowA?.userOrgContact.length) ? rowA?.userOrgContact[0].firstName : "";
        const valB : string = (rowB?.userOrgContact && rowB?.userOrgContact.length) ? rowB?.userOrgContact[0].firstName : "";
        if (order === 'asc') {
          return valA.localeCompare(valB);
        }
        else {
          return valB.localeCompare(valA);
        }
      }
    },
    {
      dataField: "contactMode",
      text: "Mode ",
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell: any, row: ILandCarerNote, rowIndex: number, formatExtraData: any) => 
        columnFormatter("contactMode", cell, row, rowIndex, formatExtraData),
      editor: {
        type: Type.SELECT,
        getOptions:  () => {
          return generateDropdownMode(configFormFields)
        }
      },
      filter: selectFilter({
        options: generateContactModeFilterOptions(configFormFields),
      })
    },
    {
      dataField: "notes",
      text: "Note",
      editor: {
        type: Type.TEXTAREA,
      },
      formatter: (cell: any, row: ILandCarerNote, rowIndex: number, formatExtraData: any) => 
        columnFormatter("notes", cell, row, rowIndex, formatExtraData),
    },
    {
      dataField: "files",
      text: "Files",
      headerStyle: () => ({ width: "8%" }),
      isDummyField: true,
      formatter: (cell: any, row: ILandCarerNote, rowIndex: number) => {
        return (
          <div>
            
            <i
              className="fa fa-paperclip gerx-hand-pointer"
              title={(row.files && row.files.length) ? "Click to replace file" : "Click to attach file to note"}
                onClick={() => 
                {
                  console.log("jm: trying to set the noteId ", row.id);
                  setNoteId(row.id);
                  setFileAddShowModal(true);
                }
              }
            />
            &nbsp;
            { row.files && row.files.length > 0 && row.files[0] &&
              <i
                className="fa fa-download gerx-hand-pointer"
                title={"Click to download file"}
                onClick={() =>
                  {
                    downloadMediaFile(currentLandCare.id, row.files[0]);
                  }
                }
              />
            } 
            &nbsp;
            { row.files && row.files.length > 0 && row.files[0] &&
              <i
                className="fa fa-trash gerx-hand-pointer"
                title={"Click to delete file"}
                onClick={() =>
                  {
                    showModalHandler(row.files[0], row.id);
                  }
                }
              />
            }
          </div>
        );
      },
      editable: () => {
        return false;
      },
    },
    {
      dataField: "files",
      text: "Delete",
      headerStyle: () => ({ width: "8%" }),
      isDummyField: true,
      editable: () => {
        return false;
      },
      formatter: (cell: any, row: ILandCarerNote, rowIndex: number) => {
        return (
          <div>
              <i
                className="fa fa-trash gerx-hand-pointer"
                title={"Click to delete file"}
                onClick={() =>
                  {
                    deleteNoteModalHandler(row.id);
                  }
                }
              />
          </div>
        );
      },
    }
  ];

  let mynotes = props.my_notes.map((notes: ILandCarerNote) => ({ ...notes }));

  const downloadMediaFile = async (moduleRefId: string, id: string) => {
    
    if(id) {
      const accessToken = await props.getAccessToken();
      let response = await getMediaFilesById(accessToken, CURRENT_COMPONENT, moduleRefId, id);
      let fileName = response.data.mediaFile.name;
      await dispatch(downloadMediaAttachment(accessToken, CURRENT_COMPONENT, moduleRefId, id, fileName, document, window));
    }
  }

  const showModalHandler = (fileId: string, noteId: string) => {
    setShowModal(true);
    setItemToDelete(fileId);
    setNoteId(noteId);
  }

  const deleteNoteModalHandler = (noteId: string) => {
    setDeleteNoteModal(true);
    setNoteId(noteId);
  }

  const deleteAttachment = async () => {
    const accessToken = await props.getAccessToken();
    await dispatch(deleteMediaAttachment(accessToken, CURRENT_COMPONENT, currentLandCare.id, itemToDelete, true, noteId, "files"));
    setShowModal(false);
  }

  const deleteNote = async () => {
    const accessToken = await props.getAccessToken();
    await dispatch(deleteLandCarerNote(accessToken, currentLandCare.id, noteId));
    setDeleteNoteModal(false);
  }

  let noteAddRef: any = useRef();

  const validateAndSave = async () => {
      const result: INoteAddResult = await noteAddRef?.current?.validate();
      
      if (result.isValid) {
          const accessToken = await props.getAccessToken();

          let data = { ...result.data, landCarerId: currentLandCare.id };
          dispatch(landCareStartLoading());
          await dispatch(addLandCarerNote(accessToken, data));
          dispatch(landCareStopLoading());

          clearAddForm();
      }
  }

  const clearAddForm = () => {
    noteAddRef?.current?.clear();
    setIsAdding(false);
  };

  // this is used for the FileAdd modal
	const handleFileCloseModal = () => {
    setFileAddShowModal(false)
  }
  
  const rowStyleFormatter = (row: any, index: number) => {
    return isSaving ? { cursor: 'process' } : {};
  };

  return (
    <div key="main" className="py-3">
      <Row>
        <Col xl="12" className="gerx-button-group text-right">
          <Button className="btn-primary" style={{ display: isAdding ? "none" : "" }} onClick={() => setIsAdding(true)}>Add Note</Button>
          <Button className="btn-success" style={{ display: isAdding ? '' : 'none' }} onClick={() => validateAndSave()}>Save</Button>
          <Button className="btn-secondary" style={{ display: isAdding ? '' : 'none' }} onClick={() => { setIsAdding(false); clearAddForm() }}>Cancel</Button>
        </Col>
      </Row>

      <div key={"add"} className={"gerx-add " + (isAdding ? "mt-3 opened" : "closed")}>
        <NoteAdd getAccessToken={props.getAccessToken} inputData={{}} ref={noteAddRef} landCarerOrgContacts={userOrgContacts} onCancel={() => setIsAdding(false)} noteId={noteId}/>
      </div>

      <FileUploadModal  
        getAccessToken={props.getAccessToken}
        showModal={showFileAddModal} 
        closeModal={handleFileCloseModal}
        fileUploadComponent={CURRENT_COMPONENT}
        noteId={noteId}
        />

      {/* // deleting a file from a note ; on noteslist */}
      <DeleteModal onHide={handleCloseModal} onDelete={deleteAttachment} showModal={showModal}>
      </DeleteModal>

      {/* // deleting a NOTE from noteslist */}
      <DeleteModal onHide={handleDeleteNoteCloseModal} onDelete={deleteNote} 
        showModal={showDeleteNoteModal}
        optionalCondition={showDeleteNoteModal} 
        optionalFragment={
          <>
            <br></br><br></br>
            <p style={{color: 'red'}}>
              Since you are deleting a note, if there is a file attached to it, 
              the file will still exist, and can be accessed/deleted from the Files Page
            </p>
          </>
        }
      >
      </DeleteModal>

      
      <BootstrapTable
        keyField="id"
        data={mynotes}
        columns={columns}
        noDataIndication="No records found"
        classes={"gerx-notes table table-bordered table-striped table-sm " + (isSaving ? "saving" : "")}
        rowStyle={rowStyleFormatter}
        cellEdit={cellEditFactory({
          mode: "dbclick",
          blurToSave: true,
          beforeSaveCell: onBlur,
        })}
        bootstrap4
        filter={filterFactory()}
      />
    </div>
  );
};

export default NotesList;
