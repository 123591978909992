import React from "react"
import { Popover } from 'react-bootstrap';
import { toast, ToastOptions } from 'react-toastify';
import * as yup from 'yup';
import { AssertsShape, TypeOfShape } from 'yup/lib/object';

export interface IValidationField {
    name: string,
    title: string,
    validation: any,
    width?: string
};

export const getErrorsByFieldName = (fieldMapping: IValidationField[], errors: string[]): any => {
    let retObj: any = {};

    errors && errors.forEach((err: string) => {
        fieldMapping.forEach((field: IValidationField) => {
            if (field.name === err.substr(0, field.name.length)) {
                if (!retObj[field.name]) retObj[field.name] = [];
                retObj[field.name].push(err.replace(field.name, field.title));
            }
        });
    });

    return retObj;
};

 export const renderTooltip = (fieldName: string, errors: string[]): any => {
    return (
        <Popover id={fieldName} placement='top'>
            <Popover.Content>
                {errors.map((err: string) => {
                    return (
                        <>
                            {errors.length > 1 ? <span>&#8226;&nbsp;</span> : null}
                            {err}<br/>
                        </>
                    )
                })}
            </Popover.Content>
        </Popover>
    );
  };

export const validateField = (schema: yup.ObjectSchema<any, Record<string, any>, TypeOfShape<any>, AssertsShape<any>>, fieldName: string, fieldValue: string) => (
    yup.reach(schema, fieldName, {}, {}).validateSync(fieldValue)
);

export const showMessage = (message: string, mode: MessageModeEnum = MessageModeEnum.WARNING) => {
    //const messageModes = ['success', 'error', 'warning', 'info'];

    const msgObj: ToastOptions = {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeButton: true,
        closeOnClick: false,
        draggable: false,
        progress: undefined
    };

    let toastObj: React.ReactText;

    switch (mode) {
        case MessageModeEnum.SUCCESS: toastObj = toast.success(message, msgObj); break;
        case MessageModeEnum.ERROR: toastObj = toast.error(message, msgObj); break;
        case MessageModeEnum.WARNING: toastObj = toast.warning(message, msgObj); break;
        case MessageModeEnum.INFO: toastObj = toast.info(message, msgObj); break;
    }

    return toastObj;
};

export enum MessageModeEnum {
    SUCCESS,
    ERROR,
    WARNING,
    INFO
};