import {configureStore, getDefaultMiddleware, combineReducers, Action}  from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';

import {LandCareReducers} from './Reducers/LandCareSlice';
import {LandHoldingReducers} from './Reducers/LandHoldingsSlice';
import {LocalStoreReducers} from './Reducers/LocalStoreSlice';
import {ModuleConfigDataReducers} from './Reducers/ModuleConfigSlice';
// import {NotesCommentsReducers} from './Reducers/NotesCommentsSlice';
import {LandCareAutoSuggestReducers} from './Reducers/LandCareAutoSuggestSlice';
import {UserOrgAutoSuggestReducers} from './Reducers/UserOrgAutoSuggestSlice';
import { LandCareChatReducer } from './Reducers/LandCareChatSlice';
import { SignalRReducer } from './Reducers/SignalRSlice';
import { userOrgReducers } from './Reducers/UserOrgSlice';

const RootStore = configureStore({
  reducer: combineReducers({
    localStore: LocalStoreReducers,
    landCarers : LandCareReducers,
    landCareAutoSuggest: LandCareAutoSuggestReducers,
    landHoldings : LandHoldingReducers,
    moduleConfigData: ModuleConfigDataReducers,
    LandCareChat: LandCareChatReducer,
    SignalRInfo: SignalRReducer,
    userOrgAutoSuggest: UserOrgAutoSuggestReducers,
    userOrg: userOrgReducers
  }),
  middleware: getDefaultMiddleware(),
  devTools: true
});

export default RootStore;

export type RootState = ReturnType<typeof RootStore.getState>;
export type AppDispatch = typeof RootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;