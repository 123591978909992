import axios from 'axios';
//import parseLink, { Links } from 'parse-link-header'

import LandholdingsApiService from '../../Services/LandHoldingsApiService' //'../Services/LandholdingsApiService';
import { ILandholding } from '../../Models/LandHoldings/LandHoldings' //'../Models/Landholdings/Landholding';
import { IMediaFileResponse } from '../../Models/MediaFiles/IMediaFile';

const landHoldingApiService = new LandholdingsApiService();

export const getAllLandHoldingsFromApi = async (accessToken: string) : Promise<ILandholding[]> => {
  return await landHoldingApiService.getAllLandholdings(accessToken);
};

export const getAllLandHoldingsByOrgId = async (accessToken: string, orgId: string) : Promise<ILandholding[]> => {
  return await landHoldingApiService.getAllLandholdingsByOrgId(accessToken, orgId);
};

export const getLandHoldingByIdFromApi = async (accessToken: string, landholdingId: string) : Promise<ILandholding> => {  
  return await landHoldingApiService.getLandholdingById(accessToken, landholdingId);
};

export const postLandHolding = async (accessToken: string, payload: any) : Promise<ILandholding> => {  
  return await landHoldingApiService.postLandholding(accessToken, payload);
};

export const putLandHolding = async (accessToken: string, landholding: ILandholding, organisationId?: string) : Promise<ILandholding> => {  
  return await landHoldingApiService.putLandholding(accessToken, landholding, organisationId);
};

export const deleteLandHolding = async (accessToken: string, landholdingId: string) : Promise<ILandholding> => {  
  return await landHoldingApiService.deleteLandholding(accessToken, landholdingId);
};

export const uploadMediaFilesById = async (accessToken: string, containerId: string, module: string, moduleRefId: string, files: Array<any>) : Promise<IMediaFileResponse> => {
  return await landHoldingApiService.uploadMediaFilesById(accessToken, containerId, module, moduleRefId, files);
};

export const getPolygonFiles =  async (accessToken: string, moduleRefId: string) : Promise<any> => {
  return await landHoldingApiService.getPolygonFiles(accessToken, moduleRefId);
};
