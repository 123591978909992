import React from 'react';
import './Environment.scss';

const Environment = () => {
    let envText = '';
    let envClass = '';

    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'DEV':
            envText = 'Development Environment';
            envClass = 'dev';
            break;
        case 'UAT':
            envText = 'UAT Environment';
            envClass = 'uat';
            break;
    }
    return (
        !envClass ? <></> :
        <div className={"environment " + envClass}>
            {envText}
        </div>
    );
};

export default Environment;