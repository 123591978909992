import React from 'react';
import { loadModules } from 'esri-loader';
import './PlantingSiteMap.css';
import lodash from 'lodash';
import { IBaseMapPoint, IBaseMapPolygon, IBaseMapPolyline } from '../../Models/BaseMap/BaseMapModels';
import { ILandCarer, IPlantingSite } from '../../Models/LandCare/LandCare';

interface IPlantingSiteMapProps {
  currentLandCare: ILandCarer;
  currentProjectSiteId: string;
  currentProjectSite: IPlantingSite;
  //listGeometryItems?: Array<any>; 
  getAccessToken: Function;
}

interface IPlantingSiteMapState {
  status?: string;
  listGeometryItems?: Array<any>; 
  showModal: boolean;
  itemToDelete: any;
}

class PlantingSiteMap extends React.Component<IPlantingSiteMapProps, IPlantingSiteMapState> {

  plantingSiteMap: any;
  plantingSiteMapView: any;
  graphicsLayer: any;
  otherPlantingSitesGraphicsLayer: any;
  plantingSitesGraphicsLayer: any;
  esriMapObject:any;
  addressLocator:any;
  listGeometryItems: Array<any>;
  listOtherPolygonItems: Array<any>;
  listPolygonItems: Array<any>;

  constructor(props: IPlantingSiteMapProps, state: IPlantingSiteMapState) {
    super(props);

    this.listGeometryItems = [];
    if(this.props.currentLandCare.landHolding && this.props.currentLandCare.landHolding[0].mapGraphItems ) {
      this.listGeometryItems = this.props.currentLandCare.landHolding[0].mapGraphItems;
    }

    this.listPolygonItems = [];
    this.listOtherPolygonItems = [];

    // draw other plantingsites as listOtherPolygonItems
    if(this.props.currentLandCare.plantingSites && this.props.currentLandCare.plantingSites.length > 0) {
      this.props.currentLandCare.plantingSites.forEach((projectSite) => {
        if(projectSite.id !== this.props.currentProjectSite.id && projectSite.polygon && projectSite.polygon.length > 0) {
            this.listOtherPolygonItems.push(projectSite.polygon[0]);
          }
      });
    } 

    // if current plantingsite has polygon, then draw it (in green)
    if(this.props.currentProjectSite && this.props.currentProjectSite.polygon && this.props.currentProjectSite.polygon.length > 0) {
      this.listPolygonItems = this.props.currentProjectSite.polygon;
    }
    
    this.state = {
      status: 'loading',
      listGeometryItems: [],
      showModal: false,
      itemToDelete: {}
    }
  }

  componentDidMount() {
    //console.log('Obj in mapper: ', this.props.thisItem)
    //console.log('Reducer obj: ', this.props.thisObj.mapGraphItems)

    loadModules([
      'esri/views/MapView', 
      'esri/WebMap',
      'esri/widgets/Search',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      "esri/layers/FeatureLayer",
      'esri/widgets/Sketch',
      "esri/config",
      "esri/tasks/Locator",
      "esri/geometry/geometryEngine"
    ])
    .then(([MapView, WebMap, Search, Graphic, GraphicsLayer, FeatureLayer, Sketch, Config, Locator, GeometryEngine]) => {
      this.esriMapObject = {};
      this.esriMapObject.MapView = MapView;
      this.esriMapObject.WebMap = WebMap;
      this.esriMapObject.Search = Search;
      this.esriMapObject.Graphic = Graphic;
      this.esriMapObject.GraphicsLayer = GraphicsLayer;
      this.esriMapObject.FeatureLayer = FeatureLayer;
      this.esriMapObject.Sketch = Sketch;
      this.graphicsLayer = new GraphicsLayer();
      this.plantingSitesGraphicsLayer = new GraphicsLayer();
      this.otherPlantingSitesGraphicsLayer = new GraphicsLayer();
      this.esriMapObject.Config = Config;
      this.esriMapObject.Locator = Locator;
      this.esriMapObject.GeometryEngine = GeometryEngine;

      this.renderMap();
      this.renderMapWidgets();
      this.renderMapGraphicItems();
      this.renderProjectSiteItems();
      this.renderOtherProjectSiteItems();
      this.plantingSiteMap.add(this.graphicsLayer);
      this.plantingSiteMap.add(this.plantingSitesGraphicsLayer);
      this.plantingSiteMap.add(this.otherPlantingSitesGraphicsLayer);

      //Add the GeometryItems to the State
      this.setState({ 
        status: "done",
        //listGeometryItems: this.props.listGeometryItems
        listGeometryItems: this.listGeometryItems
      });

    })
    .catch(err => {
      // handle any errors
      console.error(err);
    });   
  }

  renderPlantingSiteMap = () => {
    if(this.state.status === 'loading') {
      return <div>loading</div>;
    }
    else {
      return <></>;
    }
  }

  renderMap = () => {

    const mapId = "86265e5a4bbb4187a59719cf134e0018";  // ESRI imaging hybrid (see https://www.arcgis.com/home/item.html?id=86265e5a4bbb4187a59719cf134e0018)
    const mapCenter = [133.77, -28.27]; // best value for mapId center at mapZoomLevel
		const mapZoomLevel = 5.5; // best zoom level of centered map

    // then we load a web map from an id
    this.plantingSiteMap = new this.esriMapObject.WebMap({
      portalItem: { // autocasts as new PortalItem()
        id: mapId
      }
    });

    this.plantingSiteMapView = new this.esriMapObject.MapView({
      map: this.plantingSiteMap,
      container: 'viewDiv',
      zoom: mapZoomLevel,
      center: mapCenter
    });

    
    this.plantingSiteMapView.on("layerview-create", (event: any) => {
        var info = "<br> <span> layerview-create </span> - " + event.layer.title + " is " + event.layer.loadStatus;
        console.log("Info: ", info);
        this.zoomToFirstPolygonFeature();
      });

    // const that = this;
    // that.graphicsLayer.when(function() {
    //   return that.graphicsLayer.queryExtent();
    // })
    // .then(function(response:any) {
    //   that.plantingSiteMapView.goTo(response.extent);
    // });

    // this.plantingSiteMapView.whenLayerView(that.graphicsLayer)
    //   .then(function(layerView:any){
    //     layerView.watch("updating", function(val:any){
    //       if(!val) {
    //         layerView.queryExtent()
    //         .then(function(response:any) {
    //           that.plantingSiteMapView.goTo(response.extent);
    //         });
    //       }
    //     });
    //   });
   
  }

  renderMapWidgets = () => {

    let esriMapObject = this.esriMapObject;
    let addressLocator = this.addressLocator;
    let geometryEngine = this.esriMapObject.GeometryEngine;
    
    //let syncMapPolygons = this.syncListGeometryItems;

    // Setup defaults
    //this.plantingSiteMapView.ui.components = ["zoom", "compass", "home", "attribution"];
    this.plantingSiteMapView.ui.components = ["zoom", "attribution"];    

    // // Search widget
    // var search = new esriMapObject.Search({
    //   view: this.plantingSiteMapView
    // });
    // this.plantingSiteMapView.ui.add(search, "top-left");

    // // Sketch widget
    // let sketch = new esriMapObject.Sketch({
    //   view: this.plantingSiteMapView,
    //   layer: this.graphicsLayer,      
    //   //creationMode: "update" // graphic will be selected as soon as it is created
    // });

    // sketch.visibleElements = {
    //     createTools: {
    //       point: false,
    //       circle: false,
    //       polyline: false,
    //       rectangle: false
    //   },
    //   selectionTools:{   
    //     "lasso-selection": false,
    //     "rectangle-selection": false
    //   }
    // };

    // // Listen to sketchViewModel's create event.
    // sketch.on("create", function(event: any) {

    //   // check if the create event's state has changed to complete indicating the graphic create operation is completed.
    //   if (event.state === "complete") {

    //     if(event.graphic && event.graphic.geometry && event.graphic.geometry.extent) {
    //       //let polygonCentryPoint = [145.70702, -34.09864];
    //       let polygonGraphic = event.graphic;
    //       polygonGraphic.attributes = {};

    //       const polygonCenterPoint = event.graphic.geometry.extent.center
    //       polygonGraphic.attributes.centerPoint = [polygonCenterPoint.latitude, polygonCenterPoint.longitude];

    //       const params = {
    //         location: polygonCenterPoint
    //       };
    //       addressLocator.locationToAddress(params)
    //       .then(async (response: any) => { // Show the address found          
    //         //showAddress(address, evt.mapPoint);
    //         console.log("Found Address:", response.address);
    //         polygonGraphic.attributes.title = response.address;
    //         polygonGraphic.attributes.address = response.address;

    //         //Can use spatial reference of either WGS-84 (wkid: 4326) or Web Mercator Auxiliary Sphere (wkid: 3857)
    //         //This method only works with WGS-84 (wkid: 4326) and Web Mercator (wkid: 3857) spatial references
    //         //var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "acres");
    //         // event.graphic.layer.title = `${response.address} - Area: ${area} Hectares`;
    //         var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "hectares");
    //         console.log("Polygon Area:", area);
    //         polygonGraphic.attributes.area = area;
    //         await syncMapPolygons();

    //       }, (err: any) => { // Show no address found
    //         //showAddress("No address found.", evt.mapPoint);
    //         console.log("Address Error:", err);
    //       });
    //     }
    //   }
    // });

    // this.plantingSiteMapView.ui.add(sketch, "top-right");

  }

  // syncListGeometryItems = async () => {
  //   let listGeometryItems : Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any> = [];
  //   let mapGeometryItems = this.graphicsLayer.graphics;
  //   for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {
  //     let gItem = mapGeometryItems.items[idx];
  //     switch(gItem.geometry.type) {
  //       case "Point": // Lat, Lng
  //       case "point" :
  //         console.log("Got Point as: (Id: " + gItem.uid + ")  ", gItem.geometry);
  //         //graphic.layer.title
  //         listGeometryItems.push({type: "Point",  
  //           id: gItem.uid, 
  //           centerPoint: gItem.attributes?.centerPoint, 
  //           address: gItem.attributes?.address,
  //           title: gItem.attributes?.title, 
  //           area: gItem.attributes?.area, 
  //           lat: gItem.geometry.latitude, 
  //           lng: gItem.geometry.longitude, 
  //           geometry: gItem.geometry});
  //         break;
  //       case "Polyline": // Paths
  //       case "polyline" :
  //         console.log("Got Polyline as: (Id: " + gItem.uid + ")  ", gItem.geometry);
  //         listGeometryItems.push({type: "Polyline",  
  //           id: gItem.uid, 
  //           centerPoint: gItem.attributes?.centerPoint, 
  //           address: gItem.attributes?.address,
  //           title: gItem.attributes?.title, 
  //           area: gItem.attributes?.area, 
  //           paths: gItem.geometry.paths, 
  //           geometry: gItem.geometry});
  //         break;
  //       case "Polygon": // Rings
  //       case "polygon" :
  //         console.log("Got Polygon as: (Id: " + gItem.uid + ")  ", gItem.geometry);
  //         listGeometryItems.push({type: "Polygon",  
  //           id: gItem.uid, 
  //           centerPoint: gItem.attributes?.centerPoint, 
  //           address: gItem.attributes?.address,
  //           title: gItem.attributes?.title, 
  //           area: gItem.attributes?.area, 
  //           rings: gItem.geometry.rings, 
  //           geometry: gItem.geometry});
  //         break;
  //       default: // ??
  //         console.log("Got Item as: (Id: " + gItem.uid + ")  ", gItem.geometry);
  //         listGeometryItems.push({type: "Item",  
  //         id: gItem.uid, 
  //         centerPoint: gItem.attributes?.centerPoint, 
  //         address: gItem.attributes?.address,
  //         title: gItem.attributes?.title, 
  //         area: gItem.attributes?.area, 
  //         geometry: gItem.geometry});
  //         break;
  //     }
  //   }

  //   let stateToUpdate: any = {};
  //   stateToUpdate.status = this.state.status;
  //   stateToUpdate.listGeometryItems = listGeometryItems;

  //   //this.updateDB(stateToUpdate);
  // }

  renderMapGraphicItems = () => {

    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)

    console.log('Polygons in LandCare -> LandHolding: ', this.listGeometryItems);

    if(this.listGeometryItems && this.listGeometryItems.length > 0) {
      this.listGeometryItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item.id, item.geometry.x, item.geometry.y);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item.id, item.paths);
            break;
          case "Polygon":
          case "polygon" :
            this.renderMapPolygons(item.id, item.geometry.rings);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderProjectSiteItems = () => {
    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)
    let spatialReference: any;

    let projectSiteColour = [183, 215, 184, 0.6]; // Green with opacity

    console.log('Polygons in PlantingSiteMap: ', this.listPolygonItems);    

    if(this.listPolygonItems && this.listPolygonItems.length > 0) {
      this.listPolygonItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item.id, item.geometry.x, item.geometry.y, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item.id, item.paths, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          case "Polygon":
          case "polygon" :
            this.renderMapPolygons(item.id, item.geometry.rings, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderOtherProjectSiteItems = () => {
    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)
    let spatialReference: any;

    console.log('Other Polygons in PlantingSiteMap: ', this.listOtherPolygonItems);

    if(this.listOtherPolygonItems && this.listOtherPolygonItems.length > 0) {
      this.listOtherPolygonItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item.id, item.geometry.x, item.geometry.y, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item.id, item.paths, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          case "Polygon":
          case "polygon" :
            this.renderMapPolygons(item.id, item.geometry.rings, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderMapPoints = (pointId: number, x: number, y: number, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {

    console.log("Rendering point on map");
    var simpleMarkerSymbol = {
      type: "simple-marker",
      color: colour ? colour : [192, 192, 192], // default or passed in colour
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };    
    
    var point = {
      type: "point",
      //latitude: lat, //34.0005930608889,
      //longitude: lng // -118.80657463861
      x: x,
      y: y,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };
    
    if(spatialReference) {
      point.spatialReference = spatialReference;
    }
    
    var pointGraphic = new this.esriMapObject.Graphic({
      symbol: simpleMarkerSymbol,
      geometry: point,
      attributes: {
        gId: pointId
      }
    });

    if(customGraphicsLayer) {
      customGraphicsLayer.add(pointGraphic);
    }
    else {
      this.graphicsLayer.add(pointGraphic);
    }
  }

  renderMapLines = (polylineId: number, paths: Array<Array<number>>, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {

    var simpleLineSymbol = {
      type: "simple-line",
      color: colour ? colour : [80, 80, 80], // default or passed in colour
      width: 1.5
    };

    var polyline = {
      type: "polyline",
      paths: paths,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polyline.spatialReference = spatialReference;
    }

    var polylineGraphic = new this.esriMapObject.Graphic({
      symbol: simpleLineSymbol,
      geometry: polyline,
      attributes: {
        gId: polylineId
      }
    });

    //this.graphicsLayer.add(polylineGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polylineGraphic);
    }
    else {
      this.graphicsLayer.add(polylineGraphic);
    }
  }

  renderMapPolygons = (polygonId: number, rings: Array<Array<number>>, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {
    console.log('Rendering polygon on map.')
    var simpleFillSymbol = {
      type: "simple-fill",
      color: colour ? colour : [192, 192, 192, 0.6], // silver, opacity 60% or passed in colour
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };
    
    var polygon = {
      type: "polygon",
      rings: rings,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polygon.spatialReference = spatialReference;
    }
    
    var polygonGraphic = new this.esriMapObject.Graphic({
      symbol: simpleFillSymbol,
      geometry: polygon,
      attributes: {
        gId: polygonId
      }
    });
    
    //this.graphicsLayer.add(polygonGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polygonGraphic);
    }
    else {
      this.graphicsLayer.add(polygonGraphic);
    }
  }

  zoomToFirstPolygonFeature = (customGraphicsLayer?: any) => {

    let hasAtLeastOnePolygon = false;
    let firstPolygon;
    let polygonToCenterAt: any;

    let graphicsLayer = this.graphicsLayer;

    if(customGraphicsLayer) {
      graphicsLayer = customGraphicsLayer;
    }

    if(graphicsLayer && graphicsLayer.graphics && graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = graphicsLayer.graphics;
      for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {

        let gItem = mapGeometryItems.items[idx];
        let feature = gItem.geometry;
        if(feature.type === 'polygon' || feature.type === 'Polygon')
        {
            if(!hasAtLeastOnePolygon) {
              hasAtLeastOnePolygon = true;
              //polygonToCenterAt = {...feature);
              //polygonToCenterAt = Object.assign({}, feature);
              //polygonToCenterAt = JSON.parse(JSON.stringify(feature));
              //firstPolygon = lodash.cloneDeep(feature);
              polygonToCenterAt = lodash.cloneDeep(feature);
              //polygonToCenterAt.rings = feature.rings;

              //console.log("Shape is a polygon...", firstPolygon);
              this.plantingSiteMapView.goTo(feature.extent); //Works!
            }
            // else {
            //   feature.rings[0].forEach( (item: any) => {
            //     polygonToCenterAt.rings[0].push(item);
            //   });
            // }

            // var polygonCenter = feature.extent.center; //Works! Gets Lat/Lon at the point!
            // //https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html
            // this.plantingSiteMapView.center = [polygonCenter.longitude, polygonCenter.latitude];
            // this.plantingSiteMapView.zoom = 8;            
        }
      }
      
      // if(hasAtLeastOnePolygon) {
      //   this.plantingSiteMapView.goTo(polygonToCenterAt.extent); //ToCheck
      // }
    }
  }

  render() {
    return (
      <div id="mapDiv">
        <div id="viewDiv" style={{height: '55vh', width: '100%'}}>{this.renderPlantingSiteMap}</div>
      </div>
    )
  }
}

export default PlantingSiteMap;