import React from 'react';
import { loadModules } from 'esri-loader';
import { ILandCareProjectsMapPoint, ILandCareProjectsMapPolygon, ILandCareProjectsMapPolyline } from "../../Models/LandCareProjectsMap/LandCareProjectsMapModels";
import lodash from 'lodash';

import { Button, Col, Container, Form, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';
import "../App/App.scss";

import { connect } from 'react-redux';
import './LandCareProjectsMap.css';
import { ILandCarer, IPlantingSite } from '../../Models/LandCare/LandCare';
import { landCareList, landCareStartLoading, landCareStopLoading, setCurrentPlantingSite, setLoadingToTrue, updatePlantingSiteField, updatePlantingSiteShallow } from '../../Store/Reducers/LandCareSlice';
import { selectCurrentPlantingSite } from '../../Store/Selectors/landCareSelectors';
import { spinnerStyle } from '../../Helpers/constants';

interface ILandCareProjectsMapProps {
  history: any;
  match: any;
  landCareState: any; //The whole thing!
  getAccessToken: Function;
  patchPlantingSite: Function;
  currentPlantingSite: IPlantingSite;
  patchPlantingSiteShallow: Function;
  setCurrentPlantingSite: Function;
  landCareStartLoading: Function;
  landCareStopLoading: Function;
  isLoading: boolean;
}

interface ILandCareProjectsMapState {
  status?: string;
  currentLandCarer?: any; //ILandCarer;
  currentPlantingSite?: IPlantingSite; 
  showModal: boolean;
  itemToDelete: any;
  locatePolygon: any;
}


const apiKey = process.env.REACT_APP_ARCGIS_API_KEY;

class LandCareProjectsMap extends React.Component<ILandCareProjectsMapProps, ILandCareProjectsMapState> {

  currentLandCare: ILandCarer;
  currentPlantingSite: IPlantingSite;

  landCareProjectsMap: any;
  landCareProjectsMapView: any;
  
  graphicsLayer: any;
  plantingSitesGraphicsLayer: any;
  otherPlantingSitesGraphicsLayer: any;
  
  esriMapObject:any;
  addressLocator:any;

  listGeometryItems: any;
  listPolygonItems: any;
  listOtherPolygonItems: any;

  //params
  landCareId: string;
  projectSiteId: string;

  constructor(props: ILandCareProjectsMapProps, state: ILandCareProjectsMapState) {
    super(props);
    
    // Retrieve url params
    //  let userId = this.props.match.params.userId;

     this.landCareId = this.props.match.params.landCareId as string;
     this.projectSiteId = this.props.match.params.projectSiteId as string;

    // LandCare props from state
    this.currentLandCare = this.props.landCareState.currentLandCare;
    this.currentPlantingSite = this.props.landCareState.currentPlantingSite;

    this.listGeometryItems = [];
    if(this.currentLandCare && this.currentLandCare.landHolding && this.currentLandCare.landHolding.length > 0) {
      this.listGeometryItems = this.currentLandCare.landHolding[0].mapGraphItems ?  this.currentLandCare.landHolding[0].mapGraphItems : [];
    }
    
    this.listPolygonItems = [];
    this.listOtherPolygonItems = [];

    if(this.currentLandCare.plantingSites && this.currentLandCare.plantingSites.length > 0) {
      this.currentLandCare.plantingSites.forEach((projectSite) => {
        if(projectSite.id !== this.currentPlantingSite.id && projectSite.polygon && projectSite.polygon.length > 0) {
            this.listOtherPolygonItems.push(projectSite.polygon[0]);
          }
      });
    }

    // // if current plantingsite has polygon, then draw it (in green)
    if(this.currentPlantingSite && this.currentPlantingSite?.polygon && this.currentPlantingSite.polygon.length > 0) {
      this.listPolygonItems = this.currentPlantingSite.polygon;
    }

    this.state = {
      // isLoading: false,
      status: 'loading',
      currentPlantingSite: {} as IPlantingSite,
      showModal: false,
      itemToDelete: {},
      locatePolygon: {}
    }

  }

  

  componentDidMount() {

    loadModules([
      'esri/views/MapView', 
      'esri/WebMap',
      'esri/widgets/Search',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/widgets/Sketch',
      "esri/config",
      "esri/tasks/Locator",
      "esri/geometry/geometryEngine"
    ])
    .then(([MapView, WebMap, Search, Graphic, GraphicsLayer, Sketch, Config, Locator, GeometryEngine]) => {
      this.esriMapObject = {};
      this.esriMapObject.MapView = MapView;
      this.esriMapObject.WebMap = WebMap;
      this.esriMapObject.Search = Search;
      this.esriMapObject.Graphic = Graphic;
      this.esriMapObject.GraphicsLayer = GraphicsLayer;
      this.esriMapObject.Sketch = Sketch;
      this.esriMapObject.Config = Config;
      this.esriMapObject.Locator = Locator;
      this.esriMapObject.GeometryEngine = GeometryEngine;
      
      //Setup ARC GIS API Key
      this.esriMapObject.Config.apiKey = apiKey;

      //Setup Locator
      this.addressLocator = new this.esriMapObject.Locator ({
        url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
      });

      this.graphicsLayer = new GraphicsLayer();
      this.plantingSitesGraphicsLayer = new GraphicsLayer();
      this.otherPlantingSitesGraphicsLayer = new GraphicsLayer();

      this.renderMap();
      this.renderMapWidgets();
      this.renderMapGraphicItems();
      this.renderProjectSiteItems();
      this.renderOtherProjectSiteItems();

      this.landCareProjectsMap.add(this.graphicsLayer);
      this.landCareProjectsMap.add(this.plantingSitesGraphicsLayer);
      this.landCareProjectsMap.add(this.otherPlantingSitesGraphicsLayer);

      this.props.setCurrentPlantingSite(this.currentLandCare.plantingSites, this.currentPlantingSite.id);
    })
    .catch(err => {
      // handle any errors
      console.error(err);
    });
  }  

  renderLandCareProjectsMap = () => {
    if(this.state.status === 'loading') {
      return <div>loading</div>;
    }
    else {
      return <></>;
    }
  }


  blurFn = async (fieldName: string, fieldValue: any) => {
    const accessToken = await this.props.getAccessToken();
    this.props.landCareStartLoading();

    const endFn = () => {
      this.props.landCareStopLoading();
    };

    // patch with api call
    this.props.patchPlantingSite(this.currentPlantingSite.id, fieldName, fieldValue, accessToken, endFn );
  };

  changeFn = async (fieldName: string, fieldValue: any) => {
    this.props.patchPlantingSiteShallow(this.currentPlantingSite.id, fieldName, fieldValue);
  }

  renderMap = () => {


    const mapId = "86265e5a4bbb4187a59719cf134e0018";  // ESRI imaging hybrid (see https://www.arcgis.com/home/item.html?id=86265e5a4bbb4187a59719cf134e0018)
		const mapCenter = [133.77, -28.27]; // best value for mapId center at mapZoomLevel
		const mapZoomLevel = 5.5; // best zoom level of centered map

    // then we load a web map from an id
    this.landCareProjectsMap = new this.esriMapObject.WebMap({
      portalItem: { // autocasts as new PortalItem()
        id: mapId
      }
    });

    // and we show that map in a container w/ id #viewDiv 
    this.landCareProjectsMapView = new this.esriMapObject.MapView({
      map: this.landCareProjectsMap,
      container: 'viewDiv',
			zoom: mapZoomLevel,
			center: mapCenter
    });

    this.landCareProjectsMapView.on("layerview-create", (event: any) => {
      var info = "<br> <span> layerview-create </span> - " + event.layer.title + " is " + event.layer.loadStatus;
      console.log("Info: ", info);
      this.zoomToFirstPolygonFeature();
    });
  }

  renderMapWidgets = () => {

    let props = this.props;
    let currentLandCare = this.currentLandCare;
    let currentPlantingSite  = this.currentPlantingSite;
    let plantingSitesGraphicsLayer  = this.plantingSitesGraphicsLayer;

    let esriMapObject = this.esriMapObject;
    let addressLocator = this.addressLocator;
    let geometryEngine = this.esriMapObject.GeometryEngine;
    
    let syncPolygons = this.syncPolygonItem;

    // Setup defaults
    this.landCareProjectsMapView.ui.components = ["zoom", "compass", "home", "attribution"];

    // Search widget
    var search = new esriMapObject.Search({
      view: this.landCareProjectsMapView
    });
    this.landCareProjectsMapView.ui.add(search, "top-left");

    // Sketch widget
    let sketch = new esriMapObject.Sketch({
      view: this.landCareProjectsMapView,
      //layer: this.graphicsLayer,      
      layer: this.plantingSitesGraphicsLayer,
      creationMode: "single"
      //creationMode: "update" // graphic will be selected as soon as it is created
    });

    sketch.visibleElements = {
        createTools: {
          point: false,
          circle: false,
          polyline: false,
          rectangle: false
      },
      selectionTools:{   
        "lasso-selection": false,
        "rectangle-selection": false
      },
      undoRedoMenu: false
    };

    // Listen to sketchViewModel's create event.
    sketch.on("create", async (event: any) => {

      // check if the create event's state has changed to complete indicating the graphic create operation is completed.
      if (event.state === "complete") {

        console.log("ESRI event object:", event);

        if(event.graphic && event.graphic.geometry && event.graphic.geometry.extent) {
          //let polygonCentryPoint = [145.70702, -34.09864];
          let polygonGraphic = event.graphic;
          polygonGraphic.attributes = {};

          const polygonCenterPoint = event.graphic.geometry.extent.center
          polygonGraphic.attributes.centerPoint = [polygonCenterPoint.latitude, polygonCenterPoint.longitude];

          const params = {
            location: polygonCenterPoint
          };
          addressLocator.locationToAddress(params)
          .then(async (response: any) => { // Show the address found          
            //showAddress(address, evt.mapPoint);
            console.log("Found Address:", response.address);
            polygonGraphic.attributes.title = response.address;
            polygonGraphic.attributes.address = response.address;

            //Can use spatial reference of either WGS-84 (wkid: 4326) or Web Mercator Auxiliary Sphere (wkid: 3857)
            //This method only works with WGS-84 (wkid: 4326) and Web Mercator (wkid: 3857) spatial references
            //var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "acres");
            // event.graphic.layer.title = `${response.address} - Area: ${area} Hectares`;
            var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "hectares");
            console.log("Polygon Area:", area);
            polygonGraphic.attributes.area = area;
            
            //await syncMapPolygons();
            
            //Set the polygon graphic to the PlantingSite.polygon
            //currentPlantingSite.polygon = []; //Clear existing polygon
            // Remove all other planting sites from the view, as we can only have 1 polygon per planting site
            const graphicItems = plantingSitesGraphicsLayer.graphics.items;
            //console.log('graphicItems: ', graphicItems);
            for(let idx = 0; idx < graphicItems.length - 1; idx++) {
              let gItem = graphicItems[idx];
              plantingSitesGraphicsLayer.graphics.remove(gItem);
              //console.log('Item deleted: ', gItem.uid, gItem.id);
            }

            let polygon = syncPolygons(plantingSitesGraphicsLayer);
            console.log("Retreived polygon list as:", polygon);

            //currentPlantingSite.polygon.push();
            const accessToken = await props.getAccessToken();
            // const siteId = currentPlantingSite?.id !== undefined  ? currentPlantingSite?.id : currentLandCare.plantingSites[0].id;
            setLoadingToTrue(true); 
            const setLoaderToFalse = () => {
              setLoadingToTrue(false);  
            };
            //await patchPlantingSite(accessToken, siteId, 2, polygon, currentPlantingSite, currentLandCare.plantingSites);
            props.patchPlantingSite(currentPlantingSite.id, "polygon", polygon, accessToken, setLoaderToFalse);
            
          }, (err: any) => { // Show no address found
            //showAddress("No address found.", evt.mapPoint);
            console.log("Address Error:", err);
          });
        }        
      }
    });

    this.landCareProjectsMapView.ui.add(sketch, "top-right");
  }

  renderMapGraphicItems = () => {

    //Render BaseMap from LandCare => LandHoldings[0] => MapGraphItems[0] => geometry    
    let listGeometryItemsRender: any = [];
    
    if(this.currentLandCare && this.currentLandCare.landHolding && this.currentLandCare.landHolding.length > 0 ) {
      listGeometryItemsRender = this.currentLandCare.landHolding[0].mapGraphItems;
    }

    if(listGeometryItemsRender && listGeometryItemsRender.length > 0) {
      listGeometryItemsRender.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            //this.renderMapPoints(item.id, item.geometry.x, item.geometry.y);
            this.renderMapPoints(item);
            break;
          case "Polyline":
          case "polyline":
            //this.renderMapLines(item.id, item.paths);
            this.renderMapLines(item);
            break;
          case "Polygon":
          case "polygon" :
            //this.renderMapPolygons(item.id, item.rings);
            this.renderMapPolygons(item);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderProjectSiteItems = () => {
    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)
    let spatialReference: any;

    let projectSiteColour = [183, 215, 184, 0.6]; // Green with opacity

    console.log('Polygons in PlantingSiteMap: ', this.listPolygonItems);    

    if(this.listPolygonItems && this.listPolygonItems.length > 0) {
      this.listPolygonItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          case "Polygon":
          case "polygon" :
            this.renderMapPolygons(item, spatialReference, this.plantingSitesGraphicsLayer, projectSiteColour);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  renderOtherProjectSiteItems = () => {
    //const listGeometryItemsRender = this.props.listGeometryItems
    //console.log('Polygons in PlantingSiteMap: ', listGeometryItemsRender)
    let spatialReference: any;

    console.log('Other Polygons in PlantingSiteMap: ', this.listOtherPolygonItems);

    if(this.listOtherPolygonItems && this.listOtherPolygonItems.length > 0) {
      this.listOtherPolygonItems.forEach((item: any) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            this.renderMapPoints(item, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          case "Polyline":
          case "polyline":
            this.renderMapLines(item, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          case "Polygon":
          case "polygon" :
            this.renderMapPolygons(item, spatialReference, this.otherPlantingSitesGraphicsLayer);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapPoints = (pointId: number, x: number, y: number, spatialReference?: any) => {
  renderMapPoints = (pointItem: any, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {

    console.log("Rendering point on map");
    var simpleMarkerSymbol = {
      type: "simple-marker",
      color: colour ? colour : [192, 192, 192], // default or passed in colour
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };    
    
    var point = {
      type: "point",
      //latitude: lat, //34.0005930608889,
      //longitude: lng // -118.80657463861
      x: pointItem.geometry.x,
      y: pointItem.geometry.y,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };
    
    if(spatialReference) {
      point.spatialReference = spatialReference;
    }
    
    var pointGraphic = new this.esriMapObject.Graphic({
      symbol: simpleMarkerSymbol,
      geometry: point,
      attributes: {
        gId: pointItem.id
      }
    });

    //this.graphicsLayer.add(pointGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(pointGraphic);
    }
    else {
      this.graphicsLayer.add(pointGraphic);
    }
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapLines = (polylineId: number, paths: Array<Array<number>>, spatialReference?: any) => {
  renderMapLines = (polylineToAdd: any, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {

    var simpleLineSymbol = {
      type: "simple-line",
      color: colour ? colour : [80, 80, 80], // default or passed in colour
      width: 1.5
    };

    var polyline = {
      type: "polyline",
      paths: polylineToAdd.paths,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polyline.spatialReference = spatialReference;
    }

    var polylineGraphic = new this.esriMapObject.Graphic({
      symbol: simpleLineSymbol,
      geometry: polyline,
      attributes: {
        gId: polylineToAdd.id
      }
    });

    //this.graphicsLayer.add(polylineGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polylineGraphic);
    }
    else {
      this.graphicsLayer.add(polylineGraphic);
    }
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapPolygons = (polygonId: number, rings: Array<Array<number>>, spatialReference?: any) => {
  renderMapPolygons = (polygonToAdd: any, spatialReference?: any, customGraphicsLayer?: any, colour? : any) => {
    
    console.log('Rendering polygon on map.')
    var simpleFillSymbol = {
      type: "simple-fill",
      color: colour ? colour : [192, 192, 192, 0.6], // silver, opacity 60% or passed in colour
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };
    
    var polygon = {
      type: "polygon",
      rings: polygonToAdd.geometry.rings,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polygon.spatialReference = spatialReference;
    }
    
    var polygonGraphic = new this.esriMapObject.Graphic({
      symbol: simpleFillSymbol,
      geometry: polygon,
      attributes: {
        gId: polygonToAdd.id,
        centerPoint: polygonToAdd.centerPoint,
        title: polygonToAdd.title,
        area: polygonToAdd.area,
        address: polygonToAdd.address
      }
    });
    
    //this.graphicsLayer.add(polygonGraphic);
    if(customGraphicsLayer) {
      customGraphicsLayer.add(polygonGraphic);
    }
    else {
      this.graphicsLayer.add(polygonGraphic);
    }
  }

  zoomToFirstPolygonFeature = (customGraphicsLayer?: any) => {

    let hasAtLeastOnePolygon = false;
    let firstPolygon;
    let polygonToCenterAt: any;

    let graphicsLayer = this.graphicsLayer;

    if(customGraphicsLayer) {
      graphicsLayer = customGraphicsLayer;
    }

    if(graphicsLayer && graphicsLayer.graphics && graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = graphicsLayer.graphics;
      for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {

        let gItem = mapGeometryItems.items[idx];
        let feature = gItem.geometry;
        if(feature.type === 'polygon' || feature.type === 'Polygon')
        {
            if(!hasAtLeastOnePolygon) {
              hasAtLeastOnePolygon = true;
              //polygonToCenterAt = {...feature);
              //polygonToCenterAt = Object.assign({}, feature);
              //polygonToCenterAt = JSON.parse(JSON.stringify(feature));
              //firstPolygon = lodash.cloneDeep(feature);
              polygonToCenterAt = lodash.cloneDeep(feature);
              //polygonToCenterAt.rings = feature.rings;

              //console.log("Shape is a polygon...", firstPolygon);
              this.landCareProjectsMapView.goTo(feature.extent); //Works!
            }
            // else {
            //   feature.rings[0].forEach( (item: any) => {
            //     polygonToCenterAt.rings[0].push(item);
            //   });
            // }

            // var polygonCenter = feature.extent.center; //Works! Gets Lat/Lon at the point!
            // //https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html
            // this.plantingSiteMapView.center = [polygonCenter.longitude, polygonCenter.latitude];
            // this.plantingSiteMapView.zoom = 8;            
        }
      }
      
      // if(hasAtLeastOnePolygon) {
      //   this.plantingSiteMapView.goTo(polygonToCenterAt.extent); //ToCheck
      // }
    }
  }
  

  syncPolygonItem = (graphicsLayer: any) => {

    let listPolygonItems : Array<ILandCareProjectsMapPoint | ILandCareProjectsMapPolyline | ILandCareProjectsMapPolygon | any> = [];
    let mapGeometryItems = graphicsLayer.graphics;
    for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {
      let gItem = mapGeometryItems.items[idx];
      switch(gItem.geometry.type) {
        case "Point": // Lat, Lng
        case "point" :
          console.log("Got Point as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          //graphic.layer.title
          listPolygonItems.push({type: "Point",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            lat: gItem.geometry.latitude, 
            lng: gItem.geometry.longitude, 
            geometry: gItem.geometry});
          break;
        case "Polyline": // Paths
        case "polyline" :
          console.log("Got Polyline as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listPolygonItems.push({type: "Polyline",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            paths: gItem.geometry.paths, 
            geometry: gItem.geometry});
          break;
        case "Polygon": // Rings
        case "polygon" :
          console.log("Got Polygon as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listPolygonItems.push({type: "Polygon",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            rings: gItem.geometry.rings, 
            geometry: gItem.geometry});
          break;
        default: // ??
          console.log("Got Item as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listPolygonItems.push({type: "Item",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            geometry: gItem.geometry});
          break;
      }
    }

    return listPolygonItems;
  }

  renderMapGeometryItems = () => {

    let fragment = <></>;

    const currentPlantingSite = this.state.currentPlantingSite;
    // if(listGeometryItems && listGeometryItems.length > 0) {
    if(currentPlantingSite && currentPlantingSite.polygon && currentPlantingSite.polygon.length > 0) {
      fragment = (
      <>
        {/* {listGeometryItems.map((item: any, idx: number) => (
          <ListGroup.Item key={item.id}>
              <span className="float-right">
                <FontAwesomeIcon icon={faSyncAlt} size="1x" data-value={item.id} onClick={() => this.refreshPolygonAttributes(item)} /> &nbsp;
                <FontAwesomeIcon icon={faSearch} size="1x" data-value={item.id} onClick={() => this.locatePolygonHandler(item)} /> &nbsp;
                <FontAwesomeIcon icon={faTrash} size="1x" data-value={item.id} onClick={() => this.showModalHandler(item)} />
              </span>
            <span>Location: {item.address} [Area: {(Math.round(item.area * 100) / 100).toFixed(2)} hectares]</span>
          </ListGroup.Item>
        ))} */}
      </>
      );
    }
    else {      
      fragment = <>
        {/* <ListGroup.Item>No shape items found.</ListGroup.Item> */}
      </>;
    }

    return fragment;
  }

  deleteGraphItem = (event:any) => {

    console.log('Delete clicked')
    let itemIdT = this.state.itemToDelete;
    console.log('Item clicked: ', itemIdT)

    event.preventDefault();

    let isDeleteSuccess = false;

    const graphicItems = this.graphicsLayer.graphics.items;
    console.log('graphicItems: ', graphicItems)
    for(let idx = 0; idx < graphicItems.length; idx++) {
      let gItem = graphicItems[idx];
      if(gItem.uid === itemIdT.id) {
        this.graphicsLayer.graphics.remove(gItem);
        isDeleteSuccess = true;
        console.log('Item deleted: ', gItem.uid, gItem.id);
        break;
      }
    } 
    
    // if(isDeleteSuccess) {
    //   this.syncListGeometryItems();
    // }

    this.setState({showModal: false})
  }

  handleCloseModal = () => {
    this.setState({ showModal: false})
  }

  handleCloseMap = () => {
    console.log("Close map event received");

    //http://localhost:3000/projects/planting/602279ab5f3916005f14da77
    let path = `/projects/planting/${this.landCareId}`;
    this.props.history.push(path);
  }

  handleClearMap = () => {
    this.plantingSitesGraphicsLayer.removeAll();
  }
  
  showModalHandler = (item: any) => {
    this.setState({ 
      showModal: true,
      itemToDelete: item
    })
  }

  refreshPolygonAttributes = (polygonItem: any) => {

    let esriMapObject = this.esriMapObject;
    let addressLocator = this.addressLocator;
    let geometryEngine = this.esriMapObject.GeometryEngine;   

    //polygonItem.geometry
    if(polygonItem && polygonItem.geometry && polygonItem.geometry.extent) {
      //let polygonCentryPoint = [145.70702, -34.09864];
      // if(!polygonItem.attributes) {
      //   polygonItem.attributes = {};
      // }

      const polygonCenterPoint = polygonItem.geometry.extent.center
      polygonItem.centerPoint = [polygonCenterPoint.latitude, polygonCenterPoint.longitude];

      const params = {
        location: polygonCenterPoint
      };
      addressLocator.locationToAddress(params)
      .then(async (response: any) => { // Show the address found          
        //showAddress(address, evt.mapPoint);
        //console.log("Found Address:", response.address);
        polygonItem.title = response.address;
        polygonItem.address = response.address;

        //Can use spatial reference of either WGS-84 (wkid: 4326) or Web Mercator Auxiliary Sphere (wkid: 3857)
        //This method only works with WGS-84 (wkid: 4326) and Web Mercator (wkid: 3857) spatial references
        //var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "acres");
        // event.graphic.layer.title = `${response.address} - Area: ${area} Hectares`;
        var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonItem.geometry), "hectares");
        console.log("Polygon Area:", area);
        polygonItem.area = area;
        
        // let currListGeometryItems = this.state.listGeometryItems;
        // if(currListGeometryItems) {
        //   for(let idx=0; idx < currListGeometryItems.length; idx++) {
        //     if(currListGeometryItems[idx].id === polygonItem.id) {
        //       currListGeometryItems[idx] = polygonItem;
        //       this.setState({
        //         listGeometryItems: currListGeometryItems
        //       });
        //       return;
        //     }
        //   }          
        // }

      }, (err: any) => { // Show no address found
        //showAddress("No address found.", evt.mapPoint);
        console.log("Address Error:", err);
      });
    }
  }

  locatePolygonHandler = (polygonItem: any) => {
    this.zoomToPolygonFeature(polygonItem);
  }

  zoomToPolygonFeature = (polygonItem: any) => {

    // let hasAtLeastOnePolygon = false;
    // let firstPolygon;
    // let polygonToCenterAt: any;

    if(this.graphicsLayer && this.graphicsLayer.graphics && this.graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = this.graphicsLayer.graphics;

      if(mapGeometryItems && mapGeometryItems.length > 0) {

        if(polygonItem && polygonItem.geometry && polygonItem.geometry.extent) {            
            this.landCareProjectsMapView.goTo(polygonItem.geometry.extent);
        }
        // else {
        //   //Get the latest added item
        //   let itemIdx = mapGeometryItems.length - 1; 
        //   let gItem = mapGeometryItems.items[itemIdx];
        //   if(gItem && gItem.geometry && gItem.geometry.extent) {
        //     this.landCareProjectsMapView.goTo(gItem.geometry.extent);
        //   }
        // }
      }
    }
  }

  

  render() {

    const style = {...spinnerStyle, display: (this.props.landCareState.isLoading ? '' : 'none')};

    return (
      <>  
        <Modal size="lg" show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Choosing delete will permanently delete this item.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.deleteGraphItem}>
              Delete permanently
            </Button>
          </Modal.Footer>
        </Modal>  

        <Container fluid>     
          <div>
            <div>
              <div style={{margin: "0px 20px"}}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Form.Group  >
                        <Form.Label className="bold-labels">Planting Site Name</Form.Label>
                        <div style={style}>
                          <Spinner animation="border" role="status" size="sm" />
                        </div>
                        <Form.Control
                            type="text"
                            value={this.props.currentPlantingSite.name}
                            onChange={(e: any) => this.changeFn("name", e.target.value)}
                            onBlur={(e: any) => this.blurFn("name", e.target.value) }
                        />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={9} xl={9}>
                    <Button className="close-edit-planting-map-btn" variant="success" onClick={this.handleCloseMap}>Close Planting Site Map</Button> 
                  </Col>

                </Row>
              </div>
              
            </div>
            <div id="mapDiv">
              <div id="viewDiv" style={{height: '80vh', width: '100%'}}>{this.renderLandCareProjectsMap}</div>
            </div>
            <div>
              <ListGroup>
                {this.renderMapGeometryItems()}
              </ListGroup>
            </div>
            <div>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
        </Container>
      </>
    )
  }
}

const UpdateMapLandholding = (accessToken: any, id: any, thisObj: any, listGeometryItems: any) => {

}

const mapStateToProps = (state: any) => {
  return {
    landCareState : landCareList(state),
    currentPlantingSite : selectCurrentPlantingSite(state),
    isLoading: landCareList(state).isLoading,
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    patchPlantingSite : (plantingSiteId: string, fieldName: string, fieldValue: string, accessToken: string, endFn: Function) => {
      dispatch(updatePlantingSiteField(accessToken, plantingSiteId, fieldName, fieldValue, endFn));
    },
    patchPlantingSiteShallow : (plantingSiteId: string, fieldName: string, fieldValue: string) => {
      dispatch(updatePlantingSiteShallow(plantingSiteId, fieldName, fieldValue));
    },
    setCurrentPlantingSite : (currentLandCarePlantingSites: IPlantingSite[],siteChosen: IPlantingSite ) => {
      if (siteChosen) {
        dispatch(setCurrentPlantingSite(currentLandCarePlantingSites, siteChosen))
      }
    },
    landCareStartLoading: () => {
      dispatch(landCareStartLoading())
    },
    landCareStopLoading: () => {
      dispatch(landCareStopLoading());
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(LandCareProjectsMap);