import { IBaseMapPoint, IBaseMapPolygon, IBaseMapPolyline } from "../BaseMap/BaseMapModels";

export interface ILandholding {
    _id?: string;
    correlationId: string;
    userId: string;
    name: string;
    organisationId: string;
    
    address: ILandholdingAddress;
    postalAddress: ILandholdingAddress;

    addresses: Array<ILandholdingAddress>;

    cadastralInformation: string;
    currentUseDescription: string;
    furtherInformationOrComments: string;

    projectStyles : ILandholdingProjectStyle;
    soilTypes : ILandholdingSoilType;
    availableSizePercentage: number;
    
    mapGraphItems?: Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any>;

    createdBy: string;
    createdAt?: Date;
    updatedBy: string;
    updatedAt?: Date;
}

export interface ILandholdingPut {
  result: {
    _id?: string;
    userId: string;
    name: string;
    address: ILandholdingAddress;
  
    cadastralInformation: string;
    currentUseDescription: string;
    furtherInformationOrComments: string;
  
    projectStyles : ILandholdingProjectStyle;
    soilTypes : ILandholdingSoilType;
    availableSizePercentage: number;
    
    //mapGraphItems?: Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any>;
  
    createdBy: string;
    createdAt?: Date;
    updatedBy: string;
    updatedAt?: Date;
  };
  _id?: string;
  userId: string;
  name: string;
  address: ILandholdingAddress;

  cadastralInformation: string;
  currentUseDescription: string;
  furtherInformationOrComments: string;

  projectStyles : ILandholdingProjectStyle;
  soilTypes : ILandholdingSoilType;
  availableSizePercentage: number;
  
  //mapGraphItems?: Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any>;

  createdBy: string;
  createdAt?: Date;
  updatedBy: string;
  updatedAt?: Date;
}

export enum AddressType {
  default = "default",
  postal = "postal"
}

export interface ILandholdingAddress {
  id?: string;
  addressType?: AddressType;
  line1?: string;
  line2?: string;
  isDirty?: boolean;
  fullAddress?: string;
  streetNumber?: string;
  streetType?: string;
  streetName?: string;
  suburb?: string;
  state?: string;
  country?: string;
  postCode?: string;
  key?: string;
  lat?: string | number;
  lon?: string | number;
}

export interface ILandholdingProjectStyle {
  isConservationBiodiversity : boolean;
  isConversionOfLowProductiveAndDegradedLand: boolean;
  isRevegetationBlockPlanting: boolean;
  isRevegetationLinearBeltPlanting: boolean;
  isSaltLandsPlanting: boolean;
  isPaddockTrees: boolean;
  isCarbonOffset: boolean;
  isWaterQualityOffset: boolean;
  isRiverRiparianRestoration: boolean;
  isWetlandRestoration: boolean;
  isErosionControl: boolean;
  isOther: boolean;
  isOtherDescription: string;
}

export interface ILandholdingSoilType {
  isClayAndSilts: boolean,
  isSand: boolean,
  isGravel: boolean,
  isLoam: boolean,
  isRocky: boolean,
  isOutCroppingRock: boolean,
  isOther: boolean,
  isOtherDescription: string
}

export interface IReduxForm {
  _id?: string; //not in form
  userId: string; //not in form
  name: string,
  fullAddress: string; //not in form
  line1: string;
  line2?: string; //not in form
  postCode: string;
  streetNumber?: string; //not in form
  streetType?: string; //not in form
  streetName?: string; //not in form
  suburb?: string; //not in form
  state: string;
  country?: string; //not in form
  lat?: string; //not in form
  lng?: string; //not in form
  cadastralInformation: string;
  currentUseDescription: string;
  isConservationBiodiversity: boolean;
  isConversionOfLowProductiveAndDegradedLand: boolean;
  isRevegetationBlockPlanting: boolean;
  isRevegetationLinearBeltPlanting: boolean;
  isSaltLandsPlanting: boolean;
  isPaddockTrees: boolean;
  isCarbonOffset: boolean;
  isWaterQualityOffset: boolean;
  isRiverRiparianRestoration: boolean;
  isWetlandRestoration: boolean;
  isErosionControl: boolean;
  landholdingStyleOther: boolean;
  projectStyleDescription: string;
  availableSizePercentage: number;
  isClayAndSilts: boolean;
  isSand: boolean;
  isGravel: boolean;
  isLoam: boolean;
  isRocky: boolean;
  isOutCroppingRock: boolean;
  soilAndDescriptionOther: boolean;
  soilTypeDescription: string;
  furtherInformationOrComments: string;
  mapGraphItems: Array<any>; //not in form
  createdBy: string; //not in form
  updatedBy: string; //not in form
  createdAt: Date; //not in form
  updatedAt: Date; //not in form
}

export interface IMapStateToProps { 
  landHoldingsReducer: {
    storeLandHoldings: any;
    currentLandHolding: any;
    isFetching: boolean;
    initialValues: any;
    keepDirtyOnReinitialize: boolean;
  }
}


export enum LandholdingFieldNames {
  propertyName = 'name',
  propertyAddress = 'propertyAddress',
  addressLine1 = 'address.line1',
  addressLine2 = 'address.line2',
  addressPostCode = 'address.postCode',
  addressState = 'address.state',
  postalAddressLine1 = 'postalAddress.line1',
  postalAddressLine2 = 'postalAddress.line2',
  postalAddressPostCode = 'postalAddress.postCode',
  postalAddressState = 'postalAddress.state',
  listGeometryItems = 'listGeometryItems',
  
  currentUseDescription = 'currentUseDescription',
  isConservationBiodiversity = 'projectStyles.isConservationBiodiversity',
  isConversionOfLowProductiveAndDegradedLand = 'projectStyles.isConversionOfLowProductiveAndDegradedLand',
  isRevegetationBlockPlanting = 'projectStyles.isRevegetationBlockPlanting',
  isRevegetationLinearBeltPlanting = 'projectStyles.isRevegetationLinearBeltPlanting',
  isSaltLandsPlanting = 'projectStyles.isSaltLandsPlanting',
  isPaddockTrees = 'projectStyles.isPaddockTrees',
  isCarbonOffset = 'projectStyles.isCarbonOffset',
  isWaterQualityOffset = 'projectStyles.isWaterQualityOffset',
  isRiverRiparianRestoration = 'projectStyles.isRiverRiparianRestoration',
  isWetlandRestoration = 'projectStyles.isWetlandRestoration',
  isErosionControl = 'projectStyles.isErosionControl',
  landholdingStyleOther = 'projectStyles.isOther',
  projectStyleDescription = 'projectStyles.isOtherDescription',
  availableSizePercentage = 'availableSizePercentage',
 
  isClayAndSilts = 'soilTypes.isClayAndSilts',
  isSand = 'soilTypes.isSand',
  isGravel = 'soilTypes.isGravel',
  isLoam = 'soilTypes.isLoam',
  isRocky = 'soilTypes.isRocky',
  isOutCroppingRock = 'soilTypes.isOutCroppingRock',
  soilAndDescriptionOther = 'soilTypes.isOther',
  soilTypeDescription = 'soilTypes.isOtherDescription',
}