import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ILandholding } from '../../Models/LandHoldings/LandHoldings';
import { updateLandHoldingSuccess } from '../../Store/Reducers/LandHoldingsSlice';
import { getUserProfile } from '../../Store/Reducers/LocalStoreSlice';
import { selectUserProfile } from '../../Store/Selectors/rootSelector';
import BaseMap from './BaseMap/BaseMap';
import { getLandCareData, resetCurrentLandCare } from '../../Store/Reducers/LandCareSlice';

import './LandCarePropertyMap.scss';
import { selectCurrentLandholding } from '../../Store/Selectors/landholdingSelector';

const LandCarePropertyMap = (props: any) => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  
  const { match: {params} } = props;
  const landCareId = props.match.params.landCareId;
  
  const userProfile = useSelector(selectUserProfile);

  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (!Object.keys(userProfile).length) {
      const refreshObj = async () =>  {
        const accessToken = await props.getAccessToken();
        
        if (accessToken && !Object.keys(userProfile).length) {
          await dispatch(getUserProfile(accessToken));
        }
      };
      
      refreshObj();
    }
  }, [isAuthenticated]);
  
  useEffect(() => {
    const refreshObj = async () => {
      const accessToken = await props.getAccessToken();
      
      if (accessToken && userProfile.id) {
        const successFn = (landholding: ILandholding) => {
          dispatch(updateLandHoldingSuccess(landholding));
        }
        setIsLoading(true);
        await dispatch(getLandCareData(accessToken, userProfile.id, landCareId, [], successFn));
        setIsLoading(false);
      }
    };

    refreshObj();
  }, [userProfile?.id]);

  const landholding = useSelector(selectCurrentLandholding);

  const handleCloseMap = () => {
    dispatch(resetCurrentLandCare());

    let path = `/projects/${landCareId}`;
    props.history.push(path);
  };

  return (
    <div className="property-map">
      <div className="map-btns">
        <Button className="justify-right" variant="success" onClick={handleCloseMap}>Close Property Site Map</Button>
      </div>


      <div id="mapContainer" className="map-size">
      {
        !landholding || !Object.keys(landholding).length ? <></> :
          <BaseMap  getAccessToken={props.getAccessToken}
                    landholding={landholding}
          />
        }
      </div>
      <br/><br/><br/>
    </div>
  );
}


export default LandCarePropertyMap;