import React, {useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

import { ILandholding } from '../../Models/LandHoldings/LandHoldings';
import { clearLandHoldingList, getLandHoldings, getLandHoldingsByOrgId, removeLandHolding} from  '../../Store/Reducers/LandHoldingsSlice';
import { RootState } from '../../Store/RootStore';
import { ConfirmModal } from '../Layout/ConfirmModal';
import './ListLandHoldings.scss';
import { loaderStyle, waitingText } from '../../Helpers/constants';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';
import { selectUserProfile } from '../../Store/Selectors/rootSelector';
import { selectAutoSuggestSearchTerm } from '../../Store/Reducers/LandCareAutoSuggestSlice';

interface IProps {
  orgId: string;
  getAccessToken: Function;
}

const ListLandHoldings : React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(selectAutoSuggestSearchTerm);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ deleteId, setDeleteId ] = useState('');

  const history = useHistory();

  const landholdings = useSelector( (state: RootState) => state.landHoldings);
  const userProfile = useSelector(selectUserProfile);

  useEffect(() => {
    const refreshObj = async () => {
      if (props.orgId && searchTerm.length >= 3) {
        setIsLoading(true);
        const accessToken = await props.getAccessToken();
        const successFn = () => setIsLoading(false);
        dispatch(getLandHoldingsByOrgId(accessToken, props.orgId, successFn));
      }
      else {
        dispatch(clearLandHoldingList());
      }
    };

    refreshObj();
  }, [props.orgId, searchTerm])

  useEffect(() => {
    const refreshObj = async () => {
      if(props.orgId) {
        setIsLoading(true);
        const accessToken = await props.getAccessToken();
        const successFn = () => setIsLoading(false);
        dispatch(getLandHoldingsByOrgId(accessToken, props.orgId, successFn));
      }
    };

    refreshObj();
  }, [props.orgId])

  const deleteGraphItem = async () => {
    const accessToken = await props.getAccessToken();
    const successFn = () => {
      showMessage('Record deleted succesfully', MessageModeEnum.SUCCESS)
    }
    await dispatch(removeLandHolding(accessToken, deleteId, successFn));
    setDeleteId('');
  };

  return (
    <>
      <ConfirmModal showModal={!!deleteId}
                    heading="Delete the property"
                    text="Are you sure you want to delete this property?"
                    handleConfirm={deleteGraphItem.bind(this)}
                    handleClose={() => setDeleteId('')}
                    confirmButtonText="Delete"
        >
      </ConfirmModal>

      <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
        <Container fluid>
          <Row>
            <Col>
              <Row className="list-landholdings">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                        <th><h3>Properties</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    { !landholdings.landHoldings?.length ? <></> :
                      landholdings.landHoldings.map((landholding: ILandholding, index) => {
                      const flag = (landholding.createdBy === userProfile._id);

                      return (
                        <tr>
                          <td>
                            <div>
                              { flag ?
                                <Link to={`/edit-landholding/${landholding._id}/${props.orgId}`}>Property: {landholding.name}</Link>
                                : <>Property: {landholding.name}</>
                              }
                              <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}
                              >
                                { !flag ? <></> :
                                  <span className="delete float-right">
                                    <FontAwesomeIcon icon={faTrash} size="sm" onClick={() => setDeleteId(landholding._id || '')} />
                                  </span>
                                }
                            </OverlayTrigger>

                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>      
            </Row>
            </Col>
          </Row>                
        </Container>
      </LoadingOverlay>
    </>
  );
}

export default ListLandHoldings;