import React from 'react';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron} from 'react-bootstrap';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Version extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <Jumbotron>
        <h4>Version Page</h4>
        <div className="lead">
          <div>[5-Dec-20] Ver 0.0.1 - Initial working sample.</div>
        </div>
      </Jumbotron>
    );
  }
}

export default Version;
