import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Modal } from "react-bootstrap";
import { Expression, JsxFragment } from "typescript";

interface DeleteModalProps {
    onHide: any;
    onDelete: any;
    showModal: boolean;
    optionalCondition?: Expression;
    optionalFragment?: JsxFragment;
}

const DeleteModal: React.FC<any> = (props: DeleteModalProps)  => {    

    return (
        <Modal size="lg" show={props.showModal} onHide={props.onHide}> 
            <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Choosing delete will permanently delete this item.
            { props.optionalCondition && 
                props.optionalFragment
            }        
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                Close
            </Button>
            <Button variant="primary" onClick={props.onDelete}>
                Delete permanently
            </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default DeleteModal;